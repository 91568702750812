var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-file-input',{attrs:{"outlined":"","rules":_vm.uploadFileRules,"accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel","prepend-inner-icon":"mdi-microsoft-excel","prepend-icon":"","label":_vm.$t('uploadExcelFile'),"clearable":false,"hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-card-actions',{staticClass:"pt-0",staticStyle:{"margin-top":"-7px"}},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.xlsxFile,"loading":_vm.uploading},on:{"click":_vm.uploadXlsxFile}},[_vm._v("\n                    "+_vm._s(_vm.$t("upload"))+"\n                    "),_c('v-icon',[_vm._v("mdi-upload")])],1)],1)]},proxy:true}]),model:{value:(_vm.xlsxFile),callback:function ($$v) {_vm.xlsxFile=$$v},expression:"xlsxFile"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.sourceSheets,"item-text":"sheetName","label":_vm.$t('sheets'),"return-object":"","hide-details":""},on:{"change":_vm.onSheetChanged},model:{value:(_vm.selectedSheet),callback:function ($$v) {_vm.selectedSheet=$$v},expression:"selectedSheet"}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"3","md":"4"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"justify-center",style:(_vm.$vuetify.theme.dark
                    ? 'background-color:#293842'
                    : 'background-color:#e6f5ff')},[_vm._v(_vm._s(_vm.$t("excelColumns")))]),_c('v-divider'),_c('v-card-text',[_c('drag',{attrs:{"data":_vm.selectedSchemeColumn},on:{"dragstart":_vm.dragstart,"dragend":function($event){_vm.currentDraggedColumnType = null}},scopedSlots:_vm._u([{key:"drag-image",fn:function(){return [_c('v-chip',{staticStyle:{"transform":"translate(10px, 5px)"},attrs:{"color":"red"}},[_vm._v("\n              "+_vm._s(_vm.sourceScheme[_vm.selectedSchemeColumn]
                  ? _vm.sourceScheme[_vm.selectedSchemeColumn].columnName
                  : "")+"\n            ")]),_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-drag-variant")])]},proxy:true}])},[_c('v-list',[_c('v-list-item-group',{attrs:{"mandatory":""},model:{value:(_vm.selectedSchemeColumn),callback:function ($$v) {_vm.selectedSchemeColumn=$$v},expression:"selectedSchemeColumn"}},_vm._l((_vm.sourceScheme),function(item,i){return _c('v-list-item',{key:i,attrs:{"two-line":""},on:{"mouseenter":function($event){return _vm.mouseoverList(item, i)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("\n                    "+_vm._s(item.columnName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.columnType))])],1),_c('v-list-item-action',[(item.isTaked)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("\n                    mdi-check\n                  ")]):_vm._e()],1)],1)}),1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"9","md":"8"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"justify-center",style:(_vm.$vuetify.theme.dark
                    ? 'background-color:#293842'
                    : 'background-color:#e6f5ff')},[_vm._v(_vm._s(_vm.$t("templateColumns")))]),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{staticClass:"template-table-degrees",attrs:{"hide-default-footer":"","headers":_vm.templateHeader,"items":_vm.templateRows},scopedSlots:_vm._u([{key:"item.StudentCode",fn:function(ref){
                    var item = ref.item;
return [_c('drop',{staticClass:"student-code-column",attrs:{"accepts-data":_vm.acceptsDataString},on:{"drop":function (val) {
                  _vm.onDrop(val, 'StudentCode');
                }}},[_c('v-select',{staticClass:"my-2",attrs:{"outlined":"","items":_vm.sourceSchemeString,"item-text":"columnName","item-value":"columnName","hide-details":"","background-color":!_vm.currentDraggedColumnType
                    ? ''
                    : _vm.currentDraggedColumnType == 'String'
                    ? 'rgba(0, 255, 0, 0.2)'
                    : 'rgba(255, 0, 0, 0.2)'},on:{"change":_vm.checkIsTaked},model:{value:(item.StudentCode),callback:function ($$v) {_vm.$set(item, "StudentCode", $$v)},expression:"item.StudentCode"}})],1)]}},{key:"item.Degree",fn:function(ref){
                    var item = ref.item;
return [_c('drop',{staticClass:"degree-column",attrs:{"accepts-data":_vm.acceptsDataDouble},on:{"drop":function (val) {
                  _vm.onDrop(val, 'Degree');
                }}},[_c('v-select',{staticClass:"my-2",attrs:{"outlined":"","items":_vm.sourceSchemeDouble,"item-text":"columnName","item-value":"columnName","hide-details":"","background-color":!_vm.currentDraggedColumnType
                    ? ''
                    : _vm.currentDraggedColumnType == 'Double'
                    ? 'rgba(0, 255, 0, 0.2)'
                    : 'rgba(255, 0, 0, 0.2)'},on:{"change":_vm.checkIsTaked},model:{value:(item.Degree),callback:function ($$v) {_vm.$set(item, "Degree", $$v)},expression:"item.Degree"}})],1)]}},{key:"item.Note",fn:function(ref){
                    var item = ref.item;
return [_c('drop',{staticClass:"note-column",on:{"drop":function (val) {
                  _vm.onDrop(val, 'Note');
                }}},[_c('v-select',{staticClass:"my-2",attrs:{"outlined":"","items":_vm.sourceScheme,"item-text":"columnName","item-value":"columnName","hide-details":"","background-color":!_vm.currentDraggedColumnType ? '' : 'rgba(0, 255, 0, 0.2)'},on:{"change":_vm.checkIsTaked},model:{value:(item.Note),callback:function ($$v) {_vm.$set(item, "Note", $$v)},expression:"item.Note"}})],1)]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.templateRows[0].StudentCode || !_vm.templateRows[0].Degree},on:{"click":_vm.SaveDegrees}},[_vm._v("\n          "+_vm._s(_vm.$t("save"))+"\n          "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-content-save")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }