<template>
  <div class="ma-2">
    <!--
    <v-card flat>
      <v-alert
        icon="mdi mdi-information-outline"
        outlined
        text
        color="primary"
        v-if="currentForum"
      >
        {{ currentForum.title }}
      </v-alert>
      <v-list>
        <v-list-item-group v-model="selectedForum">
          <div v-for="(item, index) in forumsData" :key="item.forumGuid">
            <v-list-item>
              <v-list-item-avatar
                v-if="
                  item.autoGenerated &&
                  item.forumType == 5 &&
                  item.subject &&
                  item.subject.coverImageUrl
                "
                size="100"
                tile
                @click="goToPage(item)"
              >
                <v-img :src="item.subject.coverImageUrl"> </v-img>
              </v-list-item-avatar>
              <v-list-item-avatar v-else tile @click="goToPage(item)">
                <v-icon large :color="getColor(item)">
                  {{ getIcon(item) }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content @click="goToPage(item)">
                <v-list-item-title class="text-wrap">{{
                  getText(item)
                }}</v-list-item-title>
                <v-list-item-subtitle class="mt-2 text-wrap">
                  {{ item.description }}
                </v-list-item-subtitle>

                <v-list-item-subtitle class="py-2 text-wrap">
                  <v-chip
                    v-if="item.autoGenerated"
                    class="ma-1"
                    :color="$vuetify.theme.dark ? '#3c6583' : '#d0e2ef'"
                    small
                    text-color="primary"
                    label
                    outlined
                  >
                    <span
                      v-if="
                        item.stage && item.department && item.forumType == 5
                      "
                    >
                      {{ item.department.departmentsName }} -
                      {{ item.stage.stageName }}</span
                    >
                    <span
                      v-else-if="
                        item.subject && item.department && item.forumType == 5
                      "
                    >
                      {{ item.department.departmentsName }}
                    </span>
                    <span v-else-if="item.department && item.forumType == 5">
                      {{ item.department.departmentsName }}
                    </span>
                    <span v-else>
                      {{ $t("forum." + item.title + "Description") }}</span
                    >
                  </v-chip>
                  <v-chip
                    small
                    :title="$t('createdBy')"
                    class="ma-1"
                    label
                    outlined
                    v-if="!item.autoGenerated && item.teacher"
                  >
                    {{ item.teacher.teacherName }}
                  </v-chip>
                  <v-chip
                    v-if="!item.autoGenerated"
                    :color="checkExpire(item)"
                    small
                    class="ma-1"
                    label
                    outlined
                  >
                    {{ item.startDate | moment("YYYY/MM/DD - h:mm a") }}</v-chip
                  >
                  <v-chip
                    :title="$t('exam.endDate')"
                    small
                    :color="checkExpire(item)"
                    class="ma-1"
                    label
                    outlined
                    v-if="
                      !item.autoGenerated && item.endDateEnable && item.endDate
                    "
                  >
                    {{ item.endDate | moment("YYYY/MM/DD - h:mm a") }}</v-chip
                  >
                  <v-icon
                    v-if="!item.visible"
                    :title="$t('forum.invisible')"
                    class="mx-4 my-1"
                    color="red"
                    >mdi mdi-eye-off</v-icon
                  >
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action
                v-if="
                  !item.autoGenerated &&
                  isInRole(2) &&
                  getUserInfo().userId == item.createdUserGuid
                "
              >
                <v-btn
                  @click="openAddForum(item.forumType, true, item)"
                  icon
                  :title="$t('edit')"
                  ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                >
                <v-btn @click="deleteForum(item)" icon :title="$t('delete')">
                  <v-icon color="red">mdi-delete</v-icon></v-btn
                >
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="index < forumsData.length - 1"
              :key="index"
            ></v-divider>
          </div>
        </v-list-item-group>
      </v-list>
      <v-speed-dial
        v-if="isInRole(2) && CheckPermission()"
        v-model="fab"
        fixed
        bottom
        :left="$vuetify.rtl"
        :right="!$vuetify.rtl"
        :class="$store.state.drawerMessage ? 'ml-16' : ''"
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="secondary" fab large elevation="6">
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-plus </v-icon>
          </v-btn>
        </template>
        <v-btn
          fab
          dark
          :title="$t('forum.AddCategory')"
          color="amber"
          @click="openAddForum(forumType.Category, false)"
        >
          <v-icon>mdi-folder</v-icon>
        </v-btn>
        <v-btn
          fab
          dark
          :title="$t('forum.AddForumTopics')"
          color="indigo"
          @click="openAddForum(null, false)"
        >
          <v-icon>mdi-file</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-card> -->

    <v-row no-gutters v-resize="onResize">
      <v-col :cols="nvaCols">
        <v-fab-transition>
          <v-btn
            icon
            class="mx-1 mb-0"
            small
            depressed
            top
            :left="!$vuetify.rtl"
            :right="$vuetify.rtl"
            @click="
              miniVariantWidthAllowChange = false;
              miniVariantWidth = 60;
              mini = false;
            "
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </v-fab-transition>

        <v-navigation-drawer
          absolute
          :permanent="true"
          v-model="treeDrawer"
          :right="$vuetify.rtl"
          mobile-breakpoint="1340"
          :width="treeDrawerWidth"
          :color="$vuetify.theme.dark ? '#1E1E1E' : '#FFFFFF'"
          class="forum-tree"
          style="z-index: 4"
          v-bind="$attrs"
          :mini-variant.sync="mini"
          :mini-variant-width="miniVariantWidth"
        >
          <forumItemsList
            :key="refreshForumsKey"
            @onChangeDrawer="changeDrawer"
            @openTopicList="openTopicList"
            @openTopicContentRecently="openTopicContentRecently"
          ></forumItemsList>
        </v-navigation-drawer>
      </v-col>
      <v-col
        :cols="forumsCols"
        style="max-height: calc(100vh - 80px); overflow-x: hidden"
        class="overflow-y-auto"
      >
        <v-row no-gutters v-if="!refreshKey" style="min-height: 300px">
          <v-col cols="12" md="12" class="text-center">
            <img src="../../assets/folder.svg" width="150px" />
            <h3>{{ $t("noData") }}</h3>
          </v-col>
        </v-row>

        <topicContentList
          v-else
          :key="refreshKey"
          class="mx-2"
          :topicGuid="topicGuid"
          :type="type"
          @openTopic="getTopic"
        ></topicContentList>
      </v-col>
    </v-row>
  </div>
</template>

  <script>
import forumItemsList from "./forumItemsList.vue";
import topicContentList from "./topicContentList.vue";

export default {
  name: "forumsLayout",
  components: {
    forumItemsList,
    topicContentList,
  },
  data() {
    return {
      forumGuid: null,
      treeDrawer: true,
      treeDrawerWidth: 260,
      miniVariantWidth: 60,
      miniVariantWidthAllowChange: true,
      innerWidth: 0,
      nvaCols: 2,
      forumsCols: 10,
      mini: false,
      topicGuid: null,
      type: "recently",
      refreshKey: null,
      title: "recently",
      refreshForumsKey: true,
    };
  },
  created() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 1340) {
      this.treeDrawer = true;
      this.miniVariantWidth = 60;
    }
    if (this.innerWidth > 600 && this.innerWidth < 1340) {
      this.treeDrawer = false;
      this.mini = true;
      this.miniVariantWidth = 60;
    } else if (this.innerWidth < 600) {
      this.treeDrawer = false;
      this.mini = true;
      this.miniVariantWidth = 0;
    }
    if (this.$route.query.topicGuid) {
      this.openTopicList({ topicGuid: this.$route.query.topicGuid });
    }
    this.onResize(null);
  },
  watch: {
    treeDrawer() {
      if (this.treeDrawer) {
        this.treeDrawerWidth = 280;
      } else {
        this.treeDrawerWidth = 0;
      }
    },
    $route(to, from) {
      if (to.query && to.query.topicGuid) {
        this.type = "topic";
        this.topicGuid = to.query.topicGuid;
        this.refreshKey = this.topicGuid + this.type;
        //this.refreshForumsKey = !this.refreshForumsKey;
      }
    },
  },

  computed: {},
  methods: {
    onResize(e) {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth > 1340) {
        this.nvaCols = 2;
        this.forumsCols = 10;
        this.miniVariantWidth = 60;
      }
      if (this.innerWidth > 600 && this.innerWidth < 1340) {
        this.nvaCols = 1;
        this.forumsCols = 11;
        this.miniVariantWidth = 60;
      } else if (this.innerWidth < 600) {
        this.nvaCols = 0;
        this.forumsCols = 12;
        if (this.miniVariantWidthAllowChange || this.mini) {
          this.miniVariantWidth = 0;
        }
      }
    },
    changeDrawer() {
      this.mini = !this.mini;
    },
    openTopicList(item) {
      if (item.topicGuid) {
        this.type = "topic";
      }
      this.topicGuid = item.topicGuid;
      this.refreshKey = this.topicGuid + this.type;
    },
    openTopicContentRecently() {
      this.type = "recently";
      this.topicGuid = null;
      this.forumGuid = null;
      this.refreshKey = this.topicGuid + this.type;
    },
    getTopic(item) {
      this.refreshForumsKey = !this.refreshForumsKey;
      this.openTopicList(item);
    },
  },
};
</script>

  <style>
.v-expansion-panel-content__wrap {
  padding: 0px;
}
</style>

<style scoped>
.forum-tree .v-navigation-drawer__content {
  padding-bottom: 0 !important;
}
</style>
