<template>
  <div>
    <v-dialog
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
      v-model="dialog" :retain-focus="false"
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark>
          {{ $t("mail.ReplyToMail") }}
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-row class="pa-0 ma-0">
            <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
              <v-text-field
                :label="$t('mail.msgTitle')"
                :placeholder="$t('mail.msgTitle')"
                outlined
                v-model="titleMail"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
              <DxHtmlEditor
                height="500px"
                v-model="markup"
                :placeholder="$t('mail.writeHere')"
              >
              <DxTableContextMenu  :enabled="true"/>
            <DxTableResizing :enabled="true"/>
            <DxMediaResizing :enabled="true" />
                <DxToolbar :multiline="isMultiline">
                  <DxItem name="undo" />
                  <DxItem name="redo" />
                  <DxItem name="separator" />
                  <DxItem :accepted-values="sizeValues" name="size" />
                  <DxItem :accepted-values="fontValues" name="font" />
                  <DxItem name="separator" />
                  <DxItem name="bold" />
                  <DxItem name="italic" />
                  <DxItem name="strike" />
                  <DxItem name="underline" />
                  <DxItem name="separator" />
                  <DxItem name="alignLeft" />
                  <DxItem name="alignCenter" />
                  <DxItem name="alignRight" />
                  <DxItem name="alignJustify" />
                  <DxItem name="separator" />
                  <DxItem name="orderedList" />
                  <DxItem name="bulletList" />
                  <DxItem name="separator" />
                  <DxItem :accepted-values="headerValues" name="header" />
                  <DxItem name="separator" />
                  <DxItem name="color" />
                  <DxItem name="background" />
                  <DxItem name="separator" />
                  <DxItem name="link" />
                  <DxItem name="image" />
                  <DxItem name="separator" />
                  <DxItem name="clear" />
                  <DxItem name="codeBlock" />
                  <DxItem name="blockquote" />
                  <DxItem name="separator" />
                  <DxItem name="insertTable" />
                  <DxItem name="deleteTable" />
                  <DxItem name="insertRowAbove" />
                  <DxItem name="insertRowBelow" />
                  <DxItem name="deleteRow" />
                  <DxItem name="insertColumnLeft" />
                  <DxItem name="insertColumnRight" />
                  <DxItem name="deleteColumn" />
                </DxToolbar>

                <div v-html="markup" />
              </DxHtmlEditor>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" md="12" sm="12" class="py-8 ma-0">
              <div class="fileUpload">
                <DxFileUploader
                  :allowed-file-extensions="allowedFileExtensions"
                  :selectButtonText="$t('lecOne.ChooseTheFile')"
                  :labelText="$t('lecOne.ChooseTheFileYouWantToUpload')"
                  [multiple]="false"
                  :chunk-size="5000000"
                  :max-file-size="209715200"
                  name="FileUpload"
                  :accept="allowedFileExtensions.join(',')"
                  uploadMode="instantly"
                  uploadMethod="POST"
                  :uploadHeaders="headers"
                  :upload-url="`${UploaderUrl}Mails`"
                  @upload-started="() => (chunks = [])"
                  @progress="onUploadProgress($event)"
                  @uploaded="onUploaded($event)"
                  messageUploadText="asd"
                />
                <v-alert dense text type="info" color="primary">
                  <span class="note"
                    >{{ $t("lecOne.AllowedFiles") }} :
                    <span
                      >jpg, jpeg, gif, png, .bmp, mp4, wmv, flv, avi, webm, weba wav, mp3,
                      aac, pdf, doc, docx, xls, xlsx, ppt, pptx, txt, zip, rar</span
                    >.</span
                  >
                  <span class="note"
                    >{{ $t("lecOne.MaximumFileSize") }} : <span>200MB</span></span
                  >
                </v-alert>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="dialog = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            :loading="sendLoad"
            color="primary darken-1"
            @click="uploadData()"
            :disabled="!titleMail"
          >
            {{ $t("send") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  DxHtmlEditor,
  DxToolbar,
  DxMediaResizing,
  DxItem,
  DxTableResizing,
    DxTableContextMenu
} from "devextreme-vue/html-editor";
import DxFileUploader from "devextreme-vue/file-uploader";
import { mapState } from "vuex";

export default {
  components: {
    DxHtmlEditor,
    DxMediaResizing,
    DxToolbar,
    DxItem,
    DxTableResizing,
    DxTableContextMenu,
    DxFileUploader,
  },
  data() {
    return {
      titleMail: "",
      sizeValues: ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"],
      fontValues: [
        "Arial",
        "Courier New",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Tahoma",
        "Times New Roman",
        "Verdana",
      ],
      headerValues: [false, 1, 2, 3, 4, 5],
      isMultiline: true,
      markup: ``,
      isLoading: false,
      fileURL: null,
      sendLoad: false,
      // uploadURL: process.env.VUE_APP_UPLOAD,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      chunks: [],
      allowedFileExtensions: [
        ".jpg",
        ".jpeg",
        ".gif",
        ".png",
        ".bmp",
        ".mp4",
        ".wmv",
        ".flv",
        ".avi",
        ".webm",
        ".weba",
        ".wav",
        ".mp3",
        ".aac",
        ".pdf",
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
        ".ppt",
        ".pptx",
        ".txt",
        ".zip",
        ".rar",
      ],
      dialog: false,
      ReplyOnGuid: null,
    };
  },
  computed: {
    ...mapState(["UploaderUrl"])
  },
  methods: {
    clear() {
      this.titleMail = null;
      this.markup = null;
      this.fileURL = null;
    },
    uploadData() {
      if (this.titleMail) {
        this.sendLoad = true;

        const data = {
          Title: this.titleMail,
          DetailsHTML: this.markup,
          ReplyOnGuid: this.ReplyOnGuid,
          FileName: this.fileURL ? this.fileURL.data.fileName : "",
        };
        // console.log(data);
        this.axios
          .post("Mails/Reply", data)
          .then((res) => {
            this.sendResultValues();
            this.fileURL = null;
            this.dialog = false;
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            // console.log(res);
          })
          .catch(() => {
            this.$swal.fire({
              title: this.$t("AnErrorOccurredDuringTheProcess"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          })
          .finally(() => {
            this.sendLoad = false;
          });
      } else {
        this.$swal.fire({
          title: this.$t("AnErrorOccurredDuringTheProcess"),
          text: "",
          icon: "error",
          confirmButtonText: this.$t("close"),
        });
      }
    },
    onUploaded(e) {
      this.fileURL = JSON.parse(e.request.responseText);
      // console.log(this.fileURL.data.fileName);
    },
    getValueInKb(value) {
      return `${(value / 1024).toFixed(0)}kb`;
    },
    onUploadProgress(e) {
      // console.log(e);
      this.chunks.push({
        segmentSize: e.segmentSize,
        bytesLoaded: e.bytesLoaded,
        bytesTotal: e.bytesTotal,
      });
    },
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim().toLowerCase();
    },
    sendResultValues() {
      this.$emit("send-result-values", "carrier");
    },

    setValue(ReplyOnGuid) {
      this.clear();
      //   console.log(ReplyOnGuid);
      this.ReplyOnGuid = ReplyOnGuid;
      this.dialog = true;
    },
  },
  created() {},
};
</script>
<style lang="scss">
.dx-htmleditor {
  border-radius: 5px;
  border-color: #909090;
}
.dx-htmleditor .dx-htmleditor-toolbar-wrapper:first-child {
  border-bottom: 1px solid #909090;
}
.dx-htmleditor-content img {
  vertical-align: middle;
  padding-right: 10px;
}

.dx-htmleditor-content table {
  width: 50%;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}
.chunk-panel {
  width: 505px;
  height: 165px;
  overflow-y: auto;
  padding: 18px;
  margin-top: 40px;
  background-color: rgba(191, 191, 191, 0.15);
}
.fileUpload {
  border: 1px solid #9e9e9e;
  padding: 10px;
  border-radius: 5px;
}
</style>
