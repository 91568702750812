<template>
  <v-dialog
    transition="dialog-bottom-transition"
    persistent
    max-width="800"
    v-model="dialog"
  >
    <v-card flat>
      <v-toolbar color="primary darken-1" dark>
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="">
        <v-row class="pa-0 ma-0 mt-8">
          <v-col cols="12" md="6" sm="12" class="py-0 ma-0">
            <v-text-field
              :label="$t('اسم المجموعة')"
              outlined
              v-model="body.GroupName"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="12" class="py-0 ma-0">
            <v-select
              :label="$t('حالة المجموعة')"
              outlined
              v-model="body.GroupMode"
              :items="groupStatusItem"
              hide-details
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end px-8 pt-0 mt-0">
        <v-btn
          :disabled="!body.GroupName || body.GroupMode == null"
          :loading="sendLoad"
          outlined
          color="teal darken-1"
          @click="addStudent()"
        >
          {{ $t("حفظ") }}
          <v-icon class="px-2">mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions></v-card
    >
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: null,
      groupStatusItem: [
        { text: "يمكن للجميع الإرسال", value: 0 },
        { text: "يمكن للإستاذ فقط الإرسال", value: 1 },
      ],
      sendLoad: false,
      dialog: false,
      body: {
        Guid: null,
        GroupName: "",
        GroupMode: null,
      },
    };
  },
  computed: {},
  methods: {
    editValue(data) {
      this.clear();
      this.body.Guid = data.guid;
      this.body.GroupName = data.groupName;
      this.body.GroupMode = data.groupMode;
      this.title = "تعديل معلومات المجموعة";
      this.dialog = true;
    },
    clear() {
      this.body = {
        Guid: null,
        GroupName: "",
        GroupMode: null,
        isEdit: false,
      };
    },
    setValue() {
      this.clear();
      this.title = "اضافة مجموعة";
      this.dialog = true;
    },
    sendResultValues() {
      this.$emit("send-result-values", "carrier");
    },
    addStudent() {
      this.sendLoad = true;
      const data = {
        Guid: this.body.Guid,
        GroupName: this.body.GroupName,
        GroupMode: this.body.GroupMode,
      };
      this.axios
        .post("ChatGroups/AddOrEditGroup", data)
        .then(() => {
          this.dialog = false;
          this.sendResultValues();
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          // console.log(res);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.sendLoad = false;
        });
    },
  },
};
</script>
<style lang="scss" >
.v-select__selection--comma {
  overflow: visible !important;
}
</style>
