/* eslint-disable spaced-comment */
/* eslint-disable import/order */
/* eslint-disable import/no-cycle */
import Vue from "vue";
import Vuex from "vuex";
import router from "./router";
import axios from "axios";
// import { createConnection, start, disconnect } from "./userHub";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        barColor: localStorage.getItem("darkMode") === "true" ? "#272727" : "#313942",
        MessageBarColor: localStorage.getItem("darkMode") === "true" ? "#1e1e1e" : "#ffffff",
        barImage: "",
        drawer: null,
        drawerMessage: null,
        idToken: localStorage.getItem("token"),
        userData: JSON.parse(localStorage.getItem("userData")),
        mailsCount: 0,
        examsCount: 0,
        allNotifications: 0,
        UploaderUrl: process.env.VUE_APP_URL,
        pathUrl: "",
        groupInfo: localStorage.getItem("groupInfo") ?
            JSON.parse(localStorage.getItem("groupInfo")) : {},
        UserChatInfo: {},
        overlay: false,
        decodedToken: null
    },
    mutations: {
        SET_GROUPINFO(state, payload) {
            state.groupInfo = payload;
        },
        SET_USERCHATINFO(state, payload) {
            state.UserChatInfo = payload;
        },
        updatNotiMutation(state, data) {
            state.allNotifications = data.allNotifications;
            state.examsCount = data.examsCount;
            state.mailsCount = data.mailsCount;
        },
        SET_BAR_IMAGE(state, payload) {
            state.barImage = payload;
        },
        SET_DRAWER(state, payload) {
            state.drawer = payload;
        },
        SET_DRAWER_Message(state, payload) {
            state.drawerMessage = payload;
        },
        SET_SCRIM(state, payload) {
            state.barColor = payload;
        },
        SET_UPLOADER(state, payload) {
            state.UploaderUrl = payload;
        },
        SET_PATHURL(state, payload) {
            state.pathUrl = payload;
        },
        SET_DECODED_TOKEN(state, payload) {
            state.decodedToken = payload
        },

        authUser(state, userData) {
            state.idToken = userData.token;
            state.userData = userData.userData;
        },
        clearAuth(state) {
            state.idToken = null;
        },
        overlay(state, overlay) {
            state.overlay = overlay;
        },
    },
    getters: {
        isLoading(state) {
            return state.overlay;
        },
    },
    actions: {
        updatNoti({ commit }, data) {
            commit("updatNotiMutation", {
                allNotifications: data.allNotifications,
                examsCount: data.examsCount,
                mailsCount: data.mailsCount
            });
        },
        login({ commit }, userData) {
            localStorage.setItem("token", userData.accessToken);
            localStorage.setItem("userData", JSON.stringify(userData));
            // var retrievedObject = JSON.parse(jsonString);
            commit("authUser", {
                token: userData.token,
                userData: userData
            });
            axios.defaults.headers.get.Authorization = `Bearer ${localStorage.getItem("token")}`;
            // this.$router.push("/");

            // createConnection();
            // start();
            commit("SET_DECODED_TOKEN", null);

            router.push({
                path: "/"
            });
        },
        overlay({ commit }, overlay) {
            commit("overlay", overlay);
        },

        logout({ commit }) {
            //disconnect();

            commit("clearAuth");

            if (this.$vuetify) {
                localStorage.setItem("darkMode", this.$vuetify.theme.dark);
                this.$vuetify.rtl = localStorage.getItem("lang") === "ar";
            }
            if (this.$i18n) {
                this.$i18n.locale = this.$i18n.locale === "ar" ? "ar" : "en";
                localStorage.setItem("lang", this.$i18n.locale);
            }
            localStorage.removeItem("token");
            localStorage.removeItem("userData");
            axios.defaults.headers.get.Authorization = null;

            // localStorage.clear();
            // localStorage.setItem("darkMode", this.$vuetify.theme.dark);
            // this.$i18n.locale = this.$i18n.locale == "ar" ? "ar" : "en";
            // localStorage.setItem("lang", this.$i18n.locale);
            // this.$vuetify.rtl = localStorage.getItem("lang") == "ar" ? true : false;
            // axios.defaults.headers.get["Authorization"] = null;
        }
    }
});
