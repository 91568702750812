<template>
  <div>
    <!-- <v-btn
      fab
      dark
      fixed
      bottom
      small
      :left="$vuetify.rtl"
      :right="!$vuetify.rtl"
      color="primary"
      @click="scrollToTop()"
      :style="$vuetify.rtl ? 'margin-left:80px' : 'margin-right:80px'"
      v-if="showScrollTop"
    >
      <v-icon> mdi-arrow-up</v-icon>
    </v-btn> -->

    <v-skeleton-loader
      :loading="loadingContent"
      translate="trans"
      type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
    >
      <template #default>
        <v-row no-gutters style="min-height: calc(100vh - 200px)" v-resize="onResize">
          <v-col :cols="forumsCols" style="max-height: calc(100vh - 80px); overflow: hidden">
            <v-fade-transition>
              <v-app-bar
                :absolute="false"
                class="px-0"
                rounded
                :color="$vuetify.theme.dark ? '#272727' : '#f5f5f5'"
                dense
                short
                elevation="0"
                style="margin: auto"
                v-if="isScrollUp"
              >
                <v-text-field
                  hide-details
                  v-model="searchText"
                  dense
                  filled
                  rounded
                  clearable
                  :placeholder="$t('search')"
                  prepend-inner-icon="mdi-magnify"
                  @keydown="onSearch"
                  :rules="[maxLengthRule]"
                >
                </v-text-field>
                <v-toolbar-items>
                  <v-btn
                    :title="$t('search')"
                    @click="onSearch({ key: 'Enter' })"
                    small
                    icon
                    :disabled="!searchText || searchText.length < 3"
                    ><v-icon color="primary">mdi mdi-magnify</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    icon
                    :title="$t('scrollTop')"
                    color="primary"
                    @click="scrollToTop()"
                    :disabled="!showScrollTop"
                  >
                    <v-icon> mdi-arrow-up</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-app-bar>
            </v-fade-transition>
            <DxScrollView
              v-if="!reloadScrollView"
              width="100%"
              :height="isScrollUp ? 'calc(100vh - 128px)' : 'calc(100vh - 20px)'"
              id="scrollview"
              ref="scrollViewWidget"
              :scroll-by-content="true"
              :scroll-by-thumb="true"
              show-scrollbar="onHover"
              :bounce-enabled="true"
              :reach-bottom-text="$t('refresh') + '...'"
              @pull-down="updateTopContent"
              @reach-bottom="updateBottomContent"
              @initialized="scrollview_initialized"
              @scroll="handleScroll"
            >
              <div>
                <v-card
                  class="mt-4"
                  outlined
                  style="margin: auto"
                  max-width="680"
                  v-if="type == 'topic' && forumTopic && forumTopic && forumTopic.topicName"
                >
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon large :color="getColor(forumTopic)">
                        {{ getIcon(forumTopic) }}
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-wrap"
                        >{{ forumTopic.forum.title }} |

                        {{ forumTopic.topicName }}
                      </v-list-item-title>

                      <v-list-item-subtitle class="py-2 text-wrap">
                        <v-chip
                          small
                          :title="$t('createdBy')"
                          class="ma-1"
                          label
                          outlined
                          v-if="forumTopic.teacher"
                        >
                          {{ forumTopic.teacher.teacherName }}
                        </v-chip>
                        <v-chip
                          :color="checkExpire(forumTopic)"
                          small
                          class="ma-1"
                          label
                          outlined
                          v-if="forumTopic.startDate"
                        >
                          {{ forumTopic.startDate | moment("YYYY/MM/DD - h:mm a") }}</v-chip
                        >
                        <v-chip
                          :title="$t('exam.endDate')"
                          small
                          :color="checkExpire(forumTopic)"
                          class="ma-1"
                          label
                          outlined
                          v-if="forumTopic.endDateEnable && forumTopic.endDate"
                        >
                          {{ forumTopic.endDate | moment("YYYY/MM/DD - h:mm a") }}
                        </v-chip>
                        <v-icon
                          v-if="!forumTopic.visible"
                          :title="$t('forum.invisible')"
                          class="mx-2 my-1"
                          color="red"
                          >mdi mdi-eye-off</v-icon
                        >
                        <v-icon
                          v-if="forumTopic.topicMemberMode == 1"
                          :title="$t('topic.topicModeRestricted')"
                          class="mx-2 my-1"
                          >mdi mdi-account-lock-outline</v-icon
                        >
                        <v-spacer></v-spacer>
                      </v-list-item-subtitle>
                      <v-card-subtitle class="ma-0 pa-0 ms-1" v-if="forumTopic.forum">
                        <v-row no-gutters
                          ><v-col>
                            <div>
                              <v-chip
                                small
                                class="me-1 mt-1"
                                outlined
                                label
                                v-if="forumTopic.forum.department"
                                >{{ forumTopic.forum.department.departmentsName }}</v-chip
                              >

                              <v-chip
                                class="me-1 mt-1"
                                small
                                label
                                outlined
                                v-if="forumTopic.forum.stage"
                              >
                                {{ forumTopic.forum.stage.stageName }} -
                                {{ forumTopic.forum.stage.studyType.studyName }}</v-chip
                              >

                              <v-chip
                                class="me-1 mt-1"
                                small
                                outlined
                                label
                                v-if="forumTopic.forum.subject"
                              >
                                {{ forumTopic.forum.subject.subjectName }}</v-chip
                              >
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="showMembersBtn"
                        fab
                        depressed
                        small
                        @click="membersDrawer = !membersDrawer"
                        ><v-icon>mdi mdi-account-multiple-outline</v-icon></v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                </v-card>

                <v-card
                  :ref="content.forumTopicContentGuid"
                  style="margin: auto"
                  class="mt-4"
                  max-width="680"
                  outlined
                  v-for="content in topicListContent"
                  :key="content.forumTopicContentGuid"
                >
                  <v-card-text class="pt-4 pb-1">
                    <v-row>
                      <v-list-item-avatar
                        :color="getColorFromName(content.teacher.teacherName)"
                        class="pb-1 white--text px-0 ms-2"
                      >
                        {{ getShortName(content.teacher.teacherName) }}
                      </v-list-item-avatar>
                      <v-card-subtitle class="px-0 pb-2">
                        {{ content.teacher.teacherName }} <br />
                        <v-icon
                          v-if="type != 'topic'"
                          :title="getForumText(content.forumTopic.forum)"
                          small
                          :color="getForumColor(content.forumTopic.forum)"
                          class="me-2"
                        >
                          {{ getForumIcon(content.forumTopic.forum) }}
                        </v-icon>
                        <v-btn
                          v-if="type != 'topic'"
                          @click="openTopic(content.forumTopic)"
                          min-width="0"
                          class="pa-0 ma-0"
                          style="text-decoration: underline"
                          small
                          color="primary"
                          text
                        >
                          {{ content.forumTopic.topicName }}</v-btn
                        >
                      </v-card-subtitle>

                      <v-spacer></v-spacer>
                      <v-icon
                        v-if="content.pin && type == 'topic'"
                        :class="type == 'topic' ? 'pb-4 px-3' : 'pb-11 px-3'"
                        color="secondary"
                        >mdi-pin</v-icon
                      >
                      <v-icon
                        v-if="content.lock"
                        :class="type == 'topic' ? 'pb-4 px-3' : 'pb-11 px-3'"
                        color="orange"
                        >mdi-lock</v-icon
                      >

                      <v-chip
                        class="mt-2"
                        small
                        outlined
                        label
                        :title="content.createdDate | moment('YYYY/MM/DD - h:mm A')"
                      >
                        {{ fromNow(content.createdDate) }}
                      </v-chip>
                      <v-menu>
                        <template v-slot:activator="{ on: menu, attrs }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                              <v-btn
                                fab
                                class="ms-1"
                                color="primary"
                                small
                                depressed
                                shaped
                                text
                                v-bind="attrs"
                                v-on="{ ...tooltip, ...menu }"
                              >
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t("options") }}</span>
                          </v-tooltip>
                        </template>
                        <v-list dense v-if="getUserInfo().userId == content.createdUserGuid">
                          <v-list-item @click="openAddContent(content, true)">
                            <v-list-item-icon class="mx-2">
                              <v-icon color="primary">mdi-pencil</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t("edit") }}</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="deleteContent(content)">
                            <v-list-item-icon class="mx-2">
                              <v-icon color="red">mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t("delete") }}</v-list-item-title>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item
                            @click="lockContent(content)"
                            :title="!content.lock ? $t('topic.lockHint') : ''"
                          >
                            <v-list-item-icon class="mx-2">
                              <v-icon :color="content.lock ? 'green' : 'orange'">{{
                                content.lock ? "mdi-lock-open" : "mdi-lock"
                              }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{
                              content.lock ? $t("topic.unlock") : $t("topic.lock")
                            }}</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            :title="!content.pin ? $t('topic.pinHint') : ''"
                            v-if="type == 'topic'"
                            @click="pinContentTop(content)"
                          >
                            <v-list-item-icon class="mx-2">
                              <v-icon :color="content.pin ? 'gray' : 'secondary'">{{
                                content.pin ? "mdi-pin-off" : "mdi-pin"
                              }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{
                              content.pin ? $t("topic.unpin") : $t("topic.pin")
                            }}</v-list-item-title>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item @click="followContent(content)">
                            <v-list-item-icon class="mx-2">
                              <v-icon>{{
                                getContentMember(content).follow
                                  ? "mdi-bookmark-minus-outline"
                                  : "mdi-bookmark-check-outline"
                              }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{
                              getContentMember(content).follow
                                ? $t("topic.unfollow")
                                : $t("topic.follow")
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                        <v-list dense v-else>
                          <v-list-item @click="followContent(content)">
                            <v-list-item-icon class="mx-2">
                              <v-icon>{{
                                getContentMember(content).follow
                                  ? "mdi-bookmark-minus-outline"
                                  : "mdi-bookmark-check-outline"
                              }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{
                              getContentMember(content).follow
                                ? $t("topic.unfollow")
                                : $t("topic.follow")
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-row>
                  </v-card-text>
                  <v-card-subtitle class="ma-0 pa-0 ms-1" v-if="content.forumTopic.forum">
                    <v-row no-gutters
                      ><v-col>
                        <div class="ms-3">
                          <v-chip
                            small
                            text-color="grey"
                            class="me-1 pa-0"
                            outlined
                            style="border-style: none !important"
                            label
                            v-if="content.forumTopic.forum.department"
                            >{{ content.forumTopic.forum.department.departmentsName }}</v-chip
                          >

                          <v-chip
                            class="me-1 pa-0"
                            text-color="grey"
                            small
                            label
                            style="border-style: none !important"
                            outlined
                            v-if="content.forumTopic.forum.stage"
                          >
                            • {{ content.forumTopic.forum.stage.stageName }} -
                            {{ content.forumTopic.forum.stage.studyType.studyName }}</v-chip
                          >

                          <v-chip
                            class="me-1 pa-0"
                            text-color="grey"
                            small
                            outlined
                            label
                            style="border-style: none !important"
                            v-if="content.forumTopic.forum.subject"
                          >
                            •
                            {{ content.forumTopic.forum.subject.subjectName }}</v-chip
                          >
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                  <v-card-title dir="auto" class="py-1" style="word-break: normal">
                    {{ content.title }}</v-card-title
                  >
                  <v-alert
                    dir="auto"
                    v-if="content.contentText"
                    class="ma-1"
                    text
                    dense
                    color="primary"
                    border="left"
                  >
                    <div
                      :id="content.forumTopicContentGuid + '_content'"
                      :style="content.showLess ? '' : 'max-height: 600px; overflow-y: hidden'"
                      v-html="getYoutube(content.contentText)"
                      class="text--primary text-wrap topic-content"
                    ></div>

                    <v-btn
                      v-if="checkContentHeight(content)"
                      @click="content.showLess ? showLess(content) : showMore(content)"
                      color="primary"
                      small
                      text
                    >
                      {{ content.showLess ? $t("ShowLess") : $t("ShowMore") }}</v-btn
                    >
                  </v-alert>
                  <v-sheet
                    class="mx-auto"
                    max-width="680"
                    v-if="content.files && content.files.length > 0"
                  >
                    <v-card-subtitle class="pa-1 px-4 text-decoration-underline">
                      {{ $t("mail.Attachments") }}
                      ({{ content.files.length }})
                    </v-card-subtitle>
                    <v-card-text class="ma-1 pa-0">
                      <v-slide-group
                        v-model="content.vSlideGroup"
                        class="attachments-content-slide"
                        show-arrows
                        mandatory
                      >
                        <v-slide-item
                          v-for="(item, index) in content.files"
                          :key="index"
                          class="mx-1"
                          :value="item.fileGuid"
                          v-slot="{ active, toggle }"
                        >
                          <v-card flat class="ma-0 pa-0" @click="toggle" :color="active ? '' : ''">
                            <download-file
                              :show-title="false"
                              :iconWidth="50"
                              :width="250"
                              v-if="item"
                              :fileUrl="item.fileUrl"
                              :key="index"
                              :showDeleteBtn="false"
                              :preview="true"
                              :file-size-format="item.fileSizeFormated"
                              :file-caption="item.fileCaption"
                              :show-file-caption="true"
                              :downloadFromSession="true"
                              thumbSuffixName="_thumb128.png"
                              :showDownloadCount="true"
                              :downloadCount="item.downloadCount"
                            >
                            </download-file>
                          </v-card>
                        </v-slide-item>
                      </v-slide-group>
                    </v-card-text>
                  </v-sheet>
                  <v-row>
                    <v-col>
                      <v-card flat outlined class="ma-1">
                        <v-card-actions>
                          <v-btn
                            :disabled="content.lock"
                            :title="$t('topic.rate')"
                            v-if="getUserInfo().userId != content.createdUserGuid"
                            @click="openAddRateContent(content)"
                            class="ma-0 pa-0 me-1"
                            depressed
                            :width="content.forumTopic.topicType == 0 ? '33.3%' : '50%'"
                          >
                            <span v-if="!$vuetify.breakpoint.xs">{{ $t("topic.rate") }}</span>

                            <v-icon
                              class="mx-2"
                              :color="getContentMember(content).rateContent ? 'orange' : 'grey'"
                              >mdi mdi-star</v-icon
                            ></v-btn
                          >
                          <v-btn
                            :title="$t('topic.rates')"
                            v-else
                            @click="openRatesContent(content)"
                            class="ma-0 pa-0 me-1"
                            depressed
                            :width="content.forumTopic.topicType == 0 ? '33.3%' : '50%'"
                          >
                            <span v-if="!$vuetify.breakpoint.xs">{{ $t("topic.rates") }}</span>

                            <v-icon class="mx-2" color="orange">mdi mdi-creation</v-icon></v-btn
                          >
                          <v-btn
                            @click="showReplies(content)"
                            :title="$t('topic.reply')"
                            class="ma-0 pa-0"
                            depressed
                            v-if="content.forumTopic.topicType == 0"
                            width="33.3%"
                          >
                            <span v-if="!$vuetify.breakpoint.xs">{{ $t("topic.reply") }}</span>
                            <v-icon class="mx-2" color="grey">mdi mdi-comment</v-icon>
                          </v-btn>
                          <v-btn
                            :title="$t('topic.markAsDone')"
                            @click="markContentAsRead(content)"
                            class="ma-0 pa-0 ms-1"
                            depressed
                            :width="content.forumTopic.topicType == 0 ? '32%' : '49%'"
                          >
                            <span v-if="!$vuetify.breakpoint.xs">{{ $t("topic.markAsDone") }}</span>
                            <v-icon
                              class="mx-2"
                              :color="getContentMember(content).markAsDone ? 'secondary' : 'grey'"
                              >mdi mdi-flag</v-icon
                            >
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>

                <v-row
                  no-gutters
                  v-if="!topicListContent || topicListContent.length == 0"
                  style="min-height: 300px"
                >
                  <v-col cols="12" md="12" class="text-center">
                    <img src="../../assets/folder.svg" width="150px" />
                    <h3>{{ $t("noData") }}</h3>
                  </v-col>
                </v-row>
              </div>
            </DxScrollView>
          </v-col>

          <v-col
            :cols="nvaCols"
            v-if="type == 'topic' && getUserInfo().userId == forumTopic.createdUserGuid"
          >
            <v-navigation-drawer
              absolute
              floating
              v-model="membersDrawer"
              :right="!$vuetify.rtl"
              mobile-breakpoint="1500"
              :width="280"
              :color="$vuetify.theme.dark ? '#1E1E1E' : '#FFFFFF'"
              class="forum-tree"
              style="z-index: 4"
              v-bind="$attrs"
            >
              <v-toolbar color="primary" dark flat dense>
                <v-card-text class="text-center pa-2"
                  >{{ $t("topic.membersList") }} ({{
                    topicMembers ? topicMembers.length : 0
                  }})</v-card-text
                >
              </v-toolbar>
              <v-toolbar elevation="0" outlined dense id="category_toolbar">
                <v-fab-transition leave-absolute mode="">
                  <v-btn
                    v-if="isInRole(2)"
                    class="me-1"
                    fab
                    color="primary"
                    @click="openAddMembers()"
                    small
                    depressed
                    shaped
                    text
                    :title="$t('topic.addMembers')"
                  >
                    <v-icon>mdi mdi-account-plus</v-icon></v-btn
                  >
                </v-fab-transition>

                <v-text-field
                  :dir="$vuetify.rtl ? 'ltr' : 'rtl'"
                  v-model="searchMemberText"
                  dense
                  filled
                  rounded
                  clearable
                  :placeholder="$t('search')"
                  prepend-inner-icon="mdi-magnify"
                  style="width: 100% !important"
                  class="pt-6 shrink expanding-search"
                  :class="{ closed: searchBoxClosed && !searchMemberText }"
                  @focus="searchBoxClosed = false"
                  @blur="
                    searchBoxClosed = false;
                    searchMemberText = null;
                  "
                ></v-text-field>
              </v-toolbar>
              <v-card-actions class="ma-0 pa-1"
                ><v-checkbox
                  dense
                  id="show_all_members"
                  class="mb-2 mt-2 ms-3 pa-0"
                  hide-details
                  @change="getGetTopicMembers"
                  :label="$t('showAll')"
                  v-model="showAllMembers"
                ></v-checkbox>
                <v-spacer></v-spacer>

                <v-btn :title="$t('refresh')" small fab icon @click="getGetTopicMembers"
                  ><v-icon color="primary">mdi mdi-refresh-circle</v-icon></v-btn
                ></v-card-actions
              >

              <v-divider style="margin: auto" width="95%"></v-divider>
              <v-list>
                <v-list-item-group v-model="memberSelect">
                  <v-list-item
                    dir="rtl"
                    class="pa-0"
                    v-for="member in membersFilter"
                    :key="member.forumTopicMemberGuid"
                  >
                    <v-list-item-avatar
                      size="35"
                      :color="getColorFromName(getMemberName(member))"
                      class="pb-1 white--text px-0 mx-2"
                    >
                      <span style="font-size: small">{{
                        getShortName(getMemberName(member))
                      }}</span>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-subtitle>
                        {{ getMemberName(member) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon :title="$t('admin')" color="secondary" v-if="member.owner" small
                          >mdi mdi-shield-crown-outline</v-icon
                        >
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon
                          :title="$t('teacher')"
                          color="grey"
                          v-if="member.teacher && !member.owner"
                          small
                          >mdi mdi-star</v-icon
                        >
                        <v-icon
                          :title="$t('topic.memberStatusNotJoined')"
                          color="orange"
                          v-if="member.memberStatus == 0"
                          small
                          >mdi mdi-account-alert</v-icon
                        >
                        <v-icon
                          :title="$t('topic.memberStatusRemoved')"
                          color="red"
                          v-if="member.memberStatus == 2"
                          small
                          >mdi mdi-account-remove</v-icon
                        >
                        <v-icon
                          :title="$t('topic.memberStatusLeaved')"
                          color="blue"
                          v-if="member.memberStatus == 3"
                          small
                          >mdi mdi-account-arrow-right</v-icon
                        >
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action
                      class="ma-0"
                      v-if="member.teacherGuid != forumTopic.createdUserGuid"
                    >
                      <v-menu
                        v-if="isInRole(2) && getUserInfo().userId == forumTopic.createdUserGuid"
                      >
                        <template v-slot:activator="{ on: menu, attrs }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                              <v-btn
                                depressed
                                shaped
                                text
                                color="grey"
                                v-bind="attrs"
                                v-on="{ ...tooltip, ...menu }"
                              >
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t("more") }}</span>
                          </v-tooltip>
                        </template>
                        <v-list dense>
                          <v-list-item
                            v-if="member.memberStatus == 1 || member.memberStatus == 0"
                            @click="removeMember(member)"
                          >
                            <v-list-item-icon class="mx-2">
                              <v-icon color="red" dense>mdi-account-remove</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t("remove") }}</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-if="member.memberStatus == 2 || member.memberStatus == 3"
                            @click="reInviteMember(member)"
                          >
                            <v-list-item-icon class="mx-2">
                              <v-icon dense>mdi-account-plus</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t("topic.reInvite") }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-navigation-drawer>
          </v-col>
        </v-row>
      </template>
    </v-skeleton-loader>
    <v-fab-transition
      v-if="type == 'topic' && isInRole(2) && CheckPermission() && !forumTopic.isExpired"
    >
      <v-btn
        dark
        color="secondary"
        fab
        large
        elevation="6"
        fixed
        bottom
        :title="$t('topic.addTopicContent')"
        :left="$vuetify.rtl"
        :right="!$vuetify.rtl"
        class="v-btn--example"
        :class="$store.state.drawerMessage ? 'ml-16' : ''"
        @click="openAddContent(null, false)"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <!------------------------Add content---------------------->
    <v-dialog
      hide-overlay
      fullscreen
      v-if="isInRole(2)"
      transition="dialog-bottom-transition"
      persistent
      no-click-animation
      :retain-focus="false"
      max-width="900"
      v-model="addContentPopup"
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark dense>
          {{ $t("topic.addTopic") }}
          <v-spacer></v-spacer>
          <v-btn icon dark @click="addContentPopup = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-text-field
            autofocus
            :label="$t('forum.title')"
            outlined
            v-model="topicContentModel.title"
            :rules="[required]"
          ></v-text-field>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <DxHtmlEditor
                dir="auto"
                :settings-allow-html-view="true"
                height="500px"
                v-model="topicContentModel.contentText"
                :placeholder="$t('mail.writeHere')"
                ref="DxHtmlEditorContent"
              >
                <DxTableContextMenu :enabled="true" />
                <DxTableResizing :enabled="true" />
                <DxMediaResizing :enabled="true" />
                <DxImageUpload :tabs="['file', 'url']" file-upload-mode="base64" />
                <DxToolbar :multiline="false">
                  <DxItem name="undo" />
                  <DxItem name="redo" />
                  <DxItem name="separator" />
                  <DxItem :accepted-values="sizeValues" name="size" />
                  <DxItem :accepted-values="fontValues" name="font" />
                  <DxItem name="separator" />
                  <DxItem name="bold" />
                  <DxItem name="italic" />
                  <DxItem name="strike" />
                  <DxItem name="underline" />
                  <DxItem name="separator" />
                  <DxItem name="alignLeft" />
                  <DxItem name="alignCenter" />
                  <DxItem name="alignRight" />
                  <DxItem name="alignJustify" />
                  <DxItem name="separator" />
                  <DxItem name="orderedList" />
                  <DxItem name="bulletList" />
                  <DxItem name="separator" />
                  <DxItem :accepted-values="headerValues" name="header" />
                  <DxItem name="separator" />
                  <DxItem name="color" />
                  <DxItem name="background" />
                  <DxItem name="separator" />
                  <DxItem name="link" />
                  <!-- <DxItem
                widget="dxButton"
                :options="checkboxOptions"
                locate-in-menu="never"
              /> -->
                  <DxItem name="image" />
                  <DxItem name="separator" />
                  <DxItem name="clear" />
                  <DxItem name="codeBlock" />
                  <DxItem name="blockquote" />
                  <DxItem name="separator" />
                  <DxItem name="insertTable" />
                  <DxItem name="deleteTable" />
                  <DxItem name="insertRowAbove" />
                  <DxItem name="insertRowBelow" />
                  <DxItem name="deleteRow" />
                  <DxItem name="insertColumnLeft" />
                  <DxItem name="insertColumnRight" />
                  <DxItem name="deleteColumn" />
                </DxToolbar>
              </DxHtmlEditor>
            </v-tab-item>
            <v-tab-item>
              <v-card color="basil" outlined flat max-height="500px" style="overflow-y: auto">
                <div class="ma-3" v-html="getYoutube(topicContentModel.contentText)"></div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <v-tabs v-model="tab">
            <v-tab>
              {{ $t("Editor") }}
            </v-tab>
            <v-tab>{{ $t("Preview") }}</v-tab>
          </v-tabs>

          <div class="v-alert v-sheet theme--dark v-alert--text primary--text pa-2 mt-2" text>
            <DxFileUploader
              v-if="addContentPopup"
              :allowed-file-extensions="allowedFileExtensions"
              :selectButtonText="$t('lecOne.ChooseTheFile')"
              :labelText="$t('lecOne.ChooseTheFileYouWantToUpload')"
              :multiple="true"
              :chunk-size="5000000"
              :max-file-size="209715200"
              name="FileUpload"
              ref="FileUpload"
              :accept="allowedFileExtensions.join(',')"
              uploadMode="instantly"
              uploadMethod="POST"
              :uploadHeaders="headers"
              :upload-url="`${UploaderUrl}Forums`"
              @upload-started="() => (chunks = [])"
              @progress="onUploadProgress($event)"
              @uploaded="onUploaded($event)"
              @upload-error="onUploadError"
              :disabled="!isInRole(2)"
            />
            <v-alert width="100%" dense text type="info" color="primary">
              <span class="note"
                >{{ $t("lecOne.AllowedFiles") }} :
                <span>{{ allowedFileExtensions.join(", ") }}</span></span
              >
              <div class="note">{{ $t("lecOne.MaximumFileSize") }} : <span>200MB</span></div>
            </v-alert>
            <v-slide-group
              multiple
              show-arrows
              class="py-2"
              mandatory
              v-if="topicContentModel.files && topicContentModel.files.length > 0"
            >
              <v-slide-item
                v-for="(item, index) in topicContentModel.files"
                :key="index"
                class="mx-1"
                v-slot="{}"
                :value="item"
              >
                <div>
                  <v-text-field
                    class="my-2"
                    hide-details
                    outlined
                    v-model="item.fileCaption"
                    dense
                    :placeholder="$t('fileCaption')"
                  ></v-text-field>
                  <download-file
                    :iconWidth="50"
                    :width="250"
                    v-if="item"
                    :fileUrl="item.fileUrl"
                    :key="item.fileUrl"
                    :showDeleteBtn="true"
                    :deleteItem="deleteTopicContentFile"
                    :item="item"
                    :preview="true"
                    thumbSuffixName="_thumb128.png"
                    :downloadFromSession="true"
                  >
                  </download-file>
                </div>
              </v-slide-item>
            </v-slide-group>
          </div>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="addContentPopup = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            :loading="loading"
            color="primary darken-1"
            @click="contentSave()"
            :disabled="!topicContentModel.title"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!---------------------------Add Memebers ------------------------------->
    <v-dialog v-model="openAddMembersDialog" persistent max-width="750" no-click-animation>
      <v-card>
        <v-toolbar color="primary darken-1" dark dense>
          {{ $t("topic.addMembers") }}
          <v-spacer></v-spacer>
          <v-btn icon dark @click="openAddMembersDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-row class="pa-0 ma-0">
            <v-col cols="12" md="6" sm="12" class="py-0 ma-0">
              <v-autocomplete
                v-model="subjectSelect"
                :items="subjects"
                outlined
                :label="$t('degrees.selectSubject')"
                item-text="subjectName"
                item-value="subjectGuid"
                @change="clearSelect"
                :rules="[required]"
              ></v-autocomplete>
            </v-col>
            <v-col class="py-0 ma-0" cols="12" md="6" sm="12">
              <v-autocomplete
                v-model="deparmentSelect"
                :items="departmentList"
                outlined
                :label="$t('home.departmentChoese')"
                item-text="departmentName"
                item-value="departmentGuid"
                @change="clearSelect2"
                :rules="[required]"
              >
              </v-autocomplete>
            </v-col>
            <v-col class="py-0 ma-0" cols="12" md="6" sm="12">
              <v-autocomplete
                v-model="stageSelect"
                :items="stageList"
                outlined
                :label="$t('lecOne.selectStage')"
                :item-text="getFieldText"
                item-value="stageGuid"
                @change="clearSelect3"
                :rules="[required]"
              >
              </v-autocomplete>
            </v-col>
            <v-col class="py-0 ma-0" cols="12" md="6" sm="12">
              <v-autocomplete
                v-model="sectionSelect"
                :items="sectionList"
                outlined
                :label="$t('mail.selectSection')"
                item-text="sectionName"
                item-value="sectionGuid"
                @change="getStudents"
                :rules="[required]"
              ></v-autocomplete>
            </v-col>
            <v-col class="py-0 ma-0" cols="12" md="12" sm="12">
              <v-autocomplete
                v-model="studentsSelect"
                :items="studentList"
                outlined
                multiple
                :label="$t('mail.ChooseStudents')"
                item-text="studentFullName"
                item-value="studentGuid"
                clearable
                chips
                small-chips
                deletable-chips
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 3">
                    <span>{{ item.studentFullName }}</span>
                  </v-chip>
                  <span v-if="index === 3" class="grey--text caption">
                    (+{{ studentsSelect.length - 3 }} {{ $t("mail.Others") }})
                  </span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggle">
                    <v-list-item-action>
                      <v-icon :color="studentsSelect.length > 0 ? 'primary' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("mail.selectAll") }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:append-item>
                  <v-divider class="mb-2"></v-divider>
                  <v-list-item disabled>
                    <v-list-item-avatar color="grey lighten-3">
                      <v-icon light> mdi-account-question-outline </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content v-if="AllStudentsSelected">
                      <v-list-item-title> {{ $t("mail.AllHaveBeenSelected") }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-content v-else-if="SomeStudentsSelected">
                      <v-list-item-title>
                        {{ $t("mail.TheNumberOfStudentsSpecified") }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ studentsSelect.length }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <v-list-item-title>
                        {{ $t("mail.NoStudentIdentified") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete></v-col
            >
            <v-col class="py-0 ma-0" cols="12" md="12" sm="12"
              ><v-autocomplete
                class="mt-3"
                v-model="teachersSelect"
                :items="teachersList"
                outlined
                multiple
                :label="$t('selectTeacher')"
                item-text="teacherName"
                item-value="teacherGuid"
                clearable
                chips
                small-chips
                deletable-chips
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 3">
                    <span>{{ item.teacherName }}</span>
                  </v-chip>
                  <span v-if="index === 3" class="grey--text caption">
                    (+{{ teachersSelect.length - 3 }} {{ $t("mail.Others") }})
                  </span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleTeacher">
                    <v-list-item-action>
                      <v-icon :color="teachersSelect.length > 0 ? 'primary' : ''">
                        {{ icon2 }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("mail.selectAll") }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:append-item>
                  <v-divider class="mb-2"></v-divider>
                  <v-list-item disabled>
                    <v-list-item-avatar color="grey lighten-3">
                      <v-icon light> mdi-account-question-outline </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content v-if="AllTeachersSelected">
                      <v-list-item-title> {{ $t("mail.AllHaveBeenSelected") }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-content v-else-if="SomeTeachersSelected">
                      <v-list-item-title>
                        {{ $t("mail.TheNumberOfStudentsSpecified") }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ teachersSelect.length }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <v-list-item-title>
                        {{ $t("mail.NoStudentIdentified") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete></v-col
            >
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="openAddMembersDialog = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            :loading="loading"
            color="primary darken-1"
            @click="addMembersSave()"
            :disabled="
              studentsSelect &&
              studentsSelect.length == 0 &&
              teachersSelect &&
              teachersSelect.length == 0
            "
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!---------------------------Add Rate ------------------------------->
    <v-dialog v-model="openAddRateDialog" persistent max-width="750" no-click-animation>
      <v-card>
        <v-toolbar color="primary darken-1" dark dense>
          {{ $t("topic.addRate") }}
          <v-spacer></v-spacer>
          <v-btn icon dark @click="openAddRateDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4 pa-1">
          <v-row class="pa-0 ma-0">
            <v-col cols="12" class="py-0 ma-0">
              <v-card-actions class="ma-0 pa-0 mb-3">
                {{ $t("topic.rate") }}:
                <v-rating
                  hover
                  background-color="orange lighten-3"
                  color="orange"
                  length="5"
                  v-model="rateContentModel.rateContent"
                ></v-rating>
              </v-card-actions>
            </v-col>
            <v-col class="py-0 ma-0" cols="12">
              <v-textarea
                dir="auto"
                v-model="rateContentModel.feedback"
                :label="$t('topic.feedback')"
                :placeholder="$t('topic.feedback')"
                height="300"
                outlined
                no-resize
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="openAddRateDialog = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            :loading="loading"
            color="primary darken-1"
            @click="addRateSave()"
            :disabled="!rateContentModel.rateContent && !rateContentModel.feedback"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!----------------------------Rates------------>
    <v-dialog
      scrollable
      v-model="openRatesContentList"
      persistent
      max-width="750"
      no-click-animation
    >
      <v-card v-if="openRatesContentList">
        <v-toolbar color="primary darken-1" dark dense>
          {{ $t("topic.rates") }}

          <v-rating
            readonly
            half-increments
            hover
            background-color="orange lighten-3"
            color="orange"
            length="5"
            :value="getTotalRates(ratesContentList)"
          ></v-rating>
          {{ getTotalRates(ratesContentList) }}
          <v-icon small class="mx-1">mdi mdi-star</v-icon><span class="mx-1">|</span>
          {{ ratesContentList.length }}
          <v-icon small class="mx-1">mdi mdi-chart-histogram</v-icon>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="openRatesContentList = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="pa-1">
          <topicContentRatesFeedbacks :rates-list="ratesContentList"></topicContentRatesFeedbacks>
        </v-card-text>
        <v-card-actions class="pt-1">
          <v-btn color="error darken-1" text @click="openRatesContentList = false">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      persistent
      no-click-animation
      :retain-focus="false"
      max-width="900"
      v-model="showReplyDialog"
      v-if="replyOnContent"
      :fullscreen="fullScreenModeReplies"
    >
      <v-card flat>
        <v-toolbar color="primary darken-1" dark dense>
          {{ $t("topic.replies") }} | {{ replyOnContent.title }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="
              fullScreenModeReplies = !fullScreenModeReplies;
              $forceUpdate();
            "
          >
            <v-icon>{{ fullScreenModeReplies ? "mdi-fullscreen-exit" : "mdi-fullscreen" }}</v-icon>
          </v-btn>
          <v-btn icon dark @click="showReplyDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="ma-0 pa-2">
          <repliesDialog
            v-if="showReplyDialog"
            :content="replyOnContent"
            :fullScreenMode="fullScreenModeReplies"
          >
          </repliesDialog>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>


import {
  DxHtmlEditor,
  DxToolbar,
  DxMediaResizing,
  DxTableResizing,
  DxTableContextMenu,
  DxItem,
  DxImageUpload,
} from "devextreme-vue/html-editor";
import DxScrollView from "devextreme-vue/scroll-view";
import DxFileUploader from "devextreme-vue/file-uploader";
import { mapState } from "vuex";
import topicContentRatesFeedbacks from "./topicContentRatesFeedbacks.vue";
import DownloadFile from "../downloadFile.vue";
import repliesDialog from "./topicContentReplies.vue";
import { bus } from "../../main";

export default {
  components: {
    DxHtmlEditor,
    DxMediaResizing,
    DxTableResizing,
    DxTableContextMenu,
    DxToolbar,
    DxItem,
    DxScrollView,
    DxFileUploader,
    DxImageUpload,
    DownloadFile,
    topicContentRatesFeedbacks,
    repliesDialog,
  },
  props: ["topicGuid", "type"],
  data() {
    return {
      fullScreenModeReplies: false,
      tab: 0,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      chunks: [],
      allowedFileExtensions: [
        ".jpg",
        ".jpeg",
        ".gif",
        ".png",
        ".bmp",
        ".mp4",
        ".wmv",
        ".flv",
        ".avi",
        ".webm",
        ".weba",
        ".wav",
        ".mp3",
        ".aac",
        ".pdf",
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
        ".ppt",
        ".pptx",
        ".txt",
        ".zip",
        ".rar",
      ],
      // checkboxOptions: {
      //   text: "Youtube ",
      //   stylingMode: "text",

      //   icon: "video",
      //   onClick: this.onClickYoutube,
      // },
      pullDown: true,
      scrollByContent: true,
      scrollByThumb: true,
      showAllMembers: false,
      teachersList: [],
      teachersSelect: [],
      studentList: [],
      studentsSelect: [],
      subjectSelect: null,
      deparmentSelect: null,
      stageSelect: null,
      sectionSelect: null,
      subjects: [],
      openAddMembersDialog: false,
      searchMemberText: "",
      searchBoxClosed: false,
      showMembers: true,
      interval: null,
      membersDrawer: true,
      membersMini: false,
      memberSelect: null,
      showMembersBtn: false,

      required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      isMultiline: true,
      loading: false,
      loadingContent: false,

      sizeValues: ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"],
      fontValues: [
        "Arial",
        "Courier New",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Tahoma",
        "Times New Roman",
        "Verdana",
      ],
      headerValues: [false, 1, 2, 3, 4, 5],

      addContentPopup: false,
      topicContentModel: { files: [] },
      topicListContent: [],
      forumTopic: {},
      roles: [],
      topicMembers: [],
      nvaCols: 3,
      forumsCols: 9,
      openAddRateDialog: false,
      ratesContentList: [],
      openRatesContentList: false,
      rateContentModel: {
        rateContent: null,
        feedback: null,
        forumTopicContentGuid: null,
      },
      reloadScrollView: false,
      showScrollTop: false,
      searchText: null,
      checkScroll: true,
      maxLengthRule: (v) => (v && v.length > 0) || "Error",
      oldScroll: 0,
      scrollY: 0,
      isScrollUp: true,
      replyOnContent: null,
      showReplyDialog: false,
      last_execution: 0,
      forumType: {
        Announcements: 0,
        News: 1,
        Events: 2,
        Files: 3,
        Subjects: 4,
        Category: 5,
      },
      selectForumTopicContentGuid: null,
      openReplyByForumTopicContentGuid: null,
      openRatesByForumTopicContentGuid: null,
      scrollViewIsInit: true,
    };
  },
  // watch: {
  //   $route(to, from) {
  //     if (to.query && to.query.topicGuid) {
  //       this.topicListContent = [];
  //     }
  //   },
  // },

  created() {
    this.scrollViewIsInit = true;
    bus.$on("selectContent", (forumTopicContentGuid) => {
      this.selectForumTopicContentGuid = forumTopicContentGuid;
      this.selectContent();
    });

    bus.$on("openRepliesDialog", (forumTopicContentGuid) => {
      this.openRatesByForumTopicContentGuid = null;
      this.openReplyByForumTopicContentGuid = forumTopicContentGuid;
      this.selectForumTopicContentGuid = forumTopicContentGuid;
      this.selectContent();
    });

    bus.$on("openRatesDialog", (forumTopicContentGuid) => {
      this.openReplyByForumTopicContentGuid = null;
      this.openRatesByForumTopicContentGuid = forumTopicContentGuid;
      this.selectForumTopicContentGuid = forumTopicContentGuid;
      this.selectContent();
    });

    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1500) {
      this.membersDrawer = false;
    }

    //this.$refs.scrollViewWidget.instance.option("onPullDown", null);

    this.loadingContent = true;
    this.refreshContent(null, "PullDown");
  },
  destroyed() {},
  computed: {
    ...mapState(["UploaderUrl"]),
    AllStudentsSelected() {
      return this.studentsSelect.length === this.studentList.length;
    },
    SomeStudentsSelected() {
      return this.studentsSelect.length > 0 && !this.AllStudentsSelected;
    },
    icon() {
      if (this.AllStudentsSelected) return "mdi-close-box";
      if (this.SomeStudentsSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    AllTeachersSelected() {
      return this.teachersSelect.length === this.teachersList.length;
    },
    SomeTeachersSelected() {
      return this.teachersSelect.length > 0 && !this.AllTeachersSelected;
    },
    icon2() {
      if (this.AllTeachersSelected) return "mdi-close-box";
      if (this.SomeTeachersSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    membersFilter() {
      let dataList = [];
      if (!this.searchMemberText || !this.topicMembers) {
        return this.topicMembers;
      }

      dataList = this.topicMembers.filter((item) =>
        this.searchMemberText
          .toLowerCase()
          .split(" ")
          .every((v) => this.getMemberName(item).toLowerCase().includes(v))
      );

      return dataList;
    },
    departmentList() {
      let dataList = [];
      if (this.subjectSelect) {
        dataList = this.subjects.filter((item) => item.subjectGuid === this.subjectSelect);
        if (dataList[0].departments && dataList[0].departments.length == 1) {
          this.deparmentSelect = dataList[0].departments[0].departmentGuid;
          this.getDepartmentTeachers();
        }

        return dataList[0].departments;
      }
      return dataList;
    },
    stageList() {
      let dataList = [];
      if (this.deparmentSelect) {
        dataList = this.departmentList.filter(
          (item) => item.departmentGuid === this.deparmentSelect
        );
        if (dataList[0].stages && dataList[0].stages.length == 1) {
          this.stageSelect = dataList[0].stages[0].stageGuid;
        }

        return dataList[0].stages;
      }
      return dataList;
    },
    sectionList() {
      let dataList = [];
      if (this.stageSelect) {
        dataList = this.stageList.filter((item) => item.stageGuid === this.stageSelect);

        if (dataList[0].sections && dataList[0].sections.length == 1) {
          this.sectionSelect = dataList[0].sections[0].sectionGuid;
          this.getStudents();
        }

        return dataList[0].sections;
      }
      return dataList;
    },
  },
  updated() {
    if (this.openAddContent) {
      $(".dx-htmleditor-content").attr("dir", "auto");
    }
  },
  methods: {
    refreshContent(args, eventName) {
      if (!args) {
        this.reloadScrollView = true;
      }
      if (eventName == "PullDown") {
        if (this.type == "topic") {
          this.getTopicListContent(args);
        } else if (this.type == "recently") {
          this.getTopicContentRecently(true, args);
        }
      } else if (eventName == "ReachBottom" && this.type == "recently") {
        this.getTopicContentRecently(false, args);
      }
    },

    updateBottomContent(e) {
      this.refreshContent(e, "ReachBottom");
    },
    updateTopContent(e) {
      this.refreshContent(e, "PullDown");
    },
    scrollview_initialized(e) {
      if (this.scrollViewIsInit) {
        this.releaseScrollview(e, this.type != "recently");
        this.scrollViewIsInit = false;
      }
    },

    getTopicListContent(args) {
      if (!this.topicGuid) {
        this.loadingContent = false;

        return;
      }

      this.overlay = true;
      var url = `ForumTopic/GetTopicContent?topicGuid=${this.topicGuid}`;

      if (this.searchText) {
        url += `&searchText=${this.searchText}`;
      }

      this.axios
        .get(url)
        .then((res) => {
          this.topicListContent = res.data.data.content.topicContent;
          this.forumTopic = res.data.data.content.topic;
          this.roles = res.data.data.roles;
          this.getGetTopicMembers();
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;
          setTimeout(() => {
            this.loadingContent = false;
            this.reloadScrollView = false;
            this.selectContent();
            this.releaseScrollview(args, true);
          }, 100);
        });
    },
    getTopicContentRecently(mostRecent, args) {
      this.overlay = true;
      var date = null;

      if (this.topicListContent && this.topicListContent.length > 0) {
        if (mostRecent) {
          date = this.topicListContent[0].createdDate;
        } else {
          date = this.topicListContent[this.topicListContent.length - 1].createdDate;
        }
      }
      var url = "ForumTopic/GetTopicContentRecently?";
      if (this.topicListContent && this.topicListContent.length > 0) {
        date = this.$moment(date).format("YYYY-MM-DDTHH:mm:ss");
        url += `date=${date}&mostRecent=${mostRecent}&`;
      }
      if (this.searchText) {
        url += `searchText=${this.searchText}`;
      }

      var list = [];
      this.axios
        .get(url)
        .then((res) => {
          list = res.data.data;
          if (mostRecent) {
            this.topicListContent = res.data.data;
          } else {
            this.addOrUpdateObjects(this.topicListContent, res.data.data);
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;
          this.loadingContent = false;
          this.reloadScrollView = false;
          this.releaseScrollview(args, list.length < 20);
        });
    },

    releaseScrollview(args, preventScrollBottom) {
      setTimeout(() => {
        if (args) {
          args.component.release(preventScrollBottom);
        } else if (this.scrollViewInstance()) {
          this.scrollViewInstance().release(preventScrollBottom);
        }
      }, 20);
    },
    scrollViewInstance() {
      if (this.$refs.scrollViewWidget) {
        return this.$refs.scrollViewWidget.instance;
      }
      return null;
    },
    addOrUpdateObjects(objectsArray, newObjectsArray) {
      for (const newObject of newObjectsArray) {
        const existingObjectIndex = objectsArray.findIndex(
          (object) => object.forumTopicContentGuid === newObject.forumTopicContentGuid
        );
        if (existingObjectIndex !== -1) {
          objectsArray[existingObjectIndex] = newObject;
        } else {
          objectsArray.push(newObject);
        }
      }

      objectsArray.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
    },
    openAddContent(item, isEdit) {
      this.addContentPopup = true;

      this.isEdit = isEdit;
      if (isEdit) {
        this.topicContentModel = { ...item };
      }
    },

    contentSave() {
      if (!this.isInRole(2)) {
        return;
      }
      if (!this.isEdit) {
        this.topicContentModel.forumTopicGuid = this.topicGuid;
      }

      this.overlay = true;
      this.loading = true;
      var url = this.isEdit ? "ForumTopic/UpdateTopicContent" : "ForumTopic/CreateTopicContent";
      this.axios
        .post(url, this.topicContentModel)
        .then((res) => {
          if (res.data.statusCode == 1) {
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });

            this.addContentPopup = false;
            this.topicContentModel = { files: [] };

            this.refreshContent(null, "PullDown");
          } else if (res.data.statusCode == 2) {
            this.$swal.fire({
              title: this.$t("topic.topicIsExpired"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else {
            this.$swal.fire({
              title: this.$t("errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          }

          // console.log(res);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },

    getGetTopicMembers() {
      if (!this.isInRole(2)) {
        return;
      }
      if (this.type == "recently") {
        return;
      }

      var url = `ForumTopic/GetTopicMembers?topicGuid=${this.topicGuid}&showAll=${this.showAllMembers}`;
      this.axios
        .get(url)
        .then((res) => {
          this.topicMembers = res.data.data;
          this.$forceUpdate();
        })
        .catch((err) => {})
        .finally(() => {});
    },
    getSubjects() {
      if (!this.isInRole(2)) {
        return;
      }
      this.axios
        .get("Subjects/Get")
        .then((res) => {
          this.subjects = res.data.data.subjects;
        })
        .catch((err) => {});
    },
    getStudents() {
      if (!this.isInRole(2)) {
        return;
      }
      if (this.sectionSelect && this.subjectSelect) {
        this.axios
          .get(`Students/Get?sectionGuid=${this.sectionSelect}&subjectGuid=${this.subjectSelect}`)
          .then((res) => {
            this.studentList = res.data.data;
            this.studentsSelect = [];
            // console.log(this.studentList);
          })
          .catch(() => {})
          .finally(() => {});
      }
    },
    getDepartmentTeachers() {
      this.overlay = true;
      const url = `Teachers/GetDepartmentTeachers?departmentGuid=${this.deparmentSelect}`;
      this.axios
        .get(url)
        .then((res) => {
          this.teachersList = res.data.data;
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;
        });
    },
    addMembersSave() {
      if (!this.isInRole(2)) {
        return;
      }
      this.overlay = true;
      this.loading = true;
      var url = "ForumTopic/Invite";
      var data = {
        forumTopicGuid: this.topicGuid,
        teachers: this.teachersSelect,
        students: this.studentsSelect,
      };
      this.axios
        .post(url, data)
        .then((res) => {
          if (res.data.statusCode == 1) {
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            this.getGetTopicMembers();
          } else {
            this.$swal.fire({
              title: this.$t("errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          }
          this.openAddMembersDialog = false;

          // console.log(res);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },
    removeMember(item) {
      if (!this.isInRole(2)) {
        return;
      }
      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            this.overlay = true;
            this.loading = true;
            var url = "ForumTopic/RemoveMemberFromTopic";
            var data = {
              forumTopicGuid: this.topicGuid,
              forumTopicMemberGuid: item.forumTopicMemberGuid,
            };
            this.axios
              .post(url, data)
              .then((res) => {
                if (res.data.statusCode == 1) {
                  this.$swal.fire({
                    title: this.$t("operationAccomplishedSuccessfully"),
                    text: "",
                    icon: "success",
                    confirmButtonText: this.$t("close"),
                    toast: true,
                    position: "bottom-start",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", this.$swal.stopTimer);
                      toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                    },
                  });
                  this.getGetTopicMembers();
                } else {
                  this.$swal.fire({
                    title: this.$t("errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                }
                this.openAddMembersDialog = false;

                // console.log(res);
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              })
              .finally(() => {
                this.overlay = false;
                this.loading = false;
              });
          }
        });
    },
    reInviteMember(item) {
      if (!this.isInRole(2)) {
        return;
      }
      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            this.overlay = true;
            this.loading = true;
            var url = "ForumTopic/ReinviteMemberToTopic";
            var data = {
              forumTopicGuid: this.topicGuid,
              forumTopicMemberGuid: item.forumTopicMemberGuid,
            };
            this.axios
              .post(url, data)
              .then((res) => {
                if (res.data.statusCode == 1) {
                  this.$swal.fire({
                    title: this.$t("operationAccomplishedSuccessfully"),
                    text: "",
                    icon: "success",
                    confirmButtonText: this.$t("close"),
                    toast: true,
                    position: "bottom-start",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", this.$swal.stopTimer);
                      toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                    },
                  });
                  this.getGetTopicMembers();
                } else {
                  this.$swal.fire({
                    title: this.$t("errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                }
                this.openAddMembersDialog = false;

                // console.log(res);
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              })
              .finally(() => {
                this.overlay = false;
                this.loading = false;
              });
          }
        });
    },

    toggle() {
      this.$nextTick(() => {
        if (this.AllStudentsSelected) {
          this.studentsSelect = [];
        } else {
          this.studentsSelect = this.studentList.map((m) => m.studentGuid);
        }
      });
    },
    toggleTeacher() {
      this.$nextTick(() => {
        if (this.AllTeachersSelected) {
          this.teachersSelect = [];
        } else {
          this.teachersSelect = this.teachersList.map((m) => m.teacherGuid);
        }
      });
    },
    clearSelect() {
      this.deparmentSelect = null;
      this.stageSelect = null;
      this.sectionSelect = null;
      this.studentsSelect = [];
      this.studentList = [];
    },
    clearSelect2() {
      this.stageSelect = null;
      this.sectionSelect = null;
      this.studentsSelect = [];
      this.studentList = [];
      this.getDepartmentTeachers();
    },
    clearSelect3() {
      this.sectionSelect = null;
      this.studentsSelect = [];
      this.studentList = [];
    },
    openAddMembers() {
      this.openAddMembersDialog = true;
      this.teachersSelect = [];
      this.studentsSelect = [];

      this.getSubjects();
    },
    getFieldText(item) {
      return `${item.stageName} - ${item.studyName}`;
    },

    checkExpire(item) {
      if (!item || !item.endDateEnable) return "";
      return new Date() > new Date(item.endDate) ? "red" : "";
    },
    CheckPermission() {
      return (
        (this.roles && this.roles.includes("AddContentInAllLeaningSite")) ||
        (this.forumTopic.createdUserGuid == this.getUserInfo().userId &&
          ((!this.forumTopic.forum && this.roles && this.roles.includes("University")) ||
            (this.forumTopic.forum != null &&
              this.roles &&
              this.roles.includes(this.forumTopic.forum.level))))
      );
    },

    getIcon(item) {
      switch (item.topicType) {
        case 0:
          return "mdi mdi-account-group";
        case 1:
          return "mdi mdi-text-box";
      }
    },
    getColor(item) {
      switch (item.topicType) {
        case 0:
          return "#D2691E";
        case 1:
          return "primary";
      }
    },
    getMemberName(member) {
      return member.teacher ? member.teacher.teacherName : member.student.studentFullName;
    },
    fromNow(date) {
      return this.$moment(date).fromNow();
    },
    onResize(e) {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1500) {
        this.nvaCols = 0;
        this.forumsCols = 12;
        this.showMembersBtn = true;
      } else {
        this.nvaCols = 3;
        this.forumsCols = 9;
        this.showMembersBtn = false;
        this.membersDrawer = true;
      }
    },
    onUploaded(e) {
      // this.fileURL = [];
      this.topicContentModel.files.push({
        fileUrl: JSON.parse(e.request.responseText).data.fileUrl,
      });

      //   console.log(e);
    },
    onUploadError(e) {
      this.$swal.fire({
        title: this.$t("AnErrorOccurredDuringTheProcess"),
        text: e.request.responseText,
        icon: "error",
        confirmButtonText: this.$t("close"),
      });
    },
    getValueInKb(value) {
      return `${(value / 1024).toFixed(0)}kb`;
    },
    onUploadProgress(e) {
      // console.log(e);
      this.chunks.push({
        segmentSize: e.segmentSize,
        bytesLoaded: e.bytesLoaded,
        bytesTotal: e.bytesTotal,
      });
    },
    deleteTopicContentFile(item) {
      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            //this.topicContentModel.files = this.topicContentModel.files.filter((e) => e.fileUrl !== item.fileUrl);
            const index = this.topicContentModel.files.map((e) => e.fileUrl).indexOf(item.fileUrl);
            if (index > -1) {
              this.topicContentModel.files.splice(index, 1);
            }
          }
        });
    },
    getContentMember(item) {
      if (item.forumTopicContentMembers && item.forumTopicContentMembers.length == 1) {
        return item.forumTopicContentMembers[0];
      } else {
        return { rateContent: null, feedback: null, markAsDone: false };
      }
    },
    openAddRateContent(item) {
      var rate = this.getContentMember(item);
      this.rateContentModel = {
        forumTopicContentGuid: item.forumTopicContentGuid,
        rateContent: rate.rateContent,
        feedback: rate.feedback,
        forumTopicContentMemberGuid: item.forumTopicContentMemberGuid,
      };
      this.openAddRateDialog = true;
    },
    addRateSave() {
      this.overlay = true;
      this.loading = true;
      var url = "ForumTopic/RateContent";
      var data = {
        forumTopicContentGuid: this.rateContentModel.forumTopicContentGuid,
        forumTopicContentMemberGuid: this.rateContentModel.forumTopicContentMemberGuid,
        ratingContent: this.rateContentModel.rateContent,
        feedback: this.rateContentModel.feedback,
      };
      this.axios
        .post(url, data)
        .then((res) => {
          if (res.data.statusCode == 1) {
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            var f = this.topicListContent.filter(
              (m) => m.forumTopicContentGuid == res.data.data.forumTopicContentGuid
            );
            f[0].forumTopicContentMembers = [res.data.data];
            this.$forceUpdate();
          } else if (res.data.statusCode == 2) {
            this.$swal.fire({
              title: this.$t("topic.topicIsExpired"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else if (res.data.statusCode == 3) {
            this.$swal.fire({
              title: this.$t("topic.topicIsLockedCannot"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else {
            this.$swal.fire({
              title: this.$t("errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          }
          this.openAddRateDialog = false;

          // console.log(res);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },
    markContentAsRead(item) {
      this.overlay = true;
      this.loading = true;
      var url = `ForumTopic/MarkContentAsRead?forumTopicContentGuid=${item.forumTopicContentGuid}`;
      this.axios
        .put(url)
        .then((res) => {
          if (res.data.statusCode == 1) {
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            item.forumTopicContentMembers = [res.data.data];
            this.$forceUpdate();
          } else {
            this.$swal.fire({
              title: this.$t("errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          }
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },
    followContent(item) {
      this.overlay = true;
      this.loading = true;
      var url = `ForumTopic/FollowContent?forumTopicContentGuid=${item.forumTopicContentGuid}`;
      this.axios
        .put(url)
        .then((res) => {
          if (res.data.statusCode == 1) {
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            item.forumTopicContentMembers = [res.data.data];
            this.$forceUpdate();
          } else {
            this.$swal.fire({
              title: this.$t("errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          }
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },
    markContentAsViewed(forumTopicContentGuid) {
      var url = `ForumTopic/MarkContentAsViewed?forumTopicContentGuid=${forumTopicContentGuid}`;
      this.axios
        .put(url)
        .then((res) => {})
        .catch(() => {})
        .finally(() => {});
    },
    deleteContent(item) {
      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            this.overlay = true;
            this.loading = true;
            const url = `ForumTopic/DeleteContent?forumTopicContentGuid=${item.forumTopicContentGuid}`;
            this.axios
              .delete(url)
              .then((res) => {
                if (res.data.statusCode == 1) {
                  this.$swal.fire({
                    title: this.$t("operationAccomplishedSuccessfully"),
                    text: "",
                    icon: "success",
                    confirmButtonText: this.$t("close"),
                    toast: true,
                    position: "bottom-start",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", this.$swal.stopTimer);
                      toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                    },
                  });
                  //this.refreshContent(null, "PullDown");
                  const index = this.topicListContent.indexOf(item);
                  if (index > -1) {
                    this.topicListContent.splice(index, 1);
                  }
                }
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              })
              .finally(() => {
                this.overlay = false;
                this.loading = false;
              });
          }
        });
    },
    lockContent(item) {
      this.overlay = true;
      this.loading = true;
      var url = `ForumTopic/LockContent?forumTopicContentGuid=${item.forumTopicContentGuid}`;
      this.axios
        .put(url)
        .then((res) => {
          if (res.data.statusCode == 1) {
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            item.lock = res.data.data.lock;
            item.lockDate = res.data.data.lockDate;
            this.$forceUpdate();
          } else {
            this.$swal.fire({
              title: this.$t("errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          }
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },
    pinContentTop(item) {
      this.overlay = true;
      this.loading = true;
      var url = `ForumTopic/PinContent?forumTopicContentGuid=${item.forumTopicContentGuid}`;
      this.axios
        .put(url)
        .then((res) => {
          if (res.data.statusCode == 1) {
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            this.refreshContent(null, "PullDown");
          } else {
            this.$swal.fire({
              title: this.$t("errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          }
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },

    openRatesContent(item) {
      if (!this.isInRole(2)) {
        return;
      }
      this.overlay = true;
      this.loading = true;

      this.axios
        .get(`ForumTopic/GetRatesContent?forumTopicContentGuid=${item.forumTopicContentGuid}`)
        .then((res) => {
          this.ratesContentList = res.data.data;
          this.openRatesContentList = true;
        })
        .catch(() => {})
        .finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },
    getTotalRates(list) {
      var sum = 0;
      $.each(list, function (key, value) {
        var number = parseInt(value.rateContent);
        sum += number;
      });
      var total = parseFloat(sum / list.length);
      return total ? total : 0;
    },
    // onClickYoutube(e) {
    //   const editor =this.$refs["DxHtmlEditorContent"].instance;
    //   const url = prompt(
    //     "Video URL",
    //     ""
    //   );
    //   editor.focus();
    //   const range = editor.getSelection();
    //   const index = range ? range.index : 0;
    //   if (url) {
    //     editor.insertEmbed(index, "video", url);
    //   }
    // },

    handleScroll(event) {
      let current_time = Date.now();
      if (current_time - this.last_execution < 200) return;

      this.last_execution = current_time;

      if (event.scrollOffset.top > 200) {
        this.showScrollTop = true;
      } else {
        this.showScrollTop = false;
      }

      // if (event.reachedTop) {
      //   this.isScrollUp = true;
      //   return;
      // }
      // if (event.reachedBottom) {
      //   this.isScrollUp = true;
      //   return;
      // }

      // var newValue = event.scrollOffset.top;
      // if (this.oldScroll == newValue) {
      //   return;
      // }

      // if (this.oldScroll < newValue) {
      //   this.isScrollUp = false;
      // } else {
      //   this.isScrollUp = true;
      // }

      // this.oldScroll = newValue;
    },
    showReplies(item) {
      this.replyOnContent = item;
      this.showReplyDialog = true;
    },
    scrollToTop() {
      this.$refs["scrollViewWidget"].instance.scrollTo(0);
    },
    onSearch(e) {
      if (e.key == "Enter" && (!this.searchText || this.searchText.length > 0))
        this.refreshContent(null, "PullDown");
    },
    openTopic(topic) {
      if (this.$route.query.topicGuid != topic.forumTopicGuid) {
        this.$router.push({
          query: {
            topicGuid: topic.forumTopicGuid,
          },
        });
      }
      this.$emit("openTopic", {
        topicGuid: topic.forumTopicGuid,
      });
    },
    getForumIcon(item) {
      if (item.subject && item.autoGenerated) {
        return "mdi mdi-book-open-blank-variant";
      }

      switch (item.forumType) {
        case this.forumType.Announcements:
          return "mdi mdi-bullhorn-variant";
        case this.forumType.News:
          return "mdi mdi-newspaper-variant-outline";
        case this.forumType.Events:
          return "mdi mdi-calendar-multiple";
        case this.forumType.Files:
          return "mdi mdi-file-multiple";
        case this.forumType.Subjects:
          return "mdi mdi-book-open";
        default:
          return "mdi mdi-forum";
      }
    },
    getForumColor(item) {
      switch (item.forumType) {
        case this.forumType.Announcements:
          return "#FF8C00";
        case this.forumType.News:
          return "#01796F";
        case this.forumType.Events:
          return "#007FFF";
        case this.forumType.Files:
          return "#964B00";
        case this.forumType.Subjects:
          return "#C71585";
        default:
          return "primary";
      }
    },
    getForumText(item) {
      if (item.autoGenerated) {
        return this.$t(`forum.${item.title}`);
      } else {
        return item.title;
      }
    },
    selectContent() {
      setTimeout(() => {
        if (this.selectForumTopicContentGuid) {
          if (this.$refs["scrollViewWidget"]) {
            var scroll = this.$refs["scrollViewWidget"].instance;
            if (scroll) {
              if (this.$refs[this.selectForumTopicContentGuid]) {
                var targetLocation = this.$refs[this.selectForumTopicContentGuid][0];
                var to = $(targetLocation.$el).position().top - 100;
                scroll.scrollTo(to);
                $(targetLocation.$el).highlight();
                this.markContentAsViewed(this.selectForumTopicContentGuid);
                this.selectForumTopicContentGuid = null;
                this.openTopic({ forumTopicGuid: this.topicGuid });
                this.openRepliesFromNotifications();
                this.openRatesFromNotifications();
              }
            }
          }
        }
      }, 0);
    },
    openRepliesFromNotifications() {
      if (this.openReplyByForumTopicContentGuid) {
        var item = this.topicListContent.find(
          (m) => m.forumTopicContentGuid == this.openReplyByForumTopicContentGuid
        );
        if (item) {
          this.showReplies(item);
          this.openReplyByForumTopicContentGuid = null;
        }
      }
    },
    openRatesFromNotifications() {
      if (this.openRatesByForumTopicContentGuid) {
        var item = this.topicListContent.find(
          (m) => m.forumTopicContentGuid == this.openRatesByForumTopicContentGuid
        );
        if (item) {
          this.openRatesContent(item);
          this.openRatesByForumTopicContentGuid = null;
        }
      }
    },
    showMore(item) {
      item.showLess = true;
      this.$forceUpdate();
    },
    showLess(item) {
      item.showLess = false;
      this.$forceUpdate();
    },
    checkContentHeight(item) {
      var id = item.forumTopicContentGuid + "_content";
      var h = $("#" + id).height();
      return h >= 600;
    },
  },
};
</script>

<style>
.forum-tree .v-navigation-drawer__content {
  padding-bottom: 0 !important;
}
label[for="show_all_members"] {
  font-size: small;
}
.v-slide-group__next {
  padding-right: 10px;
  padding-left: 10px;
}
.v-slide-group__prev {
  padding-right: 10px;
  padding-left: 10px;
}
.dx-fileuploader-files-container {
  max-height: 160px;
  overflow-y: auto;
}
.dx-scrollview-pull-down {
  height: unset !important;
}
.topic-content::-webkit-scrollbar {
  width: 10px;
}
</style>
