/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import myDirective from "vue-touch-card-effect";
import VAvatar from "v-avatar";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "devextreme/dist/css/dx.common.css";
// import 'devextreme/dist/css/dx.material.blue.light.css';

import VeeValidate from "vee-validate"; // import Vuelidate from 'vuelidate'
import jwt_decode from "jwt-decode";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/base";
// import "./plugins/vue-world-map";
// eslint-disable-next-line import/order
import VueSplash from "vue-splash";
// eslint-disable-next-line import/order
import JsonExcel from "vue-json-excel";
// eslint-disable-next-line import/order
import QrcodeVue from "qrcode.vue";

const VueCookie = require("vue-cookie");

Vue.use(VueCookie);
// import { createConnection, start } from "./userHub";
// Vue.config.devtools = false;
Vue.config.productionTip = false;
Vue.use(VueSplash);
const moment = require("moment");
// require("moment/locale/ar");
moment.locale(localStorage.getItem("lang") === "en" ? "en" : "ar-dz");
Vue.use(require("vue-moment"), { moment });
// Vue.moment.locale("ar");
Vue.component("downloadExcel", JsonExcel);
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields"
});
Vue.use(VAvatar);
Vue.use(QrcodeVue);

// Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueAxios, axios);
// if (axios.defaults.baseURL === "" || axios.defaults.baseURL === undefined) {
//   axios.get("static/config.json").then(res => {
//     axios.defaults.baseURL = res.data.VUE_APP_URL;
//     store.commit("SET_UPLOADER", res.data.VUE_APP_UPLOAD);
//     store.commit("SET_PATHURL", res.data.VUE_APP_FILE_URL);
//   });
// }
Vue.directive("touch-card", myDirective);
Vue.config.productionTip = false;
// axios.defaults.baseURL = process.env.VUE_APP_URL;
axios.defaults.headers.get.Accepts = "application/json";
axios.interceptors.request.use(
  config => {
    store.commit("overlay", true);
    const token = localStorage.getItem("token");
    const auth = token ? `Bearer ${token}` : "";
    config.headers.common.Authorization = auth;
    return config;
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  response => {
    store.commit("overlay", false);
    return response;
  },
  error => {
    store.commit("overlay", false);
    if (error.response.status === 401 || error.response.status === 403) {
      // store.dispatch('logout')
      router.push({ name: "Login" });
    }
    return Promise.reject(error);
  }
);

Vue.mixin({
  created(){
    $.fn.highlight = function () {
      $(this).each(function () {
          var el = $(this);
          $("<div/>")
          .width(el.outerWidth())
          .height(el.outerHeight())
          .css({
              "position": "absolute",
              "left": el.offset().left,
              "top": el.offset().top,
              "background-color": "#ffffcc",
              "opacity": ".5",
          }).appendTo('body').fadeOut(1000).queue(function () { $(this).remove(); });
      });
  }
  },
  computed: {},
  methods: {
    decodeToken() {
      if (store.state.decodedToken) {
        return store.state.decodedToken;
      }

      const token = localStorage.getItem("token");
      const decoded = jwt_decode(token);
      store.commit("SET_DECODED_TOKEN", decoded);
      return decoded;
    },
    isInRole(role) {
      const roles = [this.decodeToken().role];
      return roles.indexOf(String(role)) > -1;
    },
    getUserInfo() {
      const data = this.decodeToken();
      return {
        userId: data.unique_name,
        userType: data.PersonType,
        fullName: data.given_name,
        userName: data.email
      };
    },

    getColorFromName(name) {
      return this.stringToHslColor(name, 40, 50);
    },
    getShortName(name) {
      return name.trim()
        .split(" ")
        .slice(0, 2)
        .map(str => (str ? str[0].toUpperCase() : ""))
        .join(".");
    },
    stringToHslColor(str, s, l) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      const h = hash % 360;
      return `hsl(${h}, ${s}%, ${l}%)`;
    },
    dateFromNow(date) {
      if (!date) {
        return null;
      }
      return this.$moment(date).fromNow();
    },
    getYoutube(inputText) {
      var youtubePattern = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?([\w\-]{10,12})(?:&feature=related)?(?:[\w\-]{0})?/g;

      if (youtubePattern.test(inputText)) {
        var replacement =
          '<iframe  src="https://www.youtube.com/embed/$1" frameborder="0" allowfullscreen></iframe>';
        inputText = inputText.replace(youtubePattern, replacement);
        inputText = inputText.replace("&amp;t=", "?t=");
      }
      return inputText;
    },
    downloadLink(url) {
      this.axios
        .post(`Uploader/Download`, {
          fileUrl: url,
        })
        .then((res) => {
          window.open(res.data.data.url, "_blank");
        })
        .catch((err) => {})
        .finally(() => {});
    },
  }
});

const variableName = vuetify.framework.theme.isDark ? "dark" : "light";
System.import(`devextreme/dist/css/dx.material.blue.${variableName}.css`);
if (localStorage.getItem("token")) {
  // createConnection();
  // start();
}
export const bus = new Vue();

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
