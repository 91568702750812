var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',{staticClass:"pa-1"},[_c('v-card-subtitle',{staticStyle:{"width":"100%"}},[_c('v-row',[_c('v-list-item-avatar',{staticClass:"pb-1 white--text px-0 ms-2",attrs:{"size":"35","color":_vm.getColorFromName(_vm.item.personName)}},[_c('span',{staticStyle:{"font-size":"12px !important"}},[_vm._v(_vm._s(_vm.getShortName(_vm.item.personName)))])]),_c('span',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.item.personName))]),_c('v-spacer'),_c('v-chip',{attrs:{"small":"","outlined":"","label":""}},[_vm._v("\n        "+_vm._s(_vm.dateFromNow(_vm.item.createdDate))+"\n      ")]),(_vm.getUserInfo().userId == _vm.item.createdUserGuid && !_vm.content.lock)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","dense":"","small":"","shaped":"","text":"","width":"20","color":"grey"}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("more")))])])]}}],null,false,391034244)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.editReplyContent(_vm.item)}}},[_c('v-list-item-icon',{staticClass:"mx-2"},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("\n                "+_vm._s(_vm.item.editMode ? "mdi-close" : "mdi-pencil")+"\n              ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("edit")))])],1),_c('v-list-item',{attrs:{"disabled":_vm.item.editMode},on:{"click":function($event){return _vm.onDeleteReplyItem(_vm.item)}}},[_c('v-list-item-icon',{staticClass:"mx-2"},[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("delete")))])],1)],1)],1):_vm._e()],1),_c('v-row',{staticClass:"mt-0"},[(!_vm.item.editMode)?_c('v-card-subtitle',{staticClass:"text-wrap text-justify pb-0",staticStyle:{"width":"100%"},attrs:{"dir":"auto"}},[_vm._v(_vm._s(_vm.item.contentText)),_c('br'),(_vm.item.modifiedDate)?_c('span',{staticClass:"text-caption",staticStyle:{"color":"#0080e0"},attrs:{"title":_vm.dateFromNow(_vm.item.modifiedDate)}},[_vm._v(_vm._s(_vm.$t("edited")))]):_vm._e()]):_c('v-text-field',{staticClass:"my-4",attrs:{"dir":_vm.item.replyEdit ? 'auto' : '',"autofocus":"","label":_vm.$t('edit'),"placeholder":_vm.$t('edit'),"outlined":"","dense":"","hide-details":""},on:{"keydown":function (e) {
            _vm.onKeyDownReplyItem(e, _vm.item);
          }},model:{value:(_vm.item.replyEdit),callback:function ($$v) {_vm.$set(_vm.item, "replyEdit", $$v)},expression:"item.replyEdit"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }