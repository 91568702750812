<template>
  <v-card flat :loading="loadingForums">
    <v-toolbar color="primary" dark flat dense>
      <v-app-bar-nav-icon @click="onChangeDrawer"></v-app-bar-nav-icon>
      <v-card-text class="text-center pa-2">{{
        getNavHeaderTitle()
      }}</v-card-text>
    </v-toolbar>

    <v-toolbar elevation="0" outlined dense id="category_toolbar">
      <v-text-field
        v-model="searchText"
        dense
        filled
        rounded
        @keydown="searchOnTopic"
        clearable
        :placeholder="$t('search')"
        prepend-inner-icon="mdi-magnify"
        style="width: 100% !important"
        class="pt-6 shrink expanding-search"
        :class="{ closed: searchBoxClosed && !searchText }"
        @focus="searchBoxClosed = false"
        @blur="
          searchBoxClosed = true;
          searchText = null;
        "
      ></v-text-field>

      <v-fab-transition leave-absolute mode="">
        <v-menu
          v-if="
            searchBoxClosed &&
            isInRole(2) &&
            CheckPermission(currentForum) &&
            !isTopic
          "
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  fab
                  class="ms-1"
                  color="primary"
                  small
                  depressed
                  shaped
                  text
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                >
                  <v-icon>mdi-plus-thick</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("add") }}</span>
            </v-tooltip>
          </template>
          <v-list dense>
            <v-list-item @click="openAddForum(forumType.Category, false)">
              <v-list-item-icon class="mx-2">
                <v-icon color="primary">mdi-forum</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{
                $t("forum.AddCategory")
              }}</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="openAddForum(null, false)">
              <v-list-item-icon class="mx-2">
                <v-icon color="amber">mdi-file</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{
                $t("forum.AddForumTopics")
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-else-if="
            searchBoxClosed &&
            isInRole(2) &&
            CheckPermission(currentForum) &&
            isTopic &&
            this.forumGuid
          "
          class="ms-1"
          fab
          color="primary"
          @click="openAddTopic(null, false)"
          small
          depressed
          shaped
          text
        >
          <v-icon>mdi-plus-thick</v-icon></v-btn
        >
      </v-fab-transition>
      <v-spacer></v-spacer>

      <v-fade-transition leave-absolute>
        <v-toolbar-items v-if="searchBoxClosed">
          <v-btn :title="$t('refresh')" small icon @click="onChangeRoute(null)"
            ><v-icon color="primary">mdi mdi-refresh-circle</v-icon>
          </v-btn>
          <v-btn
            @click="goToPage({ forumGuid: currentForum.forumParentGuid })"
            class="mx-2"
            v-if="currentForum"
            small
            icon
            :title="$t('back')"
            ><v-icon color="primary">
              {{
                $vuetify.rtl
                  ? "mdi mdi-arrow-right-circle"
                  : "mdi mdi-arrow-left-circle"
              }}</v-icon
            ></v-btn
          >
          <v-btn
            @click="goToPage({ forumGuid: null })"
            small
            icon
            :title="$t('drawer.home')"
            ><v-icon color="primary">mdi mdi-home</v-icon></v-btn
          >
        </v-toolbar-items>
      </v-fade-transition>
    </v-toolbar>
    <v-list dense class="pa-0">
      <v-list-item-group
        :mandatory="defaultForumsItemsSelect != null"
        :value="defaultForumsItemsSelect"
      >
        <div v-for="item in defaultForumsItems" :key="item.type">
          <v-list-item :value="item.type" class="pa-0" @click="goToPage(item)">
            <v-list-item-avatar tile class="ma-1" :title="item.hint">
              <v-icon :color="item.iconColor">
                {{ item.icon }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </div>
      </v-list-item-group>
    </v-list>

    <v-scroll-x-transition>
      <v-list dense v-if="!loadingForums" class="pt-0">
        <v-list-item-group
          :mandatory="selectedForum != null"
          :value="selectedForum"
          v-if="!currentForum || currentForum.forumType == forumType.Category"
        >
          <div v-for="(item, index) in forumsData" :key="item.forumGuid">
            <v-list-item
              link
              @click="goToPage(item)"
              class="pa-0"
              :value="item.forumGuid"
            >
              <v-list-item-avatar
                :title="item.title"
                v-if="
                  item.autoGenerated &&
                  item.forumType == 5 &&
                  item.subject &&
                  item.subject.coverImageUrl
                "
                class="ma-1"
              >
                <v-img :src="item.subject.coverImageUrl"> </v-img>
              </v-list-item-avatar>

              <v-list-item-avatar v-else tile class="ma-1" :title="item.title">
                <v-icon :color="getColor(item)">
                  {{ getIcon(item) }}
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content :title="getExtraInfo(item)">
                <v-list-item-title class="text-wrap">{{
                  getText(item)
                }}</v-list-item-title>
                <v-list-item-subtitle>
                  <v-icon
                    v-if="!item.autoGenerated"
                    small
                    :color="checkExpire(item) ? 'red' : item.endDateEnable ?'orange': ''"
                    :title="getStartEndDate(item)"
                  >
                    mdi mdi mdi-clock-time-eight-outline
                  </v-icon>
                  <v-icon
                    v-if="!item.visible"
                    :title="$t('forum.invisible')"
                    class="mx-2"
                    small
                    color="red"
                    >mdi mdi-eye-off</v-icon
                  >
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="ma-0">
                <v-menu
                  v-if="
                    CheckPermission(item) &&
                    isInRole(2) &&
                    !item.autoGenerated &&
                    getUserInfo().userId == item.createdUserGuid
                  "
                >
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          depressed
                          shaped
                          text
                          color="grey"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("more") }}</span>
                    </v-tooltip>
                  </template>
                  <v-list dense>
                    <v-list-item
                      @click="openAddForum(item.forumType, true, item)"
                    >
                      <v-list-item-icon class="mx-2">
                        <v-icon dense>mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ $t("edit") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteForum(item)">
                      <v-list-item-icon class="mx-2">
                        <v-icon dense>mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ $t("delete") }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>

            <v-divider
              v-if="index < forumsData.length - 1"
              :key="index"
            ></v-divider>
          </div>
        </v-list-item-group>
        <v-list-item-group
          v-else
          :mandatory="selectedTopic != null"
          :value="selectedTopic"
        >
          <div v-for="(item, index) in topicsData" :key="item.forumTopicGuid">
            <v-list-item
              link
              @click="openTopicList(item)"
              class="pa-0"
              :value="item.forumTopicGuid"
            >
              <v-list-item-avatar
                :title="item.topicName"
                class="ma-1 me-2"
                :color="getTopicColor(item)"
              >
                <v-icon color="white">
                  {{ getTopicIcon(item) }}
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content :title="getTopicExtraInfo(item)">
                <v-list-item-title class="text-wrap"
                  >{{ item.topicName }}
                </v-list-item-title>

                <v-list-item-subtitle class="py-2 text-wrap">
                  <v-list-item-title class="text-wrap">{{
                    getText(item)
                  }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-icon
                      v-if="getUserInfo().userId == item.createdUserGuid"
                      small
                      color="secondary"
                      class="me-2"
                      :title="$t('admin')"
                    >
                      mdi mdi-shield-crown-outline
                    </v-icon>
                    <v-icon
                      small
                      :color="checkExpire(item) ? 'red' : item.endDateEnable ?'orange': ''"
                      class="me-2"
                      :title="getStartEndDate(item)"
                    >
                      mdi mdi-clock-time-eight-outline
                    </v-icon>
                    <v-icon
                      v-if="!item.visible"
                      :title="$t('forum.invisible')"
                      small
                      class="me-2"
                      color="red"
                      >mdi mdi-eye-off</v-icon
                    >

                    <v-icon
                      v-if="item.topicMemberMode == 1"
                      :title="$t('topic.topicModeRestricted')"
                      class="me-2"
                      small
                      >mdi mdi-account-lock-outline
                    </v-icon>
                    <v-icon
                      v-if="isTopicFavorite(item)"
                      small
                      color="orange"
                      class="me-2"
                      :title="$t('forum.favorite')"
                    >
                      mdi mdi-star
                    </v-icon>
                    <v-icon
                      v-if="
                        !item.forumTopicMembers ||
                        item.forumTopicMembers.length == 0
                      "
                      small
                      color="orange"
                      class="me-2"
                      :title="$t('topic.notJoined')"
                    >
                      mdi mdi-account-alert-outline
                    </v-icon>
                  </v-list-item-subtitle>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="ma-0">
                <v-menu
                  v-if="
                    (isInRole(2) &&
                      getUserInfo().userId == item.createdUserGuid) ||
                    (getUserInfo().userId != item.createdUserGuid &&
                      item.forumType != forumType.Category)
                  "
                >
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          depressed
                          shaped
                          text
                          color="grey"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("more") }}</span>
                    </v-tooltip>
                  </template>
                  <v-list dense>
                    <v-list-item
                      @click="addTopicToFavorite(item, !isTopicFavorite(item))"
                    >
                      <v-list-item-icon class="mx-2">
                        <v-icon
                          :color="!isTopicFavorite(item) ? 'orange' : 'grey'"
                          dense
                          >{{
                            isTopicFavorite(item) ? "mdi-star-off" : "mdi-star"
                          }}</v-icon
                        >
                      </v-list-item-icon>
                      <v-list-item-title>{{
                        isTopicFavorite(item)
                          ? $t("topic.removeFromFavorite")
                          : $t("topic.addToFavorite")
                      }}</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      v-if="
                        CheckPermission(item.forum) &&
                        isInRole(2) &&
                        getUserInfo().userId == item.createdUserGuid
                      "
                      @click="openAddTopic(item, true)"
                    >
                      <v-list-item-icon class="mx-2">
                        <v-icon dense>mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ $t("edit") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="
                        CheckPermission(item.forum) &&
                        isInRole(2) &&
                        getUserInfo().userId == item.createdUserGuid
                      "
                      @click="deleteTopic(item)"
                    >
                      <v-list-item-icon class="mx-2">
                        <v-icon dense>mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ $t("delete") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="
                        getUserInfo().userId != item.createdUserGuid &&
                        item.topicMemberMode == 1
                      "
                      @click="leaveTopic(item)"
                    >
                      <v-list-item-icon class="mx-2">
                        <v-icon color="red" dense
                          >mdi-account-arrow-left</v-icon
                        >
                      </v-list-item-icon>
                      <v-list-item-title>{{
                        $t("topic.leave")
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="index < topicsData.length - 1"
              :key="index"
            ></v-divider>
          </div>
        </v-list-item-group>
      </v-list>
    </v-scroll-x-transition>

    <v-dialog
      v-if="isInRole(2)"
      transition="dialog-bottom-transition"
      persistent
      max-width="900"
      v-model="addForumPopup"
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark>
          {{
            addForumType != forumType.Category
              ? $t("forum.AddForumTopics")
              : $t("forum.AddCategory")
          }}
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-text-field
            :label="$t('forum.title')"
            outlined
            v-model="forumModel.title"
            :rules="[required]"
          ></v-text-field>
          <v-textarea
            :label="$t('forum.description')"
            outlined
            v-model="forumModel.description"
            hide-details
          ></v-textarea>
          <v-select
            v-if="addForumType != forumType.Category"
            :items="forumTypeDataSource"
            v-model="forumModel.forumType"
            :label="$t('forum.forumType')"
            outlined
            item-text="text"
            item-value="forumType"
            hide-details
            class="mt-3"
            :rules="[required]"
          >
            <template v-slot:item="{ item }">
              <v-icon :color="getColor(item)" class="ml-3 mr-3">{{
                getIcon(item)
              }}</v-icon
              >{{ item.text }}
            </template>
          </v-select>
          <v-checkbox
            v-model="forumModel.endDateEnable"
            :label="$t('forum.endDateEnable')"
            hide-details
          >
          </v-checkbox>
          <v-row class="pa-0 ma-0">
            <v-col class="py-0 ma-0">
              <span style="font-size: 12px">{{ $t("mail.dateStart") }} </span>
              <DxDateBox
                display-format="yyyy/MM/dd hh:mm:ss a"
                :placeholder="$t('mail.dateStart')"
                styling-mode="outlined"
                v-model="forumModel.startDate"
                :value="forumModel.startDate"
                type="datetime"
                :use-mask-behavior="true"
              />
              <div class="v-text-field__details">
                <div class="v-messages theme--light">
                  <div class="v-messages__wrapper"></div>
                </div>
              </div>
            </v-col>
            <v-col class="py-0 ma-0">
              <span style="font-size: 12px">{{ $t("mail.dateEnd") }} </span>
              <DxDateBox
                :disabled="!forumModel.endDateEnable"
                display-format="yyyy/MM/dd hh:mm:ss a"
                :placeholder="$t('mail.dateEnd')"
                styling-mode="outlined"
                v-model="forumModel.endDate"
                :value="forumModel.endDate"
                type="datetime"
                :use-mask-behavior="true"
              />
              <div class="v-text-field__details">
                <div class="v-messages theme--light">
                  <div class="v-messages__wrapper"></div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-checkbox
            hide-details
            v-model="forumModel.visible"
            :label="$t('forum.visible')"
          >
          </v-checkbox>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="addForumPopup = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            :loading="loading"
            color="primary darken-1"
            @click="SaveForum()"
            :disabled="
              !forumModel.title ||
              (addForumType != forumType.Category &&
                forumModel.forumType == null)
            "
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="isInRole(2)"
      transition="dialog-bottom-transition"
      persistent
      max-width="900"
      v-model="addTopicPopup"
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark>
          {{ $t("topic.addTopic") }}
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-text-field
            :label="$t('forum.title')"
            outlined
            v-model="topicModel.topicName"
            :rules="[required]"
          ></v-text-field>
          <v-select
            :items="topicTypeDataSource"
            v-model="topicModel.topicType"
            :label="$t('topic.topicType')"
            outlined
            item-text="text"
            item-value="value"
            hide-details
            class="mt-3"
            :rules="[required]"
          >
            <template v-slot:item="{ item }">
              <v-card-text>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.hint }}
                </v-list-item-subtitle>
              </v-card-text>
            </template>
          </v-select>
          <v-select
            :items="topicMemberModeDataSource"
            v-model="topicModel.topicMemberMode"
            :label="$t('topic.topicMemberMode')"
            outlined
            item-text="text"
            item-value="value"
            hide-details
            class="mt-3"
            :rules="[required]"
          >
            <template v-slot:item="{ item }">
              <v-card-text>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.hint }}
                </v-list-item-subtitle>
              </v-card-text>
            </template>
          </v-select>

          <v-autocomplete
            class="mt-3"
            v-if="topicModel.topicMemberMode == 1 && !this.isEdit"
            v-model="topicModel.students"
            :items="students"
            outlined
            multiple
            :label="$t('mail.ChooseStudents')"
            item-text="studentFullName"
            item-value="studentGuid"
            clearable
            chips
            small-chips
            deletable-chips
            hide-details
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 3">
                <span>{{ item.studentFullName }}</span>
              </v-chip>
              <span v-if="index === 3" class="grey--text caption">
                (+{{ topicModel.students.length - 3 }} {{ $t("mail.Others") }})
              </span>
            </template>
            <template v-slot:prepend-item>
              <v-list-item ripple @click="toggle">
                <v-list-item-action>
                  <v-icon
                    :color="topicModel.students.length > 0 ? 'primary' : ''"
                  >
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("mail.selectAll")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item disabled>
                <v-list-item-avatar color="grey lighten-3">
                  <v-icon light> mdi-account-question-outline </v-icon>
                </v-list-item-avatar>

                <v-list-item-content v-if="AllStudentsSelected">
                  <v-list-item-title>
                    {{ $t("mail.AllHaveBeenSelected") }}</v-list-item-title
                  >
                </v-list-item-content>

                <v-list-item-content v-else-if="SomeStudentsSelected">
                  <v-list-item-title>
                    {{ $t("mail.TheNumberOfStudentsSpecified") }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ topicModel.students.length }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title>
                    {{ $t("mail.NoStudentIdentified") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>

          <v-autocomplete
            v-if="topicModel.topicMemberMode == 1 && !this.isEdit"
            class="mt-3"
            v-model="topicModel.teachers"
            :items="teachers"
            outlined
            multiple
            :label="$t('selectTeacher')"
            item-text="teacherName"
            item-value="teacherGuid"
            clearable
            chips
            small-chips
            deletable-chips
            hide-details
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 3">
                <span>{{ item.teacherName }}</span>
              </v-chip>
              <span v-if="index === 3" class="grey--text caption">
                (+{{ topicModel.teachers.length - 3 }} {{ $t("mail.Others") }})
              </span>
            </template>
            <template v-slot:prepend-item>
              <v-list-item ripple @click="toggleTeacher">
                <v-list-item-action>
                  <v-icon
                    :color="topicModel.teachers.length > 0 ? 'primary' : ''"
                  >
                    {{ icon2 }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("mail.selectAll")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item disabled>
                <v-list-item-avatar color="grey lighten-3">
                  <v-icon light> mdi-account-question-outline </v-icon>
                </v-list-item-avatar>

                <v-list-item-content v-if="AllTeachersSelected">
                  <v-list-item-title>
                    {{ $t("mail.AllHaveBeenSelected") }}</v-list-item-title
                  >
                </v-list-item-content>

                <v-list-item-content v-else-if="SomeTeachersSelected">
                  <v-list-item-title>
                    {{ $t("mail.TheNumberOfStudentsSpecified") }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ topicModel.teachers.length }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title>
                    {{ $t("mail.NoStudentIdentified") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>

          <!-- <v-autocomplete
            v-if="currentForum && currentForum.level == 'Subject'"
            class="mt-3"
            v-model="topicModel.shareWithForumGuid"
            :items="subjectForumsList"
            outlined
            multiple
            :label="$t('topic.shareWithForum')"
            item-text="forumName"
            item-value="forumGuid"
            clearable
            chips
            small-chips
            deletable-chips
            hide-details
          >
          </v-autocomplete> -->

          <v-checkbox
            v-model="topicModel.endDateEnable"
            :label="$t('forum.endDateEnable')"
            hide-details
          >
          </v-checkbox>
          <v-row class="pa-0 ma-0">
            <v-col class="py-0 ma-0">
              <span style="font-size: 12px">{{ $t("mail.dateStart") }} </span>
              <DxDateBox
                display-format="yyyy/MM/dd hh:mm:ss a"
                :placeholder="$t('mail.dateStart')"
                styling-mode="outlined"
                v-model="topicModel.startDate"
                :value="topicModel.startDate"
                type="datetime"
                :use-mask-behavior="true"
              />
              <div class="v-text-field__details">
                <div class="v-messages theme--light">
                  <div class="v-messages__wrapper"></div>
                </div>
              </div>
            </v-col>
            <v-col class="py-0 ma-0">
              <span style="font-size: 12px">{{ $t("mail.dateEnd") }} </span>
              <DxDateBox
                :disabled="!topicModel.endDateEnable"
                display-format="yyyy/MM/dd hh:mm:ss a"
                :placeholder="$t('mail.dateEnd')"
                styling-mode="outlined"
                v-model="topicModel.endDate"
                :value="topicModel.endDate"
                type="datetime"
                :use-mask-behavior="true"
              />
              <div class="v-text-field__details">
                <div class="v-messages theme--light">
                  <div class="v-messages__wrapper"></div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-checkbox
            hide-details
            v-model="topicModel.visible"
            :label="$t('forum.visible')"
          >
          </v-checkbox>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="addTopicPopup = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            :loading="loading"
            color="primary darken-1"
            @click="topicSave()"
            :disabled="!topicModel.topicName"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import DxDateBox from "devextreme-vue/date-box";

export default {
  name: "forumItemsList",
  components: { DxDateBox },
  data() {
    return {
      required: (value) =>
        !!value || parseInt(value) >= 0 || this.$t("ThisFieldIsRequired"),
      addForumType: null,
      addForumPopup: false,
      isEdit: false,
      forumModel: {
        forumGuid: null,
        parentForumGuid: null,
        title: null,
        description: null,
        forumType: null,
        startDate: new Date(),
        endDate: null,
        endDateEnable: false,
        visible: true,
        stageGuid: null,
        subjectGuid: null,
      },
      fab: false,
      selectedForum: null,
      selectedTopic: null,
      loading: false,
      forumsData: [],
      currentForum: null,
      forumTypeDataSource: [
        {
          forumType: 0,
          text: this.$t("forum.Announcements"),
        },
        {
          forumType: 1,
          text: this.$t("forum.News"),
        },
        {
          forumType: 2,
          text: this.$t("forum.Events"),
        },
        {
          forumType: 3,
          text: this.$t("forum.Files"),
        },
        {
          forumType: 4,
          text: this.$t("forum.Subjects"),
        },
      ],
      forumType: {
        Announcements: 0,
        News: 1,
        Events: 2,
        Files: 3,
        Subjects: 4,
        Category: 5,
      },
      roles: [],
      forumGuid: null,
      loadingForums: false,
      searchText: null,
      searchBoxClosed: true,
      topicModel: {
        forumTopicGuid: null,
        topicName: null,
        topicType: 1,
        topicMemberMode: 0,
        visible: 1,
        startDate: new Date(),
        endDate: null,
        endDateEnable: 0,
        forumGuid: null,
        teachers: [],
        students: [],
        shareWithForumGuid: [],
      },
      topicMemberModeDataSource: [
        {
          text: this.$t("topic.topicModeGeneral"),
          hint: this.$t("topic.topicModeGeneralHint"),
          value: 0,
        },
        {
          text: this.$t("topic.topicModeRestricted"),
          hint: this.$t("topic.topicModeRestrictedHint"),
          value: 1,
        },
      ],
      topicTypeDataSource: [
        {
          text: this.$t("topic.discussionForum"),
          hint: this.$t("topic.discussionForumHint"),
          value: 0,
        },
        {
          text: this.$t("topic.singleActivity"),
          hint: this.$t("topic.singleActivityHint"),
          value: 1,
        },
      ],
      requiredList: (value) =>
        value.length > 0 || this.$t("ThisFieldIsRequired"),
      addTopicPopup: false,
      topicsData: [],
      students: null,
      teachers: null,
      subjectForumsList: [],
      defaultForumsItems: [
        {
          type: "recently",
          icon: "mdi-rss-box",
          iconColor: "secondary",
          title: this.$t("forum.recently"),
          hint: this.$t("forum.recently"),
        },
        {
          type: "myTopics",
          icon: "mdi-account-group-outline",
          iconColor: "primary",
          title: this.$t("forum.myTopics"),
          hint: this.$t("forum.myTopics"),
        },
        {
          type: "favorite",
          icon: "mdi-star",
          iconColor: "orange",
          title: this.$t("forum.favorite"),
          hint: this.$t("forum.favorite"),
        },
      ],
      defaultForumsItemsSelect: null,
    };
  },
  created() {
    if (!this.$route.query.forumGuid && !this.$route.query.topicGuid) {
      this.defaultForumsItemsSelect = "recently";
    }
    if (this.$route.query && this.$route.query.type) {
      this.defaultForumsItemsSelect = this.$route.query.type;
    }

    this.onChangeRoute(null);
  },
  computed: {
    AllStudentsSelected() {
      return this.topicModel.students.length === this.students.length;
    },
    SomeStudentsSelected() {
      return this.topicModel.students.length > 0 && !this.AllStudentsSelected;
    },
    icon() {
      if (this.AllStudentsSelected) return "mdi-close-box";
      if (this.SomeStudentsSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    AllTeachersSelected() {
      return this.topicModel.teachers.length === this.teachers.length;
    },
    SomeTeachersSelected() {
      return this.topicModel.teachers.length > 0 && !this.AllTeachersSelected;
    },
    icon2() {
      if (this.AllTeachersSelected) return "mdi-close-box";
      if (this.SomeTeachersSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    isTopic() {
      return !(
        !this.currentForum ||
        this.currentForum.forumType == this.forumType.Category
      );
    },
  },
  watch: {
    $route(to, from) {
      this.defaultForumsItemsSelect = null;
      this.selectedTopic = null;
      this.selectedForum = null;

      if (to.query && to.query.type) {
        this.defaultForumsItemsSelect = to.query.type;

        return;
      } else if (to.query && !to.query.topicGuid) {
        this.loadingForums = true;
        this.onChangeRoute(null);
      }

      if (to.query) {
        if (to.query.topicGuid) {
          this.selectedTopic = to.query.topicGuid;
        } else {
          this.selectedTopic = null;
        }
        if (to.query.forumGuid) {
          this.selectedForum = to.query.forumGuid;
        } else {
          this.selectedForum = null;
        }
      }
    },
  },

  methods: {
    getTopics() {
      var url = "ForumTopic/GetTopics?";
      if (!this.forumGuid) {
        url += "topicGuid=" + this.selectedTopic;
      } else {
        url += "forumGuid=" + this.forumGuid;
      }
      this.overlay = true;
      this.axios
        .get(url)
        .then((res) => {
          this.topicsData = res.data.data.forumTopic;
          this.currentForum = res.data.data.currentforum;
          this.forumGuid = this.currentForum.forumGuid;
          this.roles = res.data.data.roles;

          if (this.topicsData && this.topicsData.length > 0) {
            // if (!this.selectedTopic) {
            //   this.selectedTopic = this.topicsData[0].forumTopicGuid;
            // }
            // this.openTopicList({ forumTopicGuid: this.selectedTopic });
          } else {
            this.goToPage({ forumGuid: this.currentForum.forumGuid });
            this.openTopicList({ forumTopicGuid: null });
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;
        });
    },
    getForums() {
      this.overlay = true;

      const url = this.forumGuid
        ? `Forums/GetForums?forumGuid=${this.forumGuid}`
        : `Forums/GetForums`;

      this.axios
        .get(url)
        .then((res) => {
          this.forumsData = res.data.data.forums;

          this.currentForum = res.data.data.currentForum;
          this.roles = res.data.data.roles;

          if (
            this.currentForum &&
            this.currentForum.forumType != this.forumType.Category
          ) {
            this.getTopics();
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loadingForums = false;
          this.overlay = false;
        });
    },
    openAddForum(forumType, isEdit, item) {
      this.forumModel = {
        visible: true,
        endDateEnable: false,
        startDate: new Date(),
      };
      this.addForumType = forumType;
      this.addForumPopup = true;
      this.isEdit = isEdit;
      if (isEdit) {
        this.forumModel = { ...item };
      }
    },

    SaveForum() {
      if (!this.isInRole(2)) {
        return;
      }
      this.forumModel.parentForumGuid = this.forumGuid;
      if (this.addForumType == this.forumType.Category) {
        this.forumModel.forumType = 5;
      }

      const { startDate } = this.forumModel;
      const { endDate } = this.forumModel;

      this.forumModel.startDate = this.$moment(startDate).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      if (this.forumModel.endDate) {
        this.forumModel.endDate = this.$moment(endDate).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
      }

      this.overlay = true;
      this.loading = true;
      const url = this.isEdit ? "Forums/UpdateForum" : "Forums/CreateForum";
      this.axios
        .post(url, this.forumModel)
        .then((res) => {
          if (res.data.statusCode == 1) {
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });

            const forum = res.data.data;
            if (!this.isEdit) {
              this.goToPage(forum);
            } else {
              this.getForums();
            }
            this.addForumPopup = false;
            this.forumModel = {
              visible: true,
              endDateEnable: false,
              startDate: new Date(),
            };
          } else if (res.data.statusCode == 2) {
            this.$swal.fire({
              title: this.$t("forum.EndDateEndsBeforeStartDate"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else if (res.data.statusCode == 3) {
            this.$swal.fire({
              title: this.$t("forum.SubjectRequired"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else if (res.data.statusCode == 4) {
            this.$swal.fire({
              title: this.$t("forum.StageRequired"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else {
            this.$swal.fire({
              title: this.$t(
                "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
              ),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          }

          // console.log(res);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },
    deleteForum(item) {
      if (!this.isInRole(2)) {
        return;
      }
      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            this.overlay = true;
            this.loading = true;
            const url = `Forums/DeleteForum?ForumGuid=${item.forumGuid}`;
            this.axios
              .delete(url)
              .then((res) => {
                if (res.data.statusCode == 1) {
                  this.$swal.fire({
                    title: this.$t("operationAccomplishedSuccessfully"),
                    text: "",
                    icon: "success",
                    confirmButtonText: this.$t("close"),
                    toast: true,
                    position: "bottom-start",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener(
                        "mouseenter",
                        this.$swal.stopTimer
                      );
                      toast.addEventListener(
                        "mouseleave",
                        this.$swal.resumeTimer
                      );
                    },
                  });
                }
                this.getForums();
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              })
              .finally(() => {
                this.overlay = false;
                this.loading = false;
              });
          }
        });
    },
    getStudentsAndTeachers() {
      this.overlay = true;
      const url = `Forums/GetStudentsAndTeachers?forumGuid=${this.forumGuid}`;
      this.axios
        .get(url)
        .then((res) => {
          this.students = res.data.data.students;
          this.teachers = res.data.data.teachers;
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;
        });
    },
    getShareableForums() {
      this.overlay = true;
      const url = `Forums/GetStudentsAndTeachers?forumGuid=${this.forumGuid}`;
      this.axios
        .get(url)
        .then((res) => {
          this.students = res.data.data.students;
          this.teachers = res.data.data.teachers;
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;
        });
    },
    getFavoriteTopics() {
      this.overlay = true;
      this.loadingForums = true;
      const url = `ForumTopic/GetFavoriteTopics`;
      this.axios
        .get(url)
        .then((res) => {
          this.topicsData = res.data.data.forumTopic;
          this.roles = res.data.data.roles;
          this.currentForum = { forumType: 1 };
          this.forumGuid = null;
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;
          this.loadingForums = false;
        });
    },
    getMyTopics() {
      this.overlay = true;
      this.loadingForums = true;
      var url = `ForumTopic/GetMyTopics`;
      if (this.searchText) {
        url += `?searchText=${this.searchText}`;
      }

      this.axios
        .get(url)
        .then((res) => {
          this.topicsData = res.data.data.forumTopic;
          this.currentForum = { forumType: 1 };
          this.roles = res.data.data.roles;
          this.forumGuid = null;
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;
          this.loadingForums = false;
        });
    },
    searchOnTopic(e) {
      if (e.key == "Enter") {
        if (this.searchText && this.searchText.trim().length > 0) {
          this.getMyTopics();
        }
      }
    },

    topicSave() {
      if (!this.isInRole(2)) {
        return;
      }

      this.topicModel.forumGuid = this.forumGuid;

      const { startDate } = this.topicModel;
      const { endDate } = this.topicModel;

      this.topicModel.startDate = this.$moment(startDate).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      if (this.topicModel.endDate) {
        this.topicModel.endDate = this.$moment(endDate).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
      }
      this.overlay = true;
      this.loading = true;
      const url = this.isEdit
        ? "ForumTopic/UpdateTopic"
        : "ForumTopic/CreateTopic";
      this.axios
        .post(url, this.topicModel)
        .then((res) => {
          if (res.data.statusCode == 1) {
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });

            this.addTopicPopup = false;
            this.topicModel = {
              topicType: 1,
              topicMemberMode: 0,
              visible: 1,
              startDate: new Date(),
              endDateEnable: 0,
              teachers: [],
              students: [],
            };
            this.selectedTopic = res.data.data.forumTopicGuid;

            this.onChangeRoute(null);
          } else if (res.data.statusCode == 2) {
            this.$swal.fire({
              title: this.$t("forum.EndDateEndsBeforeStartDate"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else {
            this.$swal.fire({
              title: this.$t(
                "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
              ),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          }

          // console.log(res);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },
    deleteTopic(item) {
      if (!this.isInRole(2)) {
        return;
      }

      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            const data = {
              forumTopicGuid: item.forumTopicGuid,
              forumGuid: this.forumGuid,
            };

            this.overlay = true;
            this.loading = true;
            const url = `ForumTopic/DeleteTopic?forumTopicGuid=${item.forumTopicGuid}`;
            this.axios
              .delete(url, data)
              .then((res) => {
                if (res.data.statusCode == 1) {
                  this.$swal.fire({
                    title: this.$t("operationAccomplishedSuccessfully"),
                    text: "",
                    icon: "success",
                    confirmButtonText: this.$t("close"),
                    toast: true,
                    position: "bottom-start",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener(
                        "mouseenter",
                        this.$swal.stopTimer
                      );
                      toast.addEventListener(
                        "mouseleave",
                        this.$swal.resumeTimer
                      );
                    },
                  });
                  if (this.selectedTopic == item.forumTopicGuid) {
                    this.selectedTopic = null;
                  }
                }
                //this.goToPage({forumGuid:item.forumGuid});
                this.forumGuid = item.forumGuid;
                this.getTopics();
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              })
              .finally(() => {
                this.overlay = false;
                this.loading = false;
              });
          }
        });
    },
    leaveTopic(item) {
      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            this.overlay = true;
            this.loading = true;
            const url = `ForumTopic/LeaveTopic?forumTopicGuid=${item.forumTopicGuid}`;
            this.axios
              .put(url)
              .then((res) => {
                if (res.data.statusCode == 1) {
                  this.$swal.fire({
                    title: this.$t("operationAccomplishedSuccessfully"),
                    text: "",
                    icon: "success",
                    confirmButtonText: this.$t("close"),
                    toast: true,
                    position: "bottom-start",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener(
                        "mouseenter",
                        this.$swal.stopTimer
                      );
                      toast.addEventListener(
                        "mouseleave",
                        this.$swal.resumeTimer
                      );
                    },
                  });

                  this.selectedTopic = null;
                  this.goToPage({ type: "recently" });
                  this.onChangeRoute(null);
                } else {
                  this.$swal.fire({
                    title: this.$t(
                      "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
                    ),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                }

                // console.log(res);
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              })
              .finally(() => {
                this.overlay = false;
                this.loading = false;
              });
          }
        });
    },
    addTopicToFavorite(item, addTofav) {
      const data = {
        forumTopicGuid: item.forumTopicGuid,
        favoriteTopic: addTofav,
      };

      this.overlay = true;
      this.loading = true;
      const url = `ForumTopic/Favorite`;
      this.axios
        .post(url, data)
        .then((res) => {
          if (res.data.statusCode == 1) {
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            this.onChangeRoute(null);
          } else {
            this.$swal.fire({
              title: this.$t(
                "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
              ),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          }

          // console.log(res);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },
    openAddTopic(item, isEdit) {
      this.topicModel = {
        topicType: 1,
        topicMemberMode: 0,
        visible: 1,
        startDate: new Date(),
        endDateEnable: 0,
        teachers: [],
        students: [],
      };
      this.isEdit = isEdit;
      this.addTopicPopup = true;

      if (isEdit) {
        this.topicModel = { ...item };
      }

      if (
        (!this.teachers ||
          this.teachers.length == 0 ||
          !this.students ||
          this.students.length == 0) &&
        !this.isEdit
      ) {
        this.getStudentsAndTeachers();
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.AllStudentsSelected) {
          this.topicModel.students = [];
        } else {
          this.topicModel.students = this.students.map((m) => m.studentGuid);
        }
      });
    },
    toggleTeacher() {
      this.$nextTick(() => {
        if (this.AllTeachersSelected) {
          this.topicModel.teachers = [];
        } else {
          this.topicModel.teachers = this.teachers.map((m) => m.teacherGuid);
        }
      });
    },
    goToPage(item) {
      if (
        item &&
        (item.type == "favorite" ||
          item.type == "recently" ||
          item.type == "myTopics")
      ) {
        this.selectedForum = null;
        this.selectedTopic = null;

        if (this.$route.query.type == item.type) {
          //just refresh data
          if (item.type == "recently") {
            this.openTopicContentRecently();
          } else if (item.type == "favorite") {
            this.getFavoriteTopics();
          } else if (item.type == "myTopics") {
            this.getMyTopics();
          }
          return;
        }

        this.$router.push({
          query: { type: item.type },
        });

        if (item.type == "recently") {
          this.openTopicContentRecently();
        } else if (item.type == "favorite") {
          this.getFavoriteTopics();
        } else if (item.type == "myTopics") {
          this.getMyTopics();
        }
      } else if (!item || !item.forumGuid) {
        if (Object.keys(this.$route.query).length == 0) {
          return;
        }
        this.$router.push({
          query: {},
        });
      } else {
        if (this.$route.query.forumGuid == item.forumGuid) {
          return;
        }
        this.$router.push({
          query: { forumGuid: item.forumGuid },
        });
      }
    },
    onChangeRoute(fromGuid) {
      if (this.$route.query.type == "favorite") {
        this.getFavoriteTopics();

        return;
      } else if (this.$route.query.type == "myTopics") {
        this.getMyTopics();

        return;
      }

      if (this.$route.query && this.$route.query.type) {
        this.defaultForumsItemsSelect = this.$route.query.type;
      }
      if (this.$route.query) {
        if (this.$route.query.topicGuid) {
          this.selectedTopic = this.$route.query.topicGuid;
        } else {
          this.selectedTopic = null;
        }
        if (this.$route.query.forumGuid) {
          this.selectedForum = this.$route.query.forumGuid;
        } else {
          this.selectedForum = null;
        }
      }

      this.forumGuid = this.selectedForum;

      if (this.selectedTopic) {
        this.getTopics();
      } else {
        this.getForums();
      }

      if (this.defaultForumsItemsSelect) {
        this.openTopicContentRecently();
      }
    },
    checkExpire(item) {
      if (!item || !item.endDateEnable) return "";

      return new Date() > new Date(item.endDate) ? "red" : "";
    },
    getIcon(item) {
      if (item.subject && item.autoGenerated) {
        return "mdi mdi-book-open-blank-variant";
      }

      switch (item.forumType) {
        case this.forumType.Announcements:
          return "mdi mdi-bullhorn-variant";
        case this.forumType.News:
          return "mdi mdi-newspaper-variant-outline";
        case this.forumType.Events:
          return "mdi mdi-calendar-multiple";
        case this.forumType.Files:
          return "mdi mdi-file-multiple";
        case this.forumType.Subjects:
          return "mdi mdi-book-open";
        default:
          return "mdi mdi-forum";
      }
    },
    CheckPermission(item) {
      return (
        (this.roles && this.roles.includes("AddContentInAllLeaningSite")) ||
        (!item && this.roles && this.roles.includes("University")) ||
        (item != null && this.roles && this.roles.includes(item.level))
      );
    },
    getColor(item) {
      switch (item.forumType) {
        case this.forumType.Announcements:
          return "#FF8C00";
        case this.forumType.News:
          return "#01796F";
        case this.forumType.Events:
          return "#007FFF";
        case this.forumType.Files:
          return "#964B00";
        case this.forumType.Subjects:
          return "#C71585";
        default:
          return "primary";
      }
    },
    getText(item) {
      if (
        item.autoGenerated &&
        !item.stageGuid &&
        !item.departmentGuid &&
        item.forumType != this.forumType.Other
      ) {
        return this.$t(`forum.${item.title}`);
      } else {
        return item.title;
      }
    },
    getStartEndDate(item) {
      let endDate = "";
      const startDate = this.$moment(item.startDate).format(
        "YYYY/MM/DD - h:mm A"
      );

      if (item.endDateEnable && item.endDate) {
        endDate = this.$moment(item.endDate).format("YYYY/MM/DD - h:mm A");
        return `${this.$t("forum.createdDate")}: ${startDate}\n${this.$t(
          "forum.endDate"
        )}: ${endDate}`;
      }
      return `${this.$t("forum.createdDate")}: ${startDate}`;
    },
    getExtraInfo(item) {
      let title = "";

      if (item.stage && item.department && item.forumType == 5) {
        title = `${item.department.departmentsName} - ${item.stage.stageName}`;
      } else if (item.subject && item.department && item.forumType == 5) {
        title = item.department.departmentsName;
      } else if (item.department && item.forumType == 5) {
        title = item.department.departmentsName;
      } else if (item.autoGenerated) {
        title = this.$t(`forum.${item.title}Description`);
      }
      if (item.description) {
        title += `\n${item.description}`;
      }
      if (!item.autoGenerated && item.teacher) {
        title += `\n${this.$t("createdBy")}: ${item.teacher.teacherName}`;
      }

      return title;
    },
    onChangeDrawer() {
      this.$emit("onChangeDrawer");
    },
    unvInfo() {
      return JSON.parse(localStorage.getItem("unvInfo"));
    },
    getTopicExtraInfo(item) {
      let title = "";
      if (item.teacher) {
        title = `${this.$t("createdBy")}: ${item.teacher.teacherName}`;
      }

      return title;
    },
    openTopicContentRecently() {
      this.$emit("openTopicContentRecently");
    },
    openTopicList(item) {
      this.$emit("openTopicList", {
        topicGuid: item.forumTopicGuid,
      });

      if (
        this.$route.query &&
        this.$route.query.topicGuid == item.forumTopicGuid
      ) {
        return;
      }

      if (item) {
        this.$router.push({
          query: {
            topicGuid: item.forumTopicGuid,
          },
        });
      }
    },
    getTopicIcon(item) {
      switch (item.topicType) {
        case 0:
          return "mdi mdi-account-group";
        case 1:
          return "mdi mdi-text-box";
      }
    },
    getTopicColor(item) {
      switch (item.topicType) {
        case 0:
          return "#D2691E";
        case 1:
          return "primary";
      }
    },
    isTopicFavorite(item) {
      return (
        item.forumTopicMembers &&
        item.forumTopicMembers[0] &&
        item.forumTopicMembers[0].favoriteTopic
      );
    },
    getNavHeaderTitle() {
      if (this.$route.query.type) {
        return this.$t(`forum.${this.$route.query.type}`);
      }
      if (this.currentForum) {
        return this.getText(this.currentForum);
      }

      return this.unvInfo().univName;
    },
  },
};
</script>
<style >
#category_toolbar .v-toolbar__content {
  padding: 4px 4px !important;
}
.v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0 13px;
}
</style>

<!-- <style lang="sass">
.v-input.expanding-search
  transition: max-width 0.5s
  .v-input__slot
  &.closed
    max-width: 50px
</style> -->
<style lang="sass">
.v-input.expanding-search
  transition: max-width 0.5s
  &.closed
    max-width: 50px
</style>
