<template>
  <v-row class="mt-2">
    <v-col cols="12" lg="12">
      <v-card outlined>
        <v-card-actions>
          <v-row>
            <v-col cols="12" md="6">
              <v-file-input
                outlined
                v-model="xlsxFile"
                :rules="uploadFileRules"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                prepend-inner-icon="mdi-microsoft-excel"
                prepend-icon=""
                :label="$t('uploadExcelFile')"
                :clearable="false"
                hide-details
              >
                <template v-slot:append>
                  <v-card-actions style="margin-top: -7px" class="pt-0">
                    <v-btn
                      color="primary"
                      :disabled="!xlsxFile"
                      @click="uploadXlsxFile"
                      :loading="uploading"
                    >
                      {{ $t("upload") }}
                      <v-icon>mdi-upload</v-icon>
                    </v-btn>
                  </v-card-actions>
                </template>
              </v-file-input></v-col
            >
            <v-col cols="12" md="6">
              <v-select
                outlined
                :items="sourceSheets"
                v-model="selectedSheet"
                item-text="sheetName"
                :label="$t('sheets')"
                return-object
                @change="onSheetChanged"
                hide-details
              >
              </v-select
            ></v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-col cols="12" lg="3" md="4">
      <v-card outlined>
        <v-card-title class="justify-center"
        :style="
                    $vuetify.theme.dark
                      ? 'background-color:#293842'
                      : 'background-color:#e6f5ff'
                  ">{{
          $t("excelColumns")
        }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <drag
            :data="selectedSchemeColumn"
            @dragstart="dragstart"
            @dragend="currentDraggedColumnType = null"
          >
            <v-list>
              <v-list-item-group v-model="selectedSchemeColumn" mandatory>
                <v-list-item
                  @mouseenter="mouseoverList(item, i)"
                  v-for="(item, i) in sourceScheme"
                  :key="i"
                  two-line
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.columnName }}</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      item.columnType
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon v-if="item.isTaked" color="green">
                      mdi-check
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <template v-slot:drag-image>
              <v-chip color="red" style="transform: translate(10px, 5px)">
                {{
                  sourceScheme[selectedSchemeColumn]
                    ? sourceScheme[selectedSchemeColumn].columnName
                    : ""
                }}
              </v-chip>
              <v-icon small color="primary">mdi-drag-variant</v-icon>
            </template>
          </drag>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" lg="9" md="8">
      <v-card outlined>
        <v-card-title class="justify-center"
        :style="
                    $vuetify.theme.dark
                      ? 'background-color:#293842'
                      : 'background-color:#e6f5ff'
                  ">{{
          $t("templateColumns")
        }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-data-table
            hide-default-footer
            :headers="templateHeader"
            :items="templateRows"
            class="template-table-degrees"
          >
            <template v-slot:[`item.StudentCode`]="{ item }">
              <drop
                @drop="function (val) {
                    onDrop(val, 'StudentCode');
                  }
                "
                :accepts-data="acceptsDataString"
                class="student-code-column"
              >
                <v-select
                  outlined
                  :items="sourceSchemeString"
                  v-model="item.StudentCode"
                  @change="checkIsTaked"
                  item-text="columnName"
                  item-value="columnName"
                  hide-details
                  class="my-2"
                  :background-color="
                    !currentDraggedColumnType
                      ? ''
                      : currentDraggedColumnType == 'String'
                      ? 'rgba(0, 255, 0, 0.2)'
                      : 'rgba(255, 0, 0, 0.2)'
                  "
                >
                </v-select>
              </drop>
            </template>
            <template v-slot:[`item.Degree`]="{ item }">
              <drop
                @drop="function (val) {
                    onDrop(val, 'Degree');
                  }
                "
                :accepts-data="acceptsDataDouble"
                class="degree-column"
              >
                <v-select
                  outlined
                  :items="sourceSchemeDouble"
                  v-model="item.Degree"
                  @change="checkIsTaked"
                  item-text="columnName"
                  item-value="columnName"
                  hide-details
                  class="my-2"
                  :background-color="
                    !currentDraggedColumnType
                      ? ''
                      : currentDraggedColumnType == 'Double'
                      ? 'rgba(0, 255, 0, 0.2)'
                      : 'rgba(255, 0, 0, 0.2)'
                  "
                >
                </v-select>
              </drop>
            </template>
            <template v-slot:[`item.Note`]="{ item }">
              <drop
                @drop="function (val) {
                    onDrop(val, 'Note');
                  }
                "
                class="note-column"
              >
                <v-select
                  outlined
                  :items="sourceScheme"
                  @change="checkIsTaked"
                  v-model="item.Note"
                  item-text="columnName"
                  item-value="columnName"
                  hide-details
                  class="my-2"
                  :background-color="
                    !currentDraggedColumnType ? '' : 'rgba(0, 255, 0, 0.2)'
                  "
                >
                </v-select>
              </drop>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="SaveDegrees"
            color="primary"
            :disabled="!templateRows[0].StudentCode || !templateRows[0].Degree"
          >
            {{ $t("save") }}
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { Drag, Drop } from "vue-easy-dnd";

export default {
  components: {
    Drag,
    Drop,
  },
  props: ["sessionGuid", "sectionGuid"],

  data() {
    return {
      uploadFileRules: [
        (value) => !value ||
          value.size < 30000000 ||
          this.$t("uploadDegreesFileXlsxSize"),
      ],
      sourceSchemeHeader: [
        {
          text: this.$t("columnName"),
          value: "columnName",
        },
        {
          text: this.$t("columnType"),
          value: "columnType",
        },
      ],
      sourceScheme: [],
      sourceSheets: [],
      selectedSheet: null,
      templateHeader: [],
      templateRows: [
        {
          StudentCode: "",
          Degree: "",
          Note: "",
        },
      ],
      uploading: false,
      xlsxFile: null,
      currentXlsxFile: null,
      selectedSchemeColumn: null,
      currentDraggedColumnType: null,
    };
  },
  computed: {
    sourceSchemeString() {
      return this.sourceScheme.filter((item) => item.columnType === "String");
    },
    sourceSchemeDouble() {
      return this.sourceScheme.filter((item) => item.columnType === "Double");
    },
  },
  methods: {
    getTemplate() {
      this.axios
        .get(`Degrees/GetXlsxTemplate`)
        .then((res) => {
          res.data.data.forEach((element) => {
            this.templateHeader.push({
              text: this.$t(element.columnName),
              value: element.columnName,
            });
          });
        })
        .catch(() => {})
        .finally(() => {});
    },
    uploadXlsxFile() {
      this.uploading = true;
      const data = new FormData();
      if (this.xlsxFile != null) {
        data.append("file", this.xlsxFile);
      }
      this.axios
        .post(`Degrees/UploadXlsxFile`, data)
        .then((res) => {
          if (res.data.data == null) {
            this.$swal.fire({
              title: this.$t("fileNotValid"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
            return;
          }
          this.sourceSheets = res.data.data.sheets;
          this.currentXlsxFile = res.data.data.fileName;
          this.xlsxFile = null;
          if (this.sourceSheets.length > 0) {
            this.selectedSheet = this.sourceSheets[0];
            this.sourceScheme = this.selectedSheet.columnsInfo;
          }
           this.checkIsTaked();
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t(
              "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
            ),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.uploading = false;
        });
    },
    SaveDegrees() {
      const data = {
        sessionGuid: this.sessionGuid,
        sectionGuid: this.sectionGuid,
        xlsxFileName: this.currentXlsxFile,
        sheetName: this.selectedSheet.sheetName,
        keyMaps: [
          {
            templateKey: "StudentCode",
            sourceKey: this.templateRows[0].StudentCode,
          },
          {
            templateKey: "Degree",
            sourceKey: this.templateRows[0].Degree,
          },
          {
            templateKey: "Note",
            sourceKey: this.templateRows[0].Note,
          },
        ],
      };

      this.axios
        .post(`Degrees/SaveXlsxDegrees`, data)
        .then((res) => {
          if (res.data.statusCode == 1) {
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            this.$emit("refresh-data-grid");
          } else {
            this.$swal.fire({
              title: this.$t(
                "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
              ),
              text: res.data.details,
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          }
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t(
              "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
            ),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {});
    },
    onSheetChanged(item) {
      this.sourceScheme = item.columnsInfo;
    },
    mouseoverList(item, i) {
      this.selectedSchemeColumn = i;
    },
    onDrop(item, itemName) {
      if (itemName == "StudentCode") {
        this.templateRows[0].StudentCode =
          this.sourceScheme[item.data].columnName;
      } else if (itemName == "Degree") {
        this.templateRows[0].Degree = this.sourceScheme[item.data].columnName;
      } else if (itemName == "Note") {
        this.templateRows[0].Note = this.sourceScheme[item.data].columnName;
      }
      // if (this.isTaked(this.sourceScheme[item.data])) {
      //   this.sourceScheme[item.data].isTaked = true;
      // } else {
      //   this.sourceScheme[item.data].isTaked = false;
      // }
      this.checkIsTaked();
    },
    acceptsDataString(data) {
      return this.sourceScheme[data].columnType == "String";
    },
    acceptsDataDouble(data) {
      return this.sourceScheme[data].columnType == "Double";
    },
    dragstart(event) {
      this.currentDraggedColumnType = this.sourceScheme[event.data].columnType;
    },
    checkIsTaked() {
      this.sourceScheme.forEach((element) => {
        if (this.isTaked(element.columnName)) {
          element.isTaked = true;
        } else {
          element.isTaked = false;
        }
      });
      this.$forceUpdate();
    },
    isTaked(columnValue) {
      return (
        this.templateRows[0].StudentCode == columnValue ||
        this.templateRows[0].Degree == columnValue ||
        this.templateRows[0].Note == columnValue
      );
    },
  },
  created() {
    this.getTemplate();
  },
};
</script>

<style scoped>
.v-list {
  max-height: 500px; /* or any height you want */
  overflow-y: auto;
}
.drop-allowed {
  background-color: rgba(37, 175, 37, 0.425);
}

.drop-forbidden {
  background-color: rgba(255, 0, 0, 0.2);
}
</style>
<style lang="scss">
.template-table-degrees tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>