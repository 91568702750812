<template>
  <v-card outlined elevation="0" class="cl">
    <v-toolbar dense color="primary" class="text-center" dark>
      <v-col class="text-center">
        <span>قائمة الاعضاء</span>
        <v-icon class="px-1">mdi-account-group-outline</v-icon>
      </v-col>
    </v-toolbar>
    <div
      id="container"
      class="mt-2"
      style="
        max-height: calc(72.5vh);
        min-height: 300px;
        height: calc(72.5vh);
        overflow-y: auto;
        overflow-x: hidden;
      "
    >
      <v-list
        nav
        dense
        v-for="item in userMemberList"
        :key="item.userGuid"
        class="py-0 my-0 mb-1"
      >
        <v-menu left bottom max-width="150px">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              link
              class="py-0 my-0"
              v-bind="attrs"
              v-on="on"
              v-if="item.userStatus != 0"
            >
              <v-list-item-icon>
                <v-badge
                  overlap
                  bordered
                  dot
                  :color="
                    item.userStatus == 3
                      ? 'teal'
                      : item.userStatus == 2
                      ? 'pink'
                      : item.userStatus == 1
                      ? 'grey'
                      : ''
                  "
                >
                  <v-avatar :color="item.color" size="28">
                    <span class="white--text">{{ item.supName }}</span>
                  </v-avatar>
                </v-badge>
              </v-list-item-icon>
              <v-list-item-title
                >{{ item.fullName }}
                <v-icon v-if="item.isOwner" class="px-1" color="amber darken-1"
                  >mdi-account-star</v-icon
                >
                <v-icon v-if="item.isMuted" class="px-1">mdi-volume-off</v-icon>
              </v-list-item-title>
            </v-list-item>
          </template>
          <v-list
            v-if="
              this.isInRole(2) && item.guid != getUserInfo().userId
            "
          >
            <v-list-item
              @click="changeMemberStatus(item, 3)"
              v-if="!item.isMuted && item.userStatus != 0"
            >
              <v-list-item-title
                ><v-icon class="px-1">mdi-volume-off</v-icon>كتم
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="changeMemberStatus(item, 4)"
              v-if="item.isMuted && item.userStatus != 0"
            >
              <v-list-item-title
                ><v-icon class="px-1">mdi-volume-high</v-icon>الغاء الكتم
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="changeMemberStatus(item, 5)">
              <v-list-item-title
                ><v-icon class="px-1">mdi-account-remove</v-icon>حذف
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="open(item)">
              <v-list-item-title
                ><v-icon class="px-1">mdi-account-remove</v-icon>فتح محادثة
              </v-list-item-title>
            </v-list-item>


            <!-- <v-list-item  @click="() => {}">
              <v-list-item-title>Option {{ n }}</v-list-item-title>
            </v-list-item> -->
          </v-list>
        </v-menu>
      </v-list>
    </div>
    <ChatBox ref="childComponent"  />

  </v-card>
</template>

<script>
import * as userHub from "../../hubs/userHub";
import ChatBox from "./chatBox1.vue";

export default {
  components: {
    ChatBox,

  },
  data() {
    return {
      userMemberList: [],
    };
  },
  computed: {
    groupInfo: {
      get() {
        return this.$store.state.groupInfo;
      },
      set(val) {
        this.$store.commit("SET_GROUPINFO", val);
      },
    },
  },
  created() {
    this.getListUsers();
  },
  mounted() {
    userHub.connectionGroupChat.on(
      "onChangeMemberStatus",
      (groupGuid, memberGuid, status) => {}
    );
    userHub.connectionGroupChat.on("updateUser", (groupGuid) => {
      this.getListUsers();
    });
  },
  methods: {
    changeMemberStatus(item, status) {
      userHub.ChangeMemberStatus(this.groupInfo.guid, item.guid, status);
    },
    open(item) {},
    getListUsers() {
      setTimeout(() => {
        userHub.connectionGroupChat
          .invoke("GetUsers", this.groupInfo.guid)
          .then((res) => {
            this.userMemberList = res;
            this.getColorFromName();
          });
      }, 200);
    },
    stringToHslColor(str, s, l) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      const h = hash % 360;
      return `hsl(${h}, ${s}%, ${l}%)`;
    },
    getColorFromName() {
      this.userMemberList.forEach((e) => {
        e.color = this.stringToHslColor(e.fullName, 40, 50);
        e.supName =
          e.fullName.charAt(0).toUpperCase() +
          e.fullName.charAt(1).toUpperCase();
      });
    },
     open(i) {
       const userinfo = { userName: i.fullName, userGuid: i.guid, count: i.count }
      this.$refs.childComponent.setValue(userinfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card > .v-toolbar {
  position: sticky;
  top: 0;
  //   z-index: 99;
}
.cl {
  //   overflow: scroll !important;
  overflow-x: hidden !important;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #109afd;
}
::-webkit-scrollbar {
  width: 1px;
}
.cssExample {
  overflow: hidden !important;
}
.cssExample:hover {
  overflow: auto !important;
}
</style>