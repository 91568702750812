<template>
  <v-dialog
    transition="dialog-bottom-transition"
    persistent
    max-width="1000"
    v-model="dialog"
    fullscreen
  >
    <v-card>
      <v-toolbar color="primary darken-1" dark>
        {{ isEdit ? $t("exam.EditAQuestion") : $t("exam.AddAQuestion") }}
        <v-spacer></v-spacer>
        <v-btn icon dark @click="clear()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-4">
        <v-row class="pa-0 ma-0">
          <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
            <v-select
              :disabled="isEdit"
              :items="items2"
              :label="$t('exam.ChooseTheTypeOfQuestion')"
              v-model="selectQuestionType"
              item-value="value"
              item-text="text"
              outlined
              :rules="[required]"
              @change="changeTypeQuestion"
            >
              <!-- <template v-slot:selection="{ item }">
                <v-icon class="ml-3 mr-3">{{ item.icon }}</v-icon
                >
                {{ item.text }}
              </template> -->
              <template v-slot:item="{ item }">
                <v-icon class="ml-3 mr-3">{{ item.icon }}</v-icon
                >{{ item.text }}
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
            <v-text-field
              :label="$t('exam.QuestionHint')"
              :placeholder="$t('exam.ExampleQuestion')"
              outlined
              v-model="examHint"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
            <v-textarea
              v-model="theQuestion"
              outlined
              :rules="[required]"
              name="input-7-4"
              :label="$t('exam.theQuestion')"
              :placeholder="$t('exam.TypeYourQuestionHere')"
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
            <v-text-field
              :label="$t('exam.TheDegreeOfTheQuestion')"
              placeholder="0.0"
              outlined
              type="number"
              :rules="[required]"
              onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46 )"
              v-model="examDegree"
              min="0"
              max="100"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- MultiChoice OneChoice MultiChoice OneChoice MultiChoice OneChoice MultiChoice -->
        <v-row
          class="pa-0 ma-0"
          v-if="
            selectQuestionType == 'MultiChoice' ||
            selectQuestionType == 'OneChoice'
          "
        >
          <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
            <v-card outlined class="mb-5 px-5" text>
              <div
                class="py-5"
                v-if="!questionOptiens || questionOptiens.length == 0"
              >
                {{ $t("exam.NoOptions") }}
              </div>
              <div
                class="list py-5 px-0"
                v-if="selectQuestionType == 'MultiChoice'"
              >
                <drop-list
                  :items="questionOptiens"
                  class="list"
                  @reorder="$event.apply(questionOptiens)"
                >
                  <template v-slot:item="{ item, index }">
                    <drag
                      :key="item.id"
                      class="item"
                      @dragstart="pickupItem"
                      @dragend="draggedItem = null"
                    >
                      <v-text-field
                        hide-details
                        class="my-2"
                        :rules="[required]"
                        outlined
                        :label="$t('exam.WriteAnOption')"
                        v-model="item.question"
                        :ref="'textfield' + index"
                      >
                        <template  slot="append-outer">
                          <v-btn
                            style="margin-top: -6px !important"
                            icon
                            @click="popUpItemFromOptiens(index)"
                            ><v-icon>mdi-close</v-icon></v-btn
                          >
                        </template>
                        <template slot="prepend">
                          <v-btn
                            icon
                            style="margin-top: -9px !important"
                            class="ma-0 pa-0"
                            ><v-icon>mdi-drag-horizontal</v-icon></v-btn
                          >
                          <v-checkbox
                            v-model="item.correctAns"
                            hide-details
                            class="shrink mx-2"
                            style="margin-top: -5px"
                          ></v-checkbox>
                        </template>
                      </v-text-field>
                    </drag>
                  </template>
                </drop-list>
              </div>
              <div class="pt-5" v-if="selectQuestionType == 'OneChoice'">
                <!-- ----------------------------------------------------------------------------------------------------- -->
                <v-radio-group
                  v-model="OneChoiceGroup"
                  v-if="questionOptiens && questionOptiens.length > 0"
                  @change="radioSelectedChange"
                  :key="refreshGroup"
                >
                  <drop-list
                    :items="questionOptiens"
                    class="list"
                    @reorder="reorderList"
                  >
                    <template v-slot:item="{ item, index }">
                      <drag
                        :key="item.id"
                        :data="item"
                        class="item"
                        @dragstart="pickupItem"
                        @dragend="dragItemEnd"
                      >
                        <v-text-field
                          :rules="[required]"
                          hide-details
                          class="my-2"
                          outlined
                          :label="$t('exam.WriteAnOption')"
                          v-model="item.question"
                          :ref="'textfield' + index"
                        >
                          <template  slot="append-outer">
                            <v-btn
                              style="margin-top: -6px !important"
                              icon
                              @click="popUpItemFromOptiens(index)"
                              ><v-icon>mdi-close</v-icon></v-btn
                            >
                          </template>
                          <template slot="prepend">
                            <v-btn
                              icon
                              style="margin-top: -9px !important"
                              class="ma-0 pa-0"
                              ><v-icon>mdi-drag-horizontal</v-icon></v-btn
                            >
                            <v-radio
                              style="margin-top: -8px"
                              v-model="item.correctAns"
                              :value="item.correctAns"
                            >
                            </v-radio>
                          </template>
                        </v-text-field>
                      </drag>
                    </template>
                  </drop-list>
                </v-radio-group>
              </div>
              <v-btn
                small
                dark
                block
                class="mb-2"
                color="primary"
                @click="pushItemToOptiens()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <div class="mb-3 red--text" v-if="questionOptiensDouble">
                * {{ $t("DuplicateValue") }}
              </div>
            </v-card>
          </v-col>
          <!-- <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
            <v-combobox
              v-model="selectUncorectAnser"
              :label="$t('exam.WrongAnswer')"
              multiple
              chips
              clearable
              outlined
              small-chips
              @keyup="toLowerUncorectAnser"
              @keypress="toLowerUncorectAnser"
              @keydown="toLowerUncorectAnser"
              @change="toLowerUncorectAnser"
            ></v-combobox>
          </v-col> -->
        </v-row>
        <!-- FillInTheBlanks Number FillInTheBlanks Number FillInTheBlanks Number FillInTheBlanks Number FillInTheBlanks Number -->
        <v-row
          class="pa-0 ma-0"
          v-if="
            selectQuestionType == 'FillInTheBlanks' ||
            selectQuestionType == 'Number'
          "
        >
          <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
            <v-textarea
              v-if="selectQuestionType == 'FillInTheBlanks'"
              v-model="theAnswer"
              outlined
              :rules="[required]"
              name="input-7-4"
              :label="$t('exam.TheCorrectAnswer')"
              :placeholder="$t('exam.TypeYourAnswerHere')"
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
            <v-text-field
              v-if="selectQuestionType == 'Number'"
              v-model="theAnswer"
              outlined
              :rules="[required]"
              name="input-7-4"
              :label="$t('exam.TheCorrectAnswer')"
              :placeholder="$t('exam.TheAnswerShouldBeANumber')"
              type="number"
              onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46 )"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
            <div class="fileUpload">
              <DxFileUploader
                :allowed-file-extensions="allowedFileExtensions"
                :selectButtonText="$t('lecOne.ChooseTheFile')"
                :labelText="$t('lecOne.ChooseTheFileYouWantToUpload')"
                [multiple]="false"
                :chunk-size="5000000"
                :max-file-size="209715200"
                name="FileUpload"
                :accept="allowedFileExtensions.join(',')"
                uploadMode="instantly"
                uploadMethod="POST"
                :uploadHeaders="headers"
                :upload-url="`${UploaderUrl}Exams`"
                @upload-started="() => (chunks = [])"
                @progress="onUploadProgress($event)"
                @uploaded="onUploaded($event)"
                messageUploadText="asd"
              />
              <v-alert dense text type="info" color="primary">
                <span class="note"
                  >{{ $t("lecOne.AllowedFiles") }} :
                  <span
                    >jpg, jpeg, gif, png, bmp, mp4, wmv, flv, avi, webm, weba
                    wav, mp3, aac, pdf, doc, docx, xls, xlsx, ppt, pptx, txt,
                    zip, rar</span
                  >.</span
                >
                <span class="note"
                  >{{ $t("lecOne.MaximumFileSize") }} : <span>200MB</span></span
                >
              </v-alert>
              <v-switch
                :disabled="!fileURL ? true : false"
                v-model="containsFile"
                :label="$t('exam.ViewTheFileForStudents')"
              ></v-switch>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="clear()">
          {{ $t("close") }}
        </v-btn>
        <v-btn
          :loading="sendLoad"
          color="primary darken-1"
          @click="uploadData()"
          :disabled="checkEditorsVaildation || questionOptiensDouble"
        >
          {{ isEdit ? $t("edit") : $t("add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DxFileUploader from "devextreme-vue/file-uploader";
import { mapState } from "vuex";
import { Drag, Drop, DropList } from "vue-easy-dnd";

export default {
  components: {
    DxFileUploader,
    Drag,
    Drop,
    DropList,
  },
  data() {
    return {
      required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      theAnswer: null,
      theQuestion: null,
      selectQuestionType: null,
      examDegree: null,
      selectCorectAnser: [],
      selectUncorectAnser: [],
      questionOptiens: [],
      OneChoiceGroup: "",
      // items: ["Programming", "Design", "Vue", "Vuetify"],
      items2: [
        {
          text: this.$t("exam.MultipleChoices"),
          value: "MultiChoice",
          icon: "mdi-order-bool-ascending-variant",
        },
        {
          text: this.$t("exam.ChooseOne"),
          value: "OneChoice",
          icon: "mdi-radiobox-marked",
        },
        {
          text: this.$t("exam.FillInTheBlank"),
          value: "FillInTheBlanks",
          icon: "mdi-form-textbox",
        },
        {
          text: this.$t("exam.FillInTheBlankNumericValue"),
          value: "Number",
          icon: "mdi-numeric",
        },
        {
          text: this.$t("exam.UploadAFile"),
          value: "AttachedFile",
          icon: "mdi-paperclip",
        },
      ],
      examHint: null,
      dialog: false,
      fileURL: null,
      sendLoad: false,
      // uploadURL: process.env.VUE_APP_UPLOAD,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      chunks: [],
      allowedFileExtensions: [
        ".jpg",
        ".jpeg",
        ".gif",
        ".png",
        ".bmp",
        ".mp4",
        ".wmv",
        ".flv",
        ".avi",
        ".webm",
        ".weba",
        ".wav",
        ".mp3",
        ".aac",
        ".pdf",
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
        ".ppt",
        ".pptx",
        ".txt",
        ".zip",
        ".rar",
      ],
      examGuid: null,
      categoryGuid: null,
      isBankQuestions: false,
      containsFile: false,
      correctAnswers: [],
      isEdit: false,
      questionGuid: null,
      draggedItem: null,
      refreshGroup: false,
      radioSelectedItem: null,
    };
  },
  computed: {
    ...mapState(["UploaderUrl"]),
    checkEditorsVaildation() {
      return (
        !this.theQuestion ||
        !this.selectQuestionType ||
        this.examDegree === null ||
        this.examDegree === "" ||
        ((this.selectQuestionType == "MultiChoice" ||
          this.selectQuestionType == "OneChoice") &&
          (this.questionOptiens.length === 0 ||
            this.questionOptiens.some((m) => !m.question)))
      );
    },
    questionOptiensDouble() {
      if (
        this.questionOptiens
          .map((m) => m.question)
          .filter((e, i, a) => a.indexOf(e) !== i).length > 0
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {},

  methods: {
    // --------------------------Re-order Items------------------------------------------
    reorderList(val) {
      const item = this.questionOptiens.splice(val.from, 1)[0];
      this.questionOptiens.splice(val.to, 0, item);
    },
    pickupItem(item) {
      this.draggedItem = item;
    },
    dragItemEnd() {
      this.draggedItem = null;
      const indexOf = this.questionOptiens.indexOf(this.radioSelectedItem);
      this.OneChoiceGroup = indexOf < 0 ? 0 : indexOf;
      this.refreshGroup = !this.refreshGroup;
    },
    radioSelectedChange(e) {
      this.radioSelectedItem = this.questionOptiens[this.OneChoiceGroup];
    },
    // reorder(list, startIndex, endIndex) {
    //   const result = Array.from(list);
    //   const [removed] = result.splice(startIndex, 1);
    //   result.splice(endIndex, 0, removed);

    //   return result;
    // },

    //----------------------------------------------------------------------------

    clear() {
      this.theAnswer = null;
      this.theQuestion = null;
      this.selectQuestionType = null;
      this.examDegree = null;
      this.selectCorectAnser = [];
      this.selectUncorectAnser = [];
      this.questionOptiens = [];
      this.examHint = null;
      this.fileURL = null;
      this.examGuid = null;
      this.containsFile = false;
      this.correctAnswers = [];
      this.isEdit = false;
      this.questionGuid = null;
      this.dialog = false;
    },
    tolowercase() {
      // console.log(typeof this.selectCorectAnser);
      if (typeof this.selectCorectAnser !== "string") {
        if (this.selectCorectAnser) {
          // this.selectCorectAnser = this.selectCorectAnser.map((v) => v.toLowerCase());
          this.selectCorectAnser = [...new Set(this.selectCorectAnser)];
          this.selectCorectAnser = this.selectCorectAnser.filter(
            (val) => !this.selectUncorectAnser.includes(val)
          );
        }
      } else {
        this.selectCorectAnser.toLowerCase();
      }
    },
    toLowerUncorectAnser() {
      if (this.selectUncorectAnser) {
        // this.selectUncorectAnser = this.selectUncorectAnser.map((v) => v.toLowerCase());
        this.selectUncorectAnser = [...new Set(this.selectUncorectAnser)];
        this.selectUncorectAnser = this.selectUncorectAnser.filter(
          (val) => !this.selectCorectAnser.includes(val)
        );
      }
    },

    editValue(examGuid, item) {
      this.changeTypeQuestion();
      this.isEdit = true;
      this.examGuid = examGuid;
      this.selectQuestionType = item.questionType;
      this.examHint = item.questionHint;
      this.examDegree = item.questionMark;
      this.theQuestion = item.question;
      this.containsFile = item.containsFile;
      this.questionGuid = item.questionGuid;
      this.categoryGuid = item.categoryGuid;
      this.isBankQuestions = item.isBankQuestions;

      const file = {
        data: {
          fileName: "",
        },
      };
      file.data.fileName = item.fileName;
      this.fileURL = file;
      if (item.questionType == "MultiChoice") {
        this.selectCorectAnser = [];
        for (let i = 0; i < item.questionAnswers.length; i++) {
          // item.questionAnswers[i].isCorrect == true
          //   ? this.selectCorectAnser.push(item.questionAnswers[i].questionAnswer)
          //   : this.selectUncorectAnser.push(item.questionAnswers[i].questionAnswer);

          this.questionOptiens.push({
            question: item.questionAnswers[i].questionAnswer,
            correctAns: item.questionAnswers[i].isCorrect,
            id: i,
          });
        }
      } else if (item.questionType == "OneChoice") {
        this.selectUncorectAnser = [];
        let correctItem = "";
        for (let i = 0; i < item.questionAnswers.length; i++) {
          this.questionOptiens.push({
            question: item.questionAnswers[i].questionAnswer,
            id: i,
          });
          if (item.questionAnswers[i].isCorrect) {
            correctItem = item.questionAnswers[i].questionAnswer;
          }
        }

        this.OneChoiceGroup = this.questionOptiens.findIndex(
          (m) => m.question === correctItem
        );
        this.radioSelectedItem = this.questionOptiens[this.OneChoiceGroup];
        this.refreshGroup = !this.refreshGroup;
      } else if (
        (item.questionType == "FillInTheBlanks" ||
        item.questionType == "Number")
        && item.questionAnswers && item.questionAnswers.length > 0
      ) {
        this.theAnswer = item.questionAnswers[0].questionAnswer;
      }
      this.dialog = true;
    },
    changeTypeQuestion() {
      this.selectCorectAnser = null;
      this.questionOptiens = [];
      this.OneChoiceGroup = "";
      this.correctAnswers = [];
      this.theAnswer = null;
      this.selectUncorectAnser = [];
      this.isEdit = false;
    },
    arrayMax(arr) {
      return Math.max.apply(
        Math,
        arr.map((o) => o.id)
      );
    },
    pushItemToOptiens() {
      if (this.OneChoiceGroup == "") {
        this.OneChoiceGroup = 0;
      }
      this.refreshGroup = !this.refreshGroup;

      let id = 0;
      const maxVal = this.arrayMax(this.questionOptiens);
      if (maxVal >= 0) id = this.arrayMax(this.questionOptiens) + 1;

      this.questionOptiens.push({ id: id });

      const index = this.questionOptiens.length - 1;
      setTimeout(() => {
        const control = this.$refs[`textfield${index}`];
        control.focus();
      }, 0);
    },
    popUpItemFromOptiens(index) {
      if (this.selectQuestionType == "OneChoice") {
        const item = this.questionOptiens[this.OneChoiceGroup]; // before delete get selected index
        this.questionOptiens.splice(index, 1);

        const indexOf = this.questionOptiens.indexOf(item);
        this.OneChoiceGroup = indexOf < 0 ? 0 : indexOf;
        this.refreshGroup = !this.refreshGroup;

        this.radioSelectedItem = this.questionOptiens[this.OneChoiceGroup];
      } else if (this.selectQuestionType == "MultiChoice") {
        this.questionOptiens.splice(index, 1);
      }
    },
    uploadData() {
      let isFullOk = false;
      // if (this.selectQuestionType == "MultiChoice") {
      //   if (this.selectCorectAnser[0] && this.selectUncorectAnser[0]) {
      //     this.correctAnswers = this.selectCorectAnser;
      //     isFullOk = true;
      //   }
      // }

      // if (this.selectQuestionType == "OneChoice") {
      //   if (this.selectCorectAnser && this.selectUncorectAnser[0]) {
      //     this.correctAnswers[0] = this.selectCorectAnser;
      //     isFullOk = true;
      //   }
      // }

      let correct = [];
      let wrong = [];

      if (
        this.selectQuestionType == "MultiChoice" ||
        this.selectQuestionType == "OneChoice"
      ) {
        if (this.questionOptiens[0]) {
          if (this.selectQuestionType == "MultiChoice") {
            correct = this.questionOptiens
              .filter((m) => m.correctAns == true)
              .map((i) => i.question);
            wrong = this.questionOptiens
              .filter((m) => !m.correctAns || m.correctAns == false)
              .map((i) => i.question);
          } else if (this.selectQuestionType == "OneChoice") {
            correct =
              this.OneChoiceGroup !== ""
                ? [this.questionOptiens[this.OneChoiceGroup].question]
                : [];
            wrong = this.questionOptiens
              .filter((m) => m.question !== correct[0])
              .map((i) => i.question);
          }

          isFullOk = true;
        }

        if (correct == null || (correct && correct.length == 0)) {
          this.$swal.fire({
            title: this.$t("exam.MustSelectCurrectAnswer"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
          return;
        }
      }

      if (
        this.selectQuestionType == "FillInTheBlanks" ||
        this.selectQuestionType == "Number"
      ) {
        if (this.theAnswer) {
          // this.correctAnswers[0] = this.theAnswer;
          correct = [this.theAnswer];
          isFullOk = true;
        }
      }
      if (this.selectQuestionType == "AttachedFile") {
        isFullOk = true;
      }

      if (
        this.selectQuestionType == "MultiChoice" ||
        this.selectQuestionType == "OneChoice"
      ) {
        this.questionOptiens.forEach((element) => {
          if (
            element.question === undefined ||
            element.question === null ||
            element.question.trim() === ""
          ) {
            isFullOk = false;
          }
        });

        const intersect1 = correct.filter((el) => wrong.indexOf(el) != -1);
        const intersect2 = correct.length === new Set(correct).size;
        const intersect3 = wrong.length === new Set(wrong).size;

        if (
          intersect1.length > 0 ||
          intersect2 === false ||
          intersect3 === false
        ) {
          this.$swal.fire({
            title: this.$t("errors.TheAnswersAreTheSame"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
          isFullOk = false;
          return;
        }
      }

      if (isFullOk == false) {
        this.$swal.fire({
          title: this.$t("BeSureToEnterTheInformation"),
          text: "",
          icon: "error",
          confirmButtonText: this.$t("close"),
        });

        return;
      }

      if (isFullOk) {
        this.sendLoad = true;

        if (
          this.selectQuestionType == "FillInTheBlanks" ||
          this.selectQuestionType == "Number"
        ) {
          this.questionOptiens = [
            { isCorrect: true, optionText: this.theAnswer, optionOrder: 0 },
          ];
        } else if (
          this.selectQuestionType == "MultiChoice" ||
          this.selectQuestionType == "OneChoice"
        ) {
          const controlType = this.selectQuestionType;
          const oneChoiceGroup = this.OneChoiceGroup;
          this.questionOptiens.forEach((value, i) => {
            if (controlType == "MultiChoice") {
              value.isCorrect = value.correctAns;
            } else if (controlType == "OneChoice") {
              value.isCorrect = oneChoiceGroup == i;
            }

            value.optionText = value.question.trim();
            value.optionOrder = i;
          });
        }

        const data = {
          questionGuid: this.isEdit ? this.questionGuid : null,
          examGuid: this.examGuid,
          categoryGuid: this.categoryGuid,
          questionType: this.selectQuestionType,
          question: this.theQuestion,
          containsFile: this.containsFile,
          fileName: this.fileURL ? this.fileURL.data.fileName : "",
          questionMark: this.examDegree,
          questionHint: this.examHint,
          // correctAnswers: this.correctAnswers,
          // wrongAnswers: this.selectUncorectAnser
          // correctAnswers: correct,
          // wrongAnswers: wrong,
          QuestionOptions: this.questionOptiens,
        };
        // console.log(data);
        this.axios
          .post("Exams/AddOrEditQuestion", data)
          .then((res) => {
            if (res.data.statusCode == 1) {
              this.sendResultValues();
              this.fileURL = null;
              this.dialog = false;
              this.$swal.fire({
                title: this.$t("operationAccomplishedSuccessfully"),
                text: "",
                icon: "success",
                confirmButtonText: this.$t("close"),
                toast: true,
                position: "bottom-start",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
              this.clear();
            } else if (res.data.statusCode == 2) {
              this.$swal.fire({
                title: this.$t("errors.YouCanNotModifyTheExamTheExamIsActiv"),
                text: "",
                icon: "error",
                confirmButtonText: this.$t("close"),
              });
              this.$emit("send-result-values", "carrier");
            } else if (res.data.statusCode == 3) {
              this.$swal.fire({
                title: this.$t("errors.ExamNotFound"),
                text: "",
                icon: "error",
                confirmButtonText: this.$t("close"),
              });
              this.$emit("send-result-values", "carrier");
            } else if (res.data.statusCode == 4) {
              this.$swal.fire({
                title: this.$t(
                  "errors.YouCanNotModifyTheExamItHasBeenAnsweredByTheStudents"
                ),
                text: "",
                icon: "error",
                confirmButtonText: this.$t("close"),
              });
              this.$emit("send-result-values", "carrier");
            } else if (res.data.statusCode == 6) {
              this.$swal.fire({
                title: this.$t("errors.TheAnswersAreTheSame"),
                text: "",
                icon: "error",
                confirmButtonText: this.$t("close"),
              });
              this.$emit("send-result-values", "carrier");
            } else if (res.data.statusCode == 10) {
              this.$swal.fire({
                title: this.$t("exam.MustSelectCurrectAnswer"),
                text: "",
                icon: "error",
                confirmButtonText: this.$t("close"),
              });
            } else {
              this.$swal.fire({
                title: this.$t(
                  "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
                ),
                text: "",
                icon: "error",
                confirmButtonText: this.$t("close"),
              });
              this.$emit("send-result-values", "carrier");
            } // console.log(res);
          })
          .catch(() => {
            this.$swal.fire({
              title: this.$t("AnErrorOccurredDuringTheProcess"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          })
          .finally(() => {
            this.sendLoad = false;
          });
      } else {
        this.$swal.fire({
          title: this.$t("BeSureToEnterTheInformation"),
          text: "",
          icon: "error",
          confirmButtonText: this.$t("close"),
        });
      }
    },
    onUploaded(e) {
      this.fileURL = JSON.parse(e.request.responseText);
      this.containsFile = true;
      // console.log(this.fileURL.data.fileName);
    },
    getValueInKb(value) {
      return `${(value / 1024).toFixed(0)}kb`;
    },
    onUploadProgress(e) {
      // console.log(e);
      this.chunks.push({
        segmentSize: e.segmentSize,
        bytesLoaded: e.bytesLoaded,
        bytesTotal: e.bytesTotal,
      });
    },
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim().toLowerCase();
    },
    setValue(examGuid, categoryGuid, isBankQuestions) {
      // console.log(examGuid);
      this.examGuid = examGuid;
      this.categoryGuid = categoryGuid;
      this.isBankQuestions = isBankQuestions;
      this.dialog = true;
    },
    sendResultValues() {
      this.$emit("send-result-values", "carrier");
    },
  },
  created() {},
};
</script>