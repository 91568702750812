/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-var */
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import axios from "axios";

export var connection;
export const createConnection = () => {
  var url = axios.defaults.baseURL.substring(0, axios.defaults.baseURL.lastIndexOf("/api"));
  connection = new HubConnectionBuilder()
    .withUrl(`${url}/applicationHub`, {
      accessTokenFactory: () => `${localStorage.getItem("token")}`
    })
    .configureLogging(LogLevel.Error)
    .build();
};
export const start = async () => {
  await connection
    .start()
    .then(() => {})
    .catch(err => {
      console.log(`Connection failed${err}`);
    });
};
export const disconnect = () => {
  connection.stop();
};
export const onCloseConnection = () => {
  connection.onclose(() => {});
};
export const JoinGroup = (groupGuid,groupType) => {
  connection
    .invoke("JoinGroup", groupGuid,groupType)
    // .then((result) => {
    //   console.log(result);
    // })
    .catch(err => {
      console.log(err);
    });
};
export const LeaveGroup = groupGuid => {
  connection.invoke("LeaveGroup", groupGuid).catch(err => {
    console.log(err);
  });
};
