<template>
  <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition" max-width="600">
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            fab
            color="warning"
            @click="onClick(currentFeedbackText)"
            small
            outlined
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon>
              mdi-pencil-outline
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t("portfolio.Feedback.edit") }}
        </span>
      </v-tooltip>
    </template>
    <!-- <template v-slot:default="dialog"> -->
    <v-card>
      <v-toolbar color="primary" dark> {{ $t("portfolio.Feedback.edit") }}</v-toolbar>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-textarea
            :rules="[v => !!v || $t('portfolio.Feedback.validation.message')]"
            :label="$t('portfolio.Feedback.edit')"
            v-model="feedbackText"
            clearable
            auto-grow
            class="ma-3"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn :loading="loading" :disabled="!valid" color="primary" @click="edit()">{{
          $t("save")
        }}</v-btn>
        <v-btn :disabled="loading" text @click="dialog = false">{{ $t("close") }}</v-btn>
      </v-card-actions>
    </v-card>
    <!-- </template> -->
  </v-dialog>
</template>

<script>
export default {
  created() {},
  data() {
    return {
      loading: false,
      valid: true,
      feedbackText: null,
      dialog: false
    };
  },
  props: ["feedbackGuid", "currentFeedbackText"],
  methods: {
    edit() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      this.axios
        .post("Feedback/Update", {
          feedbackText: this.feedbackText,
          feedbackGuid: this.feedbackGuid
        })
        .then(() => {
          this.$emit("editData", { id: this.feedbackGuid, feedbackText: this.feedbackText });
          this.dialog = false;
          this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            });
        })
        .catch(() => {
            this.$swal.fire({
              title: this.$t("AnErrorOccurredDuringTheProcess"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            });
          })
        .finally(() => {
          this.loading = false;
        });
    },
    onClick(text) {
      this.feedbackText = text;
    }
  }
};
</script>

<style lang="scss" scoped></style>
