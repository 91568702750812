<template>
  <div>
    <v-card v-if="editData" flat>
      <v-card-text class="">
        <v-row class="pa-0 ma-0 pt-8">
          <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
            <v-autocomplete v-model="editData.examType" :items="examTypeList" outlined
              :label="$t('degrees.ChooseTheTypeofExam')" item-text="name" item-value="value">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" sm="12" class="py-0 ma-0">
            <!-- <v-datetime-picker
              :timeFormat="$moment(fromDatetime).format('h:mm a')"
              v-model="fromDatetime"
              :label="$t('mail.dateStart')"
              :clearText="$t('close')"
              :okText="$t('ok')"
            >
            </v-datetime-picker> -->

            <span style="font-size: 12px">{{ $t("mail.dateStart") }} </span>
            <DxDateBox display-format="yyyy/MM/dd hh:mm:ss a" :placeholder="$t('mail.dateStart')"
              styling-mode="outlined" v-model="fromDatetime" :value="fromDatetime" type="datetime"
              :use-mask-behavior="true" />
            <div class="v-text-field__details">
              <div class="v-messages theme--light">
                <div class="v-messages__wrapper"></div>
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="6" sm="12" class="py-0 ma-0">
            <!-- <v-datetime-picker
              :timeFormat="$moment(toDatetime).format('h:mm a')"
              v-model="toDatetime"
              :min="
                fromDatetime
                  ? fromDatetime.toISOString().substring(0, 10)
                  : null
              "
              :mintime="
                fromDatetime && toDatetime
                  ? fromDatetime.toISOString().substring(0, 10) ==
                    toDatetime.toISOString().substring(0, 10)
                    ? $moment(this.fromDatetime).format('HH:mm')
                    : null
                  : null
              "
              :label="$t('mail.dateEnd')"
              :clearText="$t('close')"
              :okText="$t('ok')"
            ></v-datetime-picker> -->

            <span style="font-size: 12px">{{ $t("mail.dateEnd") }} </span>
            <DxDateBox display-format="yyyy/MM/dd hh:mm:ss a" :placeholder="$t('mail.dateEnd')" styling-mode="outlined"
              v-model="toDatetime" :value="toDatetime" type="datetime" :use-mask-behavior="true" />

            <div class="v-text-field__details">
              <div class="v-messages theme--light">
                <div class="v-messages__wrapper"></div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
            <v-text-field :label="$t('exam.TitleOfTheExam')" :placeholder="$t('exam.TypeATitleForTheExam')" outlined
              v-model="editData.title"></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0 ma-0">
            <v-checkbox class="pb-5" hide-details v-model="editData.restrictedByTime"
              :label="$t('exam.RestrictedPeriod')"></v-checkbox>
            <v-time-picker-2 hide-details :text="$t('exam.TimePeriod')" color="primary" width="800"
              v-model="editData.restrictedTime" v-if="editData.restrictedByTime" outlined></v-time-picker-2>
          </v-col>
          <v-col cols="12" class="py-0 ma-0">
            <v-checkbox hide-details class="pb-2" :label="$t('exam.EnablePasswordToEnterExam')"
              v-model="editData.enablePasswordToEnter"></v-checkbox>
            <v-text-field v-if="editData.enablePasswordToEnter" :label="$t('exam.EnterToExamPassword')"
              :rules="[required]" outlined v-model="editData.passwordToEnter">
            </v-text-field>
          </v-col>
          <v-col cols="12" class="py-0 ma-0">
            <v-list-item class="pa-0">
              <v-checkbox :label="$t('exam.RestrictedMovingToNext')" :title="$t('exam.RestrictedMovingToNextHint')"
                v-model="editData.restrictedMovingToNext"></v-checkbox>

              <v-checkbox class="px-4" :label="$t('exam.ShowCorrectAnswerAfterSubmit')"
                v-model="editData.showCorrectAnswerAfterSubmit"></v-checkbox>
            </v-list-item>
          </v-col>
          <v-col cols="12" class="py-0 ma-0">
            <v-text-field v-model="editData.numberOfQuestionsInPage" type="number" class="pb-3" outlined
              :label="$t('exam.NumberOfQuestionsInPage')" :rules="[numberOfQuestionsInPageValidate]"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
            <v-textarea outlined name="input-7-4" :label="$t('exam.WriteTheDescriptionHereIfAny')"
              v-model="editData.description"></v-textarea>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col cols="12" md="4" sm="12" class="py-0 ma-0">
            <v-switch v-model="editDataIsActive" :label="$t('exam.ActivateTheExam')"></v-switch>
            <v-switch v-model="editData.showResult" :label="$t('mail.ViewResults')"></v-switch>
          </v-col>
        </v-row>
        <p class="px-4 orange--text">
          {{ $t("errors.alertShowRes") }}
        </p>
      </v-card-text>
      <v-card-actions class="justify-end pt-0 mt-0">
        <v-btn sm outlined @click="reExam(editData)" color="primary">
          {{ $t("exam.reExam") }}
          <v-icon>mdi-calendar-refresh</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :loading="sendLoad" outlined color="teal darken-1" @click="uploadData()" :disabled="!editData.title ||
      editData.examType == null ||
      !editData.startDate ||
      !editData.endDate ||
      (editData.enablePasswordToEnter && !editData.passwordToEnter) ||
      checkRestrictedTime ||
      !editData.numberOfQuestionsInPage ||
      editData.numberOfQuestionsInPage < 1
      ">
          {{ $t("exam.SaveModificationss") }}
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-divider class="my-4"></v-divider>
    <v-card v-if="editData" flat>
      <v-toolbar :class="$vuetify.theme.dark ? undefined : 'blue lighten-5'" elevation="0">
        {{ $t("exam.SettingsForRandomQuestions") }}
        <v-spacer></v-spacer>
        <v-switch :disabled="editData.isActive" class="pt-5" :loading="randomLoad"
          v-model="editData.randomQuestionsEnable" :label="editData.randomQuestionsEnable == true
        ? $t('exam.Enabled')
        : $t('exam.NotEnabled')
      " @change="randomQuestionsChangeStatus"></v-switch>
      </v-toolbar>
      <v-card-text class="mt-4">
        <v-row class="pa-0 ma-0">
          <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
            <v-data-table :headers="headers" :items="randomInfo" hide-default-footer class="elevation-0">
              <template v-slot:[`item.numberOfQuestions`]="{ item }">
                <v-text-field :disabled="editData.isActive || !editData.randomQuestionsEnable
      " class="pa-0 ma-0 mt-5" v-model="item.numberOfQuestions" value="0" filled dense outlined
                  type="number" onkeypress="return (event.charCode >= 48 && event.charCode <= 57 )" :rules="[
      (v) =>
        item.numberOfQuestions <= item.questionCount ||
        $t(
          'exam.TheNumberIsRequiredOrGreaterThanTheNumberOfQuestions'
        ),
    ]" min="0"></v-text-field>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-spacer></v-spacer>
        <v-btn :disabled="editData.isActive || !editData.randomQuestionsEnable" :loading="sendLoad2" outlined
          color="teal darken-1" @click="uploadRandom()">
          {{ $t("exam.SaveModificationss") }}
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <AddExam v-if="isInRole(2)" ref="addExamComponent" v-on:send-result-values="addFun" :folderId="editData.folderId">
    </AddExam>
  </div>
</template>

<script>
import DxDateBox from "devextreme-vue/date-box";
import AddExam from "./addExam.vue";
import VTimePicker2 from "../timePicker.vue";

export default {
  components: {
    DxDateBox,
    AddExam,
    VTimePicker2,
  },
  props: ["examGuid", "examQuestions"],
  data() {
    return {
      editDataIsActive: this.examQuestions.exam.isActive,
      totalRemainingSeconds: 0,
      sendLoad2: false,
      description: null,
      fromDatetime: null,
      toDatetime: null,
      examTypeSelect: null,
      required: (value) => value != null || this.$t("ThisFieldIsRequired"),
      examTypeList: [
        {
          name: this.$t("degrees.daily"),
          value: 0,
        },
        {
          name: this.$t("degrees.Monthly"),
          value: 3,
        },
        {
          name: this.$t("degrees.midFinal"),
          value: 2,
        },
        {
          name: this.$t("degrees.final"),
          value: 1,
        },
      ],
      titleMail: "",
      isLoading: false,
      subjectSelect: null,
      lectures: [],
      sendLoad: false,
      dialog: false,
      deparmentSelect: null,
      stageSelect: null,
      sectionSelect: null,
      studentsSelect: [],
      studentList: [],
      editData: this.examQuestions.exam,
      randomLoad: false,
      randomInfo: [],
      headers: [
        {
          text: this.$t("exam.TypeOfQuestion"),
          align: "start",
          value: "questionType",
        },
        {
          text: this.$t("exam.TheTotalNumberOfQuestions"),
          value: "questionCount",
        },
        { text: this.$t("exam.TotalScores"), value: "totalMarks" },
        {
          text: this.$t("exam.TheNumberOfQuestionsTheStudentHas"),
          value: "numberOfQuestions",
        },
      ],
      numberOfQuestionsInPageValidate: (value) => (value != null && value > 0) ||
        `${this.$t("ThisFieldIsRequired")
        } - ${this.$t("exam.ValueMustBeGreaterThanZero")}`,
    };
  },
  computed: {
    checkRestrictedTime() {
      return (
        this.editData.restrictedByTime &&
        (!this.editData.restrictedTime || this.editData.restrictedTime == 0)
      );
    }
  },
  methods: {
    uploadRandom() {
      this.sendLoad2 = true;
      const data = {
        examGuid: this.examGuid,
        fillInTheBlanks: this.randomInfo[1].numberOfQuestions,
        multiChoice: this.randomInfo[2].numberOfQuestions,
        oneChoice: this.randomInfo[4].numberOfQuestions,
        number: this.randomInfo[3].numberOfQuestions,
        attachedFile: this.randomInfo[0].numberOfQuestions,
      };
      // console.log(data);
      this.axios
        .put("Exams/RandomQuestionsSettingSave", data)
        .then((res) => {
          if (res.data.statusCode == 1) {
            this.sendResultValues();
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
          } else if (res.data.statusCode == 2) {
            this.$swal.fire({
              title: this.$t("errors.YouCanNotModifyTheExamTheExamIsActiv"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else if (res.data.statusCode == 3) {
            this.$swal.fire({
              title: this.$t("errors.ExamNotFound"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else if (res.data.statusCode == 4) {
            this.$swal.fire({
              title: this.$t(
                "errors.YouCanNotModifyTheExamItHasBeenAnsweredByTheStudents"
              ),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else if (res.data.statusCode == 5) {
            this.$swal.fire({
              title: this.$t("errors.ErrorInvaildSettings"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else {
            this.$swal.fire({
              title: this.$t(
                "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
              ),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          }
          this.sendResultValues();
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.sendLoad2 = false;
        });
    },
    randomQuestionsChangeStatus() {
      this.randomLoad = true;
      this.axios
        .put(`Exams/RandomQuestionsChangeStatus?Guid=${this.examGuid}`, {})
        .then((res) => {
          if (res.data.statusCode == 1) {
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            this.sendResultValues();
          } else if (res.data.statusCode == 2) {
            this.$swal.fire({
              title: this.$t("errors.YouCanNotModifyTheExamTheExamIsActiv"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
            this.$emit("send-result-values", "carrier");
          } else if (res.data.statusCode == 3) {
            this.$swal.fire({
              title: this.$t("errors.ExamNotFound"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
            this.$emit("send-result-values", "carrier");
          } else if (res.data.statusCode == 4) {
            this.$swal.fire({
              title: this.$t(
                "errors.YouCanNotModifyTheExamItHasBeenAnsweredByTheStudents"
              ),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
            this.$emit("send-result-values", "carrier");
          } else {
            this.$swal.fire({
              title: this.$t(
                "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
              ),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
            this.$emit("send-result-values", "carrier");
          }
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.randomLoad = false;
        });
    },

    sendResultValues() {
      this.$emit("send-result-values", "carrier");
    },
    uploadData() {
      if (this.fromDatetime > this.toDatetime) {
        this.$swal.fire({
          title: this.$t("exam.fromDateTimeGreaterThanToDateTime"),
          text: "",
          icon: "error",
          confirmButtonText: this.$t("close"),
        });
        return;
      }
      this.sendLoad = true;
      const data = {
        Guid: this.editData.guid,
        title: this.editData.title,
        description: this.editData.description,
        startDate: this.$moment(this.fromDatetime).format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
        endDate: this.$moment(this.toDatetime).format("YYYY-MM-DDTHH:mm:ss"),
        isActive: this.editDataIsActive,
        showResult: this.editData.showResult,
        examType: this.editData.examType,
        enablePasswordToEnter: this.editData.enablePasswordToEnter,
        passwordToEnter: this.editData.passwordToEnter,
        numberOfQuestionsInPage: this.editData.numberOfQuestionsInPage,
        showCorrectAnswerAfterSubmit:
          this.editData.showCorrectAnswerAfterSubmit,
        restrictedMovingToNext: this.editData.restrictedMovingToNext,
        restrictedByTime: this.editData.restrictedByTime,
        restrictedTime: this.editData.restrictedTime,
      };
      //   console.log(data);
      this.axios
        .put("Exams/Edit", data)
        .then((res) => {
          this.sendResultValues();
          this.dialog = false;

          if (res.data.statusCode == 1) {
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
          } else if (res.data.statusCode == 2) {
            this.$swal.fire({
              title: this.$t("errors.YouCanNotModifyTheExamTheExamIsActiv"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else if (res.data.statusCode == 3) {
            this.$swal.fire({
              title: this.$t("errors.ExamNotFound"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else if (res.data.statusCode == 4) {
            this.$swal.fire({
              title: this.$t(
                "errors.YouCanNotModifyTheExamItHasBeenAnsweredByTheStudents"
              ),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else if (res.data.statusCode == 6) {
            this.$swal.fire({
              title: this.$t("exam.RestrictedTimeMustBeBetweenStartEndTime"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else {
            this.$swal.fire({
              title: this.$t(
                "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
              ),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          }
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.sendLoad = false;
        });
    },
    reExam(data) {
      this.$refs.addExamComponent.reExam(data.guid, data.subjectGuid);
    },
    addFun(val) {
      // console.log(val);
      this.$router.push({
        path: `/showExam=${val}`,
        params: { id: val },
      });
    },
  },
  created() {
    this.fromDatetime = new Date(this.editData.startDate);
    this.toDatetime = new Date(this.editData.endDate);

    if (this.editData.randomQuestionsEnable) {
      this.randomInfo.push(
        this.examQuestions.randomQuestionsSettings.attachedFile
      );
      this.randomInfo[0].questionType = this.$t("exam.UploadAFile");
      this.randomInfo.push(
        this.examQuestions.randomQuestionsSettings.fillInTheBlanks
      );
      this.randomInfo[1].questionType = this.$t("exam.FillInTheBlank");
      this.randomInfo.push(
        this.examQuestions.randomQuestionsSettings.multiChoice
      );
      this.randomInfo[2].questionType = this.$t("exam.MultipleChoices");
      this.randomInfo.push(this.examQuestions.randomQuestionsSettings.number);
      this.randomInfo[3].questionType = this.$t(
        "exam.FillInTheBlankNumericValue"
      );
      this.randomInfo.push(
        this.examQuestions.randomQuestionsSettings.oneChoice
      );
      this.randomInfo[4].questionType = this.$t("exam.ChooseOne");
      // console.log(this.randomInfo);
    }
  },
};
</script>

<style>
.dx-texteditor-input {
  padding-right: 8px !important;
}
</style>
