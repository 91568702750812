<template>
  <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="white" outlined v-bind="attrs" v-on="on">{{
        $t("portfolio.Reflections.add")
      }}</v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card flat :disabled="loading">
        <v-toolbar color="primary" dark tile>{{ $t("portfolio.Reflections.add") }}</v-toolbar>
        <v-card-text class="mt-5">
          <DxHtmlEditor
            v-show="!loading"
            height="500px"
            v-model="description"
            :placeholder="$t('mail.writeHere')"
          >
            <DxMediaResizing :enabled="true" />
            <DxToolbar :multiline="isMultiline">
              <DxItem name="undo" />
              <DxItem name="redo" />
              <DxItem name="separator" />
              <DxItem :accepted-values="sizeValues" name="size" />
              <DxItem :accepted-values="fontValues" name="font" />
              <DxItem name="separator" />
              <DxItem name="bold" />
              <DxItem name="italic" />
              <DxItem name="strike" />
              <DxItem name="underline" />
              <DxItem name="separator" />
              <DxItem name="alignLeft" />
              <DxItem name="alignCenter" />
              <DxItem name="alignRight" />
              <DxItem name="alignJustify" />
              <DxItem name="separator" />
              <DxItem name="orderedList" />
              <DxItem name="bulletList" />
              <DxItem name="separator" />
              <DxItem :accepted-values="headerValues" name="header" />
              <DxItem name="separator" />
              <DxItem name="color" />
              <DxItem name="background" />
              <DxItem name="separator" />
              <DxItem name="link" />
              <DxItem name="image" />
              <DxItem name="separator" />
              <DxItem name="clear" />
              <DxItem name="codeBlock" />
              <DxItem name="blockquote" />
              <DxItem name="separator" />
              <DxItem name="insertTable" />
              <DxItem name="deleteTable" />
              <DxItem name="insertRowAbove" />
              <DxItem name="insertRowBelow" />
              <DxItem name="deleteRow" />
              <DxItem name="insertColumnLeft" />
              <DxItem name="insertColumnRight" />
              <DxItem name="deleteColumn" />
            </DxToolbar>

            <div v-html="description" />
          </DxHtmlEditor>
          <h3 v-show="loading">{{ $t("Loading") }}</h3>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            :disabled="!valid || loading"
            :loading="loading"
            color="primary"
            @click="AddRequest()"
            >{{ $t("add") }}</v-btn
          >
          <v-btn :disabled="loading" text @click="dialog.value = false">{{ $t("close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import {
 DxHtmlEditor, DxToolbar, DxMediaResizing, DxItem
} from "devextreme-vue/html-editor";

export default {
  data() {
    return {
      valid: false,
      description: null,
      dialog: false,
      loading: false,
      sizeValues: ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"],
      fontValues: [
        "Arial",
        "Courier New",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Tahoma",
        "Times New Roman",
        "Verdana"
      ],
      headerValues: [false, 1, 2, 3, 4, 5],
      isMultiline: true,
      isLoading: false,
      fileURL: null,
      sendLoad: false,
      // uploadURL: process.env.VUE_APP_UPLOAD,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      chunks: [],
      allowedFileExtensions: [
        ".jpg",
        ".jpeg",
        ".gif",
        ".png",
        ".bmp",
        ".mp4",
        ".wmv",
        ".flv",
        ".avi",
        ".webm",
        ".weba",
        ".wav",
        ".mp3",
        ".aac",
        ".pdf",
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
        ".ppt",
        ".pptx",
        ".txt",
        ".zip",
        ".rar"
      ]
    };
  },
  methods: {
    async AddRequest() {
      this.loading = true;
      const requestDto = {
        workSampleGuid: this.$route.params.id,
        reflectionText: this.description
      };
      await this.axios
        .post("Reflection/Create", requestDto)
        .then(() => {
          this.$emit("addReflection");
          this.description = null;
          this.dialog = false;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  components: {
    DxHtmlEditor,
    DxMediaResizing,
    DxToolbar,
    DxItem
  },
  watch: {
    description(newDescription) {
      // Use a watcher to monitor changes to 'description'
      this.valid = !/^\s*$/.test(newDescription); // Check if description is not empty or contains only whitespace
    }
  }
};
</script>

<style></style>
