<template>
  <div>
    <v-dialog
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
      v-model="dialog"
      no-click-animation
      :retain-focus="false"
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark>
          {{ $t("mail.SendMail") }}
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-row class="pa-0 ma-0" v-if="isInRole(2)">
            <v-col cols="12" md="3" sm="12" class="py-0 ma-0">
              <v-autocomplete
                v-model="subjectSelect"
                :items="lectures"
                outlined
                :label="$t('degrees.selectSubject')"
                item-text="subjectName"
                item-value="subjectGuid"
                @change="clearSelect"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" sm="12" class="py-0 ma-0">
              <v-autocomplete
                v-model="deparmentSelect"
                :items="departmentList"
                outlined
                :label="$t('home.departmentChoese')"
                item-text="departmentName"
                item-value="departmentGuid"
                @change="clearSelect2"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" sm="12" class="py-0 ma-0">
              <v-autocomplete
                v-model="stageSelect"
                :items="stageList"
                outlined
                :label="$t('lecOne.selectStage')"
                :item-text="getFieldText"
                item-value="stageGuid"
                @change="clearSelect3"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" sm="12" class="py-0 ma-0">
              <v-autocomplete
                v-model="sectionSelect"
                :items="sectionList"
                outlined
                :label="$t('mail.selectSection')"
                item-text="sectionName"
                item-value="sectionGuid"
                @change="getStudent"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" md="12" sm="12" class="py-0 ma-0" v-if="isInRole(0)">
              <v-autocomplete
                v-model="selectSubjTech"
                :items="lectures"
                outlined
                :label="$t('mail.ChooseATeacherOrSubject')"
                :item-text="getTeacher"
                :item-value="getTeacherValue"
                clearable
                chips
                small-chips
                deletable-chips
                :loading="isLoading"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0 ma-0" v-if="isInRole(2)">
              <v-autocomplete
                v-model="studentsSelect"
                :items="studentList"
                outlined
                multiple
                :label="$t('mail.ChooseStudents')"
                item-text="studentFullName"
                item-value="studentGuid"
                clearable
                chips
                small-chips
                deletable-chips
                :loading="isLoading"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 3">
                    <span>{{ item.studentFullName }}</span>
                  </v-chip>
                  <span v-if="index === 3" class="grey--text caption">
                    (+{{ studentsSelect.length - 3 }} {{ $t("mail.Others") }})
                  </span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggle">
                    <v-list-item-action>
                      <v-icon :color="studentsSelect.length > 0 ? 'primary' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("mail.selectAll") }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:append-item>
                  <v-divider class="mb-2"></v-divider>
                  <v-list-item disabled>
                    <v-list-item-avatar color="grey lighten-3">
                      <v-icon light> mdi-account-question-outline </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content v-if="likesAllFruit">
                      <v-list-item-title>
                        {{ $t("mail.AllHaveBeenSelected") }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-content v-else-if="likesSomeFruit">
                      <v-list-item-title>
                        {{ $t("mail.TheNumberOfStudentsSpecified") }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ studentsSelect.length }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <v-list-item-title>
                        {{ $t("mail.NoStudentIdentified") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
              <v-text-field
                :label="$t('mail.msgTitle')"
                :placeholder="$t('mail.msgTitle')"
                outlined
                v-model="titleMail"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
              <DxHtmlEditor
                height="500px"
                v-model="markup"
                :placeholder="$t('mail.writeHere')"
              >
              <DxTableContextMenu  :enabled="true"/>
            <DxTableResizing :enabled="true"/>
            <DxMediaResizing :enabled="true" />
                            <DxToolbar :multiline="isMultiline">
                  <DxItem name="undo" />
                  <DxItem name="redo" />
                  <DxItem name="separator" />
                  <DxItem :accepted-values="sizeValues" name="size" />
                  <DxItem :accepted-values="fontValues" name="font" />
                  <DxItem name="separator" />
                  <DxItem name="bold" />
                  <DxItem name="italic" />
                  <DxItem name="strike" />
                  <DxItem name="underline" />
                  <DxItem name="separator" />
                  <DxItem name="alignLeft" />
                  <DxItem name="alignCenter" />
                  <DxItem name="alignRight" />
                  <DxItem name="alignJustify" />
                  <DxItem name="separator" />
                  <DxItem name="orderedList" />
                  <DxItem name="bulletList" />
                  <DxItem name="separator" />
                  <DxItem :accepted-values="headerValues" name="header" />
                  <DxItem name="separator" />
                  <DxItem name="color" />
                  <DxItem name="background" />
                  <DxItem name="separator" />
                  <DxItem name="link" />
                  <DxItem name="image" />
                  <DxItem name="separator" />
                  <DxItem name="clear" />
                  <DxItem name="codeBlock" />
                  <DxItem name="blockquote" />
                  <DxItem name="separator" />
                  <DxItem name="insertTable" />
                  <DxItem name="deleteTable" />
                  <DxItem name="insertRowAbove" />
                  <DxItem name="insertRowBelow" />
                  <DxItem name="deleteRow" />
                  <DxItem name="insertColumnLeft" />
                  <DxItem name="insertColumnRight" />
                  <DxItem name="deleteColumn" />
                </DxToolbar>

                <div v-html="markup" />
              </DxHtmlEditor>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" md="12" sm="12" class="py-8 ma-0">
              <div class="fileUpload">
                <DxFileUploader
                  :allowed-file-extensions="allowedFileExtensions"
                  :selectButtonText="$t('lecOne.ChooseTheFile')"
                  :labelText="$t('lecOne.ChooseTheFileYouWantToUpload')"
                  [multiple]="false"
                  :chunk-size="5000000"
                  :max-file-size="209715200"
                  name="FileUpload"
                  :accept="allowedFileExtensions.join(',')"
                  uploadMode="instantly"
                  uploadMethod="POST"
                  :uploadHeaders="headers"
                  :upload-url="`${UploaderUrl}Mails`"
                  @upload-started="() => (chunks = [])"
                  @progress="onUploadProgress($event)"
                  @uploaded="onUploaded($event)"
                  messageUploadText="asd"
                />
                <v-alert dense text type="info" color="primary">
                  <span class="note"
                    >{{ $t("lecOne.AllowedFiles") }} :
                    <span
                      >jpg, jpeg, gif, png, .bmp, mp4, wmv, flv, avi, webm, weba wav, mp3,
                      aac, pdf, doc, docx, xls, xlsx, ppt, pptx, txt, zip, rar</span
                    >.</span
                  >
                  <span class="note"
                    >{{ $t("lecOne.MaximumFileSize") }} : <span>200MB</span></span
                  >
                </v-alert>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="dialog = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            :loading="sendLoad"
            color="primary darken-1"
            @click="uploadData()"
            :disabled="!titleMail"
          >
            {{ $t("send") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  DxHtmlEditor,
  DxToolbar,
  DxMediaResizing,
  DxItem,
  DxTableResizing,
  DxTableContextMenu
} from "devextreme-vue/html-editor";
import DxFileUploader from "devextreme-vue/file-uploader";
import { mapState } from "vuex";

export default {
  components: {
    DxHtmlEditor,
    DxMediaResizing,
    DxToolbar,
    DxItem,
    DxFileUploader,
    DxTableResizing,
  DxTableContextMenu
  },
  data() {
    return {
      titleMail: "",
      sizeValues: ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"],
      fontValues: [
        "Arial",
        "Courier New",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Tahoma",
        "Times New Roman",
        "Verdana",
      ],
      headerValues: [false, 1, 2, 3, 4, 5],
      isMultiline: true,
      markup: ``,
      isLoading: false,
      subjectSelect: null,
      lectures: [],
      fileURL: null,
      sendLoad: false,
      // uploadURL: process.env.VUE_APP_UPLOAD,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      chunks: [],
      allowedFileExtensions: [
        ".jpg",
        ".jpeg",
        ".gif",
        ".png",
        ".bmp",
        ".mp4",
        ".wmv",
        ".flv",
        ".avi",
        ".webm",
        ".weba",
        ".wav",
        ".mp3",
        ".aac",
        ".pdf",
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
        ".ppt",
        ".pptx",
        ".txt",
        ".zip",
        ".rar",
      ],

      dialog: false,
      deparmentSelect: null,
      stageSelect: null,
      sectionSelect: null,
      studentsSelect: [],
      studentList: [],
      selectSubjTech: {
        subjectID: null,
        TeacherID: null,
      },
    };
  },
  computed: {
    ...mapState(["UploaderUrl"]),
    likesAllFruit() {
      return this.studentsSelect.length === this.studentList.length;
    },
    likesSomeFruit() {
      return this.studentsSelect.length > 0 && !this.likesAllFruit;
    },
    icon() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    departmentList() {
      let dataList = [];
      if (this.subjectSelect) {
        dataList = this.lectures.filter(
          (item) => item.subjectGuid === this.subjectSelect
        );
        // console.log(dataList);
        return dataList[0].departments;
      }
      return dataList;
    },
    stageList() {
      let dataList = [];
      if (this.deparmentSelect) {
        dataList = this.departmentList.filter(
          (item) => item.departmentGuid === this.deparmentSelect
        );
        return dataList[0].stages;
      }
      return dataList;
    },
    sectionList() {
      let dataList = [];
      if (this.stageSelect) {
        dataList = this.stageList.filter((item) => item.stageGuid === this.stageSelect);
        return dataList[0].sections;
      }
      return dataList;
    },
  },
  methods: {
    clearSelect() {
      this.sectionSelect = null;
      this.stageSelect = null;
      this.deparmentSelect = null;
    },
    clearSelect2() {
      this.sectionSelect = null;
      this.stageSelect = null;
    },
    clearSelect3() {
      this.sectionSelect = null;
    },
    clear() {
      this.titleMail = null;
      this.markup = null;
      this.studentsSelect = [];
      this.subjectSelect = null;
      this.stageSelect = null;
      this.deparmentSelect = null;
      this.sectionSelect = null;
      this.fileURL = null;
    },
    uploadData() {
      if (this.isInRole(0)) {
        this.studentsSelect[0] = this.selectSubjTech.teacherGuid;
        this.subjectSelect = this.selectSubjTech.subjectGuid;
        this.sectionSelect = this.$store.state.userData.sectionGuid;
      }

      if (this.titleMail && this.studentsSelect[0]) {
        this.sendLoad = true;
        // if (this.likesAllFruit) {
        //   for (let i = 0; i < this.studentsSelect.length; i++) {
        //     this.studentsSelect[i] = this.studentsSelect[i].studentGuid;
        //   }
        // }

        const data = {
          Title: this.titleMail,
          DetailsHTML: this.markup,
          ToGuid: this.studentsSelect.some((s) => s.studentGuid)
            ? this.studentsSelect.map((a) => a.studentGuid)
            : this.studentsSelect,
          SubjectGuid: this.subjectSelect,
          SectionGuid: this.sectionSelect,
          FileName: this.fileURL ? this.fileURL.data.fileName : "",
        };
        // console.log(data);
        this.axios
          .post("Mails/Send", data)
          .then((res) => {
            this.sendResultValues();
            this.fileURL = null;
            this.dialog = false;
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            // console.log(res);
          })
          .catch(() => {
            this.$swal.fire({
              title: this.$t("AnErrorOccurredDuringTheProcess"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          })
          .finally(() => {
            this.sendLoad = false;
          });
      } else {
        this.$swal.fire({
          title: this.$t("BeSureToEnterTheInformation"),
          text: "",
          icon: "error",
          confirmButtonText: this.$t("close"),
        });
      }
    },
    onUploaded(e) {
      this.fileURL = JSON.parse(e.request.responseText);
      // console.log(this.fileURL.data.fileName);
    },
    getValueInKb(value) {
      return `${(value / 1024).toFixed(0)}kb`;
    },
    onUploadProgress(e) {
      // console.log(e);
      this.chunks.push({
        segmentSize: e.segmentSize,
        bytesLoaded: e.bytesLoaded,
        bytesTotal: e.bytesTotal,
      });
    },
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim().toLowerCase();
    },
    sendResultValues() {
      this.$emit("send-result-values", "carrier");
    },
    markupFun() {
      // console.log(this.markup);
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.studentsSelect = [];
        } else {
          this.studentsSelect = this.studentList.slice();
        }
      });
    },
    getStudent() {
      if (this.sectionSelect && this.subjectSelect) {
        this.isLoading = true;
        this.axios
          .get(
            `Students/Get?sectionGuid=${this.sectionSelect}&subjectGuid=${this.subjectSelect}`
          )
          .then((res) => {
            this.studentList = res.data.data;
            this.studentsSelect = [];
            // console.log(this.studentList);
          })
          .catch(() => {})
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    setValue() {
      this.clear();
      this.dialog = true;
    },
    getFieldText(item) {
      return `${item.stageName} - ${item.studyName}`;
    },
    getTeacher(item) {
      return `${item.teacherName} - ${item.subjectName}`;
    },
    getTeacherValue(item) {
      return { teacherGuid: item.teacherGuid, subjectGuid: item.subjectGuid };
    },
    getLec() {
      this.axios
        .get("Subjects/Get")
        .then((res) => {
          if (this.isInRole(2)) {
            this.lectures = res.data.data.subjects;
          } else {
            this.lectures = res.data.data;
          }
        })
        .catch((err) => {});
    },
  },
  created() {
    this.getLec();
  },
};
</script>
<style lang="scss">
.dx-htmleditor {
  border-radius: 5px;
  border-color: #909090;
}
.dx-htmleditor .dx-htmleditor-toolbar-wrapper:first-child {
  border-bottom: 1px solid #909090;
}
.dx-htmleditor-content img {
  vertical-align: middle;
  padding-right: 10px;
}

.dx-htmleditor-content table {
  width: 50%;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}
.chunk-panel {
  width: 505px;
  height: 165px;
  overflow-y: auto;
  padding: 18px;
  margin-top: 40px;
  background-color: rgba(191, 191, 191, 0.15);
}
.fileUpload {
  border: 1px solid #9e9e9e;
  padding: 10px;
  border-radius: 5px;
}
</style>
