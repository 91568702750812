var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.questionInfoFun)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"flat":"","outlined":""}},[_c('v-card-text',[_c('question-title',{attrs:{"examQuestion":_vm.questionInfo}}),(_vm.questionInfo.questionType === 'MultiChoice')?_c('div',{staticClass:"text--primary"},[_c('v-alert',{attrs:{"color":"primary","text":""}},[_c('div',_vm._l((_vm.questionInfo.questionAnswers),function(multequestionInfo){return _c('v-alert',{key:multequestionInfo.answerGuid,staticClass:"pa-1 ma-1",attrs:{"color":_vm.getColor(multequestionInfo.isCorrect),"outlined":"","text":""}},[_c('v-checkbox',{staticClass:"py-0 my-0",attrs:{"hide-details":"","readonly":(_vm.ShowCorrectAnswerAfterSubmit &&
                        _vm.questionInfo.studentAnswers.length > 0) ||
                      !_vm.isInRole(0),"label":multequestionInfo.questionAnswer,"value":multequestionInfo.questionAnswer,"color":multequestionInfo.isCorrect ? 'green' : ''},model:{value:(_vm.multipleAnswers),callback:function ($$v) {_vm.multipleAnswers=$$v},expression:"multipleAnswers"}})],1)}),1)])],1):_vm._e(),(_vm.questionInfo.questionType === 'OneChoice')?_c('div',{staticClass:"text--primary"},[_c('v-alert',{attrs:{"color":"primary","text":""}},[_c('v-radio-group',{attrs:{"readonly":(_vm.ShowCorrectAnswerAfterSubmit &&
                    _vm.questionInfo.studentAnswers.length > 0) ||
                  !_vm.isInRole(0)},model:{value:(_vm.oneAnswers),callback:function ($$v) {_vm.oneAnswers=$$v},expression:"oneAnswers"}},[_c('div',_vm._l((_vm.questionInfo.questionAnswers),function(oneChoesquestionInfo){return _c('v-alert',{key:oneChoesquestionInfo.answerGuid,staticClass:"pa-1 ma-1",attrs:{"color":_vm.getColor(oneChoesquestionInfo.isCorrect),"outlined":"","text":""}},[_c('v-radio',{key:oneChoesquestionInfo.answerGuid,attrs:{"label":oneChoesquestionInfo.questionAnswer,"value":oneChoesquestionInfo.questionAnswer}})],1)}),1)])],1)],1):_vm._e(),(_vm.questionInfo.questionType === 'FillInTheBlanks')?_c('div',{staticClass:"text--primary"},[_c('v-alert',{staticClass:"py-8",attrs:{"color":"primary","text":""}},[_c('v-textarea',{attrs:{"hide-details":"","readonly":(_vm.ShowCorrectAnswerAfterSubmit &&
                    _vm.questionInfo.studentAnswers.length > 0) ||
                  !_vm.isInRole(0),"outlined":"","name":"input-7-4","label":_vm.$t('exam.TypeYourAnswerHere'),"placeholder":_vm.$t('exam.TypeYourAnswerHere')},model:{value:(_vm.fillInTheBlank),callback:function ($$v) {_vm.fillInTheBlank=$$v},expression:"fillInTheBlank"}})],1),(
                _vm.isInRole(2) ||
                (_vm.ShowCorrectAnswerAfterSubmit &&
                  _vm.questionInfo.studentAnswers &&
                  _vm.questionInfo.studentAnswers.length > 0)
              )?_c('v-alert',{staticClass:"pa-1 ma-0 mb-2 my-2",attrs:{"color":"success","text":""}},[_c('v-card-subtitle',{staticClass:"ma-0 pa-0 px-2"},[_vm._v("\n                "+_vm._s(_vm.$t("exam.TheCorrectAnswer"))+" :\n              ")]),(
                  _vm.questionInfo.questionAnswers &&
                  _vm.questionInfo.questionAnswers.length > 0
                )?_c('v-card-text',{staticClass:"mt-2",style:(_vm.$vuetify.theme.dark ? 'color:white' : 'color:black')},[_vm._v("\n                "+_vm._s(_vm.questionInfo.questionAnswers[0].questionAnswer)+"\n              ")]):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.questionInfo.questionType === 'Number')?_c('div',{staticClass:"text--primary"},[_c('v-alert',{staticClass:"pb-0 pt-8",attrs:{"color":"primary","text":""}},[_c('v-text-field',{attrs:{"readonly":(_vm.ShowCorrectAnswerAfterSubmit &&
                    _vm.questionInfo.studentAnswers.length > 0) ||
                  !_vm.isInRole(0),"outlined":"","name":"input-7-4","label":_vm.$t('exam.TypeYourAnswerHere'),"placeholder":_vm.$t('exam.TheAnswerShouldBeANumber'),"type":"number"},model:{value:(_vm.fillInTheBlank),callback:function ($$v) {_vm.fillInTheBlank=$$v},expression:"fillInTheBlank"}})],1),(
                _vm.isInRole(2) ||
                (_vm.ShowCorrectAnswerAfterSubmit &&
                  _vm.questionInfo.studentAnswers &&
                  _vm.questionInfo.studentAnswers.length > 0)
              )?_c('v-alert',{staticClass:"pa-1 ma-0 mb-2 my-2",attrs:{"color":"success","text":""}},[_c('v-card-subtitle',{staticClass:"ma-0 pa-0 px-2"},[_vm._v("\n                "+_vm._s(_vm.$t("exam.TheCorrectAnswer"))+" :\n              ")]),(
                  _vm.questionInfo.questionAnswers &&
                  _vm.questionInfo.questionAnswers.length > 0
                )?_c('v-card-text',{staticClass:"mt-2",style:(_vm.$vuetify.theme.dark ? 'color:white' : 'color:black')},[_vm._v("\n                "+_vm._s(_vm.questionInfo.questionAnswers[0].questionAnswer)+"\n              ")]):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.questionInfo.questionType === 'AttachedFile')?_c('div',{staticClass:"text--primary"},[_c('div',{staticClass:"v-alert v-sheet theme--dark v-alert--text primary--text pa-2",attrs:{"text":""}},[_c('DxFileUploader',{ref:"FileUpload",attrs:{"allowed-file-extensions":_vm.allowedFileExtensions,"selectButtonText":_vm.$t('lecOne.ChooseTheFile'),"labelText":_vm.$t('lecOne.ChooseTheFileYouWantToUpload'),"multiple":true,"chunk-size":5000000,"max-file-size":209715200,"name":"FileUpload","accept":_vm.allowedFileExtensions.join(','),"uploadMode":"instantly","uploadMethod":"POST","uploadHeaders":_vm.headers,"upload-url":(_vm.UploaderUrl + "ExamAnswer"),"disabled":!_vm.isInRole(0)},on:{"upload-started":function () { return (_vm.chunks = []); },"progress":function($event){return _vm.onUploadProgress($event)},"uploaded":function($event){return _vm.onUploaded($event)},"upload-error":_vm.onUploadError}}),_c('v-alert',{attrs:{"width":"100%","dense":"","text":"","type":"info","color":"primary"}},[_c('span',{staticClass:"note"},[_vm._v(_vm._s(_vm.$t("lecOne.AllowedFiles"))+" :\n                  "),_c('span',[_vm._v("jpg, jpeg, gif, png, bmp, mp4, wmv, flv, avi, webm, weba,\n                    wav, mp3, aac, pdf, doc, docx, xls, xlsx, ppt, pptx, txt,\n                    zip, rar")]),_vm._v(".")]),_c('div',{staticClass:"note"},[_vm._v("\n                  "+_vm._s(_vm.$t("lecOne.MaximumFileSize"))+" : "),_c('span',[_vm._v("200MB")])])]),(
                  _vm.questionInfo.studentAnswers &&
                  _vm.questionInfo.studentAnswers.length > 0
                )?_c('v-slide-group',{staticClass:"py-2",attrs:{"multiple":"","show-arrows":"","mandatory":""}},_vm._l((_vm.questionInfo.studentAnswers),function(item,index){return _c('v-slide-item',{key:index,staticClass:"mx-1",attrs:{"value":item},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [(item && item.answerText)?_c('download-file',{key:index,attrs:{"iconWidth":50,"width":250,"fileUrl":item.answerText,"showDeleteBtn":_vm.isInRole(0) && !_vm.ShowCorrectAnswerAfterSubmit,"deleteItem":_vm.deleteAttachedItem,"item":item}}):_vm._e()]}}],null,true)})}),1):_vm._e()],1)]):_vm._e()],1),_c('v-card-actions',{staticClass:"mx-2 mb-3"},[(_vm.isInRole(0))?_c('v-btn',{staticClass:"primary",attrs:{"disabled":_vm.ShowCorrectAnswerAfterSubmit &&
              _vm.questionInfo.studentAnswers.length > 0,"loading":_vm.loading},on:{"click":function($event){return _vm.saveQusetionAnser(_vm.questionInfo)}}},[_vm._v("\n            "+_vm._s(_vm.$t("save"))+"\n            "),_c('v-icon',{staticClass:"px-1"},[_vm._v(" mdi-content-save ")])],1):_vm._e(),_c('v-spacer'),(_vm.isInRole(0))?_c('v-btn',{attrs:{"title":!_vm.flagStatus ? this.$t('exam.flagQuestionHint') : this.$t('exam.removeTheFlag'),"icon":"","elevation":"1"},on:{"click":function($event){return _vm.changeFlagStatus()}}},[_c('v-icon',{key:_vm.flagStatus,attrs:{"color":!_vm.flagStatus ? 'grey' : 'red'}},[_vm._v(_vm._s(!_vm.flagStatus ? "mdi-flag-outline" : "mdi-flag"))])],1):_vm._e()],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }