<template>
  <div>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="500"
      v-model="dialog"
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark>
          ارسال ملف
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-8">
          <v-row class="pa-0 ma-0">
            <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
              <v-text-field
                label="الرسالة"
                placeholder="الرسالة"
                outlined
                v-model="messageFile"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12" sm="12" class="pb-8 ma-0 mt-n4">
              <div class="fileUpload">
                <DxFileUploader
                  :allowed-file-extensions="allowedFileExtensions"
                  :selectButtonText="$t('lecOne.ChooseTheFile')"
                  :labelText="$t('lecOne.ChooseTheFileYouWantToUpload')"
                  :multiple="true"
                  :chunk-size="5000000"
                  :max-file-size="209715200"
                  name="FileUpload"
                  :accept="allowedFileExtensions.join(',')"
                  uploadMode="useButtons"
                  uploadMethod="POST"
                  :uploadHeaders="headers"
                  :upload-url="`${UploaderUrl}${ChatType}&extraParm=${
                    ChatType == 'ChatGroup'
                      ? this.info.guid
                      : this.info.userGuid
                  }&extraParm2=${messageFile}&extraParm3=${
                    ChatType == 'ChatGroup'
                      ? this.info.groupName
                      : this.info.userName
                  }`"
                  @upload-started="() => (chunks = [])"
                  @progress="onUploadProgress($event)"
                  @uploaded="onUploaded($event)"
                  messageUploadText="asd"
                />
                <v-alert dense text type="info" color="primary">
                  <span class="note"
                    >{{ $t("lecOne.AllowedFiles") }} :
                    <span
                      >jpg, jpeg, gif, png, .bmp, mp4, wmv, flv, avi, webm, weba
                      wav, mp3, aac, pdf, doc, docx, xls, xlsx, ppt, pptx, txt,
                      zip, rar</span
                    >.</span
                  >
                  <span class="note"
                    >{{ $t("lecOne.MaximumFileSize") }} :
                    <span>200MB</span></span
                  >
                </v-alert>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="dialog = false">
            {{ $t("close") }}
          </v-btn>
          <!-- <v-btn
            :loading="sendLoad"
            color="primary darken-1"
            @click="uploadData()"
            :disabled="!messageFile"
          >
            {{ $t("send") }}
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DxFileUploader from "devextreme-vue/file-uploader";
import { mapState } from "vuex";

export default {
  components: {
    DxFileUploader,
  },
  data() {
    return {
      info: {},
      ChatType: "",
      messageFile: "",
      isLoading: false,
      fileURL: null,
      sendLoad: false,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      chunks: [],
      allowedFileExtensions: [
        ".jpg",
        ".jpeg",
        ".gif",
        ".png",
        ".bmp",
        ".mp4",
        ".wmv",
        ".flv",
        ".avi",
        ".webm",
        ".weba",
        ".wav",
        ".mp3",
        ".aac",
        ".pdf",
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
        ".ppt",
        ".pptx",
        ".txt",
        ".zip",
        ".rar",
      ],
      dialog: false,
    };
  },
  computed: {
    ...mapState(["UploaderUrl"]),
  },
  methods: {
    clear() {
      this.messageFile = "";
      this.fileURL = null;
      this.ChatType = "";
      this.info = {};
    },

    onUploaded(e) {
      // console.log(e.component);
      e.component.reset();
      this.fileURL = JSON.parse(e.request.responseText);
      this.dialog = false;
      // console.log(this.fileURL.data.fileName);
    },
    getValueInKb(value) {
      return `${(value / 1024).toFixed(0)}kb`;
    },
    onUploadProgress(e) {
      this.chunks.push({
        segmentSize: e.segmentSize,
        bytesLoaded: e.bytesLoaded,
        bytesTotal: e.bytesTotal,
      });
    },
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim().toLowerCase();
    },
    sendResultValues() {
      this.$emit("send-result-values", "carrier");
    },

    setValue(chatType, info) {
      this.clear();
      this.ChatType = chatType;
      this.info = info;
      this.dialog = true;
    },
  },
  created() {},
};
</script>
<style lang="scss">
.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}
.chunk-panel {
  width: 505px;
  height: 165px;
  overflow-y: auto;
  padding: 18px;
  margin-top: 40px;
  background-color: rgba(191, 191, 191, 0.15);
}
.fileUpload {
  border: 1px solid #9e9e9e;
  padding: 10px;
  border-radius: 5px;
}
</style>
