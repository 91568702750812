<template>
  <v-row justify="center">
    <v-dialog
      v-if="studentResult"
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"

    >

          <v-card  :loading="overlay" class="justify-center">
        <v-toolbar dark color="primary" id="container">

          <v-toolbar-title>
            {{studentExamInfo ? studentExamInfo.studentName+" | " : ""}}
            {{studentResult.title+' / '+studentResult.subjectName}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeFun()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="12" md="2" sm="12"> </v-col>
            <v-col cols="12" md="8" sm="12">
              <v-alert
                outlined
                color="teal"
                prominent
                border="left"
                class="ma-4 px-9"
                icon="mdi-clipboard-text-search-outline"
              >
                {{ $t("exam.yourDegree") }} :
                <strong>{{ studentResult ? studentResult.totalStudentDegrees : "" }}</strong>
                {{ $t("mail.from") }}
                <strong>{{ studentResult ? studentResult.totalDegrees : "" }}</strong>
              </v-alert>
              <v-btn fab dark fixed bottom right color="primary" @click="scrollToTop()" v-if="fab">
                <v-icon> mdi-arrow-up</v-icon>
              </v-btn>
              <div v-if="!overlay">
                <div v-if="!studentResult.examQuestions[0]" class="text-center pa-4">
                  <img src="../../assets/document.svg" width="200px" />
                  <h3>{{ $t("exam.ThereAreNoQuestions") }}</h3>
                </div>
                <div
                  v-for="item in studentResult.examQuestions"
                  :key="item.questionGuid"
                  class="justify-center"
                >
                  <v-card class="ma-4 justify-center" flat outlined>
                    <v-card-text>
                           <question-title :examQuestion="item"></question-title>

                      <!-- MultiChoice MultiChoice MultiChoice MultiChoice MultiChoice MultiChoice MultiChoice MultiChoice  -->
                      <div class="text--primary" v-if="item.questionType === 'MultiChoice'">
                        <!-- <v-alert color="primary" text> -->
                        <div v-for="multeItem in item.questionAnswers" :key="multeItem.answerGuid">
                          <v-alert
                            class="py-0 my-2"
                            :color="multeItem.isCorrect ? 'green lighten-3' : 'pink lighten-3'"
                            outlined
                            text
                          >
                            <v-checkbox
                              hide-details
                              class="py-2 my-0"
                              readonly
                              v-model="multeItem.studentAnswers"
                              :label="multeItem.questionAnswer"
                              :value="multeItem.studentAnswers"
                              :color="multeItem.studentAnswers ? 'success' : 'error'"
                            >
                            </v-checkbox>
                          </v-alert>
                        </div>
                        <!-- </v-alert> -->
                      </div>
                      <!-- OneChoice OneChoice OneChoice OneChoice OneChoice OneChoice OneChoice OneChoice OneChoice  -->
                      <div class="text--primary" v-if="item.questionType === 'OneChoice'">
                        <!-- <v-alert color="primary" text> -->
                        <!-- <div
                          v-for="oneChoesVal in item.questionAnswers"
                          :key="oneChoesVal.answerGuid"
                        > -->
                        <v-radio-group
                          class="py-1 my-0"
                          v-for="oneChoesItem in item.questionAnswers"
                          :key="oneChoesItem.answerGuid"
                          readonly
                          v-model="oneChoesItem.studentAnswers"
                          hide-details=""
                        >
                          <v-alert
                            class="py-0 my-0"
                            :color="oneChoesItem.isCorrect ? 'green lighten-3' : 'pink lighten-3'"
                            outlined
                            text
                          >
                            <v-radio
                              class="py-2 my-0"
                              :key="oneChoesItem.answerGuid"
                              :label="oneChoesItem.questionAnswer"
                              :value="oneChoesItem.studentAnswers"
                            ></v-radio>
                          </v-alert>
                        </v-radio-group>
                        <!-- </div> -->
                        <!-- </v-alert> -->
                      </div>
                      <!-- FillInTheBlanks FillInTheBlanks FillInTheBlanks FillInTheBlanks FillInTheBlanks FillInTheBlanks -->
                      <div class="text--primary" v-if="item.questionType === 'FillInTheBlanks'">
                        <v-alert outlined color="primary" text>
                          {{ item.studentAnswers[0] ? item.studentAnswers[0].answerText : "" }}
                        </v-alert>
                        <v-alert color="green" text>
                          {{
                            ` ${$t("exam.TheCorrectAnswerIs")} :  ${
                              item.questionAnswers[0].questionAnswer
                            } `
                          }}
                        </v-alert>
                      </div>
                      <!-- Number Number Number Number Number Number Number Number Number Number Number Number Number Number -->
                      <div class="text--primary" v-if="item.questionType === 'Number'">
                        <v-alert outlined color="primary" text>
                          {{ item.studentAnswers[0] ? item.studentAnswers[0].answerText : "" }}
                        </v-alert>
                        <v-alert color="green" text>
                          {{
                            ` ${$t("exam.TheCorrectAnswerIs")} :  ${
                              item.questionAnswers[0].questionAnswer
                            } `
                          }}
                        </v-alert>
                      </div>
                      <!-- AttachedFile AttachedFile AttachedFile AttachedFile AttachedFile AttachedFile AttachedFile AttachedFile  -->
                      <div class="text--primary" v-if="item.questionType === 'AttachedFile'">
                           <div
                          class="
                            v-alert v-sheet
                            theme--dark
                            v-alert--text
                            primary--text
                          "
                          text
                        >
                          <v-slide-group
                            multiple
                            show-arrows
                            class="py-4"
                            mandatory
                            v-if="
                              item.studentAnswers &&
                              item.studentAnswers.length > 0
                            "
                          >
                            <v-slide-item
                              v-for="(item2, index) in item.studentAnswers"
                              :key="index"
                              class="mx-1"
                              v-slot="{}"
                              :value="item2"
                            >
                              <download-file
                                :iconWidth="50"
                                :width="250"
                                v-if="item2 && item.studentAnswers"
                                :fileUrl="item2.answerText"
                                :key="index"
                              >
                              </download-file>
                            </v-slide-item>
                          </v-slide-group>
                           <p v-else class="pt-2 error--text">
                            {{ $t("exam.NoFileSent") }}
                          </p>
                        </div>
                      </div>
                    </v-card-text>
                    <v-alert
                      class="mx-4"
                      :class="$vuetify.theme.dark ? undefined : 'grey lighten-4'"
                    >
                      <v-row class="pa-0 ma-0">
                        <v-col cols="12" md="4" sm="12" v-if="item.examResult">
                          <v-text-field
                            disabled
                            hide-details
                            dense
                            v-model="item.examResult.degree"
                            outlined
                            name="input-7-4"
                            :label="$t('degrees.theDegree')"
                            :placeholder="$t('exam.TheDegreeMustBeANumber')"
                            type="number"
                            onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46 )"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="8" sm="12" v-if="item.examResult">
                          <v-textarea
                            disabled
                            hide-details
                            dense
                            rows="1"
                            v-model="item.examResult.notex"
                            outlined
                            name="input-7-4"
                            :label="$t('exam.Observation')"
                            :placeholder="$t('exam.WriteTheNoteHereIfAny')"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-card>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="2" sm="12"> </v-col>
          </v-row>
        </v-container>
      </v-card>


    </v-dialog>
  </v-row>
</template>
<script>
import downloadFile from "../downloadFile.vue";
import QuestionTitle from './questionTitle.vue';

export default {
  components: { downloadFile, QuestionTitle },
  data() {
    return {
      studentExamInfo: null,
      getExamGuid: null,
      dialog: false,
      overlay: false,
      theDegree: null,
      theNote: null,
      studentResult: null,
      fab: false,
      loading: false
    };
  },
  methods: {
    scrollToTop: function() {
      document.getElementById("container").scrollIntoView({ behavior: "smooth" });
      this.fab = false;
      setTimeout(() => {
        this.fab = true;
      }, 5000);
    },
    closeFun() {
      this.sendResultValues();
      this.dialog = false;
    },

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    getStudentAnsor() {
      this.overlay = true;
      this.loading = true;
      this.studentResult = null;
      this.axios
        .get(
          `Exams/GetQuestions?ExamGuid=${this.getExamGuid}&StudentGuid=${this.getUserInfo().userId}`
        )
        .then(res => {
          this.studentResult = res.data.data.exam;

          for (let index = 0; index < this.studentResult.examQuestions.length; index++) {
            if (!this.studentResult.examQuestions[index].examResult) {
              this.studentResult.examQuestions[index].examResult = {
                degree: null,
                notex: null,
                sendLoad: false
              };
            }

            if (this.studentResult.examQuestions[index].questionType == "MultiChoice") {
              for (
                let j = 0;
                j < this.studentResult.examQuestions[index].studentAnswers.length;
                j++
              ) {
                for (
                  let i = 0;
                  i < this.studentResult.examQuestions[index].questionAnswers.length;
                  i++
                ) {
                  if (this.studentResult.examQuestions[index].studentAnswers[j]) {
                    if (
                      this.studentResult.examQuestions[index].studentAnswers[j].answerText ==
                      this.studentResult.examQuestions[index].questionAnswers[i].questionAnswer
                    ) {
                      this.studentResult.examQuestions[index].questionAnswers[
                        i
                      ].studentAnswers = true;
                    }
                  }
                }
              }
            } else if (this.studentResult.examQuestions[index].questionType == "OneChoice") {
              for (
                let i = 0;
                i < this.studentResult.examQuestions[index].questionAnswers.length;
                i++
              ) {
                if (this.studentResult.examQuestions[index].studentAnswers[0]) {
                  if (
                    this.studentResult.examQuestions[index].studentAnswers[0].answerText ==
                    this.studentResult.examQuestions[index].questionAnswers[i].questionAnswer
                  ) {
                    this.studentResult.examQuestions[index].questionAnswers[
                      i
                    ].studentAnswers = true;
                  }
                }
              }
            }
          }
          setTimeout(() => {
            this.fab = true;
          }, 5000);
         // console.log(this.studentResult);
        })
        .catch(err => {
          this.dialog = false;
        })
        .finally(() => {
          this.overlay = false;
                this.loading = false;
        });
    },
    sendResultValues() {
      this.$emit("send-result-values", "carrier");
    },
    setValue(item) {
      this.getExamGuid = item;
      this.dialog = true;
      this.getStudentAnsor();
    },
    downloadFileAttachment(item) {
      window.open(`${item.fileUrl}`);
    },
    downloadStudentFile(urlFile) {
      window.open(`${urlFile}`);
      return;
      this.axios({
        url: `${urlFile}`,
        method: "GET",
        responseType: "blob"
      })
        .then(response => {
          const blob = new Blob([response.data], {
            type: response.data.type
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = this.studentExamInfo.studentName;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("TheFileDoesNotExist"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close")
          });
        });
    },
    get_url_extension(url) {
      return url
        .split(/[#?]/)[0]
        .split(".")
        .pop()
        .trim()
        .toLowerCase();
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.scrol {
  scroll-behavior: smooth;
}
</style>
