<template>
  <v-dialog v-model="dialog" persistent max-width="300px" :retain-focus="true" :open-on-focus="true">
    <!-- <template v-slot:activator="{ on }">
      <v-icon @click="setValues(tag)" v-on="on" class="mx-1" :color="textColor"
        >mdi-pen
      </v-icon>

    </template> -->
    <v-card>
      <v-card-title>
        <span class="">{{ $t("portfolio.TagsItem.delete") }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          class="pa-lg-10 pa-md-5 mt-lg-0 mt-5"
          lazy-validation
        >
          <v-overlay absolute :value="overlay">
            <!-- <v-btn color="success" @click="overlay = false"> Hide Overlay </v-btn> -->
            <v-card color="primary" dark width="400" max-width="100%" class="pa-3 mb-5">
              <v-card-title>
                {{ $t("Processing") }}
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0 mt-2"
                ></v-progress-linear>
              </v-card-title>
            </v-card>
          </v-overlay>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <div class="d-flex flex-column">
                <div class="d-flex">
                  {{ $t("portfolio.TagsItems.selectedColor") }}
                  <v-card
                    height="30"
                    class="pa-2 mx-3 mb-3 text-center d-flex align-center"
                    :color="tag.tagColor"
                    :style="`color: ${tag.tagTextColor};`"
                  >
                    {{ tag.tagName }}
                  </v-card>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <!-- <small>*indicates required field</small> -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="$emit('closeTagDelete')">
          {{ $t("close") }}
        </v-btn>
        <v-btn
          :disabled="!valid"
          :loading="loading"
          color="error darken-1"
          @click="deleteTagItem()"
        >
          {{ $t("delete") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["tag", "dialog"],
  data() {
    return {
      loading: false,
      overlay: false,
      valid: true,
      color: {
        hexa: "#02bdad",
        textColor: "#ffffff",
      },
      request: {
        tagItemGuid: null,
        tagName: null,
        tagColor: null,
      },
    };
  },
  methods: {
    async deleteTagItem() {
      this.loading = true;
      this.overlay = true;
      const req = {
        tagItemGuid: this.tag.tagItemGuid,
      };
      await this.axios
        .post("TagItem/Delete", req)
        .then(() => {
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          this.loading = false;
          this.$emit("closeDeleteDialog");
          this.$emit("refreshTags");
          // this.dialog = false;
          this.overlay = false;
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          this.loading = false;
          // this.dialog = false;
          this.overlay = false;
        });
    },
    test(c) {
      console.log(c);
      // this.request.tagColor = this.color.hexa;
      // this.$emit("putTagMethod", this.request);
    },
    closeDialog() {
      this.$emit("closeDeleteDialog");
    },
  },
};
</script>

<style></style>
