<template>
  <v-sheet v-if="ratesList && ratesList.length > 0">
    <v-card
      outlined
      class="mt-4"
      v-for="rate in ratesList"
      :key="rate.ForumTopicContentMemberGuid"
    >
      <v-card-text class="pa-2">
        <v-row no-gutters>
          <v-list-item-avatar
            :color="getColorFromName(getPersonName(rate))"
            class="pb-1 white--text px-0 ms-2 my-0"
          >
            {{ getShortName(getPersonName(rate)) }}
          </v-list-item-avatar>

          <v-card-subtitle class="px-0 pa-0 pt-2"
            >{{ getPersonName(rate) }}
          </v-card-subtitle>
          <v-spacer></v-spacer>
          <v-card-subtitle class="px-0 pa-0 pt-2">
            {{ dateFromNow(rate.rateContentDate) }}
          </v-card-subtitle>
        </v-row>
        <v-card-text class="pa-0 ps-12">
          <v-card-subtitle class="pa-0">
            <v-rating
              readonly
              hover
              background-color="orange lighten-3"
              color="orange"
              length="5"
              v-model="rate.rateContent"
            ></v-rating
          ></v-card-subtitle>
          <v-card-subtitle class="pa-0 ps-3 text-justify" dir="auto" style="width: 100%;"
            >{{ rate.feedback }}<br/>
            <span
              v-if="rate.rateContentModifyDate"
              :title="dateFromNow(rate.rateContentModifyDate)"
              class="text-caption " style="color:#0080e0 "
              >{{ $t("edited") }}</span
            >
          </v-card-subtitle>
        </v-card-text>
      </v-card-text>
    </v-card>
  </v-sheet>
  <v-card v-else min-height="300" class="mt-4" outlined>
    <v-card-text>
      {{ $t("topic.noRatingsYet") }}
    </v-card-text>
  </v-card>
</template>
    
<script>
export default {
  props: ["ratesList"],
  data() {
    return {};
  },
  methods: {
    getPersonName(item) {
      if (item.teacher) {
        return item.teacher.teacherName;
      } else if (item.student) {
        return item.student.studentFullName;
      } else return "";
    },
  },
};
</script>