<template>
  <v-menu
    v-model="menu"
    bottom
    absolute
    left
    origin="center"
    transition="scale-transition"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            @click="getTags(id)"
            color="primary"
            fab
            small
            class="ma-1"
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
          >
            <v-icon size="25"> mdi-eye-outline </v-icon>
          </v-btn>
        </template>
        <span>{{ $t("portfolio.TagsItems.show") }}</span>
      </v-tooltip>
    </template>

    <v-list width="180px" max-width="100%">
      <!-- <v-tooltip>
                      <template v-slot:activator="{ on: tooltip }"> -->
      <!-- Fixed item -->
      <v-list-item class="fixed-item">
        <v-text-field
          :label="$t('portfolio.TagsItem.search')"
          clearable
          full-width
          v-model="searchTag"
          outlined
        >
        </v-text-field>
      </v-list-item>

      <!-- Scrollable items -->
      <div class="scrollable-list">
        <v-list-item
          bottom
          v-for="(tag, i) in tagsFilter"
          :key="i"
          :title="tag.tagName"
          @mouseenter="tag.hover = true"
          @mouseover="tag.hover = true"
          @mouseleave="tag.hover = false"
          class="mb-2 mx-auto"
          :style="`;background-color: ${tag.tagColor}; border-radius:5px;
                      width:90% !important;text-align:center;${
                        i == 0 ? 'margin-top:20px;' : ''
                      }`"
          :color="tag.tagColor"
        >
          <v-list-item-title
            class="text-truncate"
            :style="`color:${tag.tagTextColor} !important ;max-width:150px;cursor:pointer`"
            >{{ tag.tagName }}</v-list-item-title
          >
          <v-list-item-icon>
            <v-icon
              @click="removeTagToWorkSample(tag.workSampleGuid, tag)"
              small
              left
              v-show="tag.hover"
              :color="tag.tagTextColor"
            >
              mdi-delete
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      swatches: [
        {
          hexa: "#02bdad",
          selected: false,
          textColor: "#ffffff",
        },
        {
          hexa: "#feae4c",
          selected: false,
          textColor: "#000000",
        },
        {
          hexa: "#df67ff",
          selected: false,
          textColor: "#ffffff",
        },
        {
          hexa: "#626262",
          selected: false,
          textColor: "#ffffff",
        },
        {
          hexa: "#f6b800",
          selected: false,
          textColor: "#000000",
        },
        {
          hexa: "#ff5a43",
          selected: false,
          textColor: "#ffffff",
        },
        {
          hexa: "#5cc0f5",
          selected: false,
          textColor: "#ffffff",
        },
        {
          hexa: "#dfdfdf",
          selected: false,
          textColor: "#000000",
        },
        {
          hexa: "#03cea4",
          selected: false,
          textColor: "#ffffff",
        },
        {
          hexa: "#512df6",
          selected: false,
          textColor: "#ffffff",
        },
        {
          hexa: "#3752af",
          selected: false,
          textColor: "#ffffff",
        },
        {
          hexa: "#5d03bd",
          selected: false,
          textColor: "#ffffff",
        },
        {
          hexa: "#0089ff",
          selected: false,
          textColor: "#ffffff",
        },
        {
          hexa: "#ff278f",
          selected: false,
          textColor: "#ffffff",
        },
        {
          hexa: "#a75f0c",
          selected: false,
          textColor: "#ffffff",
        },
        {
          hexa: "#d7e4ef",
          selected: false,
          textColor: "#000000",
        },
        {
          hexa: "#fededf",
          selected: false,
          textColor: "#000000",
        },
        {
          hexa: "#efe5ce",
          selected: false,
          textColor: "#000000",
        },
        {
          hexa: "#a2a2a2",
          selected: false,
          textColor: "#000000",
        },
        {
          hexa: "#ffe01a",
          selected: false,
          textColor: "#000000",
        },
      ],
      searchTag: null,
      menu: false,
      tags: [],
      tag: {
        tagColor: null,
        tagItemGuid: null,
        tagName: null,
        tagTextColor: null,
        dialog: false,
        show: false,
        tagsLoading: false,
      },
    };
  },
  methods: {
    async getTags(id) {
      this.searchTag = null;
      this.tags = [];
      // console.log(this.tags);
      await this.axios
        .get(`Tag/Get?workSampleGuid=${id}`)
        .then((result) => {
          const { data } = result.data;
          // console.log(Array.isArray(data));
          if (Array.isArray(data)) {
            // this.tags = [];
            data.forEach((obj) => {
              // console.log(obj);
              const switchItem = this.swatches.find((sw) => sw.hexa === obj.tagColor);
              const toAddTag = {
                hover: false,
                workSampleGuid: obj.workSampleGuid,
                tagItemGuid: obj.tagItem.tagItemGuid,
                tagGuid: obj.tagGuid,
                tagName: obj.tagItem.tagName,
                tagColor: obj.tagItem.tagColor,
                tagTextColor: switchItem !== undefined ? switchItem.textColor : "white",
              };
              this.tags.push(toAddTag);
            });
          }
        })
        .catch(() => {});
    },
    removeTagToWorkSampleMenu(sampleId, tag) {
      this.$emit("removeTagToWorkSample", {
        sampleId: sampleId,
        tag: tag,
      });
      // this.menu = false;
    },
    async removeTagToWorkSample(sampleId, tag) {
      if (Array.isArray(this.tags)) {
        const exists = this.tags.some((obj) => tag.tagItemGuid === obj.tagItemGuid);
        console.log(exists, this.tags);
        if (exists) {
          this.$emit("removeTagToWorkSample", {
            isOverlayOn: true,
          });
          // Add the tag to the array if it's not already present
          const tagItem = this.tags.findIndex((sw) => sw.tagItemGuid === tag.tagItemGuid);
          const tagItemObj = this.tags.find((sw) => sw.tagItemGuid === tag.tagItemGuid);
          console.log(tagItemObj);
          await this.axios
            .post("Tag/Delete", { tagGuid: tagItemObj.tagGuid })
            .then(() => {
              this.tags.splice(tagItem, 1);
              this.$emit("removeTagToWorkSample", {
                isOverlayOn: false,
              });
              this.removeTagToWorkSampleMenu(sampleId, tag);
              this.$swal.fire({
                title: this.$t("operationAccomplishedSuccessfully"),
                text: "",
                icon: "success",
                confirmButtonText: this.$t("close"),
                toast: true,
                position: "bottom-start",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
              this.tags = [];
              this.getTags(this.id);
            })
            .catch(() => {
              this.$emit("removeTagToWorkSample", {
                isOverlayOn: false,
              });
              this.$swal.fire({
                title: this.$t("AnErrorOccurredDuringTheProcess"),
                text: "",
                icon: "error",
                confirmButtonText: this.$t("close"),
                toast: true,
                position: "bottom-start",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });
            });
          // setTimeout(() => {
          //   tags.splice(tagItem, 1);
          //   this.overlay = false;
          // }, 300);
        }
      }
    },
  },
  computed: {
    tagsFilter() {
      const data = this.tags;

      // if (this.isLiveOnly) {
      //   data = data.filter((item) => item.isLive === true);
      // }

      if (this.searchTag) {
        return data.filter((item) => this.searchTag
            .toLowerCase()
            .split(" ")
            .every((v) => item.tagName.toLowerCase().includes(v)));
      } else {
        return data;
      }
    },
  },
};
</script>

<style scoped>
#tagSearch {
  width: 150px !important;
  max-width: 100% !important;
}
#tagSearch >>> * {
  font-size: 14px !important;
}

.fixed-item {
  display: flex;
  height: 10px;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 30px;
  /* Optional, add background color as needed */
  z-index: 1; /* Optional, adjust z-index as needed */
  /* Add any other styles for your fixed item */
}

.scrollable-list {
  z-index: 2;
  margin-top: 70px;
  max-height: 250px; /* Set the maximum height for scrollable list */
  overflow-y: auto; /* Add a vertical scrollbar if necessary */
  /* Add any other styles for your scrollable list */
}
</style>
