<template>
  <div>
    <LoadingScreen v-if="load" />
    <router-view :key="$route.path" v-else />
  </div>
</template>

<script>
import LoadingScreen from "./components/loadingScreen";

export default {
  components: {
    LoadingScreen
  },
  name: "App",
  data() {
    return {
      load: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.load = false;
    }, 1500);
  },
  created() {
    this.getApiUrl();
    // document.body.style.zoom="90%"
    this.load = true;
    localStorage.setItem("darkMode", this.$vuetify.theme.dark);
    this.$i18n.locale = this.$i18n.locale == "ar" ? "ar" : "en";
    localStorage.setItem("lang", this.$i18n.locale);
    this.$vuetify.rtl = localStorage.getItem("lang") == "ar";
  },
  beforeCreate() {},
  methods: {
    getApiUrl() {
      if (this.axios.defaults.baseURL === "" || this.axios.defaults.baseURL === undefined) {
        this.axios
          .get(`static/config.json?v=${Math.random()}`)
          .then(res => {
            this.axios.defaults.baseURL = res.data.VUE_APP_URL;
            this.$store.commit("SET_UPLOADER", res.data.VUE_APP_UPLOAD);
            this.$store.commit("SET_PATHURL", res.data.VUE_APP_FILE_URL);
            this.getUnivInfo();
          })
          .catch(() => {})
          .finally(() => {});
      }
    },

    getUnivInfo() {
      this.axios
        .get("Account/GetUniversityInfo")
        .then(res => {
          localStorage.setItem("unvInfo", JSON.stringify(res.data));
          if (res.data.clientVersion != localStorage.getItem("version")) {
            localStorage.setItem("version", res.data.clientVersion);
            window.location.reload(true);
          }
        })
        .catch(() => {})
        .finally(() => {});
    }
  }
};
</script>

<style lang="scss">
@import "~@sweetalert2/theme-dark/dark.scss";

* {
  font-family: "Cairo", sans-serif;
}
html {
  overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #313942;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3581c4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #185c97;
}
.container {
  max-width: 100%;
}
</style>
