<template>
  <v-container>
    <v-row v-if="examQuestion.question">
      <v-col cols="12" md="12" class="pa-1">
        <div v-if="examQuestion.questionHint">{{ examQuestion.questionHint }}</div>
        <p
          dir="auto"
          style="white-space: pre-line !important"
          class="title text--primary pt-2 pb-2 text-justify"
        >
          {{ examQuestion.question.trim() }}
        </p>
        <div v-if="examQuestion.containsFile">
          <div
            v-if="
              ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(
                get_url_extension(examQuestion.fileUrl)
              )
            "
          >
            <v-img
              lazy-src="../../assets/loadImg.svg"
              :src="examQuestion.fileUrl"
              width="100%"
              max-width="400"
            >
              <v-btn
                @click="viewImagesFullScreen = true"
                tile
                icon
                color="grey"
                :title="$t('fullscreen')"
              >
                <v-icon color="primary" large>mdi-fullscreen</v-icon>
              </v-btn>
            </v-img>
          </div>
          <div v-else>
            <download-file
              v-if="examQuestion.containsFile"
              :fileUrl="examQuestion.fileUrl"
            >
            </download-file>
          </div>
        </div>
        <p class="pt-4">
          {{
            `${
              examQuestion.questionType == "MultiChoice"
                ? $t("exam.MultipleChoices")
                : examQuestion.questionType == "OneChoice"
                ? $t("exam.ChooseOne")
                : examQuestion.questionType == "FillInTheBlanks"
                ? $t("exam.FillInTheBlank")
                : examQuestion.questionType == "Number"
                ? $t("exam.FillInTheBlankNumericValue")
                : examQuestion.questionType == "AttachedFile"
                ? $t("exam.UploadAFile")
                : ""
            } - ( ${$t("exam.TheDegreeOfTheQuestion")} :  ${
              examQuestion.questionMark
            } ) `
          }}
        </p>
      </v-col>
    </v-row>
    <v-dialog v-model="viewImagesFullScreen" fullscreen>
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-3">
          <v-toolbar-title> {{ $t("Preview") }} </v-toolbar-title
          ><v-spacer></v-spacer>
          <v-btn icon dark @click="viewImagesFullScreen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-img
          :src="examQuestion.fileUrl"
          :style="{ maxHeight: '88vh' }"
          contain
          class="pa-4"
        ></v-img>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DownloadFile from "../downloadFile.vue";

export default {
  components: {
    DownloadFile,
  },
  props: ["examQuestion"],
  data() {
    return { viewImagesFullScreen: false, fullscreenImage: null };
  },
  methods: {
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim().toLowerCase();
    },
    stopOtherVideos(currentVideoId) {
      try {
        // Pause all other videos except the one that was just played
        const videoPlayer = this.$refs[currentVideoId];
        const allVideos = document.querySelectorAll("video");
        allVideos.forEach((video) => {
          if (video !== videoPlayer) {
            video.pause();
          }
        });
      } catch (e) {}
    },
  },
};
</script>
