var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.examQuestion.question)?_c('v-row',[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12","md":"12"}},[(_vm.examQuestion.questionHint)?_c('div',[_vm._v(_vm._s(_vm.examQuestion.questionHint))]):_vm._e(),_c('p',{staticClass:"title text--primary pt-2 pb-2 text-justify",staticStyle:{"white-space":"pre-line !important"},attrs:{"dir":"auto"}},[_vm._v("\n        "+_vm._s(_vm.examQuestion.question.trim())+"\n      ")]),(_vm.examQuestion.containsFile)?_c('div',[(
            ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(
              _vm.get_url_extension(_vm.examQuestion.fileUrl)
            )
          )?_c('div',[_c('v-img',{attrs:{"lazy-src":require("../../assets/loadImg.svg"),"src":_vm.examQuestion.fileUrl,"width":"100%","max-width":"400"}},[_c('v-btn',{attrs:{"tile":"","icon":"","color":"grey","title":_vm.$t('fullscreen')},on:{"click":function($event){_vm.viewImagesFullScreen = true}}},[_c('v-icon',{attrs:{"color":"primary","large":""}},[_vm._v("mdi-fullscreen")])],1)],1)],1):_c('div',[(_vm.examQuestion.containsFile)?_c('download-file',{attrs:{"fileUrl":_vm.examQuestion.fileUrl}}):_vm._e()],1)]):_vm._e(),_c('p',{staticClass:"pt-4"},[_vm._v("\n        "+_vm._s(((_vm.examQuestion.questionType == "MultiChoice"
              ? _vm.$t("exam.MultipleChoices")
              : _vm.examQuestion.questionType == "OneChoice"
              ? _vm.$t("exam.ChooseOne")
              : _vm.examQuestion.questionType == "FillInTheBlanks"
              ? _vm.$t("exam.FillInTheBlank")
              : _vm.examQuestion.questionType == "Number"
              ? _vm.$t("exam.FillInTheBlankNumericValue")
              : _vm.examQuestion.questionType == "AttachedFile"
              ? _vm.$t("exam.UploadAFile")
              : "") + " - ( " + (_vm.$t("exam.TheDegreeOfTheQuestion")) + " :  " + (_vm.examQuestion.questionMark) + " ) "))+"\n      ")])])],1):_vm._e(),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.viewImagesFullScreen),callback:function ($$v) {_vm.viewImagesFullScreen=$$v},expression:"viewImagesFullScreen"}},[_c('v-card',[_c('v-toolbar',{staticClass:"mb-3",attrs:{"dark":"","color":"primary","dense":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("Preview"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.viewImagesFullScreen = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-img',{staticClass:"pa-4",style:({ maxHeight: '88vh' }),attrs:{"src":_vm.examQuestion.fileUrl,"contain":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }