<template>
  <v-card flat :loading="loading" min-height="450">
    <v-card-text class="ma-0 pa-0">
      <v-card
        flat
        class="ma-0 pa-0 overflow-y-auto"
        :style="
          fullScreenMode ? 'max-height: calc(100vh - 150px)' : 'max-height: calc(100vh - 300px)'
        "
        :ref="content.forumTopicContentGuid"
      >
        <v-card-text class="ma-0 pa-0">
          <div v-for="item in content.replies" :key="item.forumTopicContentGuid">
            <v-card
              outlined
              class="mt-1 me-1"
              :color="item.editMode ? ($vuetify.theme.dark ? '#333333' : '#efefef') : ''"
            >
              <reply-item
                :key="item.refreshReplyKey"
                :item="item"
                :content="content"
                @onKeyDownReplyItem="onKeyDownReply"
                @onDeleteReplyItem="deleteReplyContent"
              ></reply-item>

              <v-card-actions class="ma-0 pa-1 pt-0" v-if="!content.lock">
                <v-btn
                  v-if="!item.showReplyTextBox"
                  small
                  class=""
                  min-width="80px"
                  depressed
                  @click="
                    content.replies.forEach((f) => {
                      f.showReplyTextBox = false;
                    });
                    item.showReplyTextBox = true;
                    item.showReplies = true;
                    item.editMode = false;
                    $forceUpdate();
                  "
                >
                  {{ $t("topic.reply") }}
                  <v-icon class="mx-1" small color="grey"> mdi-comment </v-icon>
                </v-btn>
                <v-text-field
                  :dir="item.reply ? 'auto' : ''"
                  autofocus
                  v-if="item.showReplyTextBox && !content.lock && content.forumTopic.topicType == 0"
                  :label="$t('topic.enterTextToReply')"
                  :placeholder="$t('topic.enterTextToReply')"
                  outlined
                  dense
                  class="my-2"
                  v-model="item.reply"
                  @keydown="
                    (e) => {
                      onKeyDownReply({ e, item });
                    }
                  "
                  hide-details
                ></v-text-field>
              </v-card-actions>
              <v-alert
                v-if="item.replies && item.replies.length > 0"
                text
                dense
                color="primary"
                class="ma-1"
              >
                <div class="mb-2">
                  <v-btn
                    small
                    depressed
                    @click="
                      item.showReplies = !item.showReplies;
                      $forceUpdate();
                    "
                  >
                    {{ $t("topic.replies") }} ({{ item.replies.length }})
                    <v-icon> {{ item.showReplies ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                  </v-btn>
                </div>
                <v-expand-transition>
                  <div v-if="item.showReplies">
                    <div
                      v-for="itemReplies in item.replies"
                      :key="itemReplies.forumTopicContentGuid"
                    >
                      <v-card outlined class="mt-1">
                        <reply-item
                          :key="itemReplies.refreshReplyKey"
                          :item="itemReplies"
                          :content="content"
                          @onKeyDownReplyItem="onKeyDownReply"
                          @onDeleteReplyItem="deleteReplyContent"
                        ></reply-item
                      ></v-card>
                    </div>
                  </div>
                </v-expand-transition>
              </v-alert>
            </v-card>
          </div>
          <v-row
            no-gutters
            v-if="!content.replies || content.replies.length == 0"
            style="min-height: 300px"
          >
            <v-col cols="12" md="12" class="text-center">
              <img src="../../assets/folder.svg" width="150px" />
              <h3>{{ $t("noData") }}</h3>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-text-field
        :dir="content.reply ? 'auto' : ''"
        autofocus
        v-if="!content.lock && content.forumTopic.topicType == 0"
        :label="$t('topic.enterTextToReply')"
        :placeholder="$t('topic.enterTextToReply')"
        outlined
        dense
        class="my-4"
        v-model="content.reply"
        @keydown="
          (e) => {
            onKeyDownReply({ e, item: content });
          }
        "
        hide-details
        ref="inputReply"
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
import * as topicContentHub from "../../hubs/topicContentHub";
import replyItem from "./topicContentReplyItem.vue";

export default {
  components: {
    replyItem,
  },
  props: ["content", "fullScreenMode"],
  data() {
    return {
      loading: false,
      showReplyTextBox: false,
    };
  },
  async beforeCreate() {
    await topicContentHub.createConnection();
  },
  async beforeDestroy() {
    const status = await topicContentHub.connection.connectionStarted;
    if (status) {
      await topicContentHub.connection.stop();
    }
  },
  async mounted() {
    topicContentHub.connection.on("ReceiveNewReply", (reply) => {
      this.addEditItemToList(reply);
    });
    topicContentHub.connection.on("ReceiveUpdateReply", (reply) => {
      this.addEditItemToList(reply);
    });

    topicContentHub.connection.on("ReceiveDeleteReply", (reply) => {
      this.deleteItemFromList(reply);
    });
  },
  async created() {
    const status = await topicContentHub.connection.connectionStarted;
    if (!status) {
      await topicContentHub.start();
    }
    topicContentHub.JoinGroup(this.content.forumTopicContentGuid, "ForumTopicContentReply");

    this.getContentReplies();
  },
  computed: {
    showPopUp() {
      return this.replyPopup;
    },
  },
  methods: {
    onKeyDownReply(e) {
      if (e.e.key == "Enter") {
        this.replyOnContentSave(e.item);
      }
    },
    replyOnContentSave(item) {
      if (item.editMode) {
        if (item.replyEdit == item.contentText) {
          if (item.editMode) {
            item.editMode = false;
            item.replyEdit = null;
          } else {
            item.reply = null;
          }
          item.refreshReplyKey = Math.random();
          this.$forceUpdate();

          return;
        }

        if (!item.replyEdit || item.replyEdit.trim() == "") {
          return;
        }
      } else if (!item.reply || item.reply.trim() == "") {
        return;
      }

      if (this.loading) {
        return;
      }
      var isMain = this.content.forumTopicContentGuid == item.forumTopicContentGuid;
      var { replyOnGuid } = item;

      if (!item.editMode) {
        //new reply
        if (isMain) {
          replyOnGuid = this.content.forumTopicContentGuid;
        } else {
          replyOnGuid = item.forumTopicContentGuid;
        }
      }

      var model = {
        contentText: item.editMode ? item.replyEdit : item.reply,
        guid: item.editMode ? item.forumTopicContentGuid : null,
        replyOnGuid: replyOnGuid,
      };

      this.overlay = true;
      this.loading = true;
      var url = item.editMode
        ? "ForumTopic/UpdateReplyTopicContent"
        : "ForumTopic/ReplyOnTopicContent";
      this.axios
        .post(url, model)
        .then((res) => {
          if (res.data.statusCode == 1) {
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            if (item.editMode) {
              item.editMode = false;
              item.replyEdit = null;
            } else {
              item.reply = null;
            }
            this.addEditItemToList(res.data.data);
          } else if (res.data.statusCode == 2) {
            this.$swal.fire({
              title: this.$t("topic.topicIsExpired"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else if (res.data.statusCode == 3) {
            this.$swal.fire({
              title: this.$t("topic.topicIsLockedCannot"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else {
            this.$swal.fire({
              title: this.$t("errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },
    editReplyContent(item) {
      if (!item.editMode) {
        this.content.replies.forEach((f) => {
          f.editMode = false;
        });
      }

      item.showReplyTextBox = false;
      item.editMode = !item.editMode;
      item.replyEdit = item.contentText;
      this.$forceUpdate();
    },
    deleteReplyContent(item) {
      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            const url = `ForumTopic/DeleteReplyTopicContent?guid=${item.forumTopicContentGuid}`;
            this.axios
              .delete(url)
              .then((res) => {
                if (res.data.statusCode == 1) {
                  this.$swal.fire({
                    title: this.$t("operationAccomplishedSuccessfully"),
                    text: "",
                    icon: "success",
                    confirmButtonText: this.$t("close"),
                    toast: true,
                    position: "bottom-start",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", this.$swal.stopTimer);
                      toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                    },
                  });
                  this.deleteItemFromList(res.data.data);
                } else if (res.data.statusCode == 2) {
                  this.$swal.fire({
                    title: this.$t("topic.topicIsExpired"),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                } else if (res.data.statusCode == 3) {
                  this.$swal.fire({
                    title: this.$t("topic.topicIsLockedCannot"),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                }
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              })
              .finally(() => {
                this.overlay = false;
                this.loading = false;
              });
          }
        });
    },
    getContentReplies() {
      this.loading = true;
      var url = `ForumTopic/GetReplies?forumTopicContentGuid=${this.content.forumTopicContentGuid}`;
      this.axios
        .get(url)
        .then((res) => {
          this.content.replies = res.data.data;
          this.$forceUpdate();
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },

    addEditItemToList(reply) {
      const item = this.findReplyItem(reply.forumTopicContentGuid);
      if (!item) {
        // if null
        //new reply
        const itemReply = this.findReplyItem(reply.replyOnGuid); // whats parent ?
        if (itemReply) {
          this.addItemToList(itemReply, reply);
        } else {
          this.addItemToList(this.content, reply);
        }
      } else {
        item.contentText = reply.contentText;
        item.modifiedDate = reply.modifiedDate;
        item.refreshReplyKey = Math.random();
        this.$forceUpdate();
      }
    },
    addItemToList(list, reply) {
      const item = list.replies.find(
        (x) => x.forumTopicContentGuid === reply.forumTopicContentGuid
      );
      if (!item) {
        //add new
        list.replies = [reply, ...list.replies];
        this.$forceUpdate();
      } else {
        //update
        item.contentText = reply.contentText;
        item.modifiedDate = reply.modifiedDate;
        item.refreshReplyKey = Math.random();
        this.$forceUpdate();
      }
    },
    findReplyItem(forumTopicContentGuid) {
      const item = this.content.replies.find(
        (x) => x.forumTopicContentGuid === forumTopicContentGuid
      );
      if (item) {
        return item; //update
      }

      this.content.replies.forEach((r) => {
        const item2 = r.replies.find((x) => x.forumTopicContentGuid === forumTopicContentGuid);
        if (item2) {
          return item2; //update sub-reply
        }
      });

      return null; //new reply
    },

    deleteItemFromList(reply) {
      const item = this.content.replies.find(
        (x) => x.forumTopicContentGuid === reply.forumTopicContentGuid
      );

      if (item) {
        const index = this.content.replies.indexOf(item);
        if (index > -1) {
          this.content.replies.splice(index, 1);
        }
      } else {
        this.content.replies.forEach((r) => {
          const item2 = r.replies.find(
            (x) => x.forumTopicContentGuid === reply.forumTopicContentGuid
          );
          if (item2) {
            const index2 = r.replies.indexOf(item2);
            if (index2 > -1) {
              r.replies.splice(index2, 1);
              this.$forceUpdate();
              return item2;
            }
          }
        });
      }
      this.$forceUpdate();
    },
  },
};
</script>
