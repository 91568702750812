<template>
  <v-dialog v-model="editDialog" persistent transition="dialog-bottom-transition" max-width="600">
    <template v-slot:activator>
      <!-- <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-title>{{ $t("edit") }}</v-list-item-title>
        <v-list-item-icon>
          <v-icon>mdi-pen</v-icon>
        </v-list-item-icon>
      </v-list-item> -->
    </template>
    <!-- <template v-slot:default="dialog"> -->
      <v-card flat :disabled="loading">
        <v-toolbar color="primary" dark tile>{{ $t("portfolio.Achievements.add") }}</v-toolbar>
        <v-card-text class="mt-5">
          <v-form ref="form" v-model="valid" class="mt-lg-0 mt-5" lazy-validation>
            <v-row no-gutters>
              <v-col cols="12">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      :label="$t(`portfolio.Achievements.date`)"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      :rules="rules.date"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" min="1950-01-01" @change="save"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  class=""
                  :label="$t('portfolio.Achievements.titleOfAchievement')"
                  required
                  filled
                  full-width
                  v-model="title"
                  :rules="rules.title"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <DxHtmlEditor
                  v-show="!loading"
                  height="500px"
                  v-model="description"
                  :placeholder="$t('mail.writeHere')"
                >
                  <DxMediaResizing :enabled="true" />
                  <DxToolbar :multiline="isMultiline">
                    <DxItem name="undo" />
                    <DxItem name="redo" />
                    <DxItem name="separator" />
                    <DxItem :accepted-values="sizeValues" name="size" />
                    <DxItem :accepted-values="fontValues" name="font" />
                    <DxItem name="separator" />
                    <DxItem name="bold" />
                    <DxItem name="italic" />
                    <DxItem name="strike" />
                    <DxItem name="underline" />
                    <DxItem name="separator" />
                    <DxItem name="alignLeft" />
                    <DxItem name="alignCenter" />
                    <DxItem name="alignRight" />
                    <DxItem name="alignJustify" />
                    <DxItem name="separator" />
                    <DxItem name="orderedList" />
                    <DxItem name="bulletList" />
                    <DxItem name="separator" />
                    <DxItem :accepted-values="headerValues" name="header" />
                    <DxItem name="separator" />
                    <DxItem name="color" />
                    <DxItem name="background" />
                    <DxItem name="separator" />
                    <DxItem name="link" />
                    <DxItem name="image" />
                    <DxItem name="separator" />
                    <DxItem name="clear" />
                    <DxItem name="codeBlock" />
                    <DxItem name="blockquote" />
                    <DxItem name="separator" />
                    <DxItem name="insertTable" />
                    <DxItem name="deleteTable" />
                    <DxItem name="insertRowAbove" />
                    <DxItem name="insertRowBelow" />
                    <DxItem name="deleteRow" />
                    <DxItem name="insertColumnLeft" />
                    <DxItem name="insertColumnRight" />
                    <DxItem name="deleteColumn" />
                  </DxToolbar>

                  <div v-html="description" />
                </DxHtmlEditor>
              </v-col> </v-row
          ></v-form>
          <h3 v-show="loading">{{ $t("Loading") }}</h3>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            :disabled="!valid || loading"
            :loading="loading"
            color="primary"
            @click="EditRequest()"
            >{{ $t("save") }}</v-btn
          >
          <v-btn :disabled="loading" text @click="dialogAction(false)">{{ $t("close") }}</v-btn>
        </v-card-actions>
      </v-card>
    <!-- </template> -->
  </v-dialog>
</template>

<script>
import {
  DxHtmlEditor, DxToolbar, DxMediaResizing, DxItem
 } from "devextreme-vue/html-editor";

export default {
  props: ["editDialog", "currantDescription", "currantTitle", "currantDate", "id"],
  data() {
    return {
      rules: {
        title: [
          v => !!v || this.$t("portfolio.Achievements.validation.required"),
          v => (v != null && v.length >= 3) ||
            this.$t("portfolio.Achievements.validation.titleMinLength"),
          v => (v != null && v.length <= 450) ||
            this.$t("portfolio.Achievements.validation.titleMaxLength")
        ],
        date: [v => !!v || this.$t("portfolio.Achievements.validation.required")]
      },
      valid: false,
      description: null,
      dialog: false,
      activePicker: null,
      date: null,
      menu: false,
      title: null,
      loading: false,
      sizeValues: ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"],
      fontValues: [
        "Arial",
        "Courier New",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Tahoma",
        "Times New Roman",
        "Verdana"
      ],
      headerValues: [false, 1, 2, 3, 4, 5],
      isMultiline: true,
      isLoading: false,
      fileURL: null,
      sendLoad: false,
      // uploadURL: process.env.VUE_APP_UPLOAD,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      chunks: [],
      allowedFileExtensions: [
        ".jpg",
        ".jpeg",
        ".gif",
        ".png",
        ".bmp",
        ".mp4",
        ".wmv",
        ".flv",
        ".avi",
        ".webm",
        ".weba",
        ".wav",
        ".mp3",
        ".aac",
        ".pdf",
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
        ".ppt",
        ".pptx",
        ".txt",
        ".zip",
        ".rar"
      ]
    };
  },
  methods: {
    showEditDialog() {
      // this.description = null;
      // this.title = null;
      // this.date = null;
      this.title = this.currantTitle;
      this.description = this.currantDescription ? this.currantDescription : null;
      this.date = new Date(this.currantDate).toISOString().substr(0, 10);
    },
    async EditRequest() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      const requestDto = {
        achievementGuid: this.id,
        title: this.title,
        description: this.description,
        achievementDate: this.date
      };
      await this.axios
        .post("Achievement/Update", requestDto)
        .then(() => {
          this.$emit("editAchievement", requestDto);
          // this.$refs.form.resetValidation();
          // this.$refs.form.reset();
          this.dialogAction(false);
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save(date) {
      this.date = date;
    },
    dialogAction(value) {
      this.$emit("editDialog", { value: value })
    }
  },
  components: {
    DxHtmlEditor,
    DxMediaResizing,
    DxToolbar,
    DxItem
  },
  watch: {
    editDialog() {
      // Check if the new title is empty or whitespace
      // this.$refs.form.validate();
      this.showEditDialog();
    },
    menu(val) {
      // eslint-disable-next-line no-unused-expressions
      val && setTimeout(() => (this.activePicker = "YEAR"));
    }
  }
};
</script>
