<template>
  <vue-splash
    :show="true"
    :logo="logo"
    title=""
    color="#109afd"
    :size="300"
    :fixed="true"
  />
</template>

<script>
import YourLogo from "../assets/logo2.svg";

export default {
  name: "YourMagnificentVueComponent",
  computed: {
    logo() {
      return YourLogo;
    }
  }
}
</script>