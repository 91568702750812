<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" class="ma-1" small v-bind="attrs" v-on="on">
          {{ $t("portfolio.WorkSampleTable.show") }}
        </v-btn>
      </template> -->
      <v-card>
        <v-toolbar dark color="primary">
          <h3>{{ title }}</h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!-- <v-toolbar-title>Settings</v-toolbar-title> -->
          <!-- <v-spacer></v-spacer> -->
          <!-- <v-toolbar-items>
            <v-btn dark text @click="dialog = false">
              Save
            </v-btn>
          </v-toolbar-items> -->
        </v-toolbar>
        <v-card-text class="mt-3 pa-5 ">
          <article v-html="content"></article>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: ["content", "showDialog", "title"]
};
</script>
