<template>
  <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="onOpenDialog()" class="ma-2"  color="secondary" v-bind="attrs" v-on="on">{{ $t("show") }}</v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card flat :disabled="loading">
        <v-toolbar color="primary" fixed width="100%" dark tile>{{ $t("portfolio.Reflections.show") }}</v-toolbar>
        <v-card-text class="mt-5">
          <article v-html="description"></article>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn :disabled="loading" text @click="dialog.value = false">{{ $t("close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>

export default {
  props: ["id", "reflectionText"],
  data() {
    return {
      valid: false,
      description: null,
      dialog: false,
      loading: false,
      sizeValues: ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"],
      fontValues: [
        "Arial",
        "Courier New",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Tahoma",
        "Times New Roman",
        "Verdana"
      ],
      headerValues: [false, 1, 2, 3, 4, 5],
      isMultiline: true,
      isLoading: false,
      fileURL: null,
      sendLoad: false,
      // uploadURL: process.env.VUE_APP_UPLOAD,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      chunks: [],
      allowedFileExtensions: [
        ".jpg",
        ".jpeg",
        ".gif",
        ".png",
        ".bmp",
        ".mp4",
        ".wmv",
        ".flv",
        ".avi",
        ".webm",
        ".weba",
        ".wav",
        ".mp3",
        ".aac",
        ".pdf",
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
        ".ppt",
        ".pptx",
        ".txt",
        ".zip",
        ".rar"
      ]
    };
  },
  methods: {
    onOpenDialog() {
      this.description = this.reflectionText;
    }
  }
};
</script>

<style></style>
