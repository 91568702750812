<template>
  <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="error" v-bind="attrs" v-on="on">{{ $t("delete") }}</v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar color="error" dark>{{ $t("portfolio.Reflections.delete") }}</v-toolbar>
        <v-card-text>
          <h2 class="pa-12">{{ $t("portfolio.Reflections.confirmDelete") }}</h2>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn :disabled="loading" text @click="dialog.value = false">{{ $t("close") }}</v-btn>
          <v-btn :loading="loading" color="error" @click="remove()">{{ $t("ok") }}</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      dialog: false,
      loading: false
    };
  },
  methods: {
    async remove() {
      this.loading = true;
      await this.axios
        .post("Reflection/Delete", { reflectionGuid: this.id })
        .then(() => {
          this.$emit("deleteDone");
          this.dialog = false;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
