import { HubConnectionBuilder } from "@microsoft/signalr";

export var connectionGroupChat;
export var connection;
export const createConnection = () => {
  connection = new HubConnectionBuilder()
    .withUrl("http://localhost:58873/userHub", {
      accessTokenFactory: () => `${localStorage.getItem("token")}`
    })
    .build();
};
export const start = () => {
  connection
    .start()
    .then(() => {
      console.log("connected started");
    })
    .catch(err => {
      console.log(`Connection failed${e}`);
    });
};
export const disconnect = () => {
  connection.stop();
};
export const onCloseConnection = () => {
  connection.onclose(() => {
    console.log("disconnected");
  });
};
export const SendMessagePrivate = (message, toGuid, toName) => {
  connection.invoke("SendMessagePrivate", message, toGuid, toName).catch(err => {
    console.log(err);
  });
};
export const ReadMessagePrivate = (messageGuid, memberGuid) => {
  connection.invoke("ReadMessagePrivate", messageGuid, memberGuid).catch(err => {
    console.log(err);
  });
};
export const DeleteMessagePrivate = (MessageGuid, ToGuid, DeleteFromMe) => {
  connection.invoke("DeleteMessagePrivate", MessageGuid, ToGuid, DeleteFromMe).catch(err => {
    console.log(err);
  });
};
export const LoadMessagesPrivate = async (guid, skip) => {
  let data;
  await connection.invoke("LoadMessagesPrivate", guid, skip).then(res => {
    data = res;
  });
  return data;
};
export const IncrementMessageCount = memberGuid => {
  connection.invoke("IncrementMessageCount", memberGuid).catch(err => {
    console.log(err);
  });
};
// nooooooooooooooooooooooooo
export const ResetMessageCount = (memberGuid, unreadMessages) => {
  connection.invoke("ResetMessageCount", memberGuid, unreadMessages).catch(err => {
    console.log(err);
  });
};
// nooooooooooooooooooooooooo
export const GetConnectionUsersCount = () => {
  connection.on("GetConnectionUsersCount", count => count);
};
// nooooooooooooooooooooooooo
export const GetNotification = async (skip, take) => {
  let data;
  await connection.invoke("GetNotification", skip, take).then(res => {
    data = res;
  });
  return data;
};
// nooooooooooooooooooooooooo
export const ReadNotification = () => {
  connection.on("ReadNotification", read => read);
};
export const ActiveUserCache = () => {
  connection.invoke("ActiveUserCache").catch(err => {
    console.log(err);
  });
};
// chat group start from here >>>> chat group start from here >>>> chat group start from here
// chat group start from here >>>> chat group start from here >>>> chat group start from here
export const createConnectionChatGroup = () => {
  connectionGroupChat = new HubConnectionBuilder()
    .withUrl("http://localhost:58873/chatHub", {
      accessTokenFactory: () => `${localStorage.getItem("token")}`
    })
    .build();
};
export const startChatGroup = () => {
  connectionGroupChat
    .start()
    .then(() => {
      console.log("connected ChatGroup started");
    })
    .catch(err => {
      console.log(`Connection ChatGroup filde${e}`);
    });
};
export const disconnectChatGroup = () => {
  connectionGroupChat.stop();
};
export const onCloseConnectionChatGroup = () => {
  connectionGroupChat.onclose(() => {
    console.log("disconnected ChatGroup");
  });
};
export const Join = groupGuid => {
  connectionGroupChat.invoke("Join", groupGuid).catch(err => {
    console.log(err);
  });
};
// nooooooooooooooooooooo
export const getProfileInfo = () => {
  connectionGroupChat.on("getProfileInfo", (FullName, Guid, Type) => {});
};
// nooooooooooooooooooooooooo
export const updateUser = () => {
  connectionGroupChat.on("updateUser", groupGuid => {});
};
export const SendMessage = (group, message) => {
  connectionGroupChat.invoke("SendMessage", group, message).catch(err => {
    console.log(err);
  });
};
export const ReadMessage = (guid, group) => {
  connectionGroupChat.invoke("ReadMessage", guid, group).catch(err => {
    console.log(err);
  });
};
export const DeleteMessage = (guid, group, deleteFromMe) => {
  connectionGroupChat.invoke("DeleteMessage", guid, group, deleteFromMe).catch(err => {
    console.log(err);
  });
};
export const MessageDeleted = () => {
  connectionGroupChat.on("MessageDeleted", (guid, deleteFromMe) => {});
};
export const LoadMessages = async (guid, skip) => {
  let data;
  await connectionGroupChat.invoke("LoadMessages", guid, skip).then(res => {
    data = res;
  });
  return data;
};
export const UpdateUserGroup = (groupGuid, mode, status) => {
  connectionGroupChat.invoke("UpdateUserGroup", groupGuid, mode, status).catch(err => {
    console.log(err);
  });
};
export const UpdateUsersCache = group => {
  connectionGroupChat.invoke("UpdateUsersCache", group).catch(err => {
    console.log(err);
  });
};
export const GetUsers = async group => {
  let data;
  await connectionGroupChat.invoke("GetUsers", group).then(res => {
    data = res;
  });
  return data;
};
// nooooooooooooooooooooooooo
export const PickEmoje = (groupGuid, memberGuid, type) => {
  connectionGroupChat.invoke("PickEmoje", groupGuid, memberGuid, type).catch(err => {
    console.log(err);
  });
};
export const ChangeMemberStatus = (groupGuid, memberGuid, status) => {
  connectionGroupChat.invoke("ChangeMemberStatus", groupGuid, memberGuid, status).catch(err => {
    console.log(err);
  });
};
export const onChangeMemberStatus = () => {
  connectionGroupChat.on("onChangeMemberStatus", (groupGuid, memberGuid, status) => {});
};
