<!-- eslint-disable radix -->
<template>
  <v-dialog :max-width="width" min-width="auto" :close-on-content-click="false" v-model="timeDialog" persistent>
    <template v-slot:activator="{ on }">
      <v-text-field :label="text" :value="formatTime()" append-icon="mdi-clock-edit-outline" readonly v-bind="$attrs"
        v-on="on"></v-text-field>
    </template>
    <v-card>
      <v-card-text>
        <v-row class="pt-5">
          <v-col>
            <v-select dense v-bind="$attrs" v-model="hours" :items="hoursOptions" @input="formatTime"
              :label="$t('hours')" hide-details></v-select></v-col>
          <v-col>
            <v-select dense v-bind="$attrs" v-model="minutes" :items="minutesOptions" @input="formatTime"
              :label="$t('minutes')" hide-details></v-select>
          </v-col>
          <v-col>
            <v-select dense v-bind="$attrs" v-model="seconds" :items="secondsOptions" @input="formatTime"
              :label="$t('seconds')" hide-details></v-select></v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" text @click="ok()"> {{ $t("ok") }} </v-btn>
        <v-btn text @click="close()"> {{ $t("cancel") }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: ["color", "width", "value", "text"],
  name: "v-time-picker",
  data() {
    return {
      timeDialog: false,
      hours: 0,
      minutes: 0,
      seconds: 0,
      totalSeconds: 0,
      hoursOptions: this.generateOptions(100),
      minutesOptions: this.generateOptions(60),
      secondsOptions: this.generateOptions(60),
    };
  },
  watch: {
    value(newValue) {
      this.totalSeconds = newValue;
    },
    timeDialog() {
      if (this.timeDialog === true) {
        this.totalSeconds = this.value;
        this.setTime();
      }
    },
  },
  methods: {
    generateOptions(limit) {
      return Array.from({ length: limit }, (_, index) => {
        const value = index.toString().padStart(2, "0");
        return { value, text: value };
      });
    },
    formatTime() {
      const formattedHours = this.hours.toString().padStart(2, "0");
      const formattedMinutes = this.minutes.toString().padStart(2, "0");
      const formattedSeconds = this.seconds.toString().padStart(2, "0");

      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    },
    setTime() {
      if (this.totalSeconds > 0) {
        const remainingSeconds = this.totalSeconds % 3600;
        // eslint-disable-next-line radix
        this.hours = parseInt(this.totalSeconds / 60 / 60)
          .toString()
          .padStart(2, "0");
        // eslint-disable-next-line radix
        this.minutes = parseInt(remainingSeconds / 60)
          .toString()
          .padStart(2, "0");
        // eslint-disable-next-line radix
        this.seconds = parseInt(remainingSeconds % 60)
          .toString()
          .padStart(2, "0");
      } else {
        this.hours = "00";
        this.minutes = "00";
        this.seconds = "00";
      }
    },
    getTotalSeconds() {
      return (
        // eslint-disable-next-line radix
        parseInt(this.hours) * 60 * 60 +
        // eslint-disable-next-line radix
        parseInt(this.minutes) * 60 +
        // eslint-disable-next-line radix
        parseInt(this.seconds)
      );
    },
    close() {
      this.totalSeconds = this.value;
      this.setTime();
      this.$emit("input", this.totalSeconds);
      this.timeDialog = false;
    },
    ok() {
      this.totalSeconds = this.getTotalSeconds();
      this.$emit("input", this.totalSeconds);
      this.timeDialog = false;
    },
  },
  created() {
    this.totalSeconds = this.value;
    this.setTime();
  },
};
</script>

<style></style>
