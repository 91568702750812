<template>
  <v-dialog transition="dialog-top-transition" max-width="600">
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn fab small outlined color="primary" v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
            <v-icon>
              mdi-eye
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t("show") }}
        </span>
      </v-tooltip>
    </template>
    <template v-slot:default="dialog">
      <v-card style="position: relative;" >
        <v-toolbar color="primary" dark>{{ $t("portfolio.Feedback.show") }}</v-toolbar>
        <v-card-text class="pa-5" id="html-content" >
          <!-- {{ text }} -->
          <v-textarea auto-grow v-model="text" readonly></v-textarea>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false">{{ $t("close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: ["text"]
};
</script>

<style scoped>

</style>
