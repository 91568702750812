<template>
  <v-dialog
    v-model="editDialog"
    fullscreen
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-btn
        v-if="absolute"
        v-bind="attrs"
        v-on="dialog"
        @click="getWorkSample(id)"
        small
        color="secondary"
        class="ma-1"
      >
        <b>
          {{ $t("portfolio.WorkSample.editWorkSample") }}
        </b>
      </v-btn>
      <v-list-item v-bind="attrs" v-on="dialog" @click="getWorkSample(id)" v-else>
        <v-list-item-title>{{ $t("portfolio.WorkSample.editWorkSample") }}</v-list-item-title>
        <v-list-item-icon> <v-icon> mdi-pen </v-icon> </v-list-item-icon>
      </v-list-item>
    </template>
    <v-card :loading="loading" :disabled="loading">
      <v-overlay absolute :value="overlay">
        <!-- <v-btn color="success" @click="overlay = false"> Hide Overlay </v-btn> -->
        <v-card color="primary" dark width="400" max-width="100%" class="pa-3">
          <v-card-title>
            {{ $t("Processing") }}
            <v-progress-linear indeterminate color="white" class="mb-0 mt-2"></v-progress-linear>
          </v-card-title>
        </v-card>
      </v-overlay>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="editDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ $t("portfolio.WorkSample.editWorkSample") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <!-- <v-btn dark text @click="editDialog = false"> {{ $t("save") }} </v-btn> -->
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <template>
          <v-form ref="form" v-model="valid" class="pa-lg-10 pa-md-5 mt-lg-0 mt-5" lazy-validation>
            <v-text-field
              v-model="requestData.title"
              :rules="rules.title"
              :label="$t('portfolio.WorkSampleTable.title')"
              required
              clearable
              outlined
            ></v-text-field>

            <!-- <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
                outlined
              ></v-text-field> -->
            <div class="d-flex flex-wrap justify-start align-center" style="width: 100%">
              <v-switch
                v-model="requestData.forAttention"
                :label="$t('portfolio.WorkSampleTable.forAttention')"
                style="width: 200px"
                color="primary"
              ></v-switch>
              <v-switch
                v-model="requestData.flag"
                :label="$t('portfolio.WorkSampleTable.flag')"
                style="width: 200px"
                color="secondary"
              ></v-switch>
            </div>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
                <v-card :disabled="loading" :loading="loading">
                  <DxHtmlEditor
                    height="500px"
                    v-model="requestData.description"
                    :placeholder="$t('mail.writeHere')"
                  >
                    <DxMediaResizing :enabled="true" />
                    <DxToolbar :multiline="isMultiline">
                      <DxItem name="undo" />
                      <DxItem name="redo" />
                      <DxItem name="separator" />
                      <DxItem :accepted-values="sizeValues" name="size" />
                      <DxItem :accepted-values="fontValues" name="font" />
                      <DxItem name="separator" />
                      <DxItem name="bold" />
                      <DxItem name="italic" />
                      <DxItem name="strike" />
                      <DxItem name="underline" />
                      <DxItem name="separator" />
                      <DxItem name="alignLeft" />
                      <DxItem name="alignCenter" />
                      <DxItem name="alignRight" />
                      <DxItem name="alignJustify" />
                      <DxItem name="separator" />
                      <DxItem name="orderedList" />
                      <DxItem name="bulletList" />
                      <DxItem name="separator" />
                      <DxItem :accepted-values="headerValues" name="header" />
                      <DxItem name="separator" />
                      <DxItem name="color" />
                      <DxItem name="background" />
                      <DxItem name="separator" />
                      <DxItem name="link" />
                      <DxItem name="image" />
                      <DxItem name="separator" />
                      <DxItem name="clear" />
                      <DxItem name="codeBlock" />
                      <DxItem name="blockquote" />
                      <DxItem name="separator" />
                      <DxItem name="insertTable" />
                      <DxItem name="deleteTable" />
                      <DxItem name="insertRowAbove" />
                      <DxItem name="insertRowBelow" />
                      <DxItem name="deleteRow" />
                      <DxItem name="insertColumnLeft" />
                      <DxItem name="insertColumnRight" />
                      <DxItem name="deleteColumn" />
                    </DxToolbar>

                    <div v-html="requestData.description" />
                  </DxHtmlEditor>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="12" sm="12" class="py-8 ma-0">
                <div class="fileUpload">
                  <DxFileUploader
                    :allowed-file-extensions="allowedFileExtensions"
                    :selectButtonText="$t('lecOne.ChooseTheFile')"
                    :labelText="$t('lecOne.ChooseTheFileYouWantToUpload')"
                    [multiple]="false"
                    :chunk-size="5000000"
                    :max-file-size="209715200"
                    name="FileUpload"
                    :accept="allowedFileExtensions.join(',')"
                    uploadMode="instantly"
                    uploadMethod="POST"
                    :uploadHeaders="headers"
                    :upload-url="`${UploaderUrl}WorkSamples`"
                    @upload-started="() => (chunks = [])"
                    @progress="onUploadProgress($event)"
                    @uploaded="onUploaded($event)"
                    messageUploadText="asd"
                  />
                  <v-alert dense text type="info" color="primary">
                    <span class="note"
                      >{{ $t("lecOne.AllowedFiles") }} :
                      <span
                        >jpg, jpeg, gif, png, .bmp, mp4, wmv, flv, avi, webm, weba wav, mp3, aac,
                        pdf, doc, docx, xls, xlsx, ppt, pptx, txt, zip, rar</span
                      >.</span
                    >
                    <span class="note"
                      >{{ $t("lecOne.MaximumFileSize") }} : <span>200MB</span></span
                    >
                  </v-alert>
                </div>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end">
              <v-spacer></v-spacer>
              <v-btn color="error darken-1" text @click="editDialog = false">
                {{ $t("close") }}
              </v-btn>
              <v-btn :loading="loading" color="primary darken-1" @click="putWorkSample()">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
            <!-- <v-btn :disabled="!valid" color="success" class="ma-4" @click="validate">
              Validate
            </v-btn> -->
            <!--

              <v-btn color="error" class="ma-4" @click="reset"> Reset Form </v-btn>

              <v-btn color="warning" class="ma-4" @click="resetValidation">
                Reset Validation
              </v-btn> -->
          </v-form>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
 DxHtmlEditor, DxToolbar, DxMediaResizing, DxItem
} from "devextreme-vue/html-editor";
import DxFileUploader from "devextreme-vue/file-uploader";
import { mapState } from "vuex";

export default {
  props: ["absolute", "id"],
  components: {
    DxHtmlEditor,
    DxMediaResizing,
    DxToolbar,
    DxItem,
    DxFileUploader
  },
  data() {
    return {
      editDialog: false,
      loading: false,
      overlay: false,
      valid: true,
      // UploaderUrl: process.env.VUE_APP_URL,
      rules: {
        title: [
          v => !!v || this.$t("portfolio.WorkSampleTable.titleRequired") // Check if it's not null or whitespace
        ]
      },
      requestData: {
        workSampleGuid: null,
        title: null,
        description: null,
        forAttention: false,
        flag: false
      },

      sizeValues: ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"],
      fontValues: [
        "Arial",
        "Courier New",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Tahoma",
        "Times New Roman",
        "Verdana"
      ],
      headerValues: [false, 1, 2, 3, 4, 5],
      isMultiline: true,
      isLoading: false,
      fileURL: null,
      sendLoad: false,
      // uploadURL: process.env.VUE_APP_UPLOAD,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      chunks: [],
      allowedFileExtensions: [
        ".jpg",
        ".jpeg",
        ".gif",
        ".png",
        ".bmp",
        ".mp4",
        ".wmv",
        ".flv",
        ".avi",
        ".webm",
        ".weba",
        ".wav",
        ".mp3",
        ".aac",
        ".pdf",
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
        ".ppt",
        ".pptx",
        ".txt",
        ".zip",
        ".rar"
      ]
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
      this.requestData.workSampleGuid = null;
      this.requestData.description = null;
      this.requestData.forAttention = null;
      this.requestData.flag = null;
      this.requestData.title = null;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onUploaded(e) {
      this.fileURL = JSON.parse(e.request.responseText);
      console.log(this.fileURL.data.fileName);
    },
    getValueInKb(value) {
      return `${(value / 1024).toFixed(0)}kb`;
    },
    onUploadProgress(e) {
      console.log(e);
      this.chunks.push({
        segmentSize: e.segmentSize,
        bytesLoaded: e.bytesLoaded,
        bytesTotal: e.bytesTotal
      });
    },
    get_url_extension(url) {
      return url
        .split(/[#?]/)[0]
        .split(".")
        .pop()
        .trim()
        .toLowerCase();
    },
    sendResultValues() {
      this.$emit("send-result-values", "carrier");
    },
    async putWorkSample() {
      if (!this.validate()) return;
      this.loading = true;
      this.overlay = true;
      await this.axios
        .post("WorkSample/Update", this.requestData)
        .then(() => {
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          });
          this.loading = false;
          this.editDialog = false;
          this.overlay = false;
          this.reset();
          this.$emit("putMethod");
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          });
          this.loading = false;
          // this.editDialog = false;
          this.overlay = false;
        });
      // setTimeout(() => {
      //   this.$swal.fire({
      //     title: this.$t("operationAccomplishedSuccessfully"),
      //     text: "",
      //     icon: "success",
      //     confirmButtonText: this.$t("close"),
      //     toast: true,
      //     position: "bottom-start",
      //     showConfirmButton: false,
      //     timer: 5000,
      //     timerProgressBar: true,
      //     didOpen: (toast) => {
      //       toast.addEventListener("mouseenter", this.$swal.stopTimer);
      //       toast.addEventListener("mouseleave", this.$swal.resumeTimer);
      //     },
      //   });
      //   this.loading = false;
      //   this.overlay = false;
      // }, 3000);
    },
    async getWorkSample(id) {
      // console.log(id);
      // this.reset();
      this.requestData.description = null;
      this.requestData.forAttention = null;
      this.requestData.flag = null;
      this.requestData.title = null;
      this.loading = true;
      this.overlay = true;
      await this.axios
        .get(`WorkSample/Get/${id}`)
        .then(response => {
          // console.log(response);
          this.requestData.workSampleGuid = response.data.data.workSampleGuid;
          this.requestData.description = response.data.data.description;
          this.requestData.forAttention = response.data.data.forAttention;
          this.requestData.flag = response.data.data.flag;
          this.requestData.title = response.data.data.title;
          this.loading = false;
          // this.editDialog = false;
          this.overlay = false;
          // this.reset();
          // this.$emit("putMethod");
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          });
          this.loading = false;
          // this.editDialog = false;
          this.overlay = false;
        });
    }
  },
  computed: {
    ...mapState(["UploaderUrl"])
  }
};
</script>
<style></style>
