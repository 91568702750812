<template>
  <v-card class="mb-0" :width="width" outlined>
    <div
      :style="
        $vuetify.rtl
          ? 'position: absolute; top: 0; left: 0; z-index: 9999'
          : 'position: absolute; top: 0; right: 0; z-index: 9999'
      "
      :title="$t('lecOne.DownloadCount')"
      v-if="showDownloadCount"
    >
      <v-chip style="opacity: 0.7" label small class="ma-1 px-2"
        >{{ downloadCount
        }}<v-icon class="pa-0" small>mdi-arrow-down-bold</v-icon>
      </v-chip>
    </div>

    <v-card-text class="ma-0 pa-1" v-if="canPreview()">
      <v-img
        @click="
          getUrl(fileUrl, false);
          viewImagesFullScreen = true;
        "
        class="image-pointer"
        style="margin: auto"
        :src="srcUrlThumb"
        height="88px"
        :alt="get_file_caption(fileUrl)"
        lazy-src="../assets/loadImg.svg"
        :title="get_file_caption(fileUrl)"
      ></v-img>
    </v-card-text>
    <v-list-item three-line class="ma-0 pa-1" v-else>
      <files-icon
        class="py-3"
        :fileType="get_url_extension(fileUrl)"
        :width="iconWidth"
        :showBadge="false"
      ></files-icon>

      <v-list-item-content class="px-2" v-if="!canPreview()">
        <div
          :title="fileFullName"
          class="mb-0 text-wrap"
          v-if="showTitle && showFileCaption && get_file_caption(fileUrl)"
        >
          {{ get_file_caption(fileUrl) }}
        </div>
        <div v-else-if="showTitle" :title="fileFullName" class="mb-0 text-wrap">
          {{ get_file_name_from_url(fileUrl) }}
        </div>
        <v-list-item-subtitle>
          {{ get_url_extension(fileUrl).toUpperCase() }}
          <span v-if="fileSizeFormat">({{ fileSizeFormat }})</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-subtitle
      :style="fileCaption ? '' : 'opacity: 0;'"
      v-if="showFileCaption"
      class="ma-0 pa-1"
      >{{ get_file_caption(fileUrl) }}</v-card-subtitle
    >
    <v-card-actions>
      <v-btn
        class="px-3"
        :title="$t('Download')"
        outlined
        rounded
        text
        @click="downLoadFile(fileUrl)"
      >
        {{ $t("Download") }}
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-btn
        icon
        v-if="
          ['mp4', 'mp3', 'wav', 'webm'].includes(get_url_extension(fileUrl))
        "
        class="px-3"
        outlined
        rounded
        text
        :title="$t('WatchVideo')"
        @click="showVideo()"
      >
        <v-icon l>mdi-play-circle</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        @click="onDeleteItem(item)"
        :title="$t('delete')"
        rounded
        color="red"
        icon
        v-if="item && showDeleteBtn"
      >
        <v-icon size="20">mdi-close</v-icon>
      </v-btn>
    </v-card-actions>

    <v-dialog
      :scrim="false"
      v-if="
        dialog &&
        ['mp4', 'mp3', 'wav', 'webm'].includes(get_url_extension(fileUrl))
      "
      transition="dialog-bottom-transition"
      v-model="dialog"
      max-width="600"
    >
      <v-card>
        <v-toolbar dense elevation="0" color="primary">
          <v-btn
            text
            dark
            @click="dialog = false"
            :title="$t('close')"
            small
            fab
          >
            <v-icon color="red">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-0">
          <video
            style="padding: 5px"
            :controls="true"
            width="100%"
            :height="
              ['mp3', 'wav'].includes(get_url_extension(fileUrl))
                ? '80px'
                : '500px'
            "
            :src="srcUrl"
          ></video>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewImagesFullScreen" fullscreen v-if="canPreview()">
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-3">
          <v-toolbar-title> {{ $t("Preview") }} </v-toolbar-title
          ><v-spacer></v-spacer>
          <v-btn icon dark @click="viewImagesFullScreen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-img
          :src="srcUrl"
          :style="{ maxHeight: '88vh' }"
          contain
          class="pa-4"
        ></v-img>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import filesIcon from "./filesIcon.vue";

export default {
  components: { filesIcon },
  data() {
    return {
      fileFullName: "",
      dialog: false,
      viewImagesFullScreen: false,
      srcUrl: null,
      srcUrlThumb: null,
    };
  },
  props: {
    fileUrl: {
      type: String,
    },
    width: {
      type: Number,
      default: 300,
    },
    fileNameLength: {
      type: Number,
      default: 25,
    },
    fileSizeFormat: {
      type: String,
      default: "",
    },
    iconWidth: {
      type: Number,
      default: 64,
    },
    deleteItem: {
      type: Function,
      default: null,
    },
    showDeleteBtn: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    fileCaption: {
      type: String,
      default: "",
    },
    showFileCaption: {
      type: Boolean,
      default: false,
    },
    downloadFromSession: {
      type: Boolean,
      default: false,
    },
    thumbSuffixName: {
      type: String,
      default: "",
    },
    showDownloadCount: {
      type: Boolean,
      default: false,
    },
    downloadCount: {
      type: Number,
      default: 0,
    },
  },
  created() {
    if (this.canPreview()) this.getUrl(this.fileUrl, true);
  },
  methods: {
    onDeleteItem(item) {
      if (this.deleteItem) {
        this.deleteItem(item);
      }
    },
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim().toLowerCase();
    },
    getFileNameWithoutExtension(path) {
      const fileName = path.split("/").pop();
      const lastIndex = fileName.lastIndexOf(".");
      return lastIndex >= 0 ? fileName.substring(0, lastIndex) : fileName;
    },
    getFileBaseUrl(fileUrl) {
      const lastIndex = fileUrl.lastIndexOf("/");
      if (lastIndex >= 0) {
        return fileUrl.substring(0, lastIndex + 1);
      } else {
        return fileUrl;
      }
    },
    get_file_name_from_url(url) {
      const matches = url.match(/\/([^\/?#]+)[^\/]*$/);
      if (matches && matches.length > 1) {
        this.fileFullName = matches[1];
        if (matches[1].length > this.fileNameLength) {
          return matches[1].substr(0, this.fileNameLength - 1);
        }
        return matches[1];
      }
      return "no name";
    },
    get_file_caption(url) {
      if (!this.fileCaption) {
        return "...";
      }
      return this.fileCaption;
    },
    downLoadFile(url) {
      if (this.downloadFromSession) {
        this.downloadLink(url);
      } else {
        window.open(url, "_blank");
      }
    },

    getUrl(url, thumb) {
      if (thumb && this.thumbSuffixName) {
        var name = this.getFileNameWithoutExtension(this.fileUrl);
        var thumb = this.getFileBaseUrl(url) + name + this.thumbSuffixName;
        this.srcUrlThumb = thumb;
        return;
      }

      if (this.downloadFromSession) {
        this.axios
          .post(`Uploader/Download`, {
            fileUrl: url,
          })
          .then((res) => {
            this.srcUrl = res.data.data.url;
          })
          .catch((err) => {})
          .finally(() => {});
      } else {
        this.srcUrl = url;
        this.srcUrlThumb = url;
      }
    },

    showVideo() {
      this.getUrl(this.fileUrl, false);
      this.dialog = true;
    },
    canPreview() {
      if (this.preview) {
        if (
          ["jpg", "jpeg", "png", "bmp", "gif"].includes(
            this.get_url_extension(this.fileUrl)
          )
        )
          return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
.v-application .text-wrap {
  white-space: normal !important;
  overflow-wrap: anywhere;
}
.v-card__actions {
  padding-top: 0;
}
.image-pointer:hover {
  cursor: pointer;
}
</style>
