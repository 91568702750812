<template>
  <div class="timer" style="direction: rtl">
    <div class="sec">
      <span class="number">{{ seconds }}</span>
      <div class="format">{{ wordString.seconds }}</div>
    </div>
    <div class="min">
      <span class="number">{{ minutes }}</span>
      <div class="format">{{ wordString.minutes }}</div>
    </div>
    <div class="hour">
      <span class="number">{{ hours }}</span>
      <div class="format">{{ wordString.hours }}</div>
    </div>
    <div v-if="days > 0" class="day">
      <span class="number">{{ days }}</span>
      <div class="format">{{ wordString.day }}</div>
    </div>
    <div class="message">{{ message }}</div>
    <!-- <div class="status-tag" :class="statusType">{{ statusText }}</div> -->
  </div>
</template>

<script>
export default {
  props: ["starttime", "endtime", "trans", "nowDate", "type", "totalSeconds"],
  data: function () {
    return {
      timer: "",
      wordString: {},
      start: "",
      end: "",
      interval: "",
      days: "",
      minutes: "",
      hours: "",
      seconds: "",
      message: "",
      statusType: "",
      statusText: "",
      expiredExam: false,
      now: 0,
      ticks: 0,

    };
  },
  created: function () {
    this.wordString = this.trans;
    // this.now = new Date(this.nowDate).getTime();
  },
  mounted() {
    this.start = new Date(this.starttime).getTime();
    this.end = new Date(this.endtime).getTime();

    if (this.type == "period" && this.totalSeconds) {
      this.ticks = this.totalSeconds;
      this.timerCountBySeconds();
      this.interval = setInterval(() => {
        this.timerCountBySeconds();
      }, 1000);
    } else {
      this.timerCount(this.start, this.end);
      this.interval = setInterval(() => {
        this.timerCount(this.start, this.end);
      }, 1000);
    }
  },
  methods: {
    sendResultValues() {
      if (this.isInRole(2)) {
        return;
      }
      this.$emit("send-result-values", this.expiredExam);
    },
    timerCount: function (start, end) {
      // Get todays date and time
      this.now = new Date().getTime();
      // this.now += 1000;
      // Find the distance between now an the count down date
      const distance = start - this.now;
      const passTime = end - this.now;

      if (distance < 0 && passTime < 0) {
        this.expiredExam = true;
        this.sendResultValues();
        this.message = this.wordString.expired;
        this.statusType = "expired";
        this.statusText = this.wordString.status.expired;
        clearInterval(this.interval);
      } else if (distance < 0 && passTime > 0) {
        this.calcTime(passTime);
        this.message = this.wordString.running;
        this.statusType = "running";
        this.statusText = this.wordString.status.running;
      } else if (distance > 0 && passTime > 0) {
        this.calcTime(distance);
        this.message = this.wordString.upcoming;
        this.statusType = "upcoming";
        this.statusText = this.wordString.status.upcoming;
      }
    },
    timerCountBySeconds() {
      if (this.ticks) {
        this.ticks -= 1;
      }

      if (this.ticks <= 0) {
        this.expiredExam = true;
        this.sendResultValues();
        this.message = this.wordString.expired;
        this.statusType = "expired";
        this.statusText = this.wordString.status.expired;
        clearInterval(this.interval);
      } else {
        this.message = this.wordString.running;
        this.statusType = "running";
        this.statusText = this.wordString.status.running;
      }

      const remainingSeconds = this.ticks % 3600;
      this.hours = parseInt(this.ticks / 60 / 60);

      this.minutes = parseInt(remainingSeconds / 60);

      this.seconds = parseInt(remainingSeconds % 60);
    },
    calcTime: function (dist) {
      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(dist / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((dist % (1000 * 60)) / 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.timer {
  font-size: 20px;
  color: #fff;
  text-align: center;
  .day,
  .hour,
  .min,
  .sec {
    font-size: 22px;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    margin: 0 1px;
    .format {
      font-weight: 300;
      font-size: 14px;
      //@include margin-start(5);
      //display: inline-block;
      opacity: 0.8;
      width: 50px;
    }
  }
  .number {
    background: rgb(47, 47, 47);
    // padding: 0 5px;
    border-radius: 5px;
    display: inline-block;
    width: 50px;
    text-align: center;
    padding: 5px;
  }
  .message {
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
    color: #109afd;
  }
}
</style>
