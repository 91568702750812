<template>
  <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined color="white" v-bind="attrs" v-on="on">
        {{ $t("portfolio.Feedback.add") }}
        <v-icon right>mdi-plus-circle-outline</v-icon>
      </v-btn>
    </template>
    <!-- <template v-slot:default="dialog"> -->
    <v-card>
      <v-toolbar color="primary" dark> {{ $t("portfolio.Feedback.add") }}</v-toolbar>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-textarea
            auto-grow
            :rules="[v => !!v || $t('portfolio.Feedback.validation.message')]"
            :label="$t('portfolio.Feedback.add')"
            v-model="feedbackText"
            clearable
            class="ma-3"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn :loading="loading" :disabled="!valid" color="primary" @click="add()">{{
          $t("add")
        }}</v-btn>
        <v-btn :disabled="loading" text @click="dialog = false">{{ $t("close") }}</v-btn>
      </v-card-actions>
    </v-card>
    <!-- </template> -->
  </v-dialog>
</template>

<script>
export default {
  created() {},
  data() {
    return {
      loading: false,
      valid: true,
      feedbackText: null,
      dialog: false
    };
  },
  props: ["sourceGuid", "sourceType"],
  methods: {
    add() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      this.axios
        .post("Feedback/Create", {
          feedbackText: this.feedbackText,
          sourceGuid: this.sourceGuid,
          sourceType: this.sourceType
        })
        .then(() => {
          this.$emit("refreshData");
          this.dialog = false;
          this.feedbackText = null;
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          });
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
