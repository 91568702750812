<template>
  <Pie
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

  <script>
import { Pie } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  ChartDataLabels
);
ChartJS.register({
  id: "NoData",
  afterDraw: function (chart) {
    if (
      chart.data.datasets.map((d) => d.allZero) &&
      chart.data.datasets.map((d) => d.allZero)[0]
    ) {
      // No data is present
      const { ctx } = chart;
      const { width } = chart;
      const { height } = chart;
      ctx.save();
      ctx.font = `1.5rem ${window.getComputedStyle(document.body).fontFamily}`;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = localStorage.getItem('darkMode') === 'true' ? 'white' : 'black';

      ctx.fillText(
        chart.data.datasets.map((d) => d.thereIsNoAnswer)[0],
        width / 2,
        height / 2
      );
      ctx.restore();
    }
  },
});
export default {
  name: "PieChart",
  components: {
    Pie,
  },
  props: {
    chartId: {
      type: String,
      default: "pie-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Array,
      default: () => [],
    },
    datasets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: this.labels,
        datasets: this.datasets,
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
          datalabels: {
            color: "white",
            labels: {
              title: {
                font: {
                  weight: "bold",
                },
              },
              value: {
                color: "white",
              },
            },
          },
        },
      },
    };
  },
};
</script>
