/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import Vue from "vue";
import Router from "vue-router";
import axios from "axios";
import jwt_decode from "jwt-decode";
import i18n from "./i18n";
import store from "./store";

Vue.use(Router);
const router = new Router({
  // mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/auth",
      component: () => import("@/views/pages/Index"),
      children: [
        {
          name: "Login",
          path: "",
          component: () => import("@/views/pages/Login")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/views/dashboard/Index"),
      beforeEnter: (to, from, next) => {
        // console.log(i18n.t(to.name));
        // window.document.title = to.name ? i18n.t(to.name) : "UIMS";
        if (localStorage.getItem("token")) {
          next();
        } else {
          next({
            name: "Login"
          });
        }
      },
      children: [
        // Dashboard
        {
          name: "drawer.home",
          path: "",
          component: () => import("@/views/dashboard/Dashboard")
        },
        {
          name: "drawer.learningWebsite",
          path: "/forums",
          props: true,
          component: () => import("@/views/dashboard/forums")
        },
        {
          name: "drawer.lectures",
          path: "lecOne/:id/:folderId?",
          props: true,
          component: () => import("./views/dashboard/lecOne")
        },
        {
          name: "drawer.lessons",
          path: "lectures",
          component: () => import("./views/dashboard/lectures")
        },
        {
          name: "drawer.degrees",
          path: "degrees",
          component: () => import("./views/dashboard/degrees")
        },
        {
          name: "drawer.finalResults",
          path: "finalResults",
          component: () => import("./views/dashboard/finalResults")
        },
        {
          name: "drawer.studentDegree",
          path: "studentDegree",
          component: () => import("./views/dashboard/studentDegree")
        },
        {
          name: "drawer.degreesPreview",
          path: "degree/:id/:id2",
          props: true,
          component: () => import("./views/dashboard/degree")
        },
        {
          name: "drawer.alerts",
          path: "alerts",
          component: () => import("./views/dashboard/alerts")
        },
        {
          name: "drawer.inbox",
          path: "inbox",
          component: () => import("./views/dashboard/inbox")
        },
        {
          name: "drawer.sent",
          path: "sent",
          component: () => import("./views/dashboard/sent")
        },
        {
          name: "drawer.mailPreview",
          path: "showMail=:id&:type",
          props: true,
          component: () => import("./views/dashboard/showMail")
        },
        {
          name: "drawer.exams",
          path: "exams/:folderId?",
          props: true,
          component: () => import("./views/dashboard/exams")
        },
        // {
        //   name: "drawer.exams",
        //   path: "studentExams",
        //   component: () => import("./views/dashboard/studentExams")
        // },
        {
          name: "drawer.examsPreview",
          path: "showExam=:id",
          props: true,
          component: () => import("./views/dashboard/showExam")
        },
        {
          name: "exam.questionsBankPreview",
          path: "showQuestionsBank=:id",
          props: true,
          component: () => import("./views/dashboard/showQuestionsBank")
        },
        {
          name: "drawer.enterExam",
          path: "enterExam=:id",
          props: true,
          component: () => import("./views/dashboard/enterExam")
        },
        {
          name: "drawer.extraDegree",
          path: "extraDegree/:id",
          props: true,
          component: () => import("./views/dashboard/extraDegree")
        },
        {
          name: "exam.questionsBank",
          path: "questionsBank",
          props: true,
          component: () => import("./views/dashboard/questionsBank")
        },
        {
          name: "drawer.absences",
          path: "absence",
          component: () => import("./views/dashboard/absence")
        },
        {
          name: "drawer.library",
          path: "library",
          component: () => import("./views/dashboard/library")
        },
        {
          name: "appBar.profile",
          path: "profile",
          component: () => import("@/views/dashboard/profile")
        },
        {
          name: "المحادثات",
          path: "chats",
          component: () => import("@/views/dashboard/chats")
        },
        {
          name: "معاينة المجموعة",
          path: "chatOpen=:id",
          props: true,
          component: () => import("./views/dashboard/chatOpen")
        },
        {
          name: "تقرير الانضمام",
          path: "chatGroupReport=:id",
          props: true,
          component: () => import("./views/dashboard/chatGroupReport")
        },
        {
          name: "portfolio.WorkSample.title",
          path: "workSamples",
          props: true,
          component: () => import("./views/dashboard/workSample")
        },
        {
          name: "portfolio.WorkSampleTable.details",
          path: "workSample=:id",
          // redirect: { name: "portfolio.Reflections.title" },
          props: true,
          // meta: {
          //   keepParentAlive: true // Enable keep-alive for this route
          // },
          component: () => import("./views/dashboard/workSampleById"),
          // children: [
          //   {
          //     name: "portfolio.Reflections.title",
          //     path: "/workSample/Reflection/workSample=:id",
          //     props: true,
          //     component: () => import("./views/dashboard/workSampleInnerPages/reflections.vue")
          //   },
          //   {
          //     name: "portfolio.TagsItem.title",
          //     path: "/workSample/Tags/workSample=:id",
          //     props: true,
          //     component: () => import("./views/dashboard/workSampleInnerPages/tags.vue")
          //   },
          //   {
          //     name: "portfolio.Feedback.title",
          //     path: "/workSample/Feedbacks/workSample=:id",
          //     props: true,
          //     component: () => import("./views/dashboard/workSampleInnerPages/feedback.vue")
          //   },
          //   {
          //     name: "portfolio.comment.title",
          //     path: "/workSample/Comment/workSample=:id",
          //     props: true,
          //     component: () => import("./views/dashboard/workSampleInnerPages/comments.vue")
          //   }
          // ]
        },
        {
          name: "portfolio.Achievements.title",
          path: "Achievements/:stuId?",
          props: true,
          component: () => import("./views/dashboard/achievements.vue")
        },
        {
          name: "portfolio.Goals.title",
          path: "Goals/:stuId?",
          props: true,
          component: () => import("./views/dashboard/goals.vue")
        }
      ]
    },
    {
      path: "*",
      component: () => import("@/views/pages/Index"),
      children: [
        {
          name: "404 Error",
          path: "",
          component: () => import("@/views/pages/Error")
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (localStorage.getItem("token")) {
    // console.clear();
    // if(to.name=="Login"){
    //   router.push({ path: "/" });
    // }

    if (to.name == "drawer.examsPreview") {
      const token = localStorage.getItem("token");
      const roles = [jwt_decode(token).role];
      if (!roles.some((f) => f == 2)) {
        router.push({ name: "Login" });
      }
    }

    window.document.title = to.name ? i18n.t(to.name) : "UIMS";

    if (axios.defaults.baseURL === "" || axios.defaults.baseURL === undefined) {
      setTimeout(() => {
        axios.get("Notifications/GetUnReadCount").then(res => {
          store.dispatch("updatNoti", res.data.data[0]);
        });
      }, 3000);
    } else {
      axios.get("Notifications/GetUnReadCount").then(res => {
        store.dispatch("updatNoti", res.data.data[0]);
      });
    }
  }
  next();
});

export default router;
