var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.hover),expression:"hover"}],attrs:{"small":"","color":_vm.tag.tagTextColor},on:{"click":function($event){return _vm.setValues(_vm.tag)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pen\n    ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"color":"primary"}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t("portfolio.TagsItem.edit")))])],1),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"mt-lg-0 mt-5",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-overlay',{attrs:{"absolute":"","value":_vm.overlay}},[_c('v-card',{staticClass:"pa-3 mb-5",attrs:{"color":"primary","dark":"","width":"200","max-width":"100%"}},[_c('v-card-title',[_vm._v("\n                "+_vm._s(_vm.$t("Processing"))+"\n                "),_c('v-progress-linear',{staticClass:"mb-0 mt-2",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('portfolio.TagsItems.name'),"required":"","filled":"","full-width":"","rules":_vm.rules.name},model:{value:(_vm.request.tagName),callback:function ($$v) {_vm.$set(_vm.request, "tagName", $$v)},expression:"request.tagName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"width":"100%"}},[_c('v-responsive',{staticClass:"mx-auto mb-4 d-flex flex-wrap overflow-y-auto overflow-x-hidden",attrs:{"width":"100%","max-height":"250"}},[_c('v-card',{staticClass:"d-flex flex-wrap",staticStyle:{"border-radius":"5px !important"},attrs:{"elevation":"2"}},_vm._l((_vm.swatches),function(c,i){return _c('v-card',{key:i,staticClass:"pa-2 ma-2",class:c.selected
                          ? _vm.$vuetify.theme.dark
                            ? 'darkenSelectedColor'
                            : 'lightenSelectedColor'
                          : '',attrs:{"width":"40","height":"40","color":c.hexa},on:{"click":function($event){return _vm.updateSelectedColor(c)}}})}),1)],1)],1)])],1)],1)],1),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error darken-1","dark":""},on:{"click":function($event){return _vm.openDeleteDialog()}}},'v-icon',attrs,false),on),[_vm._v("\n              mdi-delete\n            ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("portfolio.TagsItem.delete")))])]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error darken-1","text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("\n          "+_vm._s(_vm.$t("close"))+"\n        ")]),_c('v-btn',{attrs:{"disabled":!_vm.valid,"loading":_vm.loading,"color":"primary darken-1"},on:{"click":function($event){return _vm.putTagItem()}}},[_vm._v("\n          "+_vm._s(_vm.$t("save"))+"\n        ")])],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})}
var staticRenderFns = []

export { render, staticRenderFns }