<template>
  <div>
    <v-card
      flat
      elevation="0"
      v-show="show"
      class="cl chat_box mx-2"
      height="400"
      max-width="350"
      width="350"
      id="chat"
    >
      <v-toolbar dense color="primary darken-1" dark @click="showhidechat()">
        {{ info ? info.userName : "" }}
        <v-spacer></v-spacer>
        <v-chip color="error" v-if="info && info.count > 0">
          {{ info ? info.count : "" }}
          <v-icon right> mdi-message-reply-text </v-icon>
        </v-chip>
        <v-btn icon dark @click="closeChat">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div
        id="container"
        style="max-height: 275px; height: 275px; overflow-y: auto"
      >
        <ul class="mt-4">
          <infinite-loading
            v-if="messagesList.length"
            spinner="spiral"
            @infinite="infiniteScroll"
            direction="top"
          ></infinite-loading>
          <li v-for="(item, index) in messagesList" :key="index">
            <v-col
              cols="12"
              class="text-center"
              v-if="
                index == 0 ||
                messagesList[index].datex.substr(0, 10) !=
                  messagesList[index - 1].datex.substr(0, 10)
              "
            >
              <v-chip disabled outlined>
                {{ item.datex | moment("YYYY/MM/DD") }}
              </v-chip>
            </v-col>

            <v-col
              :class="
                item.fromGuid == getUserInfo().userId
                  ? 'text-right my-0 py-0 d-flex justify-start'
                  : 'text-left my-0 py-0 d-flex justify-end'
              "
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  class="mb-2 pt-1 pb-0 px-2"
                  :class="
                    item.fromGuid == getUserInfo().userId
                      ? 'rounded-l-lg rounded-tr-0 rounded-br-lg ml-10'
                      : 'rounded-r-lg rounded-tl-0 rounded-bl-lg mr-10'
                  "
                  :color="
                    item.fromGuid == getUserInfo().userId ? 'grey lighten-2' : ''
                  "
                  outlined
                  width="fit-content"
                >
                  <v-row
                    class="pa-0 ma-0 pt-2"
                    v-if="item.attachedFile && !item.deleteFromAll"
                  >
                    <v-col cols="12" md="12" class="pa-0 ma-0">
                      <v-img
                        v-if="
                          get_url_extension(item.attachedFile) == 'jpg' ||
                          get_url_extension(item.attachedFile) == 'jpeg' ||
                          get_url_extension(item.attachedFile) == 'png' ||
                          get_url_extension(item.attachedFile) == 'gif' ||
                          get_url_extension(item.attachedFile) == 'bmp'
                        "
                        lazy-src="../../assets/loadImg.svg"
                        :src="`${pathUrl}Content/ChatPrivateFilesPath/${item.fromGuid}/${item.toGuid}/${item.attachedFile}`"
                        width="100%"
                        max-width="250"
                      ></v-img>
                      <img
                        src="../../assets/fileType/pdf.svg"
                        width="100px"
                        v-else-if="
                          get_url_extension(item.attachedFile) == 'pdf'
                        "
                      />
                      <img
                        src="../../assets/fileType/doc.svg"
                        width="100px"
                        v-else-if="
                          get_url_extension(item.attachedFile) == 'doc' ||
                          get_url_extension(item.attachedFile) == 'docx'
                        "
                      />
                      <img
                        src="../../assets/fileType/ppt.svg"
                        width="100px"
                        v-else-if="
                          get_url_extension(item.attachedFile) == 'pptx' ||
                          get_url_extension(item.attachedFile) == 'ppt'
                        "
                      />
                      <img
                        src="../../assets/fileType/xls.svg"
                        width="100px"
                        v-else-if="
                          get_url_extension(item.attachedFile) == 'xls' ||
                          get_url_extension(item.attachedFile) == 'xlsx'
                        "
                      />
                      <img
                        src="../../assets/fileType/mp4.svg"
                        width="100px"
                        v-else-if="
                          get_url_extension(item.attachedFile) == 'mp4' ||
                          get_url_extension(item.attachedFile) == 'wmv' ||
                          get_url_extension(item.attachedFile) == 'wav' ||
                          get_url_extension(item.attachedFile) == 'weba' ||
                          get_url_extension(item.attachedFile) == 'webm' ||
                          get_url_extension(item.attachedFile) == 'avi' ||
                          get_url_extension(item.attachedFile) == 'flv'
                        "
                      />
                      <img
                        src="../../assets/fileType/mp3.svg"
                        width="100px"
                        v-else-if="
                          get_url_extension(item.attachedFile) == 'mp3' ||
                          get_url_extension(item.attachedFile) == 'aac'
                        "
                      />
                      <img
                        src="../../assets/fileType/txt.svg"
                        width="100px"
                        v-else-if="
                          get_url_extension(item.attachedFile) == 'txt'
                        "
                      />
                      <img
                        src="../../assets/fileType/file.svg"
                        width="100px"
                        v-else
                      />
                      <p
                        v-if="
                          get_url_extension(item.attachedFile) != 'jpg' &&
                          get_url_extension(item.attachedFile) != 'jpeg' &&
                          get_url_extension(item.attachedFile) != 'png' &&
                          get_url_extension(item.attachedFile) != 'gif' &&
                          get_url_extension(item.attachedFile) != 'bmp'
                        "
                        style="font-size: 10px; color: #8c8c8c; width: 100px"
                      >
                        {{ item.attachedFile }}
                      </p>
                    </v-col>
                    <v-fade-transition>
                      <v-overlay v-if="hover" absolute color="#111">
                        <v-btn icon @click="insLec(item)">
                          <v-icon
                            color="white"
                            size="48"
                          >mdi-progress-download</v-icon
                        ></v-btn>
                      </v-overlay>
                    </v-fade-transition>
                  </v-row>
                  <v-row class="pa-0 ma-0">
                    <v-col cols="12" md="12" class="pa-0 ma-0">
                      <span v-if="item.deleteFromAll" class="grey--text">
                        {{ `هذه الرسالة محذوفة` }}
                        <v-icon color="grey">mdi-cancel</v-icon>
                      </span>
                      <span
                        v-else
                        v-html="item.textMessage ? item.textMessage : ''"
                      >
                      </span>
                    </v-col>
                    <v-col
                      cols="12"
                      md="1"
                      class="pa-0 ma-0"
                      v-if="item.fromGuid == getUserInfo().userId"
                    >
                      <v-chip
                        small
                        color="error deletButton"
                        v-if="hover && !item.deleteFromAll"
                        :class="{ 'on-hover': hover }"
                        @click="deleteFun(item)"
                        class="mx-0 px-0 px-1"
                      >
                        <v-icon class="" small color="white"
                          >mdi-delete-outline</v-icon
                        >
                      </v-chip>
                    </v-col>
                  </v-row>
                  <p
                    style="font-size: 11px"
                    class="pt-2 pb-0 mb-1 grey--text"
                    :class="
                      item.fromGuid == getUserInfo().userId
                        ? 'text-left'
                        : 'text-right'
                    "
                  >
                    {{ item.datex | moment(" h:mm a ") }}
                  </p>
                </v-card>
              </v-hover>
            </v-col>
          </li>
        </ul>
      </div>

      <v-card-actions>
        <emoji
          class="pl-2"
          :emoji-opened="emojiOpened"
          :position-top="true"
          @add-emoji="addEmoji"
          @open-emoji="emojiOpened = $event"
        >
          <template v-for="(i, name) in $scopedSlots" #[name]="data">
            <slot :name="name" v-bind="data" />
          </template>
        </emoji>
        <v-text-field
          v-on:keyup.enter="message ? sendMessage(true) : ''"
          hide-details
          v-model="message"
          :append-outer-icon="message ? 'mdi-send' : 'mdi-paperclip'"
          filled
          clear-icon="mdi-close-circle"
          clearable
          label="الرسالة"
          type="text"
          @click:append-outer="sendMessage"
          @click:clear="clearMessage"
        >
          <template slot="append-outer">
            <v-icon
              :color="message ? 'primary' : ''"
              @click="sendMessage(message ? true : false)"
              >{{ message ? "mdi-send" : "mdi-paperclip" }}</v-icon
            >
          </template>
        </v-text-field>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="deleteDialog" max-width="300">
      <v-card>
        <v-card-title class="text-h5">
          هل انت متاكد من حذف الرسالة ؟
        </v-card-title>
        <v-card-text>
          <v-checkbox v-model="deleteFromBothSides">
            <template v-slot:label>
              <div class="text-right">
                {{ `احذف من ${info ? info.userName : ""} ايضا` }}
              </div>
            </template>
          </v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="deleteDialog = false">
            الغاء
          </v-btn>
          <v-btn color="error darken-1" text @click="deleteMessage()">
            حذف
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <FileFiled ref="fileFiled" v-on:send-result-values="fileSended" />
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { mapState } from "vuex";
import * as userHub from "../../hubs/userHub";
import Emoji from "../emoji.vue";
import FileFiled from "../fileFiled.vue";

export default {
  components: {
    Emoji,
    FileFiled,
    InfiniteLoading,
  },
  data() {
    return {
      deleteFromBothSides: false,
      NewMessageJson: null,
      emojiOpened: false,
      show: false,
      message: "",
      marker: true,
      iconIndex: 0,
      info: null,
      messagesList: [],
      skipMessage: 0,
      deleteDialog: false,
      deleteInfo: null,
    };
  },
  destroyed() {},
  beforeDestroy() {
    userHub.connection.off("ReceiveMessagePrivate");
  },
  mounted() {
    userHub.connection.on(
      "MessageDeletedPrivate",
      (MessageGuid, deleteFromMe) => {
        if (deleteFromMe) {
          this.messagesList.forEach((item, index, object) => {
            if (MessageGuid == item.guid) {
              object.splice(index, 1);
            }
          });
        } else {
          this.messagesList.forEach((item) => {
            if (MessageGuid == item.guid) {
              item.deleteFromAll = true;
            }
          });
        }
      }
    );
    userHub.connection.on(
      "ReceiveMessagePrivate",
      (
        GroupGuid,
        UserGuid,
        UserName,
        MessageGuid,
        MessageText,
        TimeString,
        FileName,
        FileType
      ) => {
        const data = {
          GroupGuid: GroupGuid,
          UserGuid: UserGuid,
          UserName: UserName,
          MessageGuid: MessageGuid,
          MessageText: MessageText,
          TimeString: TimeString,
          FileName: FileName,
          FileType: FileType,
        };
        const o = document.getElementById("chat");
        if (
          data.GroupGuid == this.info.userGuid &&
          data.UserGuid == this.getUserInfo().userId
        ) {
          const item = {
            attachedFile: data.FileName,
            attachedFileFormatSize: null,
            attachedFileSize: 0,
            attachedFileType: data.FileType,
            datex: new Date(`${new Date().toString().split("GMT")[0]} UTC`)
              .toISOString()
              .split(".")[0],
            deleteFromAll: false,
            deleteFromAllDate: "0001-01-01T00:00:00",
            deleteFromMe: false,
            fromGuid: data.UserGuid,
            fromType: null,
            guid: data.MessageGuid,
            isMine: 1,
            lastMsgDate: null,
            textMessage: data.MessageText,
            toGuid: data.GroupGuid,
            userGuid: null,
            userName: data.UserName,
          };
          this.messagesList.push(item);
          this.scrollToEnd();
        } else if (
          data.UserGuid == this.info.userGuid &&
          data.GroupGuid == this.getUserInfo().userId
        ) {
          const item = {
            attachedFile: data.FileName,
            attachedFileFormatSize: null,
            attachedFileSize: 0,
            attachedFileType: data.FileType,
            datex: new Date(`${new Date().toString().split("GMT")[0]} UTC`)
              .toISOString()
              .split(".")[0],
            deleteFromAll: false,
            deleteFromAllDate: "0001-01-01T00:00:00",
            deleteFromMe: false,
            fromGuid: data.UserGuid,
            fromType: null,
            guid: data.MessageGuid,
            isMine: 1,
            lastMsgDate: null,
            textMessage: data.MessageText,
            toGuid: data.GroupGuid,
            userGuid: null,
            userName: data.UserName,
          };
          this.messagesList.push(item);
          this.scrollToEnd();
          if (this.show == true && o.style.bottom == "0px") {
            userHub.ReadMessagePrivate(data.MessageGuid, data.UserGuid);
          } else if (this.show == true && o.style.bottom !== "0px") {
            // userHub.IncrementMessageCount(data.UserGuid);
            this.info.count++;
          }
        }
      }
    );
  },
  computed: {
    ...mapState(["pathUrl"]),
  },
  created() {},
  methods: {
    insLec(item) {
      console.log(item);
      this.axios({
        url: `${this.pathUrl}Content/ChatPrivateFilesPath/${item.fromGuid}/${item.toGuid}/${item.attachedFile}`,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          // console.log(response)
          const blob = new Blob([response.data], {
            type: response.data.type,
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = item.attachedFile;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {
          window.open(
            `${this.pathUrl}Content/ChatPrivateFilesPath/${item.fromGuid}/${item.toGuid}/${item.attachedFile}`,
            "_blank"
          );
          // this.$swal.fire({
          //   title: this.$t("TheFileDoesNotExist"),
          //   text: "",
          //   icon: "error",
          //   confirmButtonText: this.$t("close"),
          // });
        });
    },
    fileSended() {},
    closeChat() {
      this.show = false;
      this.sendResultValues(false);
    },
    sendResultValues(data) {
      this.$emit("send-result-values", data);
    },
    deleteMessage() {
      userHub.DeleteMessagePrivate(
        this.deleteInfo.guid,
        this.deleteInfo.toGuid,
        !this.deleteFromBothSides
      );
      this.deleteDialog = false;
    },
    deleteFun(item) {
      console.log(item);
      this.deleteInfo = item;
      this.deleteDialog = true;
    },
    linkify(inputText) {
      let replacedText; let replacePattern1; let replacePattern2; let
replacePattern3;

      // URLs starting with http://, https://, or ftp://
      replacePattern1 =
        /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
      replacedText = inputText.replace(
        replacePattern1,
        '<a href="$1" target="_blank">$1</a>'
      );

      // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
      replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
      replacedText = replacedText.replace(
        replacePattern2,
        '$1<a href="http://$2" target="_blank">$2</a>'
      );

      // Change email addresses to mailto:: links.
      replacePattern3 =
        /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
      replacedText = replacedText.replace(
        replacePattern3,
        '<a href="mailto:$1">$1</a>'
      );

      const youtubePattern =
        /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(\S+)/g;

      if (youtubePattern.test(inputText)) {
        const replacement =
          '<iframe style="width:500px;max-width:100%;" height="345" src="https://www.youtube.com/embed/$1" frameborder="0" allowfullscreen></iframe>';
        replacedText = inputText.replace(youtubePattern, replacement);
        // For start time, turn get param & into ?
        replacedText = replacedText.replace("&amp;t=", "?t=");
      }
      console.log(replacedText);
      return replacedText;
    },
    Clear() {
      this.messagesList = [];
      this.skipMessage = 0;
    },
    async infiniteScroll($state) {
      this.skipMessage = this.messagesList.length;
      const messagesGet = await userHub.LoadMessagesPrivate(
        this.info.userGuid,
        this.skipMessage
      );
      console.log(messagesGet);
      this.messagesList.reverse();
      if (messagesGet.length > 1) {
        messagesGet.forEach((item) => this.messagesList.push(item));
        $state.loaded();
      } else {
        $state.complete();
      }
      this.messagesList.reverse();
    },

    addEmoji(emoji) {
      this.message += `${emoji.icon}`;
      // console.log(emoji.icon);
    },
    toggleMarker() {
      this.marker = !this.marker;
    },
    sendMessage(value) {
      if (value) {
        userHub.SendMessagePrivate(
          this.message,
          this.info.userGuid,
          this.info.userName
        );
        this.resetIcon();
        this.clearMessage();
      } else {
        this.$refs.fileFiled.setValue("ChatPrivate", this.info);
      }
    },
    clearMessage() {
      this.message = "";
    },
    resetIcon() {
      this.iconIndex = 0;
    },
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim().toLowerCase();
    },
    scrollToEnd() {
      setTimeout(() => {
        const container = this.$el.querySelector("#container");
        container.scrollTop = container.scrollHeight;
      }, 10);
    },
    async getMessagesUser(userGuid, skipMessage) {
      this.messagesList = await userHub.LoadMessagesPrivate(
        userGuid,
        skipMessage
      );
      this.messagesList.reverse();
      // console.log(this.messagesList);
    },
    setValue(i) {
      this.Clear();
      this.info = i;
      console.log(i);
      this.getMessagesUser(i.userGuid, 0);
      this.show = true;
      const o = document.getElementById("chat");
      setTimeout(() => {
        o.style.bottom = "0px";
        this.info.count = 0;
        this.scrollToEnd();
      }, 100);
    },
    showhidechat() {
      const o = document.getElementById("chat");
      console.log(o);
      if (o.style.bottom !== "0px") {
        o.style.bottom = "0px";
        this.info.count = 0;
        this.sendResultValues(true);
      } else {
        o.style.bottom = "-355px";
        this.sendResultValues(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-card > .v-toolbar {
  position: sticky;
  top: 0;
  z-index: 999;
}
.chat_box {
  position: fixed;
  bottom: -355px;
  transition: all 0.6s;
  z-index: 1000;
}
.cl {
  //   overflow: scroll !important;
  overflow-x: hidden !important;
}

::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #109afd;
}
::-webkit-scrollbar {
  width: 1px;
}

.cssExample {
  overflow: hidden !important;
}
.cssExample:hover {
  overflow: auto !important;
}
.v-application ul,
.v-application ol {
  padding-left: 0px;
}
.mdi-send {
  transform: rotate(180deg);
}

.deletButton {
  position: absolute;
  top: 0;
  left: -15px;
  z-index: 5;
}
</style>