<template>
  <v-card-text class="pa-1">
    <v-card-subtitle style="width: 100%">
      <v-row>
        <v-list-item-avatar
          size="35"
          :color="getColorFromName(item.personName)"
          class="pb-1 white--text px-0 ms-2"
        >
          <span style="font-size: 12px !important">{{ getShortName(item.personName) }}</span>
        </v-list-item-avatar>
        <span class="mt-3">{{ item.personName }}</span>
        <v-spacer></v-spacer>
        <v-chip small outlined label>
          {{ dateFromNow(item.createdDate) }}
        </v-chip>
        <v-menu v-if="getUserInfo().userId == item.createdUserGuid && !content.lock">
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  depressed
                  dense
                  small
                  shaped
                  text
                  width="20"
                  color="grey"
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("more") }}</span>
            </v-tooltip>
          </template>
          <v-list dense>
            <v-list-item @click="editReplyContent(item)">
              <v-list-item-icon class="mx-2">
                <v-icon small color="primary">
                  {{ item.editMode ? "mdi-close" : "mdi-pencil" }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t("edit") }}</v-list-item-title>
            </v-list-item>
            <v-list-item :disabled="item.editMode" @click="onDeleteReplyItem(item)">
              <v-list-item-icon class="mx-2">
                <v-icon small color="red">mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t("delete") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
      <v-row class="mt-0">
        <v-card-subtitle
          v-if="!item.editMode"
          dir="auto"
          style="width: 100%"
          class="text-wrap text-justify pb-0"
          >{{ item.contentText }}<br />
          <span
            v-if="item.modifiedDate"
            :title="dateFromNow(item.modifiedDate)"
            class="text-caption"
            style="color: #0080e0"
            >{{ $t("edited") }}</span
          >
        </v-card-subtitle>
        <v-text-field
          :dir="item.replyEdit ? 'auto' : ''"
          autofocus
          v-else
          :label="$t('edit')"
          :placeholder="$t('edit')"
          outlined
          dense
          class="my-4"
          v-model="item.replyEdit"
          @keydown="
            (e) => {
              onKeyDownReplyItem(e, item);
            }
          "
          hide-details
        ></v-text-field>
      </v-row>
    </v-card-subtitle>
  </v-card-text>
</template>
<script>
export default {
  props: ["item", "content"],
  data() {
    return {
      loading: false,
      showReplyTextBox: false,
    };
  },
  methods: {
    onKeyDownReplyItem(e, item) {
      this.$emit("onKeyDownReplyItem", {
        e: e,
        item: item,
      });
    },
    editReplyContent(item) {
      if (!item.editMode) {
        this.content.replies.forEach((f) => {
          f.editMode = false;
        });
      }

      item.showReplyTextBox = false;
      item.editMode = !item.editMode;
      item.replyEdit = item.contentText;
      this.$forceUpdate();
    },
    onDeleteReplyItem(item) {
      this.$emit("onDeleteReplyItem", item);
    },
  },
};
</script>
