var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"cl",attrs:{"outlined":"","elevation":"0"}},[_c('v-toolbar',{staticClass:"text-center",attrs:{"dense":"","color":"primary","dark":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v("قائمة الاعضاء")]),_c('v-icon',{staticClass:"px-1"},[_vm._v("mdi-account-group-outline")])],1)],1),_c('div',{staticClass:"mt-2",staticStyle:{"max-height":"calc(72.5vh)","min-height":"300px","height":"calc(72.5vh)","overflow-y":"auto","overflow-x":"hidden"},attrs:{"id":"container"}},_vm._l((_vm.userMemberList),function(item){return _c('v-list',{key:item.userGuid,staticClass:"py-0 my-0 mb-1",attrs:{"nav":"","dense":""}},[_c('v-menu',{attrs:{"left":"","bottom":"","max-width":"150px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.userStatus != 0)?_c('v-list-item',_vm._g(_vm._b({staticClass:"py-0 my-0",attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-badge',{attrs:{"overlap":"","bordered":"","dot":"","color":item.userStatus == 3
                    ? 'teal'
                    : item.userStatus == 2
                    ? 'pink'
                    : item.userStatus == 1
                    ? 'grey'
                    : ''}},[_c('v-avatar',{attrs:{"color":item.color,"size":"28"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.supName))])])],1)],1),_c('v-list-item-title',[_vm._v(_vm._s(item.fullName)+"\n              "),(item.isOwner)?_c('v-icon',{staticClass:"px-1",attrs:{"color":"amber darken-1"}},[_vm._v("mdi-account-star")]):_vm._e(),(item.isMuted)?_c('v-icon',{staticClass:"px-1"},[_vm._v("mdi-volume-off")]):_vm._e()],1)],1):_vm._e()]}}],null,true)},[(
            this.isInRole(2) && item.guid != _vm.getUserInfo().userId
          )?_c('v-list',[(!item.isMuted && item.userStatus != 0)?_c('v-list-item',{on:{"click":function($event){return _vm.changeMemberStatus(item, 3)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"px-1"},[_vm._v("mdi-volume-off")]),_vm._v("كتم\n            ")],1)],1):_vm._e(),(item.isMuted && item.userStatus != 0)?_c('v-list-item',{on:{"click":function($event){return _vm.changeMemberStatus(item, 4)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"px-1"},[_vm._v("mdi-volume-high")]),_vm._v("الغاء الكتم\n            ")],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.changeMemberStatus(item, 5)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"px-1"},[_vm._v("mdi-account-remove")]),_vm._v("حذف\n            ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.open(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"px-1"},[_vm._v("mdi-account-remove")]),_vm._v("فتح محادثة\n            ")],1)],1)],1):_vm._e()],1)],1)}),1),_c('ChatBox',{ref:"childComponent"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }