<template>
  <!-- <v-row justify="center"> -->
  <v-dialog v-model="dialog" persistent max-width="300px">
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on" class="my-2" color="secondary">
        <v-list-item-title class="addTagsTitle">
          {{ $t("portfolio.TagsItem.addNew") }}
        </v-list-item-title>
        <v-list-item-icon>
          <v-icon>mdi-plus-circle </v-icon>
        </v-list-item-icon>
      </v-list-item>
      <!-- <v-btn
        left
        v-bind="attrs"
        v-on="on"
        color="primary"
        class="pa-5 d-flex justify-center align-center mx-2 mt-1"
      >
        <strong>{{ $t("portfolio.TagsItem.addNew") }}</strong>
        <v-icon color="white" right>mdi-plus-circle-outline</v-icon>
      </v-btn> -->
    </template>
    <v-card flat>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">{{ $t("portfolio.TagsItem.addNew") }}</v-toolbar-title>
      </v-toolbar>
      <!-- <v-card-text> -->
      <v-form ref="form" v-model="valid" class="mt-lg-0 mt-5" lazy-validation>
        <v-overlay absolute :value="overlay">
          <!-- <v-btn color="success" @click="overlay = false"> Hide Overlay </v-btn> -->
          <v-card color="primary" dark width="200" max-width="100%" class="pa-3 mb-5">
            <v-card-title>
              {{ $t("Processing") }}
              <v-progress-linear indeterminate color="white" class="mb-0 mt-2"></v-progress-linear>
            </v-card-title>
          </v-card>
        </v-overlay>
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              class=""
              :label="$t('portfolio.TagsItems.name')"
              required
              filled
              full-width
              v-model="request.tagName"
              :rules="rules.name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <div class="d-flex flex-column justify-center align-center" style="width: 100%">
              <v-responsive
                width="100%"
                max-height="250"
                class="mx-auto mb-4 d-flex flex-wrap overflow-y-auto overflow-x-hidden"
              >
                <div
                  class="d-flex flex-wrap"
                  style="border-radius: 5px !important; width: 100% !important"
                >
                  <v-card
                    v-for="(c, i) in swatches"
                    width="40"
                    height="40"
                    class="pa-2 ma-2"
                    :elevation="c.selected ? '12' : '0'"
                    :class="
                      c.selected
                        ? $vuetify.theme.dark
                          ? 'darkenSelectedColor'
                          : 'lightenSelectedColor'
                        : ''
                    "
                    @click="updateSelectedColor(c)"
                    :color="c.hexa"
                    :key="i"
                  >
                    <!-- {{ c }} -->
                  </v-card>
                </div>
              </v-responsive>
            </div>
            <!-- <v-color-picker
                :swatches="swatches"
                show-swatches
                swatches-max-height="300px"
                hide-sliders
                hide-canvas
                @input="test(color)"
                hide-inputs
                :rules="rules.color"
                :label="$t('portfolio.TagsItems.color')"
                hint="example of helper text only on focus"
                v-model="color.hexa"
              ></v-color-picker> -->
          </v-col>
        </v-row>
      </v-form>
      <!-- <small>*indicates required field</small> -->
      <!-- </v-card-text> -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="dialog = false">
          {{ $t("close") }}
        </v-btn>
        <v-btn
          :disabled="!valid"
          :loading="loading"
          color="primary darken-1"
          @click="postTagItem()"
        >
          {{ $t("send") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- </v-row> -->
</template>

<script>
export default {
  data() {
    return {
      swatches: [
        {
          hexa: "#02bdad",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#feae4c",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#df67ff",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#626262",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#f6b800",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#ff5a43",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#5cc0f5",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#dfdfdf",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#03cea4",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#512df6",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#3752af",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#5d03bd",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#0089ff",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#ff278f",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#a75f0c",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#d7e4ef",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#fededf",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#efe5ce",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#a2a2a2",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#ffe01a",
          selected: false,
          textColor: "#000000"
        }
      ],
      rules: {
        name: [
          v => !!v || this.$t("portfolio.TagsItems.nameRequired"), // Check if it's not null or whitespace
          v => (v != null && !v.includes(" ")) || this.$t("portfolio.TagsItems.nameNoSpaces"), // Check if the name has no spaces
          v => (v != null && v.length >= 3) || this.$t("portfolio.TagsItems.nameMinLength"), // Check if the name is at least 3 characters long
          v => (v != null && v.length <= 100) || this.$t("portfolio.TagsItems.nameMaxLength") // Check if the name is not longer than 100 characters
        ],
        color: [
          v => !!v || this.$t("portfolio.TagsItems.colorRequired") // Check if it's not null or whitespace
        ]
      },
      dialog: false,
      loading: false,
      overlay: false,
      valid: true,
      color: {
        hexa: "#02bdad",
        textColor: "#ffffff"
      },
      request: {
        tagName: null,
        tagColor: null
      }
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    async postTagItem() {
      if (!this.validate()) return;
      this.loading = true;
      this.overlay = true;
      this.request.tagColor = this.color.hexa;
      await this.axios
        .post("TagItem/Create", this.request)
        .then(() => {
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          });
          this.loading = false;
          this.dialog = false;
          this.overlay = false;
          this.reset();
          this.$emit("postTagMethod");
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          });
          this.loading = false;
          // this.dialog = false;
          this.overlay = false;
        });
    },
    updateSelectedColor(obj) {
      this.swatches.forEach(swatch => {
        if (swatch.hexa === obj.hexa) {
          swatch.selected = true;
          this.color.hexa = swatch.hexa;
          this.color.textColor = swatch.textColor;
        } else {
          swatch.selected = false;
        }
      });
      // console.log(obj);
    }
  }
};
</script>

<style scoped>
.lightenSelectedColor {
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px -4px 14px 4px #000000 !important;
  -moz-box-shadow: 0px -4px 14px 4px #000000 !important;
  box-shadow: 0px -4px 14px 4px #000000 !important;
}
.darkenSelectedColor {
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px -4px 14px 4px #ffffff !important;
  -moz-box-shadow: 0px -4px 14px 4px #ffffff !important;
  box-shadow: 0px -4px 14px 4px #ffffff !important;
}
.addTagsTitle {
  font-size: small !important ;
}
</style>
