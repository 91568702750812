<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on" v-if="!isButton">
        <v-list-item-title>{{ $t("portfolio.comment.title") }}</v-list-item-title>
        <v-list-item-icon>
          <v-icon>mdi-comment-multiple-outline</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-btn
        color="blue-grey white--text"
        v-bind="attrs"
        v-on="on"
        :outlined="isOutlineButton === true"
        v-else
        >{{ $t("portfolio.comment.title") }}</v-btn
      >
    </template>
    <v-card flat>
      <v-toolbar color="primary white--text" flat>
        <v-card-title class="ma-1">
          {{ $t("portfolio.comment.title") }} - {{ title }}
        </v-card-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn dark fab icon :loading="loading" @click="get(sourceGuid, sourceType)">
            <!-- {{$t("close")}} -->
            <v-icon>mdi-reload</v-icon>
          </v-btn>
          <v-btn dark fab icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="d-flex pa-5" v-if="loading">
        <h3 class="ma-auto">{{ $t("Loading") }}</h3>
      </div>
      <v-list three-line v-else-if="comments.length !== 0">
        <v-list-item class="form-create-comment">
          <v-card flat width="100%">
          <v-form
            ref="form"
            style="width: 100%;border-radius: 5px;"
            class="pa-5"
            v-model="valid"
            lazy-validation
          >
            <v-textarea
              outlined
              full-width
              class="comments"
              :loading="loadingText"
              auto-grow
              :rules="commentRules"
              clearable
              name="input-7-4"
              :label="$t('portfolio.comment.add')"
              v-model="commentText"
            >
              <v-btn icon slot="prepend" @click="resetValidation()">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-restart</v-icon>
                  </template>
                  <span>{{ $t("portfolio.comment.restart") }}</span>
                </v-tooltip>
              </v-btn>
              <v-btn icon slot="prepend" @click="addComment()">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }" open-delay="20">
                      <v-icon v-bind="attrs" v-on="on" :color="!hover ? '' : 'primary'">
                        mdi-comment-plus-outline</v-icon
                      >
                    </v-hover>
                  </template>
                  <span>{{ $t("portfolio.comment.add") }}</span>
                </v-tooltip>
              </v-btn>
            </v-textarea>
          </v-form>
          </v-card>
        </v-list-item>
        <v-list-item
          :color="comment.commentColor"
          small
          v-for="(comment, index) in comments"
          :key="index"
        >
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center">
              <v-list-item-avatar class="white--text" color="primary" size="50">
                <p class="pa-0 ma-0">
                  {{ avatarText(comment) }}
                </p>
              </v-list-item-avatar>
              <div class="d-flex flex-column">
                <p class="pb-1 ma-0">
                  {{
                    getUserInputComment(comment)
                  }}
                </p>
                <abbr style="font-size: 12px;">
                  {{ comment.createdDate | moment("YYYY/MM/DD - h:mm a") }}
                </abbr>
              </div>
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-textarea
                class="comments py-3"
                :outlined="comment.focus"
                auto-grow
                :loading="comment.loading"
                :filled="!comment.focus"
                v-model="comment.commentText"
                :readonly="!comment.focus || comment.loading"
              >
                <v-btn
                  icon
                  slot="append"
                  @click="showDeleteCommentDialog(comment)"
                  v-if="showActions(comment)"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-delete-outline</v-icon>
                    </template>
                    <span>{{ $t("delete") }}</span>
                  </v-tooltip>
                </v-btn>
                <v-btn icon slot="append" v-show="!!comment.modifiedDate">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-receipt-text-edit</v-icon>
                    </template>
                    <span>{{ comment.modifiedDate | moment("YYYY/MM/DD - h:mm a") }}</span>
                  </v-tooltip>
                </v-btn>

                <v-btn icon slot="append" v-if="!comment.focus && showActions(comment)">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }" open-delay="20">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          :color="!hover ? '' : 'warning'"
                          @click="comment.focus = true"
                        >
                          mdi-pen</v-icon
                        >
                      </v-hover>
                    </template>
                    <span>{{ $t("edit") }}</span>
                  </v-tooltip>
                </v-btn>
                <v-btn icon slot="append" v-if="comment.focus && showActions(comment)">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }" open-delay="20">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          :color="!hover ? '' : 'warning'"
                          @click="saveComment(comment)"
                        >
                          mdi-content-save-outline</v-icon
                        >
                      </v-hover>
                    </template>
                    <span>{{ $t("save") }}</span>
                  </v-tooltip>
                </v-btn>
              </v-textarea>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-card flat width="100%" class="d-flex flex-column pa-5" v-else>
        <!-- <div class="form-create-comment"> -->
          <v-form ref="form" s class="pa-5" v-model="valid" lazy-validation>
            <v-textarea
              outlined
              class="comments"
              :loading="loadingText"
              auto-grow
              :rules="commentRules"
              clearable
              name="input-7-4"
              :label="$t('portfolio.comment.add')"
              v-model="commentText"
            >
              <v-btn icon slot="prepend" @click="resetValidation()">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-restart</v-icon>
                  </template>
                  <span>{{ $t("portfolio.comment.restart") }}</span>
                </v-tooltip>
              </v-btn>
              <v-btn icon slot="prepend" @click="addComment()">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }" open-delay="20">
                      <v-icon v-bind="attrs" v-on="on" :color="!hover ? '' : 'primary'">
                        mdi-comment-plus-outline</v-icon
                      >
                    </v-hover>
                  </template>
                  <span>{{ $t("portfolio.comment.add") }}</span>
                </v-tooltip>
              </v-btn>
            </v-textarea>
          </v-form>
        <h3 class="ma-auto">{{ $t("noData") }}</h3>

        <!-- </div> -->
      </v-card>
    </v-card>
    <v-dialog v-model="deleteDialog" transition="dialog-bottom-transition" max-width="600">
      <template>
        <v-card>
          <v-toolbar color="error" dark>{{ $t("portfolio.comment.delete") }}</v-toolbar>
          <v-card-text>
            <h4 class="pa-5">
              {{ $t("portfolio.comment.deleteConform") }}
            </h4>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn color="error" @click="deleteComment(currentComment)">{{ $t("ok") }} </v-btn>
            <v-btn text @click="deleteDialog = false">{{ $t("close") }} </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-dialog>
</template>

<script>
import * as commentHub from "../../../hubs/commentHub";

export default {
  props: ["sourceGuid", "sourceType", "title", "isButton", "isTextButton", "isOutlineButton"],
  async beforeCreate() {
    // console.log("B Create");
    await commentHub.createConnection();
    // await commentHub.start();
  },
  async created() {
    // console.log("Create");
    // this.sourceGuid = this.sourceGuid;
    // this.sourceType = 0;
  },
  data() {
    return {
      userId: JSON.parse(localStorage.userData).guid,
      valid: true,
      loading: false,
      loadingText: false,
      deleteDialog: false,
      dialog: false,
      commentText: null,
      commentRules: [
        v => !!v || this.$t("portfolio.comment.validation.message"),
        v => (v && v.length <= 450) || this.$t("portfolio.comment.validation.maxLength")
      ],
      comments: [],
      currentComment: {
        commentGuid: null,
        teacher: null,
        student: null,
        personType: null,
        commentText: null,
        modifiedDate: null,
        loading: false,
        focus: false,
        createdDate: null
      }
    };
  },
  methods: {
    getUserInputComment(comment) {
      return comment.student === undefined || comment.student === null
        ? comment.teacher.teacherName
        : comment.student.studentFullName;
    },
    showActions(comment) {
      if (comment.teacher !== undefined && comment.teacher !== null) {
        return comment.teacher.teacherGuid === this.userId;
      } else {
        return comment.student.studentGuid === this.userId;
      }
    },
    avatarText(comment) {
      if (comment.teacher !== undefined && comment.teacher !== null) {
        return `${comment.teacher.teacherName.split(" ")[0][0]} ${
          comment.teacher.teacherName.split(" ")[1][0]
        }`;
      } else {
        return `${comment.student.studentFullName.split(" ")[0][0]} ${
          comment.student.studentFullName.split(" ")[1][0]
        }`;
      }
    },
    async get(sourceGuid, sourceType) {
      this.comments = [];
      this.loading = true;
      await this.axios
        .get(`Comment/Get?sourceGuid=${sourceGuid}&sourceType=${sourceType}`)
        .then(response => {
          const { data } = response.data;
          // console.log(response);
          if (Array.isArray(data)) {
            data.forEach(element => {
              element.focus = false;
              element.loading = false;
              this.comments.push(element);
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async addComment() {
      if (!this.validate()) {
        return;
      }
      this.loadingText = true;
      await this.axios
        .post("Comment/Create", {
          sourceType: this.sourceType,
          sourceGuid: this.sourceGuid,
          commentText: this.commentText
        })
        .then(response => {
          const { data } = response.data;
          // console.log(data);
          if (data) {
            data.focus = false;
            data.loading = false;
            const condition = this.comments.find(x => x.commentGuid === data.commentGuid);
            // console.log(condition);
            if (!condition) this.comments.push(data);
            this.reset();
            this.resetValidation();
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            });
          }
        })
        .catch(err => {
          console.log(err.response);
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          });
        })
        .finally(() => {
          this.loadingText = false;
        });
    },
    async saveComment(comment) {
      comment.loading = true;
      await this.axios
        .post("Comment/Update", {
          commentGuid: comment.commentGuid,
          commentText: comment.commentText
        })
        .then(response => {
          const { data } = response.data;
          console.log(response);
          if (data !== undefined && data.modifiedDate !== undefined) {
            comment.modifiedDate = data.modifiedDate;
          }
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          });
        })
        .catch(() => {
          // console.log(ee);
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          });
        })
        .finally(() => {
          comment.loading = false;
          comment.focus = false;
        });
    },
    showDeleteCommentDialog(comment) {
      this.deleteDialog = true;
      this.currentComment = comment;
    },
    async deleteComment(comment) {
      if (comment) {
        comment.loading = true;
        await this.axios
          .post("Comment/Delete", {
            commentGuid: comment.commentGuid
          })
          .then(() => {
            // const index = this.comments.findIndex(x => x.commentGuid === comment.commentGuid);
            // const commentObj = this.comments.find(x => x.commentGuid === comment.commentGuid);
            // this.comments.splice(index, 1);
            // console.log(`index for delete : ${index}`);
            // if (index === -1 && commentObj !== undefined) {
            //   this.deleteDialog = false;
            //   return;
            // }
            // this.deleteDialog = false;
            this.currentComment = {
              commentGuid: null,
              teacher: null,
              student: null,
              personType: null,
              commentText: null,
              modifiedDate: null,
              loading: false,
              focus: false,
              createdDate: null
            };
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            });
          })
          .catch(() => {
            this.$swal.fire({
              title: this.$t("AnErrorOccurredDuringTheProcess"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            });
          })
          .finally(() => {
            comment.loading = false;
            comment.focus = false;
          });
      }
    }
  },
  // beforeUnmount() {
  //   commentHub.LeaveGroup(this.sourceGuid);
  //   commentHub.disconnect();
  // },
  beforeDestroy() {
    // commentHub.LeaveGroup(this.sourceGuid);
    // commentHub.disconnect();
  },
  async mounted() {
    // console.log("mount");
    commentHub.connection.on("CreateComment", message => {
      const condition = this.comments.find(x => x.commentGuid === message.commentGuid);
      // console.log(condition);
      if (!condition) {
        const commentSignalR = {
          commentGuid: message.commentGuid,
          teacher: message.teacher !== undefined ? message.teacher : null,
          student: message.student !== undefined ? message.student : null,
          personType: message.personType,
          commentText: message.commentText,
          modifiedDate: message.modifiedDate !== undefined ? message.modifiedDate : null,
          loading: false,
          focus: false,
          newComment: true,
          createdDate: message.createdDate
        };
        this.$swal.fire({
          title: this.$t("portfolio.comment.new"),
          text: "",
          icon: "success",
          confirmButtonText: this.$t("close"),
          toast: true,
          position: "bottom-start",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          }
        });
        this.comments.push(commentSignalR);
      }
    });
    commentHub.connection.on("UpdateComment", message => {
      // console.log(message);
      const commentInList = this.comments.find(x => x.commentGuid === message.commentGuid);
      // console.log(commentInList, message);
      if (commentInList) {
        commentInList.commentGuid = message.commentGuid;
        commentInList.commentText = message.commentText;
        commentInList.modifiedDate =
          message.modifiedDate !== undefined ? message.modifiedDate : null;
      }
    });
    commentHub.connection.on("DeleteComment", message => {
      // console.log(message);
      const commentInList = this.comments.findIndex(x => x.commentGuid === message.commentGuid);
      const commentInListObj = this.comments.find(x => x.commentGuid === message.commentGuid);
      // console.log(commentInList, commentInListObj);
      if (commentInList !== -1 && commentInListObj !== undefined) {
        this.comments.splice(commentInList, 1);
      }
      this.deleteDialog = false;
    });
  },
  watch: {
    async dialog() {
      if (this.dialog) {
        const status = await commentHub.connection.connectionStarted;
        if (!status) {
          await commentHub.start();
        }
        commentHub.JoinGroup(this.sourceGuid,'Comments_'+this.sourceType);
        await this.get(this.sourceGuid, this.sourceType);
      } else {
        const status = await commentHub.connection.connectionStarted;
        console.log("on close ", commentHub.connection.connectionStarted);
        if (status) {
          // await commentHub.LeaveGroup(this.sourceGuid);
          await commentHub.connection.stop();
          console.log(`closed  ${await commentHub.connection.connectionStarted}`);
        }
      }
    }
  }
};
</script>

<style scoped>
.comments >>> .v-input__prepend-outer {
  display: flex !important;
  flex-direction: column !important;
}
.containerCard {
  position: relative !important;
  overflow: hidden;
}
.form-create-comment {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  width: 100%;
  opacity: 1 !important;
  margin: auto;
  z-index: 10;
}
</style>
