<template>
  <div>
    <v-dialog
      transition="dialog-bottom-transition"
      persistent
      v-model="dialog"
      fullscreen
      no-click-animation
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark dense>
          {{ !examId ? $t("exam.addExam") : $t("exam.reExam") }}
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-row class="pa-0 ma-0">
            <v-col cols="12" md="3" sm="12" class="py-0 ma-0" v-show="!examId">
              <v-autocomplete
                v-model="subjectSelect"
                :items="lectures"
                outlined
                :label="$t('degrees.selectSubject')"
                item-text="subjectName"
                item-value="subjectGuid"
                @change="clearSelect"
                :rules="[required]"
              ></v-autocomplete>
            </v-col>
            <v-col class="py-0 ma-0">
              <v-autocomplete
                v-model="deparmentSelect"
                :items="departmentList"
                outlined
                :label="$t('home.departmentChoese')"
                item-text="departmentName"
                item-value="departmentGuid"
                @change="clearSelect2"
                :rules="[required]"
              >
              </v-autocomplete>
            </v-col>
            <v-col class="py-0 ma-0">
              <v-autocomplete
                v-model="stageSelect"
                :items="stageList"
                outlined
                :label="$t('lecOne.selectStage')"
                :item-text="getFieldText"
                item-value="stageGuid"
                @change="clearSelect3"
                :rules="[required]"
              >
              </v-autocomplete>
            </v-col>
            <v-col class="py-0 ma-0">
              <v-autocomplete
                v-model="sectionSelect"
                :items="sectionList"
                outlined
                :label="$t('mail.selectSection')"
                item-text="sectionName"
                item-value="sectionGuid"
                @change="getStudent"
                :rules="[required]"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" md="6" sm="12" class="py-0 ma-0" v-if="!examId">
              <v-autocomplete
                v-model="examTypeSelect"
                :items="examTypeList"
                outlined
                :label="$t('degrees.ChooseTheTypeofExam')"
                item-text="name"
                item-value="value"
                :rules="[required]"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              :md="!examId ? '6' : '12'"
              sm="12"
              class="py-0 ma-0"
            >
              <v-autocomplete
                v-model="studentsSelect"
                :items="studentList"
                outlined
                multiple
                :label="$t('mail.ChooseStudents')"
                item-text="studentFullName"
                item-value="studentGuid"
                clearable
                chips
                small-chips
                deletable-chips
                :loading="isLoading"
                :rules="[required]"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 3">
                    <span>{{ item.studentFullName }}</span>
                  </v-chip>
                  <span v-if="index === 3" class="grey--text caption">
                    (+{{ studentsSelect.length - 3 }} {{ $t("mail.Others") }})
                  </span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggle">
                    <v-list-item-action>
                      <v-icon
                        :color="studentsSelect.length > 0 ? 'primary' : ''"
                      >
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("mail.selectAll")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:append-item>
                  <v-divider class="mb-2"></v-divider>
                  <v-list-item disabled>
                    <v-list-item-avatar color="grey lighten-3">
                      <v-icon light> mdi-account-question-outline </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content v-if="likesAllFruit">
                      <v-list-item-title>
                        {{ $t("mail.AllHaveBeenSelected") }}</v-list-item-title
                      >
                    </v-list-item-content>

                    <v-list-item-content v-else-if="likesSomeFruit">
                      <v-list-item-title>
                        {{ $t("mail.TheNumberOfStudentsSpecified") }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ studentsSelect.length }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <v-list-item-title>
                        {{ $t("mail.NoStudentIdentified") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col class="py-0 ma-0">
              <!-- <v-datetime-picker
                v-model="fromDatetime"
                :label="$t('mail.dateStart')"
                :clearText="$t('close')"
                :okText="$t('ok')"
              ></v-datetime-picker> -->

              <span style="font-size: 12px">{{ $t("mail.dateStart") }} </span>
              <DxDateBox
                display-format="yyyy/MM/dd hh:mm:ss a"
                :placeholder="$t('mail.dateStart')"
                styling-mode="outlined"
                v-model="fromDatetime"
                :value="fromDatetime"
                type="datetime"
                :use-mask-behavior="true"
              />
              <div class="v-text-field__details">
                <div class="v-messages theme--light">
                  <div class="v-messages__wrapper"></div>
                </div>
              </div>
            </v-col>

            <v-col class="py-0 ma-0">
              <!-- <v-datetime-picker
                v-model="toDatetime"
                :min="
                  fromDatetime
                    ? fromDatetime.toISOString().substring(0, 10)
                    : null
                "
                :mintime="
                  fromDatetime && toDatetime
                    ? fromDatetime.toISOString().substring(0, 10) ==
                      toDatetime.toISOString().substring(0, 10)
                      ? $moment(this.fromDatetime).format('HH:mm')
                      : null
                    : null
                "
                :label="$t('mail.dateEnd')"
                :clearText="$t('close')"
                :okText="$t('ok')"
              ></v-datetime-picker> -->

              <span style="font-size: 12px">{{ $t("mail.dateEnd") }} </span>
              <DxDateBox
                display-format="yyyy/MM/dd hh:mm:ss a"
                :placeholder="$t('mail.dateEnd')"
                styling-mode="outlined"
                v-model="toDatetime"
                :value="toDatetime"
                type="datetime"
                :use-mask-behavior="true"
              />
              <div class="v-text-field__details">
                <div class="v-messages theme--light">
                  <div class="v-messages__wrapper"></div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0" v-if="!examId">
            <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
              <v-text-field
                :label="$t('exam.TitleOfTheExam')"
                :placeholder="$t('exam.TypeATitleForTheExam')"
                outlined
                v-model="titleExam"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0 ma-0">
              <v-checkbox
                class="pb-5"
                hide-details
                v-model="restrictedByTime"
                :label="$t('exam.RestrictedPeriod')"
              ></v-checkbox>
              <v-time-picker
                hide-details
                :text="$t('exam.TimePeriod')"
                color="primary"
                width="800"
                v-model="restrictedTime"
                v-if="restrictedByTime"
                outlined
              ></v-time-picker>
            </v-col>
            <v-col cols="12" class="py-0 ma-0">
              <v-checkbox
                hide-details
                class="pb-2"
                :label="$t('exam.EnablePasswordToEnterExam')"
                v-model="enablePasswordToEnter"
              ></v-checkbox>
              <v-text-field
                v-if="enablePasswordToEnter"
                :label="$t('exam.EnterToExamPassword')"
                :rules="[required]"
                outlined
                v-model="passwordToEnter"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="py-0 ma-0">
              <v-list-item class="pa-0">
                <v-checkbox
                  :label="$t('exam.RestrictedMovingToNext')"
                  :title="$t('exam.RestrictedMovingToNextHint')"
                  v-model="restrictedMovingToNext"
                ></v-checkbox>

                <v-checkbox
                  class="px-4"
                  :label="$t('exam.ShowCorrectAnswerAfterSubmit')"
                  v-model="showCorrectAnswerAfterSubmit"
                ></v-checkbox>
              </v-list-item>
            </v-col>
            <v-col cols="12" class="py-0 ma-0">
              <v-text-field
                v-model="numberOfQuestionsInPage"
                type="number"
                class="pb-3"
                outlined
                :label="$t('exam.NumberOfQuestionsInPage')"
                :rules="[numberOfQuestionsInPageValidate]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0" v-if="!examId">
            <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
              <v-textarea
                outlined
                name="input-7-4"
                :label="$t('exam.WriteTheDescriptionHereIfAny')"
                v-model="description"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="dialog = false">
            {{ $t("close") }}</v-btn
          >
          <v-btn
            :loading="sendLoad"
            color="primary darken-1"
            @click="examId ? reExamSendData() : uploadData()"
            :disabled="
              !subjectSelect ||
              !deparmentSelect ||
              !stageSelect ||
              !sectionSelect ||
              !studentsSelect[0] ||
              (examId
                ? false
                : (enablePasswordToEnter && !passwordToEnter) ||
                  !titleExam || checkRestrictedTime ||
                  !numberOfQuestionsInPage ||
                  numberOfQuestionsInPage < 1)
            "
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DxDateBox from "devextreme-vue/date-box";
import VDatetimePicker from "../dataTime.vue";
import VTimePicker from "../timePicker.vue";

export default {
  components: {
    VDatetimePicker,
    VTimePicker,
    DxDateBox,
  },
  props: ["folderId"],
  data() {
    return {
      required: (value) => value != null || this.$t("ThisFieldIsRequired"),
      examId: null,
      description: null,
      fromDatetime: new Date(),
      toDatetime: new Date(),
      examTypeSelect: null,
      examTypeList: [
        {
          name: this.$t("degrees.daily"),
          value: 0,
        },
        {
          name: this.$t("degrees.Monthly"),
          value: 3,
        },
        {
          name: this.$t("degrees.midFinal"),
          value: 2,
        },
        {
          name: this.$t("degrees.final"),
          value: 1,
        },
      ],
      titleExam: "",
      isLoading: false,
      subjectSelect: null,
      lectures: [],
      sendLoad: false,
      dialog: false,
      deparmentSelect: null,
      stageSelect: null,
      sectionSelect: null,
      studentsSelect: [],
      studentList: [],
      examGuid: null,
      folderGuid: null,

      enablePasswordToEnter: false,
      passwordToEnter: null,
      numberOfQuestionsInPage: 1,
      numberOfQuestionsInPageValidate: (value) => (value != null && value > 0) ||
        `${this.$t("ThisFieldIsRequired")
          } - ${
          this.$t("exam.ValueMustBeGreaterThanZero")}`,

      showCorrectAnswerAfterSubmit: false,
      restrictedMovingToNext: false,
      restrictedByTime: false,
      restrictedTime: null,
    };
  },
  computed: {
    checkRestrictedTime() {
      return this.restrictedByTime && (!this.restrictedTime || this.restrictedTime == 0)
    },
    likesAllFruit() {
      return this.studentsSelect.length === this.studentList.length;
    },
    likesSomeFruit() {
      return this.studentsSelect.length > 0 && !this.likesAllFruit;
    },
    icon() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    departmentList() {
      let dataList = [];
      if (this.subjectSelect) {
        dataList = this.lectures.filter(
          (item) => item.subjectGuid === this.subjectSelect
        );
        // console.log(dataList);
        return dataList[0].departments;
      }
      return dataList;
    },
    stageList() {
      let dataList = [];
      if (this.deparmentSelect) {
        dataList = this.departmentList.filter(
          (item) => item.departmentGuid === this.deparmentSelect
        );
        return dataList[0].stages;
      }
      return dataList;
    },
    sectionList() {
      let dataList = [];
      if (this.stageSelect) {
        dataList = this.stageList.filter(
          (item) => item.stageGuid === this.stageSelect
        );
        return dataList[0].sections;
      }
      return dataList;
    },
  },
  methods: {
    clearSelect() {
      this.sectionSelect = null;
      this.stageSelect = null;
      this.deparmentSelect = null;
    },
    clearSelect2() {
      this.sectionSelect = null;
      this.stageSelect = null;
    },
    clearSelect3() {
      this.sectionSelect = null;
    },
    getLec() {
      this.axios
        .get("Subjects/Get")
        .then((res) => {
          if (this.isInRole(2)) {
            this.lectures = res.data.data.subjects;
          } else {
            this.lectures = res.data.data;
          }
        })
        .catch((err) => {});
    },
    getStudent() {
      if (this.sectionSelect && this.subjectSelect) {
        this.isLoading = true;
        this.axios
          .get(
            `Students/Get?sectionGuid=${this.sectionSelect}&subjectGuid=${this.subjectSelect}`
          )
          .then((res) => {
            this.studentList = res.data.data;
            this.studentsSelect = [];
            // console.log(this.studentList);
          })
          .catch(() => {})
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    setValue() {
      this.clear();
      this.dialog = true;
    },
    reExam(examId, subjectId) {
      this.clear();
      this.examId = examId;
      this.subjectSelect = subjectId;
      this.dialog = true;
    },
    getFieldText(item) {
      return `${item.stageName} - ${item.studyName}`;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.studentsSelect = [];
        } else {
          this.studentsSelect = this.studentList.slice();
        }
      });
    },
    sendResultValues() {
      this.$emit("send-result-values", this.examGuid);
    },
    clear() {
      this.examId = null;
      this.titleExam = null;
      this.description = null;
      this.fromDatetime = new Date();
      this.toDatetime = new Date().setHours(new Date().getHours() + 1);
      this.subjectSelect = null;
      this.sectionSelect = null;
      this.examTypeSelect = null;
      this.studentsSelect = [];
      this.deparmentSelect = null;
      this.stageSelect = null;
      this.enablePasswordToEnter = false;
      this.passwordToEnter = null;
      this.numberOfQuestionsInPage = 1;
      this.showCorrectAnswerAfterSubmit = false;
      this.restrictedMovingToNext = false;
      this.restrictedByTime = false;
      this.restrictedTime = null;
    },
    reExamSendData() {
      if (this.fromDatetime > this.toDatetime) {
        this.$swal.fire({
          title: this.$t("exam.fromDateTimeGreaterThanToDateTime"),
          text: "",
          icon: "error",
          confirmButtonText: this.$t("close"),
        });
        return;
      }
      this.sendLoad = true;
      // if (this.likesAllFruit) {
      //   for (let i = 0; i < this.studentsSelect.length; i++) {
      //     this.studentsSelect[i] = this.studentsSelect[i].studentGuid;
      //   }
      // }
      const data = {
        examGuid: this.examId,
        startDate: this.$moment(this.fromDatetime).format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
        endDate: this.$moment(this.toDatetime).format("YYYY-MM-DDTHH:mm:ss"),
        sectionGuid: this.sectionSelect,
        studentGuids: this.studentsSelect.some((s) => s.studentGuid)
          ? this.studentsSelect.map((a) => a.studentGuid)
          : this.studentsSelect,
      };
      this.axios
        .post("Exams/ReExam", data)
        .then((res) => {
          this.examGuid = res.data.data.examGuid;
          this.sendResultValues();
          this.dialog = false;
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          // console.log(res);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.sendLoad = false;
        });
    },
    uploadData() {
      this.sendLoad = true;
      // if (this.likesAllFruit) {
      //   for (let i = 0; i < this.studentsSelect.length; i++) {
      //     this.studentsSelect[i] = this.studentsSelect[i].studentGuid;
      //   }
      // }
      if (
        this.folderId == "undefined" ||
        this.folderId == null ||
        !this.folderId
      ) {
        this.folderGuid = "00000000-0000-0000-0000-000000000000";
      } else {
        this.folderGuid = this.folderId;
      }

      const data = {
        title: this.titleExam,
        description: this.description,
        startDate: this.$moment(this.fromDatetime).format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
        endDate: this.$moment(this.toDatetime).format("YYYY-MM-DDTHH:mm:ss"),
        subjectGuid: this.subjectSelect,
        sectionGuid: this.sectionSelect,
        studentGuids: this.likesAllFruit
          ? this.studentsSelect.map((a) => a.studentGuid)
          : this.studentsSelect,
        examType: this.examTypeSelect,
        FolderGuid: this.folderGuid,
        enablePasswordToEnter: this.enablePasswordToEnter,
        passwordToEnter: this.passwordToEnter,
        numberOfQuestionsInPage: this.numberOfQuestionsInPage,
        showCorrectAnswerAfterSubmit: this.showCorrectAnswerAfterSubmit,
        restrictedMovingToNext: this.restrictedMovingToNext,
        restrictedByTime: this.restrictedByTime,
        restrictedTime: this.restrictedTime,
      };

      // console.log(data);
      this.axios
        .post("Exams/New", data)
        .then((res) => {
          if (res.data.statusCode == 1) {
            this.examGuid = res.data.data.examGuid;
            this.sendResultValues();
            this.dialog = false;
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
          } else if (res.data.statusCode == 6) {
            this.$swal.fire({
              title: this.$t("exam.RestrictedTimeMustBeBetweenStartEndTime"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else {
            this.$swal.fire({
              title: this.$t(
                "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
              ),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          }

          // console.log(res);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.sendLoad = false;
        });
    },
  },
  created() {
    this.getLec();
  },
};
</script>

<style>
.dx-texteditor-input {
  padding-right: 8px !important;
}
</style>
