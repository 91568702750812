<template>
  <v-dialog v-model="dialog" persistent max-width="300px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-show="hover"
        @click="setValues(tag)"
        v-bind="attrs"
        v-on="on"
        small
        class=""
        :color="tag.tagTextColor"
        >mdi-pen
      </v-icon>
    </template>
    <template v-slot:default="dialog">
      <v-card flat>
        <v-toolbar color="primary">
          <v-toolbar-title class="white--text">{{ $t("portfolio.TagsItem.edit") }}</v-toolbar-title>
        </v-toolbar>
        <!-- <v-card-title @click="test()">
          <span class="">{{ $t("portfolio.TagsItem.edit") }}</span>
        </v-card-title>
      -->
        <v-card-text>
          <v-form ref="form" v-model="valid" class="mt-lg-0 mt-5" lazy-validation>
            <v-overlay absolute :value="overlay">
              <!-- <v-btn color="success" @click="overlay = false"> Hide Overlay </v-btn> -->
              <v-card color="primary" dark width="200" max-width="100%" class="pa-3 mb-5">
                <v-card-title>
                  {{ $t("Processing") }}
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0 mt-2"
                  ></v-progress-linear>
                </v-card-title>
              </v-card>
            </v-overlay>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  :label="$t('portfolio.TagsItems.name')"
                  required
                  v-model="request.tagName"
                  filled
                  full-width
                  :rules="rules.name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="d-flex flex-column" style="width: 100%">
                  <!-- <div class="d-flex">
                    {{ $t("portfolio.TagsItems.selectedColor") }}
                    <v-card
                      height="30"
                      class="pa-2 mx-3 mb-3 text-center d-flex align-center"
                      :color="color.hexa"
                      :style="`color: ${color.textColor};`"
                    >
                      {{ request.tagName }}
                    </v-card>
                  </div> -->
                  <v-responsive
                    width="100%"
                    max-height="250"
                    class="mx-auto mb-4 d-flex flex-wrap overflow-y-auto overflow-x-hidden"
                  >
                    <v-card
                      elevation="2"
                      class="d-flex flex-wrap"
                      style="border-radius: 5px !important"
                    >
                      <v-card
                        v-for="(c, i) in swatches"
                        width="40"
                        height="40"
                        class="pa-2 ma-2"
                        :class="
                          c.selected
                            ? $vuetify.theme.dark
                              ? 'darkenSelectedColor'
                              : 'lightenSelectedColor'
                            : ''
                        "
                        @click="updateSelectedColor(c)"
                        :color="c.hexa"
                        :key="i"
                      >
                        <!-- {{ c }} -->
                      </v-card>
                    </v-card>
                  </v-responsive>
                </div>
              </v-col>
            </v-row>
          </v-form>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="error darken-1"
                dark
                v-bind="attrs"
                v-on="on"
                @click="openDeleteDialog()"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t("portfolio.TagsItem.delete") }}</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="dialog.value = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            :disabled="!valid"
            :loading="loading"
            color="primary darken-1"
            @click="putTagItem()"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: ["tag", "textColor", "hover"],
  data() {
    return {
      swatches: [
        {
          hexa: "#02bdad",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#feae4c",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#df67ff",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#626262",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#f6b800",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#ff5a43",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#5cc0f5",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#dfdfdf",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#03cea4",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#512df6",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#3752af",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#5d03bd",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#0089ff",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#ff278f",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#a75f0c",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#d7e4ef",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#fededf",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#efe5ce",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#a2a2a2",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#ffe01a",
          selected: false,
          textColor: "#000000"
        }
      ],
      rules: {
        name: [
          v => !!v || this.$t("portfolio.TagsItems.nameRequired"), // Check if it's not null or whitespace
          v => (v != null && !v.includes(" ")) || this.$t("portfolio.TagsItems.nameNoSpaces"), // Check if the name has no spaces
          v => (v != null && v.length >= 3) || this.$t("portfolio.TagsItems.nameMinLength"), // Check if the name is at least 3 characters long
          v => (v != null && v.length <= 100) || this.$t("portfolio.TagsItems.nameMaxLength") // Check if the name is not longer than 100 characters
        ],
        color: [
          v => !!v || this.$t("portfolio.TagsItems.colorRequired") // Check if it's not null or whitespace
        ]
      },
      dialog: false,
      loading: false,
      overlay: false,
      valid: true,
      color: {
        hexa: "#02bdad",
        textColor: "#ffffff"
      },
      request: {
        tagItemGuid: null,
        tagName: null,
        tagColor: null
      }
    };
  },
  methods: {
    openDeleteDialog() {
      const tag = {
        tagColor: this.tag.tagColor,
        tagItemGuid: this.tag.tagItemGuid,
        tagName: this.tag.tagName,
        tagTextColor: this.tag.tagTextColor,
        dialog: false,
        show: false,
        tagsLoading: false
      };
      this.dialog = false;
      this.$emit("setTagForDelete", tag);
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    setValues(param) {
      // console.log(param);
      this.request.tagItemGuid = param.tagItemGuid;
      this.color.hexa = param.tagColor;
      this.color.textColor = param.tagTextColor;
      this.request.tagName = param.tagName;
      // this.dialog = true;
      // this.$emit("openTagEdit");
    },
    async putTagItem() {
      if (!this.validate()) return;
      this.loading = true;
      this.overlay = true;
      this.request.tagColor = this.color.hexa;
      // console.log(this.color);
      await this.axios
        .post("TagItem/Update", this.request)
        .then(() => {
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          });
          this.loading = false;
          // this.$emit("closeTagEdit");
          this.dialog = false;
          this.overlay = false;
          this.request.textColor = this.color.textColor;
          this.$emit("putTagMethod", this.request);
          this.reset();
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          });
          this.loading = false;
          // this.dialog = false;
          this.overlay = false;
        });
    },
    test() {
      this.dialog = false;
    },
    updateSelectedColor(obj) {
      this.swatches.forEach(swatch => {
        if (swatch.hexa === obj.hexa) {
          swatch.selected = true;
          this.color.hexa = swatch.hexa;
          this.color.textColor = swatch.textColor;
        } else {
          swatch.selected = false;
        }
      });
      // console.log(obj);
    }
  }
};
</script>

<style scoped>
.lightenSelectedColor {
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px -4px 14px 4px #000000 !important;
  -moz-box-shadow: 0px -4px 14px 4px #000000 !important;
  box-shadow: 0px -4px 14px 4px #000000 !important;
}
.darkenSelectedColor {
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px -4px 14px 4px #ffffff !important;
  -moz-box-shadow: 0px -4px 14px 4px #ffffff !important;
  box-shadow: 0px -4px 14px 4px #ffffff !important;
}
</style>
