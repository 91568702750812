var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","loading":_vm.loading,"min-height":"450"}},[_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-card',{ref:_vm.content.forumTopicContentGuid,staticClass:"ma-0 pa-0 overflow-y-auto",style:(_vm.fullScreenMode ? 'max-height: calc(100vh - 150px)' : 'max-height: calc(100vh - 300px)'),attrs:{"flat":""}},[_c('v-card-text',{staticClass:"ma-0 pa-0"},[_vm._l((_vm.content.replies),function(item){return _c('div',{key:item.forumTopicContentGuid},[_c('v-card',{staticClass:"mt-1 me-1",attrs:{"outlined":"","color":item.editMode ? (_vm.$vuetify.theme.dark ? '#333333' : '#efefef') : ''}},[_c('reply-item',{key:item.refreshReplyKey,attrs:{"item":item,"content":_vm.content},on:{"onKeyDownReplyItem":_vm.onKeyDownReply,"onDeleteReplyItem":_vm.deleteReplyContent}}),(!_vm.content.lock)?_c('v-card-actions',{staticClass:"ma-0 pa-1 pt-0"},[(!item.showReplyTextBox)?_c('v-btn',{attrs:{"small":"","min-width":"80px","depressed":""},on:{"click":function($event){_vm.content.replies.forEach(function (f) {
                    f.showReplyTextBox = false;
                  });
                  item.showReplyTextBox = true;
                  item.showReplies = true;
                  item.editMode = false;
                  _vm.$forceUpdate();}}},[_vm._v("\n                "+_vm._s(_vm.$t("topic.reply"))+"\n                "),_c('v-icon',{staticClass:"mx-1",attrs:{"small":"","color":"grey"}},[_vm._v(" mdi-comment ")])],1):_vm._e(),(item.showReplyTextBox && !_vm.content.lock && _vm.content.forumTopic.topicType == 0)?_c('v-text-field',{staticClass:"my-2",attrs:{"dir":item.reply ? 'auto' : '',"autofocus":"","label":_vm.$t('topic.enterTextToReply'),"placeholder":_vm.$t('topic.enterTextToReply'),"outlined":"","dense":"","hide-details":""},on:{"keydown":function (e) {
                    _vm.onKeyDownReply({ e: e, item: item });
                  }},model:{value:(item.reply),callback:function ($$v) {_vm.$set(item, "reply", $$v)},expression:"item.reply"}}):_vm._e()],1):_vm._e(),(item.replies && item.replies.length > 0)?_c('v-alert',{staticClass:"ma-1",attrs:{"text":"","dense":"","color":"primary"}},[_c('div',{staticClass:"mb-2"},[_c('v-btn',{attrs:{"small":"","depressed":""},on:{"click":function($event){item.showReplies = !item.showReplies;
                    _vm.$forceUpdate();}}},[_vm._v("\n                  "+_vm._s(_vm.$t("topic.replies"))+" ("+_vm._s(item.replies.length)+")\n                  "),_c('v-icon',[_vm._v(" "+_vm._s(item.showReplies ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1),_c('v-expand-transition',[(item.showReplies)?_c('div',_vm._l((item.replies),function(itemReplies){return _c('div',{key:itemReplies.forumTopicContentGuid},[_c('v-card',{staticClass:"mt-1",attrs:{"outlined":""}},[_c('reply-item',{key:itemReplies.refreshReplyKey,attrs:{"item":itemReplies,"content":_vm.content},on:{"onKeyDownReplyItem":_vm.onKeyDownReply,"onDeleteReplyItem":_vm.deleteReplyContent}})],1)],1)}),0):_vm._e()])],1):_vm._e()],1)],1)}),(!_vm.content.replies || _vm.content.replies.length == 0)?_c('v-row',{staticStyle:{"min-height":"300px"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"12"}},[_c('img',{attrs:{"src":require("../../assets/folder.svg"),"width":"150px"}}),_c('h3',[_vm._v(_vm._s(_vm.$t("noData")))])])],1):_vm._e()],2)],1),(!_vm.content.lock && _vm.content.forumTopic.topicType == 0)?_c('v-text-field',{ref:"inputReply",staticClass:"my-4",attrs:{"dir":_vm.content.reply ? 'auto' : '',"autofocus":"","label":_vm.$t('topic.enterTextToReply'),"placeholder":_vm.$t('topic.enterTextToReply'),"outlined":"","dense":"","hide-details":""},on:{"keydown":function (e) {
          _vm.onKeyDownReply({ e: e, item: _vm.content });
        }},model:{value:(_vm.content.reply),callback:function ($$v) {_vm.$set(_vm.content, "reply", $$v)},expression:"content.reply"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }