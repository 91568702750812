<template>
  <v-card v-if="editData" flat>
    <v-card-text class="">
      <v-row class="pa-0 ma-0">
        <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
          <v-autocomplete
            v-model="studentsSelect"
            :items="studentList"
            outlined
            multiple
            :label="$t('mail.ChooseStudents')"
            item-text="studentFullName"
            item-value="studentGuid"
            clearable
            chips
            small-chips
            deletable-chips
            :loading="isLoading"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 3">
                <span>{{ item.studentFullName }}</span>
              </v-chip>
              <span v-if="index === 3" class="grey--text caption">
                (+{{ studentsSelect.length - 3 }} {{$t('mail.Others')}})
              </span>
            </template>
            <template v-slot:prepend-item>
              <v-list-item ripple @click="toggle">
                <v-list-item-action>
                  <v-icon :color="studentsSelect.length > 0 ? 'primary' : ''">
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{$t('mail.selectAll')}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item disabled>
                <v-list-item-avatar color="grey lighten-3">
                  <v-icon light> mdi-account-question-outline </v-icon>
                </v-list-item-avatar>

                <v-list-item-content v-if="likesAllFruit">
                  <v-list-item-title> {{$t('mail.AllHaveBeenSelected')}} </v-list-item-title>
                </v-list-item-content>

                <v-list-item-content v-else-if="likesSomeFruit">
                  <v-list-item-title> {{$t('mail.TheNumberOfStudentsSpecified')}} </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ studentsSelect.length }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title> {{$t('mail.NoStudentIdentified')}} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-end px-7 pt-0 mt-0">
      <v-btn
        :loading="sendLoad"
        outlined
        color="teal darken-1"
        @click="addStudent()"
        :disabled="!studentsSelect[0]"
      >
        {{$t('exam.SaveModificationss')}}
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-card-actions></v-card
  >
</template>

<script>
export default {
  props: ["examGuid", "examQuestions"],

  data() {
    return {
      subjectSelect: null,
      lectures: [],
      sendLoad: false,
      deparmentSelect: null,
      stageSelect: null,
      sectionSelect: null,
      studentsSelect: [],
      studentList: [],
      editData: this.examQuestions.exam,
      isLoading: false
    };
  },
  computed: {
    likesAllFruit() {
      return this.studentsSelect.length === this.studentList.length;
    },
    likesSomeFruit() {
      return this.studentsSelect.length > 0 && !this.likesAllFruit;
    },
    icon() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    departmentList() {
      let dataList = [];
      if (this.subjectSelect) {
        dataList = this.lectures.filter(
          (item) => item.subjectGuid === this.subjectSelect
        );
        // console.log(dataList);
        return dataList[0].departments;
      }
      return dataList;
    },
    stageList() {
      let dataList = [];
      if (this.deparmentSelect) {
        dataList = this.departmentList.filter(
          (item) => item.departmentGuid === this.deparmentSelect
        );
        return dataList[0].stages;
      }
      return dataList;
    },
    sectionList() {
      let dataList = [];
      if (this.stageSelect) {
        dataList = this.stageList.filter((item) => item.stageGuid === this.stageSelect);
        return dataList[0].sections;
      }
      return dataList;
    },
  },
  methods: {
    sendResultValues() {
      this.$emit("send-result-values", "carrier");
    },
    addStudent() {
      const data = {
        examGuid: this.editData.guid,
        subjectGuid: this.editData.subjectGuid,
        sectionGuid: this.editData.sectionGuid,
        studentGuids: this.studentsSelect.some(s => s.studentGuid) ? this.studentsSelect.map(a => a.studentGuid) : this.studentsSelect,
      };
        this.sendLoad = true;


      this.axios.put("Exams/AddStudents", data)
        .then((res) => {
            if (res.data.statusCode == 1) {
            this.sendResultValues();
          this.$swal.fire({
            title: this.$t('operationAccomplishedSuccessfully'),
            text: "",
            icon: "success",
            confirmButtonText: this.$t('close'),
             toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          } else if (res.data.statusCode == 3) {
            this.$swal.fire({
              title: this.$t("errors.ExamNotFound"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close")
            });
          } else {
            this.$swal.fire({
              title: this.$t("errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close")
            });
          }

          // console.log(res);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t('AnErrorOccurredDuringTheProcess'),
            text: "",
            icon: "error",
            confirmButtonText: this.$t('close'),
          });
        })
        .finally(() => {
          this.sendLoad = false;
        });
    },
    getExamStudent() {
      this.axios.get(`Exams/GetStudents?ExamGuid=${this.editData.guid}`).then((res) => {
        // console.log(res.data.data);
        this.studentsSelect = [];
        for (let i = 0; i < res.data.data.length; i++) {
          this.studentsSelect.push(res.data.data[i].studentGuid);
        }
        // console.log(this.studentsSelect);
      });
    },
    getStudent() {
      if (this.editData.sectionGuid && this.editData.subjectGuid) {
        this.isLoading = true;
        this.axios.get(
          `Students/Get?sectionGuid=${this.editData.sectionGuid}&subjectGuid=${this.editData.subjectGuid}`
        )
          .then((res) => {
            this.studentList = res.data.data;
            // console.log(this.studentList);
          })
          .catch(() => {})
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    getFieldText(item) {
      return `${item.stageName} - ${item.studyName}`;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.studentsSelect = [];
        } else {
          this.studentsSelect = this.studentList.slice();
        }
      });
    },
  },
  created() {
    this.getStudent();
    this.getExamStudent();
  },
};
</script>

<style></style>
