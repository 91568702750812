<template>
  <div>
    <v-dialog
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
      v-model="dialog"
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark>
          {{ $t("exam.addExam") }}
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-row class="pa-0 ma-0">
            <v-col cols="12" md="3" sm="12" class="py-0 ma-0">
              <v-autocomplete
                v-model="subjectSelect"
                :items="lectures"
                outlined
                :label="$t('degrees.selectSubject')"
                item-text="subjectName"
                item-value="subjectGuid"
                @change="clearSelect"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" sm="12" class="py-0 ma-0">
              <v-autocomplete
                v-model="deparmentSelect"
                :items="departmentList"
                outlined
                :label="$t('home.departmentChoese')"
                item-text="departmentName"
                item-value="departmentGuid"
                @change="clearSelect2"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" sm="12" class="py-0 ma-0">
              <v-autocomplete
                v-model="stageSelect"
                :items="stageList"
                outlined
                :label="$t('lecOne.selectStage')"
                :item-text="getFieldText"
                item-value="stageGuid"
                @change="clearSelect3"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" sm="12" class="py-0 ma-0">
              <v-autocomplete
                v-model="sectionSelect"
                :items="sectionList"
                outlined
                :label="$t('mail.selectSection')"
                item-text="sectionName"
                item-value="sectionGuid"
                @change="getStudent"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" md="12" sm="12" class="py-0 ma-0">
              <v-autocomplete
                v-model="studentsSelect"
                :items="studentList"
                outlined
                multiple
                :label="$t('mail.ChooseStudents')"
                item-text="studentFullName"
                item-value="studentGuid"
                clearable
                chips
                small-chips
                deletable-chips
                :loading="isLoading"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 3">
                    <span>{{ item.studentFullName }}</span>
                  </v-chip>
                  <span v-if="index === 3" class="grey--text caption">
                    (+{{ studentsSelect.length - 3 }} {{ $t("mail.Others") }})
                  </span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggle">
                    <v-list-item-action>
                      <v-icon
                        :color="studentsSelect.length > 0 ? 'primary' : ''"
                      >
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("mail.selectAll")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:append-item>
                  <v-divider class="mb-2"></v-divider>
                  <v-list-item disabled>
                    <v-list-item-avatar color="grey lighten-3">
                      <v-icon light> mdi-account-question-outline </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content v-if="likesAllFruit">
                      <v-list-item-title>
                        {{ $t("mail.AllHaveBeenSelected") }}</v-list-item-title
                      >
                    </v-list-item-content>

                    <v-list-item-content v-else-if="likesSomeFruit">
                      <v-list-item-title>
                        {{ $t("mail.TheNumberOfStudentsSpecified") }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ studentsSelect.length }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <v-list-item-title>
                        {{ $t("mail.NoStudentIdentified") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" md="12" sm="12" class="py-0 ma-0"> </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="dialog = false">
            {{ $t("close") }}</v-btn
          >
          <v-btn
            :loading="sendLoad"
            color="primary darken-1"
            @click="uploadData()"
            :disabled="
              !deparmentSelect ||
              !stageSelect ||
              !sectionSelect ||
              !studentsSelect[0]
            "
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      subjectSelect: null,
      lectures: [],
      sendLoad: false,
      dialog: false,
      deparmentSelect: null,
      stageSelect: null,
      sectionSelect: null,
      studentsSelect: [],
      studentList: [],
      groupGuid: "",
    };
  },
  computed: {
    likesAllFruit() {
      return this.studentsSelect.length === this.studentList.length;
    },
    likesSomeFruit() {
      return this.studentsSelect.length > 0 && !this.likesAllFruit;
    },
    icon() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    departmentList() {
      let dataList = [];
      if (this.subjectSelect) {
        dataList = this.lectures.filter(
          (item) => item.subjectGuid === this.subjectSelect
        );
        // console.log(dataList);
        return dataList[0].departments;
      }
      return dataList;
    },
    stageList() {
      let dataList = [];
      if (this.deparmentSelect) {
        dataList = this.departmentList.filter(
          (item) => item.departmentGuid === this.deparmentSelect
        );
        return dataList[0].stages;
      }
      return dataList;
    },
    sectionList() {
      let dataList = [];
      if (this.stageSelect) {
        dataList = this.stageList.filter(
          (item) => item.stageGuid === this.stageSelect
        );
        return dataList[0].sections;
      }
      return dataList;
    },
  },
  methods: {
    clearSelect() {
      this.sectionSelect = null;
      this.stageSelect = null;
      this.deparmentSelect = null;
      this.studentsSelect = [];
      this.studentList = [];
    },
    clearSelect2() {
      this.sectionSelect = null;
      this.stageSelect = null;
      this.studentsSelect = [];
      this.studentList = [];
    },
    clearSelect3() {
      this.sectionSelect = null;
      this.studentsSelect = [];
      this.studentList = [];
    },

    getLec() {
      this.axios
        .get("Subjects/Get")
        .then((res) => {
          if (this.isInRole(2)) {
            this.lectures = res.data.data.subjects;
          } else {
            this.lectures = res.data.data;
          }
        })
        .catch((err) => {});
    },
    getStudent() {
      if (this.sectionSelect && this.subjectSelect) {
        this.isLoading = true;
        this.axios
          .get(
            `ChatGroups/GetStudent?sectionGuid=${this.sectionSelect}&subjectGuid=${this.subjectSelect}&groupGuid=${this.groupGuid}`
          )
          .then((res) => {
            this.studentList = res.data.data;
             this.studentsSelect = [];
            // console.log(this.studentList);
          })
          .catch(() => {})
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    openInvite(groupGuid) {
      this.clear();
      this.groupGuid = groupGuid;
      this.dialog = true;
    },
    sendResultValues() {
      this.$emit("send-result-values", "invited student");
    },
    getFieldText(item) {
      return `${item.stageName} - ${item.studyName}`;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.studentsSelect = [];
        } else {
          this.studentsSelect = this.studentList.slice();
        }
      });
    },
    clear() {
      this.subjectSelect = null;
      this.sectionSelect = null;
      this.studentsSelect = [];
      this.deparmentSelect = null;
      this.stageSelect = null;
    },
    uploadData() {
      this.sendLoad = true;
      // if (this.likesAllFruit) {
      //   for (let i = 0; i < this.studentsSelect.length; i++) {
      //     this.studentsSelect[i] = this.studentsSelect[i].studentGuid;
      //   }
      // }

      this.axios
        .post("ChatGroups/InviteStudent", {
          GroupGuid: this.groupGuid,
          MemberGuids: this.studentsSelect.some(s => s.studentGuid) ? this.studentsSelect.map(a => a.studentGuid) : this.studentsSelect,
        })
        .then(() => {
          this.sendResultValues();
          this.dialog = false;
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          // console.log(res);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.sendLoad = false;
        });
    },
  },
  created() {
    this.getLec();
  },
};
</script>

<style></style>
