<template>
  <div class="mt-3">
    <v-btn
      fab
      dark
      fixed
      bottom
      large
      :left="$vuetify.rtl"
      :right="!$vuetify.rtl"
      color="primary"
      @click="scrollToTop()"
      v-if="showScrollTop"
    >
      <v-icon> mdi-arrow-up</v-icon>
    </v-btn>

    <v-card-actions v-if="examData.studentsDetails" class="pa-0 mb-1">
      <v-btn @click="getStatistics()" depressed color="">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-spacer></v-spacer>

      <div v-if="examData.studentsDetails">
        <v-chip outlined color="primary" class="ma-1" label>
          {{ $t("exam.StudentCount") }} :
          <v-card-title>
            {{ examData.studentsDetails.length }}
          </v-card-title>
        </v-chip>
        <v-chip
          outlined
          :color="
            getColorByDegree(
              parseFloat(
                (this.examData.examDetails.passCount /
                  this.examData.examDetails.totalCount) *
                  100
              )
            )
          "
          class="ma-1"
          label
        >
          {{ $t("exam.TheSuccessPercentage") }} :
          <v-card-title>
            {{
              parseFloat(
                (examData.examDetails.passCount /
                  examData.examDetails.totalCount) *
                  100
              ).toFixed(2)
            }}%
          </v-card-title>
        </v-chip>
        <v-chip outlined dark color="teal" class="ma-1" label>
          {{ $t("exam.EnterToExamStatus") }} :
          <v-card-title>
            {{
              examData.studentsDetails.filter((m) => m.enterDate != null).length
            }}
            /&nbsp;
            <span style="color: red">{{
              examData.studentsDetails.filter((m) => m.enterDate == null).length
            }}</span>
          </v-card-title>
        </v-chip>

        <v-chip outlined dark color="teal" class="ma-1" label>
          {{ $t("exam.EndExamStatus") }} :
          <v-card-title>
            {{
              examData.studentsDetails.filter((m) => m.endDate != null).length
            }}

            /&nbsp;
            <span style="color: red">
              {{
                examData.studentsDetails.filter((m) => m.endDate == null).length
              }}</span
            ></v-card-title
          ></v-chip
        >
      </div>
    </v-card-actions>

    <!-- <v-card flat>
      <v-card-title class="mx-0 pa-0">
        <v-alert
          color="primary lighten-1"
          width="100%"
          style="text-align: center"
          outlined
          dense
          text
          class="mt-2 ma-0 pa-0"
        >
          {{ $t("exam.ExamQuestions") }}</v-alert
        >
      </v-card-title>
    </v-card> -->

    <v-tabs v-model="tab"  center-active>
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#tab-1">
        <v-icon class="px-2">mdi-list-box-outline</v-icon>
        {{ $t("summary") }}
      </v-tab>

      <v-tab href="#tab-2">
        <v-icon class="px-2">mdi-calendar-expand-horizontal</v-icon>
        {{ $t("expanded") }}
      </v-tab>
      <v-tab href="#tab-3" v-if="examData.studentsDetails">
        <v-icon class="px-2">mdi-account-group</v-icon>
        {{ $t("exam.studentsDetails") }} ({{ examData.studentsDetails.length }})
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-card flat>
          <v-card-text>
            <v-row class="mb-0">
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="searchChart"
                  append-icon="mdi-magnify"
                  :label="$t('writeWhatYouSearch')"
                  outlined
                  dense
                  hide-details
                  ><template v-slot:append-outer>
                    <v-card-actions style="margin-top: -19px" class="pt-0">
                      <v-checkbox
                        hide-details
                        id="showAnswersChk"
                        v-model="showAnswers"
                        :label="$t('exam.DisplayTheStudentsAnswer')"
                      ></v-checkbox
                    ></v-card-actions>
                  </template>
                </v-text-field>
                <v-data-table
                  :headers="getHeaders"
                  :items="examData.questions"
                  :search="searchChart"
                  item-key="questionGuid"
                >
                  <template v-slot:[`item.questionType`]="{ item }">
                    {{
                      `${
                        item.questionType == "MultiChoice"
                          ? $t("exam.MultipleChoices")
                          : item.questionType == "OneChoice"
                          ? $t("exam.ChooseOne")
                          : item.questionType == "FillInTheBlanks"
                          ? $t("exam.FillInTheBlank")
                          : item.questionType == "Number"
                          ? $t("exam.FillInTheBlankNumericValue")
                          : item.questionType == "AttachedFile"
                          ? $t("exam.UploadAFile")
                          : ""
                      } `
                    }}
                  </template>
                  <template v-slot:[`item.answeredCount`]="{ item }">
                    <v-chip outlined color="primary" label class="mt-1">
                      <b> {{ item.answeredCount }}</b></v-chip
                    >
                  </template>
                  <template v-slot:[`item.correctAnswerd`]="{ item }">
                    <v-chip
                      outlined
                      label
                      :color="`${getColorByDegree(
                        item.answeredCount > 0
                          ? parseFloat(
                              (item.correctAnswerd / item.answeredCount) * 100
                            )
                          : 0
                      )}`"
                    >
                      <b>
                        {{ item.correctAnswerd }} -
                        {{
                          item.answeredCount > 0
                            ? parseFloat(
                                (item.correctAnswerd / item.answeredCount) * 100
                              ).toFixed(2)
                            : 0
                        }}%</b
                      >
                    </v-chip>
                  </template>
                  <template v-slot:[`item.anaswersChart`]="{ item }">
                    <v-list dense v-if="getData(item)" class="ma-0 pa-0">
                      <v-list-item
                        class="ma-0 pa-0"
                        link
                        style="min-height: 0px"
                        v-for="answer in getData(item)"
                        :key="answer.answerText"
                      >
                        <v-list-item-content class="py-0">
                          <v-list-item-title>
                            <v-chip
                              :text-color="answer.isCorrect ? '#41B883' : 'red'"
                              :color="answer.isCorrect ? '#41B883' : '#ffaba5'"
                              outlined
                              small
                              label
                              style="min-width: 50px"
                              class="mx-1 mt-1 justify-center"
                            >
                              <b>{{ answer.answersCount }}</b>
                            </v-chip>
                            {{ answer.answerText }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <v-row v-for="item in examData.questions" :key="item.questionGuid">
          <v-col cols="12" md="12">
            <v-card class="mx-auto" outlined :key="item.questionGuid">
              <v-card-text>
                <p
                  dir="auto"
                  style="white-space: pre-line !important"
                  class="title text--primary pt-2 pb-4 text-justify"
                >
                  {{ item.questionTitle.trim() }}
                </p>
                <p class="pt-1">
                  {{
                    `${
                      item.questionType == "MultiChoice"
                        ? $t("exam.MultipleChoices")
                        : item.questionType == "OneChoice"
                        ? $t("exam.ChooseOne")
                        : item.questionType == "FillInTheBlanks"
                        ? $t("exam.FillInTheBlank")
                        : item.questionType == "Number"
                        ? $t("exam.FillInTheBlankNumericValue")
                        : item.questionType == "AttachedFile"
                        ? $t("exam.UploadAFile")
                        : ""
                    } - ( ${$t("exam.TheDegreeOfTheQuestion")} :  ${
                      item.questionMark
                    } ) `
                  }}
                </p>
                <v-chip outlined color="primary" label class="mt-1">
                  {{ $t("exam.AnsweredQuestionsCount") }} :
                  <v-card-title> {{ item.answeredCount }}</v-card-title></v-chip
                >
                <span
                  v-if="
                    ['OneChoice', 'MultiChoice'].includes(item.questionType)
                  "
                >
                  <v-chip outlined dark color="teal" label class="mt-1 mx-2">
                    {{ $t("exam.NumberCorrectAnswers") }} :
                    <v-card-title>
                      {{ item.correctAnswerd }}</v-card-title
                    ></v-chip
                  >
                  <v-chip
                    outlined
                    dark
                    :color="
                      getColorByDegree(
                        item.answeredCount > 0
                          ? parseFloat(
                              (item.correctAnswerd / item.answeredCount) * 100
                            )
                          : 0
                      )
                    "
                    label
                    class="mt-1"
                  >
                    {{ $t("exam.PercentageCorrectAnswers") }} :
                    <v-card-title>
                      {{
                        item.answeredCount > 0
                          ? parseFloat(
                              (item.correctAnswerd / item.answeredCount) * 100
                            ).toFixed(2)
                          : 0
                      }}%</v-card-title
                    ></v-chip
                  >
                  <Bar :key="item.questionGuid"
                    v-if="
                      ['OneChoice', 'MultiChoice'].includes(item.questionType)
                    "
                    :datasets="getChartDataSets(item)"
                    :labels="getChartLabels(item)"
                    class="mb-3"
                  ></Bar>
                </span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="tab-3">
        <v-card flat>
          <v-card-text>
            <v-row class="mb-0">
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('writeWhatYouSearch')"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="examData.studentsDetails"
          :search="search"
          width="100%"
          item-key="studentGuid"
        >
          <template v-slot:[`item.enterDate`]="{ item }">
            <v-chip
              outlined
              dark
              :color="item.enterDate == null ? 'red' : 'green'"
              :title="
                item.enterDate == null
                  ? ''
                  : $moment(item.enterDate).format('YYYY/MM/DD - h:mm:ss A')
              "
            >
              {{
                item.enterDate == null
                  ? $t("exam.ExamNotEnter")
                  : $t("exam.ExamEnter")
              }}
            </v-chip>
          </template>
          <template v-slot:[`item.endDate`]="{ item }">
            <v-chip
              outlined
              dark
              :color="item.endDate == null ? 'red' : 'green'"
              :title="
                item.endDate == null
                  ? ''
                  : $moment(item.endDate).format('YYYY/MM/DD - h:mm:ss A')
              "
            >
              {{
                item.endDate == null
                  ? $t("exam.ExamNotFinish")
                  : $t("exam.ExamFinish")
              }}
            </v-chip>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Pie from "../charts/Pie.vue";
import Bar from "../charts/Bar.vue";
import QuestionTitle from "./questionTitle.vue";

export default {
  components: {
    Pie,
    Bar,
    QuestionTitle,
  },
  props: ["examGuid"],
  data() {
    return {
      showAnswers: false,
      tab: null,
      showScrollTop: false,
      examData: {},
      search: "",
      searchChart: "",
      headersChart: [
        {
          text: this.$t("exam.theQuestion"),
          value: "questionTitle",
          show: true,
        },
        {
          text: this.$t("exam.TypeOfQuestion"),
          value: "questionType",
          show: true,
        },
        {
          text: this.$t("exam.questionsAnsweredCount"),
          value: "answeredCount",
          show: true,
        },
        {
          text: this.$t("exam.NumberCorrectAnswers"),
          value: "correctAnswerd",
          show: true,
        },
        {
          text: this.$t("options"),
          value: "anaswersChart",
          show: true,
        },
        {
          text: this.$t("exam.TheDegreeOfTheQuestion"),
          value: "questionMark",
          show: true,
        },
      ],
      headers: [
        {
          text: this.$t("name"),
          value: "studentName",
          show: true,
        },
        {
          text: this.$t("degrees.theDegree"),
          value: "degree",
          show: true,
        },
        {
          text: this.$t("exam.questionsCount"),
          value: "questionsCount",
          show: true,
        },
        {
          text: this.$t("exam.questionsAnsweredCount"),
          value: "questionsAnsweredCount",
          show: true,
        },
        {
          text: this.$t("exam.questionsNotAnsweredCount"),
          value: "questionsNotAnsweredCount",
          show: true,
        },
        {
          text: this.$t("exam.enterDate"),
          value: "enterDate",
          show: true,
        },
        {
          text: this.$t("exam.endDate"),
          value: "endDate",
          show: true,
        },
      ],
    };
  },
  computed: {
    getHeaders() {
      if (this.showAnswers) {
        return this.headersChart.filter((m) => m.show);
      } else {
 return this.headersChart.filter(
          (m) => m.show && m.value != "anaswersChart"
        );
}
    },
  },
  methods: {
    getStatistics() {
      this.axios
        .get(`/Exams/Statistics?examGuid=${this.examGuid}`)
        .then((response) => {
          this.examData = response.data.data;
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    getData(item) {
      const question = this.examData.questionsDetails.filter(
        (m) => m.questionGuid == item.questionGuid
      );
      return question;
    },
    getChartDataSets(item) {
      const question = this.getData(item);

      const data = question.map((m) => m.answersCount);
      // var data2 = this.examData.questionsDetails
      //   .filter((m) => m.questionGuid == item.questionGuid)
      //   .map((m) => m.answerText);

      // var colors = data2.map((m) => this.stringToHslColor(m, 80, 50));
      // ["#41B883", "#E46651", "#00D8FF", "#DD1B16"],

      const datasetes = [
        {
          label: "",
          backgroundColor: function (context) {
            if (
              context.dataset.orgenalData[context.index] &&
              context.dataset.orgenalData[context.index].isCorrect
            ) {
              return "#41B883";
            } else {
              return ["#E46651", "#00D8FF", "#DD1B16"];
            }
          },
          borderWidth: 2,
          data: data,
          orgenalData: question,
          allZero: data.reduce((p, a) => p + a, 0) === 0,
          thereIsNoAnswer: this.$t("exam.ThereIsNoAnswer"),
        },
      ];
      return datasetes;
    },
    getChartLabels(item) {
      return this.examData.questionsDetails
        .filter((m) => m.questionGuid == item.questionGuid)
        .map((m) => m.answerText);
    },

    getColorByDegree(val) {
      if (val < 50) {
        return "red";
      }
      if (val >= 50 && val <= 70) {
        return "#ffc107";
      }
      if (val > 70) {
        return "green";
      }
    },

    stringToHslColor(str, s, l) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      const h = hash % 360;
      return `hsl(${h}, ${s}%, ${l}%)`;
    },
    handleScroll(event) {
      if (window.scrollY > 100) {
        this.showScrollTop = true;
      } else {
        this.showScrollTop = false;
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    this.getStatistics();
  },
};
</script>
<style >
label[for="showAnswersChk"] {
  position: unset !important;
}
</style>