import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "@/i18n";

Vue.use(Vuetify);

const theme = {
    primary2: "#0C65AF",
    primary: "#109afd",
    secondary: "#EC407A",
    accent: "#9C27b0",
    info: "#00CAE3"
};

export default new Vuetify({
    rtl: localStorage.getItem("lang") ? (localStorage.getItem("lang") === "ar") : true,
    lang: {
        t: (key, ...params) => i18n.t(key, params)
    },
    theme: {
        dark: localStorage.getItem("darkMode") ?
            localStorage.getItem("darkMode") === "true" : false,
        themes: {
            dark: theme,
            light: theme
        }
    }
});