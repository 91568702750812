<template>
  <v-dialog transition="dialog-bottom-transition" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-title>{{ $t("portfolio.Achievements.description") }}</v-list-item-title>
        <v-list-item-icon>
          <v-icon>mdi-eye</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </template>
    <template v-slot:default="dialog">
      <v-card >
        <v-toolbar flat color="primary" dark>{{
          $t("portfolio.Achievements.description")
        }}
        <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog.value = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <article v-html="description" class="pa-12"></article>
        </v-card-text>
        <v-card-actions class="justify-end ">
          <v-btn color="primary"  @click="dialog.value = false">{{ $t("close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: ["description"]
};
</script>

<style></style>
