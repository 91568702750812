var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-0",attrs:{"width":_vm.width,"outlined":""}},[(_vm.showDownloadCount)?_c('div',{style:(_vm.$vuetify.rtl
        ? 'position: absolute; top: 0; left: 0; z-index: 9999'
        : 'position: absolute; top: 0; right: 0; z-index: 9999'),attrs:{"title":_vm.$t('lecOne.DownloadCount')}},[_c('v-chip',{staticClass:"ma-1 px-2",staticStyle:{"opacity":"0.7"},attrs:{"label":"","small":""}},[_vm._v(_vm._s(_vm.downloadCount)),_c('v-icon',{staticClass:"pa-0",attrs:{"small":""}},[_vm._v("mdi-arrow-down-bold")])],1)],1):_vm._e(),(_vm.canPreview())?_c('v-card-text',{staticClass:"ma-0 pa-1"},[_c('v-img',{staticClass:"image-pointer",staticStyle:{"margin":"auto"},attrs:{"src":_vm.srcUrlThumb,"height":"88px","alt":_vm.get_file_caption(_vm.fileUrl),"lazy-src":require("../assets/loadImg.svg"),"title":_vm.get_file_caption(_vm.fileUrl)},on:{"click":function($event){_vm.getUrl(_vm.fileUrl, false);
        _vm.viewImagesFullScreen = true;}}})],1):_c('v-list-item',{staticClass:"ma-0 pa-1",attrs:{"three-line":""}},[_c('files-icon',{staticClass:"py-3",attrs:{"fileType":_vm.get_url_extension(_vm.fileUrl),"width":_vm.iconWidth,"showBadge":false}}),(!_vm.canPreview())?_c('v-list-item-content',{staticClass:"px-2"},[(_vm.showTitle && _vm.showFileCaption && _vm.get_file_caption(_vm.fileUrl))?_c('div',{staticClass:"mb-0 text-wrap",attrs:{"title":_vm.fileFullName}},[_vm._v("\n        "+_vm._s(_vm.get_file_caption(_vm.fileUrl))+"\n      ")]):(_vm.showTitle)?_c('div',{staticClass:"mb-0 text-wrap",attrs:{"title":_vm.fileFullName}},[_vm._v("\n        "+_vm._s(_vm.get_file_name_from_url(_vm.fileUrl))+"\n      ")]):_vm._e(),_c('v-list-item-subtitle',[_vm._v("\n        "+_vm._s(_vm.get_url_extension(_vm.fileUrl).toUpperCase())+"\n        "),(_vm.fileSizeFormat)?_c('span',[_vm._v("("+_vm._s(_vm.fileSizeFormat)+")")]):_vm._e()])],1):_vm._e()],1),(_vm.showFileCaption)?_c('v-card-subtitle',{staticClass:"ma-0 pa-1",style:(_vm.fileCaption ? '' : 'opacity: 0;')},[_vm._v(_vm._s(_vm.get_file_caption(_vm.fileUrl)))]):_vm._e(),_c('v-card-actions',[_c('v-btn',{staticClass:"px-3",attrs:{"title":_vm.$t('Download'),"outlined":"","rounded":"","text":""},on:{"click":function($event){return _vm.downLoadFile(_vm.fileUrl)}}},[_vm._v("\n      "+_vm._s(_vm.$t("Download"))+"\n      "),_c('v-icon',[_vm._v("mdi-download")])],1),(
        ['mp4', 'mp3', 'wav', 'webm'].includes(_vm.get_url_extension(_vm.fileUrl))
      )?_c('v-btn',{staticClass:"px-3",attrs:{"icon":"","outlined":"","rounded":"","text":"","title":_vm.$t('WatchVideo')},on:{"click":function($event){return _vm.showVideo()}}},[_c('v-icon',{attrs:{"l":""}},[_vm._v("mdi-play-circle")])],1):_vm._e(),_c('v-spacer'),(_vm.item && _vm.showDeleteBtn)?_c('v-btn',{attrs:{"title":_vm.$t('delete'),"rounded":"","color":"red","icon":""},on:{"click":function($event){return _vm.onDeleteItem(_vm.item)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-close")])],1):_vm._e()],1),(
      _vm.dialog &&
      ['mp4', 'mp3', 'wav', 'webm'].includes(_vm.get_url_extension(_vm.fileUrl))
    )?_c('v-dialog',{attrs:{"scrim":false,"transition":"dialog-bottom-transition","max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","elevation":"0","color":"primary"}},[_c('v-btn',{attrs:{"text":"","dark":"","title":_vm.$t('close'),"small":"","fab":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-0"},[_c('video',{staticStyle:{"padding":"5px"},attrs:{"controls":true,"width":"100%","height":['mp3', 'wav'].includes(_vm.get_url_extension(_vm.fileUrl))
              ? '80px'
              : '500px',"src":_vm.srcUrl}})])],1)],1):_vm._e(),(_vm.canPreview())?_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.viewImagesFullScreen),callback:function ($$v) {_vm.viewImagesFullScreen=$$v},expression:"viewImagesFullScreen"}},[_c('v-card',[_c('v-toolbar',{staticClass:"mb-3",attrs:{"dark":"","color":"primary","dense":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("Preview"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.viewImagesFullScreen = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-img',{staticClass:"pa-4",style:({ maxHeight: '88vh' }),attrs:{"src":_vm.srcUrl,"contain":""}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }