<template>
  <v-container>
    <v-row v-if="questionInfoFun">
      <v-col cols="12" md="12">
        <v-card class="mx-auto" flat outlined>
          <v-card-text>
            <question-title :examQuestion="questionInfo"></question-title>

            <!-- MultiChoice MultiChoice MultiChoice MultiChoice MultiChoice MultiChoice MultiChoice MultiChoice  -->
            <div
              class="text--primary"
              v-if="questionInfo.questionType === 'MultiChoice'"
            >
              <v-alert color="primary" text>
                <div>
                  <v-alert
                    v-for="multequestionInfo in questionInfo.questionAnswers"
                    :key="multequestionInfo.answerGuid"
                    class="pa-1 ma-1"
                    :color="getColor(multequestionInfo.isCorrect)"
                    outlined
                    text
                  >
                    <v-checkbox
                      class="py-0 my-0"
                      hide-details
                      :readonly="
                        (ShowCorrectAnswerAfterSubmit &&
                          questionInfo.studentAnswers.length > 0) ||
                        !isInRole(0)
                      "
                      v-model="multipleAnswers"
                      :label="multequestionInfo.questionAnswer"
                      :value="multequestionInfo.questionAnswer"
                      :color="multequestionInfo.isCorrect ? 'green' : ''"
                    >
                    </v-checkbox>
                  </v-alert>
                </div>
              </v-alert>
            </div>
            <!-- OneChoice OneChoice OneChoice OneChoice OneChoice OneChoice OneChoice OneChoice OneChoice  -->
            <div
              class="text--primary"
              v-if="questionInfo.questionType === 'OneChoice'"
            >
              <v-alert color="primary" text>
                <v-radio-group
                  v-model="oneAnswers"
                  :readonly="
                    (ShowCorrectAnswerAfterSubmit &&
                      questionInfo.studentAnswers.length > 0) ||
                    !isInRole(0)
                  "
                >
                  <!-- <div v-if="!ShowCorrectAnswerAfterSubmit || questionInfo.studentAnswers.length==0">
                    <v-radio
                      :disabled="!isInRole(0)"
                      v-for="oneChoesquestionInfo in questionInfo.questionAnswers"
                      :key="oneChoesquestionInfo.answerGuid"
                      :label="oneChoesquestionInfo.questionAnswer"
                      :value="oneChoesquestionInfo.questionAnswer"
                    >
                    </v-radio>
                  </div> -->

                  <div>
                    <v-alert
                      v-for="oneChoesquestionInfo in questionInfo.questionAnswers"
                      :key="oneChoesquestionInfo.answerGuid"
                      class="pa-1 ma-1"
                      :color="getColor(oneChoesquestionInfo.isCorrect)"
                      outlined
                      text
                    >
                      <v-radio
                        :key="oneChoesquestionInfo.answerGuid"
                        :label="oneChoesquestionInfo.questionAnswer"
                        :value="oneChoesquestionInfo.questionAnswer"
                      >
                      </v-radio>
                    </v-alert>
                  </div>
                </v-radio-group>
              </v-alert>
            </div>
            <!-- FillInTheBlanks FillInTheBlanks FillInTheBlanks FillInTheBlanks FillInTheBlanks FillInTheBlanks -->
            <div
              class="text--primary"
              v-if="questionInfo.questionType === 'FillInTheBlanks'"
            >
              <v-alert color="primary" text class="py-8">
                <v-textarea
                  hide-details
                  :readonly="
                    (ShowCorrectAnswerAfterSubmit &&
                      questionInfo.studentAnswers.length > 0) ||
                    !isInRole(0)
                  "
                  v-model="fillInTheBlank"
                  outlined
                  name="input-7-4"
                  :label="$t('exam.TypeYourAnswerHere')"
                  :placeholder="$t('exam.TypeYourAnswerHere')"
                ></v-textarea>
              </v-alert>
              <v-alert
                color="success"
                text
                class="pa-1 ma-0 mb-2 my-2"
                v-if="
                  isInRole(2) ||
                  (ShowCorrectAnswerAfterSubmit &&
                    questionInfo.studentAnswers &&
                    questionInfo.studentAnswers.length > 0)
                "
              >
                <v-card-subtitle class="ma-0 pa-0 px-2">
                  {{ $t("exam.TheCorrectAnswer") }} :
                </v-card-subtitle>
                <v-card-text
                  v-if="
                    questionInfo.questionAnswers &&
                    questionInfo.questionAnswers.length > 0
                  "
                  class="mt-2"
                  :style="$vuetify.theme.dark ? 'color:white' : 'color:black'"
                >
                  {{ questionInfo.questionAnswers[0].questionAnswer }}
                </v-card-text>
              </v-alert>
            </div>
            <!-- Number Number Number Number Number Number Number Number Number Number Number Number Number Number -->
            <div
              class="text--primary"
              v-if="questionInfo.questionType === 'Number'"
            >
              <v-alert class="pb-0 pt-8" color="primary" text>
                <v-text-field
                  :readonly="
                    (ShowCorrectAnswerAfterSubmit &&
                      questionInfo.studentAnswers.length > 0) ||
                    !isInRole(0)
                  "
                  v-model="fillInTheBlank"
                  outlined
                  name="input-7-4"
                  :label="$t('exam.TypeYourAnswerHere')"
                  :placeholder="$t('exam.TheAnswerShouldBeANumber')"
                  type="number"
                ></v-text-field>
              </v-alert>
              <v-alert
                color="success"
                text
                class="pa-1 ma-0 mb-2 my-2"
                v-if="
                  isInRole(2) ||
                  (ShowCorrectAnswerAfterSubmit &&
                    questionInfo.studentAnswers &&
                    questionInfo.studentAnswers.length > 0)
                "
              >
                <v-card-subtitle class="ma-0 pa-0 px-2">
                  {{ $t("exam.TheCorrectAnswer") }} :
                </v-card-subtitle>
                <v-card-text
                  v-if="
                    questionInfo.questionAnswers &&
                    questionInfo.questionAnswers.length > 0
                  "
                  class="mt-2"
                  :style="$vuetify.theme.dark ? 'color:white' : 'color:black'"
                >
                  {{ questionInfo.questionAnswers[0].questionAnswer }}
                </v-card-text>
              </v-alert>
            </div>
            <!-- AttachedFile AttachedFile AttachedFile AttachedFile AttachedFile AttachedFile AttachedFile AttachedFile  -->
            <div
              class="text--primary"
              v-if="questionInfo.questionType === 'AttachedFile'"
            >
              <div
                class="v-alert v-sheet theme--dark v-alert--text primary--text pa-2"
                text
              >
                <DxFileUploader
                  :allowed-file-extensions="allowedFileExtensions"
                  :selectButtonText="$t('lecOne.ChooseTheFile')"
                  :labelText="$t('lecOne.ChooseTheFileYouWantToUpload')"
                  :multiple="true"
                  :chunk-size="5000000"
                  :max-file-size="209715200"
                  name="FileUpload"
                  ref="FileUpload"
                  :accept="allowedFileExtensions.join(',')"
                  uploadMode="instantly"
                  uploadMethod="POST"
                  :uploadHeaders="headers"
                  :upload-url="`${UploaderUrl}ExamAnswer`"
                  @upload-started="() => (chunks = [])"
                  @progress="onUploadProgress($event)"
                  @uploaded="onUploaded($event)"
                  @upload-error="onUploadError"
                  :disabled="!isInRole(0)"
                />
                <v-alert width="100%" dense text type="info" color="primary" >
                  <span class="note"
                    >{{ $t("lecOne.AllowedFiles") }} :
                    <span
                      >jpg, jpeg, gif, png, bmp, mp4, wmv, flv, avi, webm, weba,
                      wav, mp3, aac, pdf, doc, docx, xls, xlsx, ppt, pptx, txt,
                      zip, rar</span
                    >.</span
                  >
                  <div class="note">
                    {{ $t("lecOne.MaximumFileSize") }} : <span>200MB</span>
                  </div>
                </v-alert>

                <v-slide-group
                  multiple
                  show-arrows
                  class="py-2"
                  mandatory
                  v-if="
                    questionInfo.studentAnswers &&
                    questionInfo.studentAnswers.length > 0
                  "
                >
                  <v-slide-item
                    v-for="(item, index) in questionInfo.studentAnswers"
                    :key="index"
                    class="mx-1"
                    v-slot="{}"
                    :value="item"
                  >
                    <download-file
                      :iconWidth="50"
                      :width="250"
                      v-if="item && item.answerText"
                      :fileUrl="item.answerText"
                      :key="index"
                      :showDeleteBtn="
                        isInRole(0) && !ShowCorrectAnswerAfterSubmit
                      "
                      :deleteItem="deleteAttachedItem"
                      :item="item"
                    >
                    </download-file>
                  </v-slide-item>
                </v-slide-group>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="mx-2 mb-3">
            <v-btn
              v-if="isInRole(0)"
              :disabled="
                ShowCorrectAnswerAfterSubmit &&
                questionInfo.studentAnswers.length > 0
              "
              :loading="loading"
              class="primary"
              @click="saveQusetionAnser(questionInfo)"
            >
              {{ $t("save") }}
              <v-icon class="px-1"> mdi-content-save </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              @click="changeFlagStatus()"
              :title="!flagStatus ? this.$t('exam.flagQuestionHint') : this.$t('exam.removeTheFlag')"
              icon
              elevation="1"
              v-if="isInRole(0)"
            >
              <v-icon :key="flagStatus"
                :color="!flagStatus ? 'grey' : 'red'"
                >{{ !flagStatus ? "mdi-flag-outline" : "mdi-flag" }}</v-icon
              >
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DxFileUploader from "devextreme-vue/file-uploader";
import { mapState } from "vuex";
import FilesIcon from "../filesIcon.vue";
import DownloadFile from "../downloadFile.vue";
import QuestionTitle from "./questionTitle.vue";

export default {
  components: {
    DxFileUploader,
    FilesIcon,
    DownloadFile,
    QuestionTitle,
  },
  props: ["IsEnd", "ExamGuid", "Question", "ShowCorrectAnswerAfterSubmit"],
  data() {
    return {
      flagStatus: 0,
      isChanged: false,
      isEnd: null,
      loading: false,
      examGuid: null,
      oneAnswers: null,
      multipleAnswers: [],
      fillInTheBlank: null,
      questionInfo: null,
      overlay: false,
      fileURL: [],
      sendLoad: false,
      // uploadURL: process.env.VUE_APP_UPLOAD,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      chunks: [],
      allowedFileExtensions: [
        ".jpg",
        ".jpeg",
        ".gif",
        ".png",
        ".bmp",
        ".mp4",
        ".wmv",
        ".flv",
        ".avi",
        ".webm",
        ".weba",
        ".wav",
        ".mp3",
        ".aac",
        ".pdf",
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
        ".ppt",
        ".pptx",
        ".txt",
        ".zip",
        ".rar",
      ],
    };
  },
  computed: {
    questionInfoFun() {
      return this.questionInfo != null;
    },

    ...mapState(["UploaderUrl"]),
  },
  created() {
    this.setValue(this.Question, this.ExamGuid, this.IsEnd);
    this.getFlagStatus();
  },
  watch: {
    questionInfo() {
      if (this.$refs.FileUpload) {
        this.$refs.FileUpload.instance.reset();
      }
    },
  },
  methods: {
    restVar() {
      this.oneAnswers = null;
      this.fillInTheBlank = null;
      this.fileURL = [];
      this.chunks = [];
    },

    saveQusetionAnser(item) {
      if (!this.isInRole(0)) {
        return;
      }

      let dateSend = null;
      if (item.questionType == "MultiChoice") {
        if (!item.questionAnswers || item.questionAnswers.length == 0) {
          this.$swal.fire({
            title: this.$t("exam.TheAnswerMustBeEntered"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
          return;
        }

        item.studentAnswers = this.multipleAnswers;
        dateSend = item.studentAnswers;
      } else if (item.questionType == "OneChoice") {
        dateSend = [this.oneAnswers];
      } else if (item.questionType == "FillInTheBlanks") {
        dateSend = [this.fillInTheBlank];
      } else if (item.questionType == "Number") {
        dateSend = [this.fillInTheBlank];
      } else if (item.questionType == "AttachedFile") {
        if (!this.fileURL || this.fileURL.length == 0) {
          this.$swal.fire({
            title: this.$t("exam.TheAnswerMustBeEntered"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
          return;
        }

        item.studentAnswers = [];
        for (let i = 0; i < this.fileURL.length; i++) {
          item.studentAnswers.push(
            this.fileURL[i] ? this.fileURL[i].data.fileName : null
          );
        }
        dateSend = item.studentAnswers;

        // dateSend = [this.fileURL ? this.fileURL.data.fileName : null];
      }

      if (dateSend[0]) {
        const dataSave = {
          examGuid: this.examGuid,
          questionGuid: this.questionInfo.questionGuid,
          answer: dateSend,
        };
        // console.log(dataSave);
        this.loading = true;
        this.axios
          .post("/Exams/AnswerSave", dataSave)
          .then((res) => {
            if (res.data.statusCode == 1) {
              this.$swal.fire({
                title: this.$t("operationAccomplishedSuccessfully"),
                text: "",
                icon: "success",
                confirmButtonText: this.$t("close"),
                toast: true,
                position: "bottom-start",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });

              if (this.ShowCorrectAnswerAfterSubmit) {
                this.questionInfo.questionAnswers = res.data.data.answers;
              }
              this.sendResultValues(dataSave, this.fileURL);
            } else if (res.data.statusCode == 2) {
              this.$swal.fire({
                title: this.$t("errors.TheExamIsNotActive"),
                text: "",
                icon: "error",
                confirmButtonText: this.$t("close"),
              });
            } else if (res.data.statusCode == 5) {
              this.$swal.fire({
                title: this.$t("exam.IncorrectPassword"),
                text: "",
                icon: "error",
                confirmButtonText: this.$t("close"),
              });
            } else if (res.data.statusCode == 6) {
              this.sendResultValues(dataSave, this.fileURL);
            } else {
              this.$swal.fire({
                title: this.$t(
                  "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
                ),
                text: "",
                icon: "error",
                confirmButtonText: this.$t("close"),
              });
            }

            // console.log(res);
          })
          .catch(() => {
            this.$swal.fire({
              title: this.$t("AnErrorOccurredDuringTheProcess"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$swal.fire({
          title: this.$t("exam.TheAnswerMustBeEntered"),
          text: "",
          icon: "error",
          confirmButtonText: this.$t("close"),
        });
      }
    },
    onUploaded(e) {
      // this.fileURL = [];
      this.fileURL.push(JSON.parse(e.request.responseText));

      //   console.log(e);
    },
    onUploadError(e) {
      this.$swal.fire({
        title: this.$t("AnErrorOccurredDuringTheProcess"),
        text: e.request.responseText,
        icon: "error",
        confirmButtonText: this.$t("close"),
      });
    },
    getValueInKb(value) {
      return `${(value / 1024).toFixed(0)}kb`;
    },
    onUploadProgress(e) {
      // console.log(e);
      this.chunks.push({
        segmentSize: e.segmentSize,
        bytesLoaded: e.bytesLoaded,
        bytesTotal: e.bytesTotal,
      });
    },
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim().toLowerCase();
    },
    downloadStudentFile(item) {
      window.open(`${item.studentAnswers[0].answerText}`);
      return;
      this.axios({
        url: `${item.studentAnswers[0].answerText}`,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.data.type,
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = item.studentAnswers[0].answerText;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("TheFileDoesNotExist"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        });
    },
    downloadFileAttachment(item) {
      window.open(`${item.fileUrl}`);
      return;

      this.axios({
        url: `${item.fileUrl}`,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.data.type,
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = item.question;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("TheFileDoesNotExist"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        });
    },
    sendResultValues(studentAnswer, urls) {
      if (urls !== null && urls.length > 0) {
        // console.log(fileUrl2);

        studentAnswer.answer = [];
        for (let i = 0; i < urls.length; i++) {
          const url = urls[i].data.fileUrl;
          // console.log(url);
          studentAnswer.answer.push(url);
        }
      }
      // console.log(studentAnswer);
      this.$emit("send-result-values", studentAnswer);
    },
    get_file_name_from_url(url) {
      // console.log(url);
      try {
        const matches = url.match(/\/([^\/?#]+)[^\/]*$/);
        if (matches && matches.length > 1) {
          this.fileFullName = matches[1];
          if (matches[1].length > this.fileNameLength) {
            return matches[1].substr(0, this.fileNameLength - 1);
          }
          return matches[1];
        }
      } catch {}

      return null;
    },
    deleteAttachedItem(item) {
      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            this.questionInfo.studentAnswers =
              this.questionInfo.studentAnswers.filter(
                (m) => m.answerText != item.answerText
              );
            this.fileURL = this.fileURL.filter(
              (m) => m.data.fileUrl != item.answerText
            );
          }
        });
    },
    setValue(questionInfo, id, isEnd) {
      this.fileURL = [];
      this.examGuid = id;
      this.isEnd = isEnd;
      this.restVar();

      if (
        questionInfo &&
        questionInfo.studentAnswers &&
        questionInfo.studentAnswers.length > 0 &&
        questionInfo.studentAnswers[0]
      ) {
        if (questionInfo.questionType == "MultiChoice") {
          this.multipleAnswers = questionInfo.studentAnswers.map(
            (m) => m.answerText
          );
        } else if (questionInfo.questionType == "OneChoice") {
          this.oneAnswers = questionInfo.studentAnswers[0].answerText;
        } else if (questionInfo.questionType == "FillInTheBlanks") {
          this.fillInTheBlank = questionInfo.studentAnswers[0].answerText;
        } else if (questionInfo.questionType == "Number") {
          this.fillInTheBlank = questionInfo.studentAnswers[0].answerText;
        } else if (questionInfo.questionType == "AttachedFile") {
          for (let i = 0; i < questionInfo.studentAnswers.length; i++) {
            if (questionInfo.studentAnswers[i].answerText == null) {
              continue;
            }
            const fileName = this.get_file_name_from_url(
              questionInfo.studentAnswers[i].answerText
            );
            if (fileName == null) {
              continue;
            }
            const attached = {
              data: {
                fileName: fileName,
                fileUrl: questionInfo.studentAnswers[i].answerText,
              },
            };
            this.fileURL.push(attached);
          }
        }
      }
      this.questionInfo = questionInfo;
    },
    getColor(value) {
      if (value == null) {
        return "transparent";
      } else if (value == true) {
        return "green lighten-3";
      } else return "pink lighten-3";
    },
    changeFlagStatus() {
      const flag = this.getFlagStatus();
      const key = `questionFlag_${this.examGuid}_${this.questionInfo.questionGuid}`;
      if (!flag) {
        localStorage.setItem(key, 1);
        this.flagStatus = 1;
      } else {
        localStorage.setItem(key, 0);
        this.flagStatus = 0;
      }
      this.onFlagChanged();
    },
    onFlagChanged() {
      this.$emit('on-flag-changed', true);
    },

    getFlagStatus() {
      const key = `questionFlag_${this.examGuid}_${this.questionInfo.questionGuid}`;

      const value = localStorage.getItem(key);
      this.flagStatus = parseInt(value);
      return this.flagStatus;
    },
  },
  mounted() {},
};
</script>
<style lang="css" scope>
/* .v-input--selection-controls{
  padding: 0 !important;
  margin: 0 !important;
} */
.v-application .title {
  font-family: inherit !important;
}
</style>
<style lang="scss" >
.v-slide-group__next,
.v-slide-group__prev {
  min-width: 12px !important;
  flex: 0 1 15px !important;
}
.select-all-cards .v-input__control {
  height: 25px;
}
</style>