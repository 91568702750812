<template>
  <v-container class="pt-4">
    <v-row v-if="!overlay">
      <v-col cols="12" md="12">
        <v-btn
          :disabled="isActive"
          block
          color="primary"
          @click="autoCorecte"
          :loading="loadingData"
          >{{ $t("exam.AutomaticCorrectionOfAnswers") }}
          <v-icon class="px-2">mdi-auto-fix</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="!overlay">
      <v-col cols="12" md="12">
        <v-card class="d-flex align-center rounded-lg" flat>
          <v-card-text>
            <v-row class="mb-0">
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('writeWhatYouSearch')"
                  outlined
                  dense
                  hide-details
                >
                  <template v-slot:append-outer>
                    <v-card-actions style="margin-top: -6px" class="pt-0">
                      <v-btn @click="getStudentsDegree()">
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                      <v-btn color="primary" @click="exportToXlsx">
                        {{ $t("Export") }}
                        <v-icon class="px-2">mdi-microsoft-excel</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="studentsDegree"
              :search="search"
              width="100%"
              :loading="loadingData"
              item-key="studentGuid"
              single-select
              @click:row="function (item, row) {row.select(true); }
              "
            >
              <template v-slot:[`item.studentStatus`]="{ item }">
                <v-card-actions>
                  <v-btn
                    :disabled="!item.studentStatus"
                    color="teal"
                    @click="openDialog(item)"
                    text
                  >
                    {{
                      item.studentStatus
                        ? $t("exam.DisplayTheStudentsAnswer")
                        : $t("exam.ThereIsNoAnswer")
                    }}
                    <v-icon class="px-2">
                      {{
                        item.studentStatus
                          ? "mdi-file-eye-outline"
                          : "mdi-eye-off-outline"
                      }}
                    </v-icon>
                  </v-btn>
                  <v-btn
                    @click="ResetEnteredPassword(item.studentGuid)"
                    :title="$t('exam.ReEntryExam')"
                    class="ma-0"
                    large
                    icon
                    color="orange"
                    v-if="item.isPasswordEntered"
                  >
                    <v-icon>mdi-lock-reset</v-icon>
                  </v-btn>
                </v-card-actions>
              </template>
              <template v-slot:[`item.enterDate`]="{ item }">
                <v-chip
                  outlined
                  dark
                  :color="item.enterDate == null ? 'red' : 'green'"
                  :title="
                    item.enterDate == null
                      ? ''
                      : $moment(item.enterDate).format('YYYY/MM/DD - h:mm:ss A')
                  "
                >
                  {{
                    item.enterDate == null
                      ? $t("exam.ExamNotEnter")
                      : $t("exam.ExamEnter")
                  }}
                </v-chip>
              </template>
              <template v-slot:[`item.endDate`]="{ item }">
                <v-chip
                  outlined
                  dark
                  :color="item.endDate == null ? 'red' : 'green'"
                  :title="
                    item.endDate == null
                      ? ''
                      : $moment(item.endDate).format('YYYY/MM/DD - h:mm:ss A')
                  "
                >
                  {{
                    item.endDate == null
                      ? $t("exam.ExamNotFinish")
                      : $t("exam.ExamFinish")
                  }}
                </v-chip>
              </template>

              <template slot="body.append">
                <tr
                  :style="
                    $vuetify.theme.dark
                      ? 'background-color:#293842'
                      : 'background-color:#e6f5ff'
                  "
                >
                  <td class="text-center font-weight-bold">
                    {{ $t("exam.StudentCount") }} = {{ StudentCount }}
                  </td>
                  <td class="text-center font-weight-bold"></td>
                  <td class="text-center font-weight-bold"></td>
                  <td class="text-center font-weight-bold"></td>
                  <td class="text-center font-weight-bold"></td>

                  <td class="font-weight-bold">
                    {{ $t("exam.ExamEnter") }} = {{ StudentEnteredCount }}
                    <br />
                    {{ $t("exam.ExamNotEnter") }} = {{ StudentNotEnteredCount }}
                  </td>
                  <td class="font-weight-bold">
                    {{ $t("exam.ExamFinish") }} = {{ StudentFinishCount }}
                    <br />
                    {{ $t("exam.ExamNotFinish") }} = {{ StudentNotFinishCount }}
                  </td>
                  <td class="text-center font-weight-bold"></td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <CheckExamQuestion
        ref="childComponent"
        v-on:send-result-values="getStudentsDegree"
      ></CheckExamQuestion>
    </v-row>
  </v-container>
</template>

<script>
import CheckExamQuestion from "./checkExamQuestion.vue";

export default {
  props: ["examGuid", "isActive", "exam"],
  components: {
    CheckExamQuestion,
  },
  data() {
    return {
      overlay: false,
      studentsDegree: [],
      rtlEnabled: true,
      showColumnLines: true,
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      overlay: false,
      degree: [],
      dataUpdata: [],
      pageSizes: [10, 15, 20],
      search: "",
      loadingData: false,
      StudentCount: 0,
      StudentEnteredCount: 0,
      StudentNotEnteredCount: 0,
      StudentFinishCount: 0,
      StudentNotFinishCount: 0,
      headers: [
        {
          text: this.$t("name"),
          value: "studentName",
          show: true,
        },
        {
          text: this.$t("degrees.theDegree"),
          value: "degree",
          show: true,
        },
        {
          text: this.$t("exam.questionsCount"),
          value: "questionsCount",
          show: true,
        },
        {
          text: this.$t("exam.questionsAnsweredCount"),
          value: "questionsAnsweredCount",
          show: true,
        },

        {
          text: this.$t("exam.enterDate"),
          value: "enterDate",
          show: true,
        },
        {
          text: this.$t("exam.endDate"),
          value: "endDate",
          show: true,
        },
        {
          text: this.$t("exam.ShowTheAnswer"),
          value: "studentStatus",
          show: true,
        },
      ],
    };
  },
  methods: {
    autoCorecte() {
      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            this.loadingData = true;
            this.axios
              .post(`Exams/AutoCorrectAnswers?ExamGuid=${this.examGuid}`, {})
              .then((res) => {
                if (res.data.statusCode == 1) {
                  this.$swal.fire({
                    title: this.$t("operationAccomplishedSuccessfully"),
                    text: "",
                    icon: "success",
                    confirmButtonText: this.$t("close"),
                    toast: true,
                    position: "bottom-start",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener(
                        "mouseenter",
                        this.$swal.stopTimer
                      );
                      toast.addEventListener(
                        "mouseleave",
                        this.$swal.resumeTimer
                      );
                    },
                  });
                  this.getStudentsDegree();
                } else if (res.data.statusCode == 2) {
                  this.$swal.fire({
                    title: this.$t(
                      "errors.YouCanNotAutoCorrectTheExamTheExamIsActive"
                    ),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                } else if (res.data.statusCode == 3) {
                  this.$swal.fire({
                    title: this.$t("errors.ExamNotFound"),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                } else {
                  this.$swal.fire({
                    title: this.$t(
                      "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
                    ),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                }
              })
              .catch((err) => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              })
              .finally(() => {
                this.loadingData = false;
              });
          }
        });
    },
    openDialog(item) {
      //   console.log(item)
      this.$refs.childComponent.setValue(item);
    },
    getStudentsDegree() {
      this.loadingData = true;
      // this.overlay = true;
      this.exportData = [];
      this.axios
        .get(`Exams/GetStudentsWithDegrees?ExamGuid=${this.examGuid}`)
        .then((res) => {
          this.studentsDegree = res.data.data;

          this.studentsDegree.forEach((e) => {
            e.questionsAnsweredCount =
              `${e.questionsAnsweredCount}/${e.questionsNotAnsweredCount}`;
          });

          this.StudentCount = res.data.data.length;
          this.StudentEnteredCount = res.data.data.filter(
            (m) => m.enterDate != null
          ).length;
          this.StudentNotEnteredCount = res.data.data.filter(
            (m) => m.enterDate == null
          ).length;
          this.StudentFinishCount = res.data.data.filter(
            (m) => m.endDate != null
          ).length;
          this.StudentNotFinishCount = res.data.data.filter(
            (m) => m.endDate == null
          ).length;
        })
        .catch((err) => {
          console.log(err);
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    exportToXlsx() {
      this.loadingData = true;

      this.axios({
        url: `Exams/GetStudentsWithDegreesXlsx?ExamGuid=${
          this.examGuid
        }&Lang=${localStorage.getItem("lang")}`,
        method: "GET",
        responseType: "arraybuffer",
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const filename = this.exam.title;
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("TheFileDoesNotExist"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    ResetEnteredPassword(studentGuid) {
      const data = {
        examGuid: this.examGuid,
        studentGuid: studentGuid,
      };

      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
      this.loadingData = true;
      this.axios
        .post(`Exams/ResetEnteredPassword`, data)
        .then((res) => {
          this.getStudentsDegree();
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.loadingData = false;
        });
          }
});
    },
  },
  created() {
    this.getStudentsDegree();
  },
};
</script>
<style lang="scss">
#txtRight {
  text-align: right !important;
}
.dx-datagrid .dx-row > td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
</style>
