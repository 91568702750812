<template>
  <v-dialog
    v-model="removeDialog"
    persistent
    width="600"
    max-width="100%"
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: dialog, attrs }">
      <!--
      <v-btn color="error" v-bind="attrs" v-on="dialog" icon>
          <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-icon v-on="tooltip"> mdi-delete </v-icon>
          </template>
          <span> {{ $t("portfolio.WorkSample.removeWorkSample") }} </span>
        </v-tooltip>
      </v-btn>
    -->
      <v-list-item v-bind="attrs" v-on="dialog">
        <v-list-item-title>
          {{ $t("portfolio.WorkSample.removeWorkSample") }}
        </v-list-item-title>
        <v-list-item-icon>
          <v-icon> mdi-delete </v-icon>
        </v-list-item-icon>
      </v-list-item>
    </template>
    <v-card :loading="loading" :disabled="loading">
      <v-overlay absolute :value="overlay">
        <!-- <v-btn color="success" @click="overlay = false"> Hide Overlay </v-btn> -->
        <v-card color="error" dark width="400" max-width="100%" class="pa-3 mb-5">
          <v-card-title>
            {{ $t("Processing") }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0 mt-2"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
      </v-overlay>
      <v-toolbar dark color="error">
        <v-btn icon dark @click="removeDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ $t("portfolio.WorkSample.removeWorkSample") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <!-- <v-btn dark text @click="removeDialog = false"> {{ $t("save") }} </v-btn> -->
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text v-if="title" class="pa-5">
        <h2>
          <p>
            {{ $t("portfolio.WorkSample.confirmRemoveWorkSample").split("{title}")[0] }}
            <b class="error--text">{{ title }}</b>
          </p>
        </h2>
        <!-- {{ id }} -->
        <v-card-actions>
          <v-btn class="ma-2" color="error" @click="deleteWorkSample(id)">
            {{ $t("ok") }}
          </v-btn>
          <v-btn
            plain
            class="ma-2 font-weight-black"
            color="primary"
            @click="removeDialog = false"
          >
            {{ $t("cancel") }}</v-btn
          >
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["id", "title"],

  data() {
    return {
      removeDialog: false,
      loading: false,
      overlay: false,
    };
  },
  methods: {
    async deleteWorkSample(id) {
      this.loading = true;
      this.overlay = true;
      const data = {
        workSampleGuid: id,
      };
      await this.axios
        .post("WorkSample/Delete", data)
        .then(() => {
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          this.loading = false;
          this.removeDialog = false;
          this.overlay = false;
          // this.reset();
          this.$emit("deleteMethod");
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          this.loading = false;
          // this.removeDialog = false;
          this.overlay = false;
        });
      // setTimeout(() => {
      //   this.$swal.fire({
      //     title: this.$t("operationAccomplishedSuccessfully"),
      //     text: "",
      //     icon: "success",
      //     confirmButtonText: this.$t("close"),
      //     toast: true,
      //     position: "bottom-start",
      //     showConfirmButton: false,
      //     timer: 5000,
      //     timerProgressBar: true,
      //     didOpen: (toast) => {
      //       toast.addEventListener("mouseenter", this.$swal.stopTimer);
      //       toast.addEventListener("mouseleave", this.$swal.resumeTimer);
      //     },
      //   });
      //   this.loading = false;
      //   this.overlay = false;
      // }, 3000);
    },
  },
};
</script>
<style></style>
